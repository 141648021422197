import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactClickOutside from 'react-click-outside';
import classNames from 'classnames';

import SuggestionList from './SuggestionList';

class InputSuggestionBox extends Component {
    constructor(props) {
        super(props);

        this.element = null;

        this.state = {
            showSuggestions: false,
        };
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            if (this.element) {
                this.element.focus();
            }
        }
    }

    getSuggestions() {
        const { value, disableAddNew } = this.props;
        const { showSuggestions } = this.state;

        if (showSuggestions !== true) {
            return null;
        }

        const filteredOptions = this.filterOptions().slice(0, 8);
        let optionsWithCreate = filteredOptions.slice();

        //sort options alphabetically
        optionsWithCreate.sort((itemA, itemB) => {
            const nameA = itemA.name.trim().toLowerCase();
            const nameB = itemB.name.trim().toLowerCase();
            if (nameA > nameB) {
                return 1;
            }
            if (nameA < nameB) {
                return -1;
            }
            return 0;
        });

        const unavailableMatch = this.findUnavailableMatchingOption();
        const match = this.findMatchingOption();

        if (unavailableMatch === null && match === null && value.trim().length > 0 && disableAddNew !== true) {
            const optionCreateNew = {
                value: null,
                name: `+  ${value}`,
            };

            // show "add new" at top of list
            optionsWithCreate = [optionCreateNew].concat(optionsWithCreate);
        }

        return (
            <SuggestionList
                tags={optionsWithCreate}
                onItemClick={option => this.handleChoice(option)}
            />
        );
    }

    getUnavailableWarning() {
        const { showSuggestions } = this.state;

        if (showSuggestions !== true) {
            return null;
        }

        const unavailableMatch = this.findUnavailableMatchingOption();

        if (unavailableMatch === null) {
            return null;
        }

        return (
            <ul className="suggestion-list unavailable-suggestions">
                <li>
                    <div
                        className="tag"
                    >
                        {
                            this.props.unavailableHintText || 'Line already in use'
                        }
                    </div>
                </li>
            </ul>
        );
    }

    getNoSuggestionWarning() {
        const { showSuggestions } = this.state;

        if (showSuggestions !== true) {
            return null;
        }

        const { disableAddNew } = this.props;

        const suggestions = this.filterOptions();

        if (suggestions.length === 0 && disableAddNew) {
            return (
                <ul className="suggestion-list unavailable-suggestions">
                    <li>
                        <div
                            className="tag"
                        >
                            No suggestions
                        </div>
                    </li>
                </ul>
            );
        }

        return null;
    }

    handleFocus() {
        this.setState({
            showSuggestions: true,
        });
    }

    handleClickOutside() {
        this.setState({
            showSuggestions: false,
        });
    }

    handleChoice(selectedOption) {
        if (this.findUnavailableMatchingOption() !== null) {
            return;
        }
        const { handleSelect, value, disableAddNew } = this.props;
        this.handleClickOutside();

        const option = selectedOption.value === null
            ? { value: null, name: value }
            : selectedOption;

        if (option.value !== null || !disableAddNew) {
            handleSelect(option);
        }
    }

    handleKeyUp(event) {
        if (event.keyCode === 13) {
            if (this.findUnavailableMatchingOption() !== null) {
                return;
            }

            this.element.blur();

            let matchedOption = this.findMatchingOption();

            if (matchedOption === null) {
                // "add new" option
                matchedOption = { value: null, name: this.props.value };
            }

            this.handleChoice(matchedOption);
        }
    }

    checkNameMatch(name1, name2) {
        const formattedName1 = this.cleanString(name1);
        const formattedName2 = this.cleanString(name2);

        return formattedName1 === formattedName2;
    }

    cleanString(str) {
        return str.toLowerCase().trim();
    }

    findMatchingOption() {
        const { options, value } = this.props;
        const match = options.find(option => this.checkNameMatch(option.name, value));

        if (typeof match === 'undefined') {
            return null;
        }
        return match;
    }

    findUnavailableMatchingOption() {
        const { unavailableOptions, value } = this.props;
        const match = unavailableOptions.find(option => this.checkNameMatch(option.name, value));

        if (typeof match === 'undefined') {
            return null;
        }
        return match;
    }

    filterOptions() {
        const { value, options } = this.props;
        const formattedInput = this.cleanString(value);

        return options.filter(option => {
            const name = this.cleanString(option.name);
            return name.indexOf(formattedInput) !== -1;
        });
    }

    render() {
        const { handleUpdate, value, placeholder } = this.props;

        return (
            <div className={classNames('forecast-dropdown', this.props.className)}>
                <div className="dropdown-container">
                    <input
                        ref={element => {
                            this.element = element;
                        }}
                        className="sorted-input"
                        value={value}
                        onFocus={() => this.handleFocus()}
                        onChange={(event) => handleUpdate(event.target.value)}
                        onKeyUp={(event) => this.handleKeyUp(event)}
                        placeholder={placeholder}
                    />
                    <div className="suggestion-list-container">
                        {this.getUnavailableWarning()}
                        {this.getNoSuggestionWarning()}
                        {this.getSuggestions()}
                    </div>
                </div>
            </div>
        );
    }
}

const optionsArrayType = PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
}));

InputSuggestionBox.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disableAddNew: PropTypes.bool,
    handleSelect: PropTypes.func,
    handleUpdate: PropTypes.func,
    options: optionsArrayType.isRequired,
    placeholder: PropTypes.string,
    unavailableHintText: PropTypes.string,
    unavailableOptions: optionsArrayType,
    value: PropTypes.string,
};

InputSuggestionBox.defaultProps = {
    unavailableOptions: [],
};

export default ReactClickOutside(InputSuggestionBox);
