"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SonCreateSubsectionPopup = void 0;
const react_1 = __importStar(require("react"));
const SonDataSection_1 = require("./SonDataSection");
function SonCreateSubsectionPopup(props) {
    const { onClose, getOnCreateSubsectionWarning, title } = props;
    const [newSubsectionName, setNewSubsectioName] = react_1.default.useState('');
    const [warning, setWarning] = react_1.default.useState(SonDataSection_1.SonSubsectionWarning.nothing);
    //to include the automatic focus in the input box for the group rename
    const searchInput = react_1.default.useRef(null);
    (0, react_1.useEffect)(() => {
        var _a;
        (_a = searchInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (react_1.default.createElement("div", { className: "flex-popup-container" },
        react_1.default.createElement("div", { className: "flex-popup create-subsection-popup" },
            react_1.default.createElement("div", { className: "popup-header" },
                react_1.default.createElement("h3", null,
                    " ",
                    title,
                    " "),
                react_1.default.createElement("div", null, " Enter New Group Name ")),
            warning !== SonDataSection_1.SonSubsectionWarning.nothing && (react_1.default.createElement("div", { className: "warning" },
                " ",
                warning,
                " ")),
            react_1.default.createElement("input", { type: "text", placeholder: "Enter New Group Name", className: "new-subsection text-cell small", onChange: change => {
                    setNewSubsectioName(change.target.value);
                    setWarning(SonDataSection_1.SonSubsectionWarning.nothing);
                } }),
            react_1.default.createElement("div", { className: "text-center enter-button" },
                react_1.default.createElement("button", { className: "btn btn-lg btn-action", onClick: () => setWarning(getOnCreateSubsectionWarning(newSubsectionName.trim())), disabled: newSubsectionName.trim() === '' }, " Create"),
                react_1.default.createElement("button", { className: "btn btn-lg btn-danger", onClick: () => {
                        setNewSubsectioName('');
                        onClose();
                    } }, " Cancel")))));
}
exports.SonCreateSubsectionPopup = SonCreateSubsectionPopup;
exports.default = SonCreateSubsectionPopup;
