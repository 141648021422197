// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
var DataStore = require('../datastore');
var FieldStore = DataStore.FieldStore;
var FieldMappingStore = DataStore.FieldMappingStore;
var FieldMapping = DataStore.FieldMapping;
var TagStore = DataStore.TagStore;
var TagCategoryStore = DataStore.TagCategoryStore;
var MonthStore = DataStore.MonthStore;
var AccountItemStore = DataStore.AccountItemStore;
var AccountItem = DataStore.AccountItem;
var AccountNumberTag = DataStore.AccountNumberTag;
var AccountNumberTagsStore = DataStore.AccountNumberTagsStore;
var Month = DataStore.Month;
var RevenueForecast = DataStore.RevenueForecast;

import Accordion from "./Accordion"
import RowStruct from "../models/RowStruct"
import Tag from "../models/SortedTag"
import Popup from "../components/Popup"
import TabMenu from "../components/TabMenu"
import PopupSplit from "./PopupSplit"
import PopupReTag from "./PopupReTag"
import Utils from "../utilities/Utils"
import { AccountItemQuery } from "../utilities/AccountItemQuery"
import { LedgerTypes,TagCategoryName } from "../models/Enums"
import DetailedAccordion from "../components/DetailedAccordion"
import Date from "../utilities/Date"
import SortedRow from "./SortedRow"
import { TagCode } from '../models/EnumsTs';

/**
 * @deprecated
 */
class ScreenSorted extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
//            editing: false,
            popupConfirmation: null,
            popupTagAccountItem: null,
            popupSplitAccountItem: null,
            loading:true,
        }
    }

    getTagCategories() {
        return TagCategoryStore.getTagCategories();
    }

    // getAccountItems() {
    //     return AccountItemStore.getState().accountItems;
    // }

    /**
     * What is a structured tag?
     *
     * @param ledgerType
     * @returns {*}
     */
    getStructuredTags(ledgerType) {
        return this.getTagCategories().filter(function (item) {
                return item.ledger_type == ledgerType;
            });
    }

    toggleEditing() {
        const { onEditingFinished } = this.props;


        var valid = true;
        if (this.state.editing) {
            valid = onEditingFinished(); //Forward to historical to check validity
        }

        if (valid) {
            this.setState({
                editing: !this.state.editing
            });
        }

//        if (this.state.editing) {
//            //let Historical deal with validation, etc.
//            onEditingFinished()
//                //wait for promise to return, then set editing state accordingly
//            .then(result => {
//                    console.log("promise result:",result);
//                    if (result) {
//                        this.setState({ editing:false });
//                    }
//                }
//            );
//        } else {
//            this.setState({
//                editing:true
//            });
//        }
    }

    onDismissConfirmationPopup() {
        this.setState({
            popupConfirmation: null
        });
    }

    onTagSelect(rowSourceIndex, tag) {

    }

    onPopupLedgerTypeBecameActive() {

    }

    getPopupNiceWork() {
        return <Popup>
            <div>
                <h1>Nice Work</h1>

                <p>Now you can view your Graphs and the Crystal Ball or you can continue with more Clean Up Steps from
                    our
                    Wizard</p>
                <button className="btn btn-lg btn-primary" onClick={() => {}}>OK</button>
            </div>
        </Popup>
    }

    /**
     * Find the root, get all the children, delete them. Delete all corresponding account items.
     * @param accountItem
     * @returns {Promise.<T>}
     */
    handleMergeSplit(accountItem) {
        // var rootAnt = AccountNumberTagsStore.getRootAccountNumberTag(accountItem.account_number);
        //
        // if (rootAnt == null) {
        //     return Promise.reject("Root account number tag is null.");
        // }
        //
        // var childrenToDelete = AccountNumberTagsStore.getChildren(rootAnt);
        // var accountNumbersToDelete = childrenToDelete.map(item => item.account_number);
        // var childrenToDeleteAccountItems = this.getAccountItems().filter(item => accountNumbersToDelete.indexOf(item.account_number) > -1);
        //
        // var antPromises = accountNumbersToDelete.map(num => AccountNumberTagsStore.performDeleteWithAccountNumber(num));
        // var accountItemPromises = childrenToDeleteAccountItems.map(item => AccountItemStore.performDeleteItem(item));
        //
        // return Promise.all(antPromises)
        //     .then(Promise.all(accountItemPromises))
        //     .then(this.setState({
        //         popupSplitAccountItem: null
        //     }))
        //     .catch(response => console.error("Problem with handleMergeSplit", response));
    }

    /**
     *
     * Get the parent_id of the item to be deleted = PID
     * Get all children for account item.
     * Change their parent_id to be PID, if PID is null, then the parent is no longer split and should be flagged as such.
     * Delete item.
     *
     * @param accountItem
     */
    handleDeleteSplit(accountItem) {
        // AccountNumberTagsStore
        //     .performDeleteWithAccountNumber(accountItem.account_number)
        //     .then(() => AccountItemStore.performDeleteItem(accountItem))
        //     .catch(response => {
        //
        //     });
    }

    getPopupSplit(accountItem) {
        // const { onChangeAccountItem } = this.props;
        // var accountItems = this.getAccountItems();
        // var tagCategories = this.getTagCategories();
        //
        // return <PopupSplit
        //     accountItems={accountItems}
        //     editing={true}
        //     onChangeAccountItem={accountItem => onChangeAccountItem(accountItem)}
        //     onDelete={item => this.handleDeleteSplit(item)}
        //     onMerge={() => this.handleMergeSplit(accountItem)}
        //     onOkPressed={() => this.setState({ popupSplitAccountItem: null })}
        //     onSplit={(item, rootTag) => this.handleSplit(item, rootTag)}
        //     selectedAccountItem={accountItem}
        //     tags={tagCategories}
        //     />;
    }

    /**
     * A split creates a new account number tag, that references the root account item tag.
     * It then creates an account item.
     *
     * @param accountItem
     * @returns {*}
     */
    handleSplit(accountItem) {
        // if (accountItem.tag_id == null) {
        //     console.error("Account item has no tag id. This is required.");
        //     return;
        // }
        //
        // let initialDate = Date.selectedMonth;
        // let monthId = MonthStore.state.month.id;
        //
        // var rootAnt = AccountNumberTagsStore.getRootAccountNumberTag(accountItem.account_number);
        // var generatedAccountNumber = Utils.generateUuid();
        //
        // // 1. Create a new account number tag
        // var newAccountNumberTag = new AccountNumberTag({
        //     account_number: generatedAccountNumber,
        //     tag_id: rootAnt.tag_id,
        //     split_root_id: rootAnt.id
        // });
        //
        // let newAccountItems = [];
        // let updatedAccountItems = [];
        //
        // // 2. Create a new account items
        // MonthStore.fetchMonths(Date.selectedMonth,Date.finalMonth)
        // .then(() => {
        //     MonthStore.state.months.forEach(month => {
        //         let parentItem = AccountItemQuery.number(month.account_items,rootAnt.account_number);
        //         var newValueFormula = isNaN(parseInt(parentItem.value_formula)) ? 50 : parseInt(parentItem.value_formula) / 2;
        //         var newAccountItem = new AccountItem({
        //             name: "Unnamed Split Item",
        //             pre_split_name: parentItem.name,
        //             account_number: generatedAccountNumber,
        //             value: parentItem.value * (newValueFormula / 100),
        //             value_formula: (month.is_forecast ? null : newValueFormula),
        //             month_id: parentItem.month_id,
        //             revenue_forecast: (month.is_forecast ? new RevenueForecast() : null)
        //         });
        //         newAccountItems.push(newAccountItem);
        //         // 3. Update the old value formula.
        //         parentItem.value_formula = newValueFormula;
        //         updatedAccountItems.push(parentItem);
        //     }
        //     )
        // })
        //
        // // 4. Save Account Item and AccountNumberTag
        // .then(() => AccountNumberTagsStore.performSave(newAccountNumberTag))
        // .then(() => AccountItemStore.performMultiBatchSave(updatedAccountItems))
        // .then(() => AccountItemStore.performMultiBatchSave(newAccountItems))
        // .then(() => AccountNumberTagsStore.fetchData())
        // .then(() => AccountItemStore.fetchAccountItemsForMonth(monthId))
        // .then(() => MonthStore.fetchMonth(initialDate))
        // //.then(AccountNumberTagsStore.fetchData)
        // .catch(error => {
        //     console.error("Something went wrong in ScreenSorted > handleSplit", error);
        // });
    }

    getPopupConfirmation(content, onOk, onCancel) {
        var okButton = onOk != null ?
            <button
                className="btn btn-lg btn-primary marginRBtn"
                onClick={() => onOk()}>OK</button> : null;

        var cancelButton = onCancel != null ?
            <button
                className="btn btn-lg btn-danger"
                onClick={() => onCancel()}>Cancel</button> : null;

        return <Popup>
            <div>
                {content}
                {okButton}
                {cancelButton}
            </div>
        </Popup>
    }

    dismissReTagPopup() {
        this.setState({
            popupTagAccountItem: null
        });
    }

    getPopupSelectANewTag(accountItem) {
        return <PopupReTag accountItem={accountItem}
                           onTagSelected={(accountItem, tag) => this.onTagSelected(accountItem, tag)}
                           onDismissPopup={() => this.dismissReTagPopup()}/>;
    }

    onTagSelected(accountItem, selectedTag) {
        // Only child tags trigger changes.
        if (selectedTag.tags == null) {
            accountItem.tag_id = selectedTag.id;

            var ant = AccountNumberTagsStore.getAccountNumberTag(accountItem.account_number);
            ant.tag_id = selectedTag.id;

            AccountItemStore
                .performSave(accountItem)
                .then(() => AccountNumberTagsStore.performSave(ant));
        }
    }

    onClickReTag(accountItem) {
        this.setState({
            popupTagAccountItem: accountItem
        });
    }

    onClickSplit(accountItem) {
        this.setState({
            popupSplitAccountItem: accountItem
        });
    }

    onClickRemoveRow(accountItem) {
        var onOkPressed = () => {
            this.onDismissConfirmationPopup();
            this.onDelete(accountItem);
        };

        var popupConfirmation = this.getPopupConfirmation(<div>
            <h1>Are you sure you want to remove this item?</h1>
        </div>, onOkPressed, () => this.onDismissConfirmationPopup());

        this.setState({
            popupConfirmation: popupConfirmation
        });
    }

    onLabelChange(accountItem, newLabel) {
        accountItem.name = newLabel;
        this.props.onChangeAccountItem(accountItem);
    }

    onTagChange(accountItem, newTag) {
        accountItem.tag_id = newTag.id;
        this.props.onChangeAccountItem(accountItem);
    }

    onDelete(accountItem) {
        this.props.onDeleteAccountItem(accountItem);
    }

    onCreate(tag) {
        var newAccountItem = AccountItem.newAccountItem("New item", tag.id, this.props.month.id);

        this.props.onCreateAccountItem(newAccountItem);
    }

    getIncomeStatementTagCategories() {
        return this.getStructuredTags("Income Statement");
    }

    getBalanceSheetTagCategories() {
        return this.getStructuredTags("Balance Sheet");
    }

    getAccordionForTagCategory(tagCategory) {
//        const { editing } = this.state;
        const { onChangeAccountItem, inputType, month, onAddAccountItem, parentAccountItems, total, getTotalForID, getDifferenceForID, onTotalEditingFinished, editing} = this.props;


        return <Accordion
                    key={tagCategory.id}
                    category={tagCategory}
                    editing={editing}
                    onChangeAccountItem={onChangeAccountItem}
                    onRemoveRow={accountItem => this.onClickRemoveRow(accountItem)}
                    onReTag={accountItem => this.onClickReTag(accountItem)}
                    onSplit={accountItem => this.onClickSplit(accountItem)}
                    inputType={inputType}
                    month={month}
                    onAddAccountItem={onAddAccountItem}
                    parentAccountItems={parentAccountItems}
                    total={total}
                    getTotalForID={getTotalForID}
                    getDifferenceForID={getDifferenceForID}
                    onTotalEditingFinished={onTotalEditingFinished}>
                </Accordion>
    }

    componentDidMount() {
        this.setState({
            loading: false,
        });
    }


    getAccordions(ledgerType) {
        const { onChangeAccountItem, inputType, month, onAddAccountItem, parentAccountItems, total, getDifferenceForID, onTotalEditingFinished, editing } = this.props;

        var categories = ledgerType == LedgerTypes.BalanceSheet ? this.getBalanceSheetTagCategories() : this.getIncomeStatementTagCategories();

        //Load the extra rows here... if income statement, add total rows
        // check to see if revenue
        //check to see if misc

        //TODO: Remove the map, iterate and add the necessary extra items.
        var accordions = [];
        for (var i in categories) {
            var tagCategory = categories[i];
            accordions.push(this.getAccordionForTagCategory(tagCategory));

            if (ledgerType == LedgerTypes.IncomeStatement) {
                //Place in calculated rows where needed:
                if (i == 1) {
                    accordions.push(this.getContributionRows());
                }
                //Profit Rows
                if (i == 3) {
                    accordions.push(this.getProfitRows());
                }

                if (i == 4) {
                    accordions.push(this.getAdjustmentsRow());
                    accordions.push(this.getChangeInRetainedEarningsRow());
                }
            } else {
                if (i == 1) {
                    accordions.push(this.getAssetsLessLiabilitiesRow());
                }
            }
        }
        return accordions;
    }


    getPopup() {
        const { popupConfirmation, popupTagAccountItem, popupSplitAccountItem } = this.state;

        var popup = null;

        if (popupConfirmation) {
            popup = popupConfirmation;
        }

        if (popupTagAccountItem) {
            popup = this.getPopupSelectANewTag(popupTagAccountItem);
        }

        if (popupSplitAccountItem) {
            popup = this.getPopupSplit(popupSplitAccountItem);
        }

        return popup;
    }

    getEditButton() {
        //edit button is now shown outside this component
        return null;
//        const { editing } = this.state;
//         const { editing, onToggleEditing } = this.props;
//
//         return <div className="col-xs-4 text-right marginBothBtn">
//             <button className="btn btn-primary"
//                     onClick={() => onToggleEditing()}>
//                 {editing ? "Done" : "Edit"}
//             </button>
//         </div>;
    }

    getContributionRows() {
        const { getTotalForID } = this.props;

        // contribution margin = total_revenue + total_direct_costs
        // contribution margin % = direct_costs/total_revenue

        var totalRevenue = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Revenue).id); // 1
        var totalDirectCosts = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.DirectCosts).id); //2
        var contributionMargin = Utils.removePrecisionAndGetCommas(totalRevenue - totalDirectCosts);
        var contributionMarginPercentage = Math.round((totalRevenue - totalDirectCosts)/totalRevenue * 100);
        if (isNaN(contributionMarginPercentage)) {
          contributionMarginPercentage = 0;
        }
        
        //Add the contribution margin fields
        return <div>
                    <DetailedAccordion heading={"Contribution Margin Dollars"} value2={"$" + contributionMargin}/>
                    <DetailedAccordion heading={"Contribution Margin Percent"} value2={contributionMarginPercentage + "%"}/>
                </div>
    }

    //return the calculated rows for profit
    getProfitRows() {
        const { month, getTotalForID, editing } = this.props;

        var profit;
        //TODO: find better calculations...
        //Profit = total_revenue - total_direct_costs - total_indirect_costs + total_misc

        var totalRevenue = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Revenue).id); //1
        var totalDirectCosts = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.DirectCosts).id); //2
        var totalIndirectCosts = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.IndirectCosts).id); //3
        var totalMisc = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Misc).id); //4

        //use the tagged if it exists, or calculate it
        let netProfitTag = TagCategoryStore.getTagForCode(TagCode.IS_NetProfit);

        var wah = AccountNumberTagsStore.getAccountNumberTags().filter(ant => ant.tag_id == netProfitTag.id);   // Array of account number tags

        var netProfitAccountItems = wah.map(ant => month.AccountItems.filter(item => item.account_number == ant.account_number).shift());

        var netProfitItem = netProfitAccountItems.filter(item => typeof item !== "undefined").shift();

        if (typeof netProfitItem !== "undefined") {
            profit = parseFloat(netProfitItem.value)
        } else {
            profit = totalRevenue - totalDirectCosts - totalIndirectCosts + totalMisc;
        }
        //console.log(profit);

        var profitPercentage = Math.floor(profit/totalRevenue * 100);

        //console.log(netProfitItem);

        var profitAccordian = <div></div>;
        if (editing) {
            profitAccordian =   <div className="category">
                                    <SortedRow
                                    className="profit-dollars"
                                    key={netProfitItem.id}
                                    title={"Net Profit"}
                                    amount={netProfitItem.value}
                                    editing={editing}
                                    accountItem={netProfitItem}
                                    onAmountChange={value => this.updateProfitItem(netProfitItem,value)}
                                    isParent={false}
                                    />
                                </div>
        } else {
            profitAccordian = <DetailedAccordion
                                className="profit-dollars"
                                heading={"Net Profit"}
                                value2={"$" + Utils.removePrecisionAndGetCommas(profit)}

                            />
        }
        return <div className="profit">
                    {profitAccordian}
                    <DetailedAccordion
                        className="profit-percent"
                        heading={"Profit Percent"}
                        value2={isNaN(profitPercentage) ? "0%" : profitPercentage + "%"}
                    />
                </div>
    }

    updateProfitItem(netProfitAccountItem,newValue) {
        const { onChangeAccountItem } = this.props;
        netProfitAccountItem.value = Utils.parseMoney(newValue);
        onChangeAccountItem(netProfitAccountItem);
    }

    getChangeInRetainedEarningsRow() {
        const { getTotalForID } = this.props;

        //Change in Retained Earnings = net profit - distributions
        var totalRevenue = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Revenue).id); //1
        var totalDirectCosts = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.DirectCosts).id); //2
        var totalIndirectCosts = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.IndirectCosts).id); //3
        var totalMisc = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Misc).id); //4

        var profit = totalRevenue - totalDirectCosts - totalIndirectCosts + totalMisc;
        var distributions = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.Distributions).id);
        var changeAndRetainedEarnings = profit - distributions;


        return <div>
                    <DetailedAccordion heading={"Change in Retained Earnings"} value2={"$" + Utils.removePrecisionAndGetCommas(changeAndRetainedEarnings)}/>
               </div>
    }

    getAssetsLessLiabilitiesRow() {
        const { getTotalForID } = this.props;

        var totalAssets = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.TotalAssets).id);
        var totalLiabilities = getTotalForID(TagCategoryStore.getTagCategoryByName(TagCategoryName.TotalLiabilities).id);

        return <div>
                    <DetailedAccordion heading={"Assets Less Liabilities"} value2={"$" + Utils.removePrecisionAndGetCommas(totalAssets - totalLiabilities)}/>
               </div>
    }

    getAdjustmentsRow() {
        //TODO: figure out how to calculate adjustments
        const { getAdjustmentsValue} = this.props;
        var adjustments = getAdjustmentsValue();

        //Add the change in retained earnings and adjustments fields.
        return  <div>
                    <DetailedAccordion className={"important"} heading={"To be allocated"} value2={"$" + Utils.removePrecisionAndGetCommas(adjustments)}/>
                </div>
    }

    render() {
//        if (this.state.loading || this.props.month.account_items.length == 0)
//            return null;


        return (
            <div className="sorted row">
                {this.getPopup()}
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-8">
                            <h1>Income Statement</h1>
                        </div>
                        {this.getEditButton()}
                    </div>
                </div>

                <div className="col-xs-12">
                {this.getAccordions(LedgerTypes.IncomeStatement)}
                </div>

                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-8">
                            <h1>Balance Sheet</h1>
                        </div>
                        {this.getEditButton()}
                    </div>
                </div>
                <div className="col-xs-12">
                    {this.getAccordions(LedgerTypes.BalanceSheet)}
                </div>
            </div>
        );
    }
}

ScreenSorted.propTypes = {
    month: PropTypes.instanceOf(Month).isRequired,
    columns: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    ledgerTypes: PropTypes.array.isRequired,
    onChangeAccountItem: PropTypes.func.isRequired,
    onDeleteAccountItem: PropTypes.func.isRequired,
    onCreateAccountItem: PropTypes.func.isRequired,
    inputType: PropTypes.string.isRequired,
    onAddAccountItem: PropTypes.func.isRequired,
    parentAccountItems: PropTypes.func,
    total: PropTypes.func,
    getDifferenceForID: PropTypes.func,
    getTotalForID: PropTypes.func,
    onTotalEditingFinished: PropTypes.func, 
    getAdjustmentsValue: PropTypes.func,
    editing: PropTypes.bool,
    onToggleEditing: PropTypes.func,
};

ScreenSorted.defaultProps = {
    editing: true
};

export default ScreenSorted;