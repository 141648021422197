"use strict";
function getQueryParams(qs) {
    qs = qs.split('+').join(' ');
    const params = {};
    const re = /[?&]?([^=]+)=([^&]*)/g;
    let tokens;
    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}
module.exports = getQueryParams;
