class Tag {
    constructor(tag, tags, accountItems) {
        /**
         * This is a Tag Category OR Tag
         * If this has children, it's a tag category.
         * If this is null, this is a child tag.
         */
        this.tag = tag;
        this.tags = tags;
        this.accountItems = accountItems;
    }

    isCategory() {
        return this.tags != null;
    }

    isTag() {
        return this.tags == null;
    }

    getChildTags() {
        return this.tags;
    }
}

export default Tag;