/* eslint-disable */
'use strict'

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Formatter from '../Formatter.ts';
import classNames from 'classnames';

class MonthSelector extends React.Component {
    /**
     * 
     * @param {number} direction 
     */
    onClick(direction) {
        const newDate = this.offsetDate(moment(this.props.date), direction);
        this.props.onDateChanged(newDate);
        if (this.props.onSelectorIndexChanged) {
            this.props.onSelectorIndexChanged(direction);
        }
    }

    offsetDate(date, direction) {
        if (direction > 0) {
            date.add(1, 'months');
        } else if (direction < 0) {
            date.subtract(1, 'months');
        }

        return date;
    }

    render() {
        const { date, showPrevious, forwardEnabled, backEnabled, alignment, monthRange, forwardTooltip, backwardTooltip, forwardPlus} = this.props;
        
        let currentMonth = date.format(Formatter.shortMonthFullYearDisplayFormat);
        let formattedDate = currentMonth;
       
        if (showPrevious) {
            currentMonth = date.format(Formatter.shortMonthOnlyDisplayFormat);
            const prevMonth = this.offsetDate(moment(date), -1).format(Formatter.shortMonthOnlyDisplayFormat);
            formattedDate = `${prevMonth} / ${currentMonth}`;
        } else if (monthRange) {
            currentMonth = date.format(Formatter.shortMonthOnlyDisplayFormat);
            const prevMonth = moment(date).subtract(monthRange - 1, 'months').format(Formatter.shortMonthOnlyDisplayFormat);
            formattedDate = `${prevMonth} - ${currentMonth}`;
        }

        const onForwardClick = forwardEnabled ? () => this.onClick(1) : null;
        const onBackClick = backEnabled ? () => this.onClick(-1) : null;

        let alignClass;
        switch (alignment) {
        case MonthSelector.Alignment.Left:
            alignClass = ' pull-left';
            break;
        case MonthSelector.Alignment.Right:
            alignClass = ' pull-right';
            break;
        case MonthSelector.Alignment.Center:
            alignClass = ' month-selector-center';
            break;
        case MonthSelector.Alignment.Inline:
        default:
            alignClass = '';
        }

        return (
            <div className={'month-selector noselect' + alignClass}>
                <button className="btn btn-primary back blue-disabled" disabled={backEnabled === false} onClick={onBackClick}>
                    <span className="glyphicon glyphicon-triangle-left"></span>
                    {backwardTooltip && backEnabled && (
                        <div className="tooltip-text"> {backwardTooltip} </div> 
                    )}
                </button>

                <span className="date-title">{formattedDate}</span>
            
                <button 
                    className="btn btn-primary forward blue-disabled" 
                    disabled={forwardEnabled === false} 
                    onClick={onForwardClick}
                >
                    <span 
                        className={
                            classNames(
                                "glyphicon", 
                                forwardPlus ? "glyphicon-plus" : "glyphicon-triangle-right",
                            )
                        }
                    >
                    </span>
                    {forwardTooltip && forwardEnabled && (
                        <div className="tooltip-text"> {forwardTooltip} </div> 
                    )}
                </button>
             </div>
        );
    }
}

MonthSelector.propTypes = {
    alignment: PropTypes.string,
    backEnabled: PropTypes.bool,
    date: PropTypes.object.isRequired,
    forwardEnabled: PropTypes.bool,
    forwardPlus: PropTypes.bool,
    monthRange: PropTypes.number,
    onDateChanged: PropTypes.func.isRequired,
    onSelectorIndexChanged: PropTypes.func,
    showPrevious: PropTypes.bool,
};

MonthSelector.Alignment = {
    Left: 'left',
    Right: 'right',
    Center: 'center',
    Inline: 'inline',
};

MonthSelector.defaultProps = {
    forwardEnabled: true,
    backEnabled: true,
};

export default MonthSelector;