/** @ts-check */
var fetch = require('isomorphic-fetch');
var Queue = require('queue');
var queue = Queue({
    concurrency: 1
});
var AsyncQueueStore = require("../stores/AsyncQueueStore");
let sha1 = require('sha1');

/**
 * @readonly
 * @enum {string}
 */
var Method = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
};

/**
 *
 * @readonly
 * @enum {string}
 */
var EndPoints = {
    Auth: 'auth',
    Month: 'month',
    AccountItem: 'account_item',
    Tag: 'tag',
    TagCategory: 'tag_category',
    Field: 'field',
    FieldMapping: 'field_mapping',
    AccountNumberTag: 'account_number_tag',
    AccountNumberTagBatch: 'account_number_tag/batch',
    AccountNumberTagDisable: 'account_number_tag/disable',
    ThreeW: 'threew',
    ThreeWTag: 'threewtag',
    SonQuestion: 'sonquestion',
    SonAnswer: 'sonanswer',
    AccountItemsForMonth: 'account_item/month',
    MonthAccountItems: 'month_account_items',
    MonthBatch: 'month/batch',
    MonthBatchWithAccountItems: 'month/with_items',
    AccountItemBatch: 'account_item/batch',
    CrystalBall: 'crystal_ball',
    Account: 'account',
    AccountUser: 'account/user',
    AccountUserResendInvite: (id) => `account/user/${id}/resend_invitation`,
    AccountUserRoles: (id) => `account/user/${id}/roles`,
    Subscription: 'subscription',
    SubscriptionPrice: 'subscription/current_default_plan',
    Coupon: 'subscription/coupon',
    User: 'user',
    UserPassword: 'user/password',
    UserChangeEmail: 'user/email_address/change',
    UserPermissions: 'user/permissions',
    UserFirebaseToken: 'user/firebase_token',
    AccountNumberGroup: 'account_number_group',
    AccountNumberGroupBatch: 'account_number_group/batch',
    AccountNumberGroupBatchWithAccountItems: 'account_number_group/with_account_items',
    AccountGroupCode: code => `account/group_code/${encodeURIComponent(code)}`,
    AccountLinkedAccounts: 'account/linked_accounts',
    AccountLinkedAccountsMonths: accountId => `account/linked_accounts/${accountId}/months`,
    AccountLinkedAccountsAccountNumberTags: accountId => `account/linked_accounts/${accountId}/account_number_tags`,
    AccountLinkedAccountsLogin: accountId => `account/linked_accounts/${accountId}/login`,
    Note: 'note',
    MonthSnapshot: 'month_snapshot',
};

class Cache {
    constructor() {
        this.cache = {};
    }

    getCache(id, clearCache, clearAllCache) {
        if (clearAllCache === true) {
            this.cache = {};
        }

        if (clearCache === true) {
            delete this.cache[id];
        }

        if (this.cache[id] == null) {
            return Promise.reject(id);
        }

        let result = this.cache[id];
        return Promise.resolve(result);
    }

    setCache(id, response) {
        this.cache[id] = response;
    }
}

let cache = new Cache();

class Ajax {
    constructor(root, appName, version) {
        this.root = root;
        this.appName = appName;
        // this.version = version; // Not used yet
        this.token = null;
    }

    queueLength() {
        return queue.length;
    }

    /**
     *
     * @param {AuthTokenResponse} authTokenResponse
     * @returns {AuthTokenResponse}
     */
    setAuthToken(authTokenResponse) {
        if (authTokenResponse.token != null) {
            this.token = authTokenResponse.token;
            console.info("Auth Token", authTokenResponse.token);
        }

        return authTokenResponse;
    }

    /**
     *  Returns a header object with an authorization token attached.
     *
     * @returns {{Content-Type: string, Authorization: string}}
     */
    authHeader() {
        var header = {
            "Content-Type": "application/json"
        };

        if (this.token != null) {
            header['Authorization'] = `Bearer ${this.token}`;
        }

        return header;
    }

    isLoggedIn() {
        return this.token != null;
    }

    /**
     *
     * Logs to the console with the result.
     *
     * @param {string} title
     * @param {*} result
     */
    log(title, result) {
        console.log(title, result);
    }

    /**
     *
     * URI Encodes an object
     *
     * @param {Object|array} obj
     * @returns {string}
     */
    static serializeObject(obj) {
        var str = [];

        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }

        return str.join("&");
    }

    /**
     *
     * @param {Array} paths - Array of strings
     * @returns {string} Path - path/made/from/items
     */
    static pathsToPath(paths) {
        return paths.join('/');
    }

    /**
     *
     * Makes AJAX call and returns a promise of the result.
     *
     * @param {string[]} paths
     * @param {Method} method
     * @param {Object=} params
     * @param {{ dontUseCache?: boolean }} options
     * @returns {Promise}
     */
    request(paths, method, params, options) {
        var suffixPath = Ajax.pathsToPath(paths);
        var url = Ajax.pathsToPath([this.root, this.appName, suffixPath]);
        var headers = this.authHeader();
        
        var requestOptions = {
            method: method,
            headers: headers
        };

        if (params != null) {
            if (method === Method.POST || method === Method.PUT) {
                requestOptions.body = JSON.stringify(params);
            }

            if (method === Method.GET && typeof params == "object") {
                url += '?' + Ajax.serializeObject(params);
            }
        }

        let cacheId = sha1(url + (typeof requestOptions.body == 'string' ? requestOptions.body : ''));

        const getDataFromDataBase = () => {
            return fetch(url, requestOptions)
              .then(response => {
                  if (response.status >= 200 && response.status < 300) {
                      return response.json()
                  }

                  throw response;
              })
        };

        const getDataFromCache = () => {
            let clearCacheForThisId = false;
            let clearAllCache = method == Method.POST || method == Method.PUT || method == Method.DELETE;

            return cache.getCache(cacheId, clearCacheForThisId, clearAllCache)
              .catch(err => getDataFromDataBase(url, requestOptions));
        };

        const getData = () => {
            if (typeof options === 'object' && options.dontUseCache) {
                return getDataFromDataBase();
            }
            return getDataFromCache();
        };

        return getData()
            .then(json => {
                if (paths[0] == EndPoints.Auth) {
                    return this.setAuthToken(json);
                }

                cache.setCache(cacheId, JSON.parse(JSON.stringify(json)));

                return json;
            })
            .catch(this.handleSessionExpiredResponse);
    }

    /**
     * handles Session Expired error and kicks user out
     * re-throws other errors
     * @param {Response} response 
     */
    handleSessionExpiredResponse(response) {
        if (response && typeof response.json === 'function') {
            return response.json()
                .then(payload => {
                    try {
                        console.error(response.url, response.status, payload)
                    } catch (err) {}
                    if (response.status === 401 && payload.message === 'Session Expired') {
                        window.location.href = '/sessionexpired';
                    }
                })
                .then(() => {
                    throw response
                })
        }
        throw response
    }

    /**
     * @param {String[]} paths
     * @param {Method} method
     * @param {Record<any>} params
     * @param {{ dontUseCache?: boolean }} options
     * @return {Promise<any>}
     */
    queue(paths, method, params, options) {
        return new Promise((resolve, reject) => {
            /**
             * NOTE: There is a bug in alt where a previous dispatch hasn't finished before the next one
             * (the below) executes, causing this to throw an invariant error.
             */
            setTimeout(() => {
                const httpRequest = done => {
                    AsyncQueueStore.queue()

                    this
                        .request(paths, method, params, options)
                        .then(resolve)
                        .catch(reject)
                        .finally(done)
                };

                queue.push(httpRequest);
                queue.start(function (err) {
                    AsyncQueueStore.finished();
                });
            }, 0)
        });
    }

    requestNoQueue(paths, method, params) {
        return new Promise((resolve, reject) => {
            return this
            .request(paths, method, params)
            .then(response => {
                resolve(response);
            })
            .catch(response => {
                reject(response);
            });
        });
    }

    /**
     *
     * Authenticates the user and returns an authorization token.
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise} Returns a promise with the Authorization token
     */
    login(email, password) {
        return this.queue([EndPoints.Auth], Method.POST, {email: email, password: password});
    }

    useToken(token) {
        if (token == null) {
            return Promise.reject("Invalid token");
        }

        this.token = token;

        // Test it
        return this.getAccount()
            .catch(response => {
                this.token = null;
                throw response;
            });
    }

    accountNumberTags() {
        return this.queue([EndPoints.AccountNumberTag], Method.GET);
    }

    saveAccountNumberTag(accountNumberTag) {

        if (accountNumberTag.id == null) {
            return this.queue([EndPoints.AccountNumberTag], Method.POST, accountNumberTag);
        } else {
            return this.queue([EndPoints.AccountNumberTag, accountNumberTag.account_number], Method.PUT, accountNumberTag);
        }
    }

    batchSaveAccountNumberTag(accountNumberTags) {
        if (!Array.isArray(accountNumberTags)) {
            throw new Error(`expected AccountNumberTag[], got', ${accountNumberTags}`)
        }
        return this.queue([EndPoints.AccountNumberTagBatch], Method.POST, { list: accountNumberTags });
    }

    disableAccountNumberTag(accountNumber) {
        return this.queue([EndPoints.AccountNumberTagDisable, accountNumber], Method.PUT);
    }

    deleteAccountNumberTag(accountNumber) {
        return this.queue([EndPoints.AccountNumberTag, accountNumber], Method.DELETE);
    }

    tagCategories() {
        return this.queue([EndPoints.TagCategory], Method.GET);
    }

    getAllMonths() {
        var params = {
        };

        return this.queue([EndPoints.Month], Method.GET, params)
            .catch(error => {
                throw error;
            });
    }

    getMonthsRange(start, end) {
        var params = {
            start_date: start,
            end_date: end
        };

        return this.queue([EndPoints.Month], Method.GET, params)
            .catch(error => {
                if (error.status == 404) {
                    if (params.start_date == params.end_date) {
                        // Create a new month, event listener requires an array of months back, so we intercept and wrap in an array.
                        return this
                            .saveMonth({
                                date: params.start_date,
                                is_forecast: null
                            })
                            .then(month => [month]);
                    }
                }

                throw error;
            });
    }

    queryMonthsRange(start, end) {
        var params = {
            start_date: start,
            end_date: end
        };

        return this.queue([EndPoints.Month], Method.GET, params)
            .catch(error => {
                throw error;
            });
    }

    getMonthLastHistoric() {
        return this.queue([EndPoints.Month], Method.GET, {historic: true, last: true});
    }

    getHistoricMonths(startDate, endDate) {
        const payload = {
            //start_date: startDate,
            //end_date: endDate,
            historic: true
        };
        if (startDate || endDate) {
            payload.start_date = startDate;
            payload.end_date = endDate;
        }
        return this.queue([EndPoints.Month], Method.GET, payload);
    }

    getForecastMonths(startDate, endDate) {
        const data = { forecast: true };
        if (startDate) {
            data.start_date = startDate;
        }
        if (endDate) {
            data.end_date = endDate;
        }

        return this.queue([EndPoints.Month], Method.GET, data);
    }

    saveMonths(months) {
        var method = Method.PUT;

        if (months.length > 0) {
            if (months[0].id == null) {
                method = Method.POST;
            }
        } else {
            console.error("No months to save.");
            return Promise.resolve([]);
        }

        return this.queue([EndPoints.MonthBatch], method, {list: months});
    }

    createOrUpdateMonths(months) {

        if (months.length === 0) {
            console.error("No months to save.");
            return Promise.resolve([]);
        }

        return this.queue([EndPoints.MonthBatchWithAccountItems], Method.PUT, {list: months});
    }

    saveMonth(month) {
        if (month.id == null) {
            return this.queue([EndPoints.Month], Method.POST, month);
        } else {
            return this.queue([EndPoints.Month, month.id], Method.PUT, month);
        }
    }

    deleteMonths() {
        return this.queue([EndPoints.Month], Method.DELETE);
    }

    deleteMonth(monthId) {
        return this.queue([EndPoints.Month, monthId], Method.DELETE);
    }

    wipeMonth(monthId) {
        return this.queue([EndPoints.AccountItemsForMonth, monthId], Method.DELETE);
    }

    getTag(tagId) {
        return this.queue([EndPoints.Tag, tagId], Method.GET);
    }

    getFields() {
        return this.queue([EndPoints.Field], Method.GET);
    }

    getFieldMapping(fieldId) {
        return this.queue([EndPoints.FieldMapping, fieldId], Method.GET);
    }

    saveFieldMapping(fieldMapping) {
        return this.getFieldMapping(fieldMapping.field_id)
            .catch(response => {
                if (response.status === 404) {
                    //console.log("saveFieldMapping CREATING", response);
                    return this.queue([EndPoints.FieldMapping], Method.POST, fieldMapping);
                }
            })
            .then(response => {
                //console.log("saveFieldMapping UPDATING", response);
                return this.queue([EndPoints.FieldMapping, fieldMapping.field_id], Method.PUT, fieldMapping);
            });
    }

    getAccountItemsForMonth(monthId) {
        return this.queue([EndPoints.AccountItem], Method.GET, {month_id: monthId});
    }

    getAccountItem(accountItemId) {
        return this.queue([EndPoints.AccountItem, accountItemId], Method.GET);
    }

    saveAccountItem(accountItem) {
        if (accountItem.id == null) {
            return this.queue([EndPoints.AccountItem], Method.POST, accountItem);
        } else {
            return this.queue([EndPoints.AccountItem, accountItem.id], Method.PUT, accountItem);
        }
    }

    saveAccountItems(accountItems) {
        if (accountItems.length == 0) {
            console.warn("No account items provided to save");
            return Promise.resolve([]);
        }

        // We look at the sample to see if we need to Create or Update the batch.
        var sample = accountItems[0];

        if (sample.id == null) {
            return this.queue([EndPoints.AccountItemBatch], Method.POST, {list: accountItems});
        } else {
            return this.queue([EndPoints.AccountItemBatch], Method.PUT, {list: accountItems});
        }
    }

    deleteAccountItem(accountItemId) {
        return this.queue([EndPoints.AccountItem, accountItemId], Method.DELETE);
    }

    deleteAccountItemsForMonth(monthId) {
        return this.queue([EndPoints.AccountItemBatch], Method.DELETE, {month_id: monthId});
    }

    getSonQuestions() {
        return this.queue([EndPoints.SonQuestion], Method.GET);
    }

    saveSonQuestion(question) {
        if (question.id == null) {
            return this.queue([EndPoints.SonQuestion], Method.POST, question);
        } else {
            return this.queue([EndPoints.SonQuestion, question.id], Method.PUT, question);
        }
    }

    deleteSonQuestion(question) {
        return this.queue([EndPoints.SonQuestion, question.id], Method.DELETE, question);
    }

    getSonAnswers(startDate, endDate) {
        return this.queue([EndPoints.SonAnswer], Method.GET, {start_date: startDate, end_date: endDate});
    }

    saveSonAnswer(sonAnswer) {
        return this.queue([EndPoints.SonAnswer], Method.PUT, sonAnswer)
            .catch(response => {
                if (response.status == 404) {
                    return this.queue([EndPoints.SonAnswer], Method.POST, sonAnswer);
                }
            });
    }

    getThreeWs() {
        return this.queue([EndPoints.ThreeW], Method.GET, { limit: 1000 }); // TODO: add proper pagination
    }

    saveThreeW(threeW) {
        if (threeW.id == null) {
            return this.queue([EndPoints.ThreeW], Method.POST, threeW);
        } else {
            return this.queue([EndPoints.ThreeW, threeW.id], Method.PUT, threeW);
        }
    }

    getThreeWTags(string) {
        var safeString = string.replace(/[^a-z0-9]/gi, '_').toLowerCase();

        return this.requestNoQueue([EndPoints.ThreeWTag, safeString], Method.GET);
    }

    deleteThreeWTag(tagName) {
        return this.queue([EndPoints.ThreeWTag, tagName], Method.DELETE);
    }

    getCrystalBall() {
        return this.queue([EndPoints.CrystalBall], Method.GET).then(data => JSON.parse(data));
    }

    saveCrystalBall(data) {
        return this.queue([EndPoints.CrystalBall], Method.PUT, JSON.stringify(data));
    }

    getAccount() {
        return this.queue([EndPoints.Account], Method.GET, null, {dontUseCache: true});
    }

    saveAccount(account) {
        return this.queue([EndPoints.Account], Method.PUT, account);
    }

    linkToGroupAccount(code) {
        return this.queue([EndPoints.AccountGroupCode(code)], Method.PUT);
    }

    unlinkGroupAccount(code) {
        return this.queue([EndPoints.AccountGroupCode(code)], Method.DELETE);
    }

    getLinkedAccounts() {
        return this.queue([EndPoints.AccountLinkedAccounts], Method.GET);
    }

    getLinkedAccountsMonths(accountId) {
        return this.queue([EndPoints.AccountLinkedAccountsMonths(accountId)], Method.GET);
    }

    getLinkedAccountsAccountNumberTags(accountId) {
        return this.queue([EndPoints.AccountLinkedAccountsAccountNumberTags(accountId)], Method.GET);
    }

    getLinkedAccountLoginToken(accountId) {
        return this.queue([EndPoints.AccountLinkedAccountsLogin(accountId)], Method.GET, null, { dontUseCache: true });
    }

    inviteToAccount(params) {
        return this.queue([EndPoints.AccountUser], Method.POST, params);
    }
    
    resendUserInvite(userId) {
        return this.queue([EndPoints.AccountUserResendInvite(userId)], Method.POST);
    }

    getUsers() {
        return this.queue([EndPoints.User], Method.GET);
    }

    /**
     *
     * @return {Promise<{
     *  administration: boolean
     *  historical_viewing: boolean
     *  historical_editing: boolean
     *  forecasting_editing: boolean
     * }>}
     */
    getPermissions() {
        return this.queue([EndPoints.UserPermissions], Method.GET);
    }

    saveUser(user) {
        return this.queue([EndPoints.User], Method.PUT, user);
    }

    deleteUser(user) {
        return this.queue([EndPoints.AccountUser, user.id], Method.DELETE);
    }

    updateUserPermissions(userId, roles) {
        return this.queue([EndPoints.AccountUserRoles(userId)], Method.PUT, { roles });
    }

    getUserFirebaseToken() {
        return this.queue([EndPoints.UserFirebaseToken], Method.GET, null, {dontUseCache: true});
    }

    changePassword(oldPassword, newPassword) {
        return this.queue([EndPoints.UserPassword], Method.POST, {old_password: oldPassword, new_password: newPassword})
    }

    changeEmail(password, new_email) {
        return this.queue([EndPoints.UserChangeEmail], Method.POST, { password, new_email })
    }

    setSubscriptionInfo(subscriptionData) {
        return this
            .queue([EndPoints.Subscription], Method.PUT, subscriptionData)
            .then(this.validateResponse)
            .catch((error) => {
                if (error.status === 404)
                    return this.request([EndPoints.Subscription], Method.POST, subscriptionData);
                throw error;
            })
            .then(response => {
                return response;
            });

    }

    cancelSubscription() {
        return this
            .queue([EndPoints.Subscription], Method.DELETE)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            })
            .then(response => {
                return response;
            });
    }

    checkCoupon(couponCode) {
        return this
            .request([EndPoints.Coupon], Method.GET, { coupon_code: couponCode })
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            })
            .then(response => {
                return response;
            });
    }

    getSubscriptionPrice() {
        const path = EndPoints.SubscriptionPrice;
        return this.request([path], Method.GET, this.authHeader)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            })
            .then(response => {
                return response;
            });
    }

    getAccountNumberGroups() {
        const path = EndPoints.AccountNumberGroup;
        return this.queue([path], Method.GET, this.authHeader)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    createAccountNumberGroup(data) {
        const path = EndPoints.AccountNumberGroup;
        return this.queue([path], Method.POST, data)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    deleteAccountNumberGroup(id) {
        const path = EndPoints.AccountNumberGroup;
        return this.queue([path, id], Method.DELETE)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    updateAccountNumberGroup(data) {
        const path = EndPoints.AccountNumberGroup;
        return this.queue([path], Method.PUT, data)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    batchUpdateAccountNumberGroups(dataList) {
        const path = EndPoints.AccountNumberGroupBatch;
        return this.queue([path], Method.PUT, {list: dataList})
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    /**
     *
     * @param payload
     *  {
            account_number_groups: {
                update: AccountNumberGroup[],
                delete: String[],
            },
            account_items: {
                update: AccountItem[],
                delete: String[],
            },
         };
     */
    batchUpdateAccountNumberGroupsAndAccountItems(payload) {
        const path = EndPoints.AccountNumberGroupBatchWithAccountItems;
        return this.queue([path], Method.PUT, payload)
            .then(this.validateResponse)
            .catch((error) => {
                throw error;
            });
    }

    fetchNotes() {
        return this.queue([EndPoints.Note], Method.GET);
    }

    saveNote(note) {
        return this.requestNoQueue([EndPoints.Note], Method.PUT, note);
    }

    saveMonthSnapshots(snapshots) {
        return this.queue([EndPoints.MonthSnapshot], Method.PUT, { list: snapshots });
    }
}


//queue.on('timeout', function (next, job) {
//    console.log('job timed out:', job.toString().replace(/\n/g, ''));
//    next();
//});

//queue.on('success', function (result, job) {
//    console.log('job finished processing:', job.toString().replace(/\n/g, ''));
//});

//var ajax = new Ajax(`http://${document.location.host}`, 'realtime', 'v1');
var location = typeof document == 'undefined' ? 'localhost' : document.location.host;
var protocol = typeof document == 'undefined' ? 'https:' : document.location.protocol;
var ajax = new Ajax(`${protocol}//${location}`, 'realtime', 'v1');

module.exports = ajax;
