const DataStore = require('../datastore/');
const { TagCategoryStore } = DataStore;

class accountItemQuery {

    static number(accountItems, accountNumber) {
        const item = accountItems.filter(item => item.account_number == accountNumber).shift();
        if (typeof item !== "undefined") {
            return item;
        }
        return null;
    }

    static code(accountItems, code) {
        const tag = TagCategoryStore.getTagForCode(code);
        const foundItem = accountItems.filter(item => item.tag_id === tag.id).shift();
        if (typeof foundItem === 'undefined') {
            return null;
        }
        return foundItem;
    }

    static allForCode(accountItems, code) {
        const tag = TagCategoryStore.getTagForCode(code);
        if (tag === null) {
            throw new Error(`no tag found with code "${code}"`);
        }
        return accountItems.filter(item => item.tag_id === tag.id);
    }

    static allForCodes(accountItems, codes) {
        const tags = codes.map(code => TagCategoryStore.getTagForCode(code));

        if (tags.indexOf(null) > -1) {
            throw new Error(`tag not found in codes [${codes.join(', ')}]`);
        }

        const tagIds = tags.map(tag => tag.id);

        return accountItems.filter(item => tagIds.indexOf(item.tag_id) > -1);
    }

    static allForTagId(accountItems, tagId) {
        return accountItems.filter(item => item.tag_id === tagId);
    }
}

class accountNumberQuery {

    static number(accountNumberTags, accountNumber) {
        const item = accountNumberTags.filter(item => item.account_number == accountNumber).shift();
        if (typeof item !== "undefined") {
            return item;
        }
        return null;
    }

    static allWithNumber(accountNumberTags, accountNumber) {
        return accountNumberTags.filter(item => item.account_number === accountNumber);
    }
}

export {
    accountItemQuery as AccountItemQuery,
    accountNumberQuery as AccountNumberQuery,
};
