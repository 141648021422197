// @ts-check
import Moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseForecastScreen from './BaseForecastScreen.tsx';
import Headings from '../generateTwelveMonthHeadings.ts';
import MultiMonthValuesRow from '../MultiMonthValuesRow.tsx';
import Formatter from '../../Formatter.ts';
import Utils from '../../utilities/Utils';
import { VideoLinks } from '../../models/VideoLinks.ts';
import { AccountItemQuery } from '../../utilities/AccountItemQuery';
import ForecastFormula from '../ForecastFormula.ts';
import ForecastCalculator from '../ForecastCalculatorInstance.ts';
import { TagCode } from '../../models/EnumsTs.ts';
import Month from '../../datastore/models/Month.ts';
import AccountNumberGroup from '../../datastore/models/AccountNumberGroup.ts';

class FixedAssetsScreen extends Component {
    constructor(props) {
        super(props);

        const { historicalMonths, forecastMonths, revenueSources, revenueTypes } = this.props;
        const evaluatedMonths = ForecastCalculator.evaluateForecastMonths(historicalMonths, forecastMonths, revenueSources, revenueTypes);

        this.state = {
            forecastMonths: evaluatedMonths,
        };
    }

    getLastHistoricalMonth() {
        const { historicalMonths } = this.props;
        return historicalMonths[historicalMonths.length - 1];
    }

    getLastHistoricalMonthName() {
        const month = this.getLastHistoricalMonth().Date;
        return Moment(month).format(Formatter.shortMonthOnlyDisplayFormat);
    }

    getBalance() {
        return `$${Utils.removePrecisionAndGetCommas(
            ForecastCalculator.getTotalForTagCodeForMonth(
                this.getLastHistoricalMonth(), TagCode.BS_TotalAssets_FixedAssetsNetOfProvision
            )
        )}`;
    }

    getAcquiredArray() {
        return this.AccountItems.map(accountItem => (ForecastFormula.evaluateFixedAssets(accountItem.value_formula).acquired));
    }

    getDisposedArray() {
        return this.AccountItems.map(accountItem => (ForecastFormula.evaluateFixedAssets(accountItem.value_formula).disposed));
    }

    getValues() {
        const accountItems = this.AccountItems;
        return accountItems.map(accountItem => accountItem.value);
    }

    getDepreciationValues() {
        return this.state.forecastMonths.map(month => ForecastCalculator.getTotalDepreciationForMonth(month));
    }

    get Percentage() {
        const accountItems = this.AccountItems;
        return ForecastFormula.evaluateFixedAssets(accountItems[0].value_formula).depreciation;
    }

    get AccountItems() {
        const { forecastMonths } = this.state;
        return forecastMonths.map(month => AccountItemQuery.code(month.account_items, this.Tag));
    }

    get Tag() {
        return TagCode.BS_TotalAssets_FixedAssetsNetOfProvision;
    }

    evaluateForecastMonths() {
        const { historicalMonths, revenueSources, revenueTypes } = this.props;
        const { forecastMonths } = this.state;
        const updatedMonths = ForecastCalculator.evaluateForecastMonths(historicalMonths, forecastMonths, revenueSources, revenueTypes);
        this.setState({ forecastMonths: updatedMonths });
    }

    updateAcquired(input, index) {
        const accountItems = this.AccountItems;
        const accountItem = accountItems[index];
        const accountItemObj = ForecastFormula.evaluateFixedAssets(accountItem.value_formula);
        accountItem.value_formula = ForecastFormula.fixedAssets(
            accountItemObj.depreciation,
            input,
            accountItemObj.disposed
        );
        this.evaluateForecastMonths();
    }

    updateDisposed(input, index) {
        const accountItems = this.AccountItems;
        const accountItem = accountItems[index];
        const accountItemObj = ForecastFormula.evaluateFixedAssets(accountItem.value_formula);
        accountItem.value_formula = ForecastFormula.fixedAssets(
            accountItemObj.depreciation,
            accountItemObj.acquired,
            input
        );
        this.evaluateForecastMonths();
    }

    // deprecated from version v1.1.1
    handlePercentageUpdate(input) {
        const accountItems = this.AccountItems;
        let value = parseInt(input, 10);
        //check that the input is valid
        if (value < 0 || isNaN(value)) {
            value = 0;
        }
        accountItems.forEach(accountItem => {
            const accountItemObj = ForecastFormula.evaluateFixedAssets(accountItem.value_formula);
            accountItem.value_formula = ForecastFormula.fixedAssets(
                value,
                accountItemObj.acquired,
                accountItemObj.disposed
            );
        });
        this.evaluateForecastMonths();
    }

    render() {
        const { goToSummary, forecastMonths, onSave } = this.props;
        const headings = Headings(forecastMonths[0].Date);
        const acquiredArray = this.getAcquiredArray();
        const disposedArray = this.getDisposedArray();
        const valuesArray = this.getValues();
        const depreciationValues = this.getDepreciationValues();
        return (
            <BaseForecastScreen
                title="Fixed Assets"
                buttonTextLeft="Cancel"
                onLeftButtonClick={() => goToSummary()}
                buttonTextRight="Accept & Save"
                onRightButtonClick={() => onSave(this.AccountItems)}
                videoLink={VideoLinks.Forecast.FixedAssets}
            >
                <div className="padding-bottom-20">
                    Project the balance of your Fixed Assets by considering the depreciation rate specified in your Income Statement,
                    and whether you plan to acquire or dispose of Fixed Assets in the future.
                </div>
                <div className="col-xs-12 border">
                    Fixed Assets Balance at <span className="month-title">{this.getLastHistoricalMonthName()}</span>
                    <div className="pull-right">
                        {this.getBalance()}
                    </div>
                </div>
                {/*
                <div className="col-xs-12 border-with-input-box">
                    Annual Depreciation Rate
                    <div className="pull-right percentage-block">
                        <Debounce
                            className="percentage-input padded-input"
                            type="number"
                            value={this.Percentage}
                            onChange={(value) => this.handlePercentageUpdate(value)}
                            delay={0}
                            min={0}
                            finishOnEnter
                        />
                        <span className="percentage-sign">%</span>
                    </div>
                </div>
                */}
                <div className="last-group-block">
                    <MultiMonthValuesRow
                        className="noBorder padding-top-50"
                        titleColContent=""
                        columnValues={headings}
                    />
                    <MultiMonthValuesRow
                        titleColContent="Acquired"
                        columnValues={acquiredArray}
                        onChange={(value, index) => this.updateAcquired(value, index)}
                        rowType="money"
                        className="light"
                    />
                    <MultiMonthValuesRow
                        titleColContent="Disposed"
                        columnValues={disposedArray}
                        onChange={(value, index) => this.updateDisposed(value, index)}
                        rowType="money"
                        className="light"
                    />
                    <MultiMonthValuesRow
                        titleColContent="Depreciation"
                        columnValues={depreciationValues}
                        rowType="money"
                    />
                    <MultiMonthValuesRow
                        titleColContent="Balance"
                        columnValues={valuesArray}
                        rowType="money"
                        className="total-assets"
                    />
                </div>
            </BaseForecastScreen>
        );
    }
}

FixedAssetsScreen.propTypes = {
    forecastMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)),
    goToSummary: PropTypes.func,
    historicalMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)),
    onSave: PropTypes.func,
    revenueSources: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberGroup)).isRequired,
    revenueTypes: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberGroup)).isRequired,
};

export default FixedAssetsScreen;
