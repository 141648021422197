var FieldMapping = require('../models/FieldMapping');
var Actions = require('../actions/FieldMappingActions');
var Ajax = require('../endpoints/ajax');

var FieldMappingSource = {
    fetchFieldMapping() {
        return {
            remote(state, id) {
                return Ajax.getFieldMapping(id);
            },

            local() {
                return null;
            },

            success: Actions.receivedItem,
            loading: Actions.loadingItem,
            error: Actions.fetchingItemFailed
        }
    },
    performSave() {
        return {
            remote(state, fieldMapping) {
                return Ajax.saveFieldMapping(fieldMapping);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    }
};

module.exports = FieldMappingSource;
