import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from '../utilities/Utils';

class RowContentCell extends Component {
    render() {
        const { className, value, percentage } = this.props;

        if (isNullOrUndefined(value)) {
            return null;
        }

        if (isNullOrUndefined(percentage)) {
            return (
                <div className={className}>
                    <div className="row">
                        <div className="col-xs-12 no-padding">
                            {value}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={className}>
                <div className="row">
                    <div className="col-xs-6 no-padding text-left">
                        {percentage}
                    </div>
                    <div className="col-xs-6 no-padding">
                        {value}
                    </div>
                </div>
            </div>
        );
    }
}

RowContentCell.propTypes = {
    className: PropTypes.string,
    percentage: PropTypes.string,
    value: PropTypes.string,
};

export default RowContentCell;
