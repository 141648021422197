"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertExists = exports.assertNotNull = void 0;
function assertNotNull(it, description) {
    if (it === null) {
        throw Error(description ? `not-null assertion failed for ${description}` : 'not-null assertion failed');
    }
}
exports.assertNotNull = assertNotNull;
function assertExists(it, description) {
    if (it === null || typeof it === 'undefined') {
        throw Error(description ? `not-null assertion failed for ${description}` : 'not-null assertion failed');
    }
}
exports.assertExists = assertExists;
