"use strict";
var FieldMapping = require('../models/FieldMapping');

class Field {
    constructor(item) {
        this.id = null;
        this.name = null;
        this.code = null;
        this.filtered_field_mapping = [];

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }

    /**
     *
     * @returns {FieldMapping|null}
     * @constructor
     */
    get FieldMapping() {
        if (Array.isArray(this.filtered_field_mapping) && this.filtered_field_mapping.length > 0) {
            return new FieldMapping(this.filtered_field_mapping[0]);
        }

        return null;
    }
}

module.exports = Field;