/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../utilities/Utils';

class FileSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: null, // overridden by props if passed in
            id: Utils.generateUuid()
        };
    }

    onChange(changeEvent) {
        // events can trigger multiple times in IE, sometimes before files exist
        if (!changeEvent.target.files[0]) {
            return;
        }

        const target = changeEvent.target;
        const file = changeEvent.target.files[0];

        const reader = new FileReader();
        const fileName = file.name;

        reader.onload = loadEvent => {
            const theTextFile = loadEvent.target.result;
            this.props.onFileLoaded(fileName, theTextFile);
            target.value = null;
        };

        reader.readAsText(file);

        this.setState({ //eslint-disable-line react/no-set-state
            fileName: fileName,
        });
    }

    getFileName() {
        const propsFileName = this.props.fileName;
        return propsFileName !== undefined ? propsFileName : this.state.fileName;
    }

    render() {
        const { id } = this.state;
        const { width } = this.props;

        const fileName = this.getFileName();

        const style = typeof width === 'number' ? {width} : {};

        return (
            <div className="file-selector">
                <span className="filename truncate-text noselect" style={style} title={fileName}>{fileName}</span>
                <label htmlFor={id}>
                    <span className="btn btn-lg select-file btn-primary">Select File</span>
                </label>
                <input id={id} onChange={event => this.onChange(event)} style={{display: 'none'}} type="file"/>
                {
                    this.props.hideUploadButton ? null : (
                        <span className="btn btn-lg btn-upload pull-right" type="button">Upload</span>
                    )
                }
            </div>
        );
    }
}

FileSelector.propTypes = {
    fileName: PropTypes.string,
    hideUploadButton: PropTypes.bool.isRequired,
    onFileLoaded: PropTypes.func.isRequired,
    width: PropTypes.number,
};

export default FileSelector;
