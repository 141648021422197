"use strict";
class AccountNumberGroup {
    /**
     * Must be initialised with an options object containing at least a Type
     * @param {AccountNumberGroup.ConstructorOptions} options
     */
    constructor(options) {
        this.name = options.name || '';
        this.type = options.type;
        this.account_numbers = (options.account_numbers || []).slice();
        this.id = options.id || null;
    }
    addAccountNumber(accountNumber) {
        if (this.containsNumber(accountNumber) === false) {
            this.account_numbers = this.account_numbers.concat(accountNumber);
        }
    }
    removeAccountNumber(accountNumber) {
        if (this.containsNumber(accountNumber)) {
            this.account_numbers = this.account_numbers.filter(existing => existing !== accountNumber);
        }
        else {
            console.error(`AccountNumberGroup.removeAccountNumber: Account Number "${accountNumber}" is not in the group`);
        }
    }
    getItems(accountItems) {
        return accountItems.filter(item => this.containsNumber(item.account_number));
    }
    containsNumber(accountNumber) {
        return this.account_numbers.indexOf(accountNumber) !== -1;
    }
    get empty() {
        return this.account_numbers.length === 0;
    }
    /**
     * returns intersection of own account_numbers and given list of accountNumbers
     * @param {Array<{String}>} accountNumbers
     * @returns {Array<{String}>}
     */
    getIntersectionOfAccountNumbers(accountNumbers) {
        return accountNumbers.filter(accountNumber => this.containsNumber(accountNumber));
    }
    clone() {
        return new AccountNumberGroup(this);
    }
}
(function (AccountNumberGroup) {
    let Types;
    (function (Types) {
        Types["MarriageRevenueSource"] = "MarriageRevenueSource";
        Types["EngagementRevenueSource"] = "EngagementRevenueSource";
        Types["RevenueType"] = "RevenueType";
        Types["UserDefinedMonthlyDataGroup"] = "UserDefinedMonthlyDataGroup";
    })(Types = AccountNumberGroup.Types || (AccountNumberGroup.Types = {}));
    function getWithType(accountNumberGroups, groupType) {
        switch (groupType) {
            case AccountNumberGroup.Types.MarriageRevenueSource:
            case AccountNumberGroup.Types.EngagementRevenueSource:
            case AccountNumberGroup.Types.RevenueType:
            case AccountNumberGroup.Types.UserDefinedMonthlyDataGroup:
                return accountNumberGroups.filter(group => group.type === groupType);
            default:
                throw new Error(`Unknown AccountNumberGroup ${groupType}`);
        }
    }
    AccountNumberGroup.getWithType = getWithType;
    /* type assertions */
    function isRevenueSource(group) {
        return group.type === AccountNumberGroup.Types.MarriageRevenueSource
            || group.type === AccountNumberGroup.Types.EngagementRevenueSource;
    }
    AccountNumberGroup.isRevenueSource = isRevenueSource;
    function isEngagementRevenueSource(group) {
        return group.type === AccountNumberGroup.Types.EngagementRevenueSource;
    }
    AccountNumberGroup.isEngagementRevenueSource = isEngagementRevenueSource;
    function isMarriageRevenueSource(group) {
        return group.type === AccountNumberGroup.Types.MarriageRevenueSource;
    }
    AccountNumberGroup.isMarriageRevenueSource = isMarriageRevenueSource;
    function isRevenueType(group) {
        return group.type === AccountNumberGroup.Types.RevenueType;
    }
    AccountNumberGroup.isRevenueType = isRevenueType;
})(AccountNumberGroup || (AccountNumberGroup = {}));
module.exports = AccountNumberGroup;
