import React from 'react';
import PropTypes from 'prop-types';
import InfoButton from '../components/InfoButton.tsx';
import PageHeading from '../PageHeading'

class WizardScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            wizardScreenHeight: 0,  // height in pixels, from top of window to bottom of wizard screen content
            windowHeight: 0,        // height in pixels
        };

        this.wizardScreenRef = null;
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        if (window !== null && typeof window !== undefined) {
            this.setState({windowHeight: window.innerHeight});
        }
    }

    render() {
        let {
            title,
            number, total, pageNumberLabel,
            onNext, onBack, onFinish, onSkip,
            nextEnabled, backEnabled, finishEnabled, skipEnabled,
            nextButtonText, finishButtonText,
            videoId,
        } = this.props;

        if (typeof nextEnabled !== "function") {
            nextEnabled = (() => true);
        }

        if (typeof backEnabled !== "function") {
            backEnabled = (() => true)
        }

        if (typeof skipEnabled !== "function") {
            skipEnabled = (() => true)
        }

        if ( typeof nextButtonText === "undefined") {
            nextButtonText = "Next"
        }

        if (typeof finishEnabled !== "function") {
            finishEnabled = (() => true);
        }

        if ( typeof finishButtonText === "undefined") {
            finishButtonText = "Finish"
        }

        let backEnabledResult = backEnabled();
        let nextEnabledResult = nextEnabled();
        let skipEnabledResult = skipEnabled();

        var backButton = onBack ? <button key={`back-${number}`} className="left-button btn btn-primary" disabled={!backEnabledResult} onClick={backEnabledResult ? onBack : null}>Back</button> : null;
        var nextButton = onNext ? <button key={`next-${number}`} className="btn btn-action" disabled={!nextEnabledResult} onClick={nextEnabledResult ? onNext : null}>{nextButtonText}</button> : null;
        var skipButton = onSkip ? <button key={`skip-${number}`} className="btn btn-action" disabled={!skipEnabledResult} onClick={skipEnabledResult ? onSkip : null}>Skip</button> : null;
        var finishButton = onFinish ? <div className="row">
            <div className="col-xs-12 text-center">
                <div className="vertical-spacer"></div>
                <p>
                    <button className="btn-action btn wide " disabled={!finishEnabled()} onClick={() => onFinish()}>{finishButtonText}</button>
                </p>
            </div>
        </div> : null;

        let footerText = null;
        if (typeof number !== 'undefined'){
            footerText = number;

            if (pageNumberLabel) {
                footerText = pageNumberLabel + ' ' + number;
            }

            if (typeof total !== 'undefined') {
                footerText += ' / ' + total;
            }
        }

        let footer = null;
        let footerClassName = "footer";
        const showFooter = backButton !== null || nextButton !== null || skipButton !== null;
        const windowScreenPadding = 80;

        if (showFooter) {
            if (this.state.windowHeight < this.state.wizardScreenHeight + windowScreenPadding) {
                footerClassName = "footer footerFixed"
            }

            footer = <div className={`text-center ${footerClassName}`}>
                {backButton}
                <div className="right-button">
                    {skipButton}
                    {nextButton}
                </div>
                <div className="text">
                    {footerText}
                </div>
            </div>;
        }

        let header = null;
        if (title) {
            header = (
                <div>
                    <PageHeading
                      title={title}
                      icon='/images/btn_account_green.svg'
                      videoId={videoId}
                    />
                    <hr/>
                </div>
            )
        }

        const updateRef = (ref) => {
            if (ref !== null) {
                this.wizardScreenRef = ref;
                const wizardScreenBoundingBox = this.wizardScreenRef.getBoundingClientRect();
                const fromTopOfWindowToBottomOfWizardScreen = wizardScreenBoundingBox.height + wizardScreenBoundingBox.top;
                if (this.state.wizardScreenHeight !== fromTopOfWindowToBottomOfWizardScreen) {
                    this.setState({wizardScreenHeight: fromTopOfWindowToBottomOfWizardScreen});
                }
            }
        };

        return (
            <div>
                <div className="wizard-screen row" ref={updateRef}>
                    {header}
                    <div className="col-xs-12">
                        {this.props.children}
                    </div>
                    {finishButton}
                </div>
                {footer}
            </div>
        )
    }
}

WizardScreen.propTypes = {
    title: PropTypes.string,
    pageNumberLabel: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onSkip: PropTypes.func,
    onFinish: PropTypes.func,
    nextEnabled: PropTypes.func,
    finishEnabled: PropTypes.func,
    skipEnabled: PropTypes.func,
    finishButtonText: PropTypes.string,
    backEnabled: PropTypes.func,
    nextButtonText: PropTypes.string,
    videoId: PropTypes.string,
};

export default WizardScreen;