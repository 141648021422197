var Field = require('../models/Field');
var Actions = require('../actions/FieldActions');
var Ajax = require('../endpoints/ajax');

var FieldSource = {
    fetchData() {
        return {
            remote(state) {
                return Ajax.getFields();
            },

            local() {
                return null;
            },

            success: Actions.receivedItems,
            loading: Actions.loadingItems,
            error: Actions.fetchingItemsFailed
        }
    }
};

module.exports = FieldSource;
