import React from 'react';
import PropTypes from 'prop-types';
import SuggestionList from "../components/SuggestionList"
import Calendar from "../third-party/react-calendar/Calendar"
import TextareaAutosize from "../components/TextareaAutosize"

class InputGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: false,
            showSuggestionList: false,
        };

        this.inputRef = React.createRef()
    }

    requestInputFocus() {
        if (this.inputRef.current) {
            setTimeout(() => {
                this.inputRef.current.focus()
            })
        }
    }

    handleDatePicked(moment) {
        if (this.props.onDatePicked(moment)) {
            this.setState({
                showCalendar: false
            });
        }
    }

    onTagPicked(tag) {
        this.props.onChange(tag.name);

        this.setState({
            showSuggestionList: false
        });
    }

    handleFocus() {
        this.props.onGainedFocus(this.props.label);
    }

    handleBlur() {
        if (this.hasFocus()) {
            // Trigger onBlur if it's set.
            if (this.props.onBlur != null) {
                this.props.onBlur();
            }

            return;
        }

        this.setState({
            showCalendar: false,
            showSuggestionList: false
        });
    }

    calendarClickAction(hasCalendar) {
        if (hasCalendar) {
            this.setState({
                showCalendar: true
            });
        }
    }

    onInputChange(value) {
        this.props.onChange(value);

        this.setState({
            showSuggestionList: true
        });
    }

    hasFocus() {
        return this.props.focus == this.props.label;
    }

    onKeyPress(e) {
        if (e.key == "Enter" && this.props.tags.length > 0) {
            this.onTagPicked(this.props.tags[0]);
        }
    }

    get Textarea() {
        const {
            value,
            type,
            readOnly,
            placeholder,
            onDatePicked,
            onChange
            } = this.props;

        if (type == "textarea") {
            var isReadOnly = readOnly === true;
            var shouldItHaveACalendar = onDatePicked != null;

            return <div>
                <TextareaAutosize
                    ref={this.inputRef}
                    rows="2"
                    onChange={value => onChange(value)}
                    readOnly={isReadOnly}
                    placeholder={placeholder}
                    onClick={() => this.calendarClickAction(shouldItHaveACalendar)}
                    onFocus={() => this.handleFocus()}
                    onBlur={() => this.handleBlur()}
                    className={"threew-entry-item"}
                    value={value}
                />
            </div>
        }

        return null;
    }

    get Input() {
        const {
            type
            } = this.props;

        if (type != "textarea") {
            const {
                value,
                readOnly,
                placeholder,
                onDatePicked
                } = this.props;

            var isReadOnly = readOnly === true;
            var shouldItHaveACalendar = onDatePicked != null;

            return <input
                ref={this.inputRef}
                type="text"
                value={value}
                onChange={e => this.onInputChange(e.target.value)}
                readOnly={isReadOnly}
                placeholder={placeholder}
                onClick={() => this.calendarClickAction(shouldItHaveACalendar)}

                onFocus={() => this.handleFocus()}
                onBlur={() => this.handleBlur()}

                onKeyPress={e => this.onKeyPress(e)}
                className={"threew-entry-item"}
                />
        }

        return null;

    }

    get Label() {
        const {
            vertical,
            label
            } = this.props;

        return vertical ? <label>{label}</label> : null;
    }

    get Calendar() {
        const {
            selectedDate,
            onDatePicked,
            } = this.props;

        var shouldItHaveACalendar = onDatePicked != null;

        if (shouldItHaveACalendar && this.state.showCalendar && this.hasFocus()) {
            return <div className="calendar-container">
                <Calendar showDaysOfWeek={true}
                          forceSixRows={false}
                          onPickDate={date => this.handleDatePicked(date) }
                          highlightDate={selectedDate}
                    />
            </div>;
        }

        return null;
    }

    get SuggestionList() {
        const {
            onDeleteTag,
            tags
            } = this.props;

        var hasSuggestionList = this.state.showSuggestionList
            && this.hasFocus()
            && tags.length > 0;

        if (hasSuggestionList) {
            return <SuggestionList
                highlightFirst
                tags={tags}
                onItemClick={tag => this.onTagPicked(tag)}
                onDeleteTag={tag => {
                    onDeleteTag(tag);
                    this.requestInputFocus();
                }}
            />;
        }

        return null;
    }

    render() {


        return <div className="input-group">
            {this.Label}
            {this.Textarea}
            {this.Input}
            {this.SuggestionList}
            {this.Calendar}
        </div>
    }
}

InputGroup.propTypes = {
    onDatePicked: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onGainedFocus: PropTypes.func,
    onBlur: PropTypes.func,
    label: PropTypes.string.isRequired,
    focus: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.object),
    onDeleteTag: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired
};

export default InputGroup;
