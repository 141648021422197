"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSonSectionsMonth = void 0;
const firebase_config_1 = require("../firebase-config");
const SonDB_1 = require("../SonDB");
function createSonSectionsMonth(sections) {
    const batch = firebase_config_1.firestore.batch();
    sections.map(section => {
        batch.set((0, SonDB_1.getSonSectionCollection)().doc(), section);
    });
    return batch.commit();
}
exports.createSonSectionsMonth = createSonSectionsMonth;
