import React from 'react';
import PropTypes from 'prop-types';
'use strict';

var moment = require('moment');
var Day = require('./Day');
var CalendarControls = require('./CalendarControls');

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment(),
        };

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }

    next() {
        this.setState({date: this.state.date.add(1, 'months')});
    }

    prev() {
        this.setState({date: this.state.date.subtract(1, 'months')});
    }

    days() {
        var days = [];
        var date = this.state.date.startOf('month');
        var diff = date.weekday() - this.props.weekOffset;
        if (diff < 0) diff += 7;

        var i, day;
        for (i = 0; i < diff; i++) {
            day = moment([this.state.date.year(), this.state.date.month(), i - diff + 1])
            days.push({day: day, classes: 'prev-month'});
        }

        var numberOfDays = date.daysInMonth();
        for (i = 1; i <= numberOfDays; i++) {
            day = moment([this.state.date.year(), this.state.date.month(), i]);
            days.push({day: day});
        }

        i = 1;
        while (days.length % 7 !== 0) {
            day = moment([this.state.date.year(), this.state.date.month(), numberOfDays + i]);
            days.push({day: day, classes: 'next-month'});
            i++;
        }

        if (this.props.forceSixRows && days.length !== 42) {
            var start = moment(days[days.length - 1].date).add(1, 'days');
            while (days.length < 42) {
                days.push({day: moment(start), classes: 'next-month'});
                start.add(1, 'days');
            }
        }

        return days;
    }

    daysOfWeek() {
        var daysOfWeek = this.props.daysOfWeek;
        if (!daysOfWeek) {
            daysOfWeek = [];
            for (var i = 0; i < 7; i++) {
                daysOfWeek.push(moment().weekday(i).format('dd').charAt(0));
            }
        }
        return daysOfWeek;
    }

    render() {
        var highlightedDateStamp = this.props.highlightDate ? this.props.highlightDate.hour(0).minute(0).second(0).unix() : 0;

        return (
            <div className='clndr'>
                <CalendarControls date={this.state.date} onNext={this.next} onPrev={this.prev}/>

                <div className='clndr-grid'>
                    <div className='day-headers'>
                        {this.props.showDaysOfWeek && this.daysOfWeek().map((day, i) => {
                            return <div key={'weekday-' + i}>{day}</div>;
                        })}
                    </div>
                    <div className='days'>
                        {this.days().map((day, i) => {
                            var isHighlighted = day.day.unix() == highlightedDateStamp;

                            return <Day key={'day-' + i} day={day} onClick={this.props.onPickDate} isHighlighted={isHighlighted}/>;
                        })}
                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>
        );
    }
}


Calendar.propTypes = {
    weekOffset: PropTypes.number,
    forceSixRows: PropTypes.bool,
    showDaysOfWeek: PropTypes.bool,
    highlightDate: PropTypes.object
};

Calendar.defaultProps = {
    weekOffset: 0,
    forceSixRows: false,
    showDaysOfWeek: false,
    onPickDate: null,
    highlightDate: moment()
};


module.exports = Calendar;
