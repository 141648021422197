// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Debounce from '../../components/Debounce';
import Popup from '../../components/Popup.tsx';
import InputSuggestionBox from '../../components/InputSuggestionBox';
import PopupWithButtons from '../../components/PopupWithButtons';
import DropDownSelector from '../../components/DropDownSelector';
import IconButton from '../../components/IconButton';

import BatchUpdateContainer from '../../utilities/BatchUpdateContainer';
import { AccountItemQuery, AccountNumberQuery } from '../../utilities/AccountItemQuery';
import Utils from '../../utilities/Utils';
import { TagCode } from '../../models/EnumsTs.ts';

import { VideoLinks } from '../../models/VideoLinks.ts';

import BaseForecastScreen from './BaseForecastScreen.tsx';
import MultiMonthValuesRow from '../MultiMonthValuesRow.tsx';
import ForecastFormula from '../ForecastFormula.ts';
import generateTwelveMonthHeadings from '../generateTwelveMonthHeadings.ts';

import ForecastCalculator from '../ForecastCalculatorInstance.ts';

const datastore = require('../../datastore');

const { AccountNumberGroup, Month, AccountItem, TagCategoryStore } = datastore;

const EngagementWeightingOptions = {
    AsAWhole: 'As a whole', // weighting percentages are the same for every month
    Individually: 'Individually per month', // weighting percentages are set individually per month
};

export default class EditRevenueScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placeholderNameInput: '',
            addRevenueLineTextInput: null,
            editingRevenueSource: null,
            deletingRevenueSource: null,
            convertToMarriageRevenueSource: null,
            revenueSources: this.getDefaultRevenueSources(),
            revenueTypes: this.getDefaultRevenueTypes(),
            accountItems: this.getDefaultAccountItems(),
            engagementWeightingOption: null,
        };
    }

    componentDidUpdate(prevProps) {
        // wipe state when updated data is received
        if (prevProps !== this.props && this.props.storesReady === true) {
            this.discardLocalChanges();
        }
    }

    onSelectWeightingOption(optionKey) {
        this.setState({
            engagementWeightingOption: optionKey,
        });

        if (optionKey === EngagementWeightingOptions.AsAWhole) {
            const revenueSource = this.state.editingRevenueSource;
            const monthPercentages = this.getEngagementWeightingPercentages(revenueSource);
            this.setEngagementPercentageAsAWhole(monthPercentages[0], revenueSource);
        }
    }

    getHistoricalRevenueItems() {
        const { historicalMonths } = this.props;
        const lastMonth = historicalMonths[historicalMonths.length - 1];
        return AccountItemQuery.allForCode(lastMonth.account_items, TagCode.IS_Revenue_Revenue);
    }

    getRevenueSourceList() {
        // only show saved revenue sources, otherwise an item will appear when the "new" popup is opened
        const revenueSources = this.getRevenueSources()
            .filter(revenueSource => revenueSource.id !== null);

        const revenueTypes = this.getRevenueTypes();

        if (revenueSources.length === 0) {
            return (
                <p><strong>There are currently no Revenue Sources specified.</strong></p>
            );
        }

        const evaluatedForecastMonths = ForecastCalculator.evaluateForecastMonths(this.props.historicalMonths, this.props.forecastMonths, revenueSources, revenueTypes);

        return revenueSources.map((revenueSource, index) => {
            const total = ForecastCalculator.getYearTotalRevenueForRevenueSource(evaluatedForecastMonths, revenueSource);
            let unweightedTotal = null;
            let percentage = null;
            let marriageButton = null;

            if (this.props.forecastRevenueType === EditRevenueScreen.ForecastRevenueTypes.Engagement) {
                const rawUnweightedTotal = ForecastCalculator.getYearTotalUnweightedRevenueForEngagementRevenueSource(evaluatedForecastMonths, revenueSource);
                unweightedTotal = `$${Utils.removePrecisionAndGetCommas(rawUnweightedTotal)}`;
                const percentages = this.getEngagementWeightingPercentages(revenueSource);
                const isSetIndividually = this.isEngagementRevenueSourcePercentageSetIndividually(revenueSource);
                if (percentages === null) {
                    // placeholder if no lines have been added
                    percentage = '100%';
                } else if (isSetIndividually) {
                    percentage = 'Individually';
                } else {
                    percentage = `${this.getEngagementWeightingPercentages(revenueSource)[0]}%`;
                }

                marriageButton = (
                    <button
                        className="btn btn-action pull-right"
                        onClick={() => this.showConvertToMarriagePopup(revenueSource)}
                        disabled={isSetIndividually}
                    >Marriage
                    </button>
                );
            }

            return ( // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="revenue-source-row-container">
                    <div className="col-xs-12">
                        <div className="row revenue-source-row">
                            <div className="col-xs-12">
                                <div className="revenue-source-row-content">
                                    <div className="revenue-source-row-content-flexible">
                                        <div className="label-column" title={revenueSource.name}>
                                            {revenueSource.name}
                                        </div>
                                        <div className="value-column">
                                            {unweightedTotal}
                                        </div>
                                        <div className="value-column">
                                            {percentage}
                                        </div>
                                        <div className="value-column">
                                            ${Utils.removePrecisionAndGetCommas(total)}
                                        </div>
                                    </div>
                                    <div className={classNames('revenue-source-row-content-fixed', marriageButton && 'engagement')}>
                                        <IconButton
                                            buttonType="delete"
                                            className="pull-right"
                                            onClick={() => this.showDeleteRevenueSourcePopup(revenueSource)}
                                        />
                                        <IconButton
                                            buttonType="edit"
                                            className="pull-right"
                                            onClick={() => this.showEditPopup(revenueSource)}
                                        />
                                        {marriageButton}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /**
     * @returns {BatchUpdateContainer} containing a copy of Revenue Sources from props
     */
    getDefaultRevenueSources() {
        const revenueSources = this.props.accountNumberGroups
            .filter(group => group.type === this.RevenueSourceType)
            .map(group => group.clone());

        return new BatchUpdateContainer({ items: revenueSources });
    }

    /**
     * @returns {BatchUpdateContainer} containing a copy of Revenue Types from props
     */
    getDefaultRevenueTypes() {
        const revenueSources = this.props.accountNumberGroups
            .filter(group => group.type === AccountNumberGroup.Types.RevenueType)
            .map(group => group.clone());

        return new BatchUpdateContainer({ items: revenueSources });
    }

    /**
     * @returns {BatchUpdateContainer} containing a copy of Revenue Account Items from props
     * TODO: add Direct Costs Account Items
     */
    getDefaultAccountItems() {
        const allAccountItems = this.props.forecastMonths.reduce((accum, month) => accum.concat(month.account_items), []);

        const codes = [
            TagCode.IS_Revenue_Revenue,
            TagCode.IS_DirectCosts_Wages,
            TagCode.IS_DirectCosts_Materials,
            TagCode.IS_DirectCosts_Depreciation,
            TagCode.IS_DirectCosts_Other,
        ];

        const accountItems = codes.reduce((accum, code) =>
            accum.concat(AccountItemQuery.allForCode(
                allAccountItems,
                code,
            )), []
        ).map(item => new AccountItem(item));

        return new BatchUpdateContainer({ items: accountItems });
    }

    getForecastRevenueTypeOptions() {
        const existingRevenueTypes = this.getRevenueTypes();

        return existingRevenueTypes.map(revenueType => ({
            value: revenueType,
            name: revenueType.name,
        }));
    }

    /**
     * Gets the list of possible Revenue Type options
     * Includes user created Revenue Types, and potential Revenue Types from Historical data.
     * @returns {Array.<{
     *   value: {AccountNumberGroup}
     *   name: {String}
     * }>}
     */
    getRevenueTypeOptions() {
        // existing Revenue Types that have been created and saved
        const forecastOptions = this.getForecastRevenueTypeOptions();

        // Potential Revenue Types than can be created based on a historical revenue line
        // Filtered out if a corresponding Revenue Type has already been created
        const historicalRevenueItems = this.getHistoricalRevenueItems();

        // add the historical account number to the Revenue Type so it can be linked to that historical line
        const historicalOptions = historicalRevenueItems
            // filter historical Revenue Items out if they have already been created
            .filter(revenueItem => {
                const existingRevenueType = forecastOptions.find(option => {
                    const revenueType = option.value;
                    return revenueType.containsNumber(revenueItem.account_number);
                });
                return typeof existingRevenueType === 'undefined';
            })
            .map(revenueItem => ({
                value: new AccountNumberGroup({
                    name: revenueItem.name,
                    type: AccountNumberGroup.Types.RevenueType,
                    account_numbers: [revenueItem.account_number]
                }),
                name: revenueItem.name,
            }));

        return forecastOptions.concat(historicalOptions);
    }

    /**
     * Gets the list of possible Revenue Type options for adding a new line to a Revenue Source
     * @param revenueSource
     * @returns {Array.<{
     *   value: {AccountNumberGroup}
     *   name: {String}
     * }>}
     */
    getNewLineOptionsForRevenueType(revenueSource) {
        const options = this.getRevenueTypeOptions();

        // filter out Revenue Types that are already used in the Revenue Source
        return options.filter(option => {
            const revenueType = option.value;
            const accountNumbersInRevenueSource = revenueType.getIntersectionOfAccountNumbers(revenueSource.account_numbers);
            return accountNumbersInRevenueSource.length === 0;
        });
    }

    /**
     * Gets the list of Revenue Type options that are already in use for a Revenue Source
     * @param revenueSource
     * @returns {Array.<{
     *   value: {AccountNumberGroup}
     *   name: {String}
     * }>}
     */
    getUnavailableNewLineOptionsForRevenueType(revenueSource) {
        const revenueTypeOptions = this.getForecastRevenueTypeOptions();

        // find only items that are already used in the Revenue Source
        return revenueTypeOptions.filter(option => {
            const revenueType = option.value;
            const accountNumbersInRevenueSource = revenueType.getIntersectionOfAccountNumbers(revenueSource.account_numbers);
            return accountNumbersInRevenueSource.length > 0;
        });
    }

    getRevenueTypeInputRow(revenueSource) {
        const { addRevenueLineTextInput } = this.state;

        if (addRevenueLineTextInput === null) {
            return (
                <div className="row">
                    <button
                        className="btn btn-action btn-add-revenue-line"
                        onClick={() => this.setState({ addRevenueLineTextInput: '' })}
                    >
                        + add new Revenue line
                    </button>
                </div>
            );
        }

        const handleSelectOption = option => {
            if (option.name.trim().length === 0) {
                return;
            }

            let revenueType = option.value;

            // create a new Revenue Type with an entered name
            if (option.value === null) {
                revenueType = new AccountNumberGroup({
                    name: option.name,
                    type: AccountNumberGroup.Types.RevenueType,
                });
            }

            this.createRevenueLine(revenueSource, revenueType);
            //clear input
            this.setState({ addRevenueLineTextInput: null });
        };

        const options = this.getNewLineOptionsForRevenueType(revenueSource);
        const unavailableOptions = this.getUnavailableNewLineOptionsForRevenueType(revenueSource);

        return (
            <div className="row deletable-row-wrapper add-line-input-row">
                <div className="col-xs-11">
                    <div className="row">
                        <MultiMonthValuesRow
                            className={classNames(MultiMonthValuesRow.ClassNames.noBorder)}
                            titleColContent={(
                                <div className="col-xs-12">
                                    <div className="row">
                                        <InputSuggestionBox
                                            autoFocus
                                            placeholder="Add new line"
                                            options={options}
                                            unavailableOptions={unavailableOptions}
                                            value={addRevenueLineTextInput}
                                            handleUpdate={val => this.setState({ addRevenueLineTextInput: val })}
                                            handleSelect={option => handleSelectOption(option)}
                                        />
                                    </div>
                                </div>
                            )}
                            columnValues={['', '', '', '', '', '', '', '', '', '', '']}
                        />
                    </div>
                </div>
                <div className="col-xs-1">
                    <IconButton
                        buttonType="delete"
                        className="pull-right"
                        onClick={() => this.setState({ addRevenueLineTextInput: null })}
                    />
                </div>
            </div>
        );
    }

    /**
     * Returns array of Account Item lines (as arrays of Account Items)
     * for revenue lines in given revenue source
     * @param revenueSource
     * returns Array<
     *   Array<RevenueItem>
     * >
     */
    getForecastRevenueLinesForRevenueSource(revenueSource) {
        const { forecastMonths } = this.props;

        const revenueItems = AccountItemQuery.allForCode(
            this.state.accountItems.items,
            TagCode.IS_Revenue_Revenue,
        );

        return revenueSource.account_numbers
            .filter(accountNumber => this.isAccountNumberHistorical(accountNumber) === false)
            .map(accountNumber => {
                const itemsForMonth = forecastMonths.map(month =>
                    revenueItems.find(item =>
                        item.month_id === month.id && item.account_number === accountNumber
                    )
                );

                const missingItems = itemsForMonth.filter(item => typeof item === 'undefined');

                if (missingItems.length > 0) {
                    console.error('accountItems missing for account number:', accountNumber);
                    return null;
                }

                return itemsForMonth;
            })
            .filter(line => line !== null);
    }

    getForecastRevenueItemsForRevenueSourceForMonth(revenueSource, month) {
        const revenueItems = AccountItemQuery.allForCode(
            this.state.accountItems.items,
            TagCode.IS_Revenue_Revenue,
        );

        return revenueSource.account_numbers
            .filter(accountNumber => this.isAccountNumberHistorical(accountNumber) === false)
            .map(accountNumber =>
                revenueItems.find(item => item.month_id === month.id && item.account_number === accountNumber)
            );
    }

    getAccountNumberRow(accountNumber, revenueSource) {
        const accountItems = this.props.forecastMonths.map(month =>
            this.state.accountItems.items.find(item => item.month_id === month.id && item.account_number === accountNumber),
        );

        if (accountItems.filter(item => typeof item === 'undefined').length > 0) {
            console.error('accountItems missing for account number:', accountNumber);
            return null;
        }

        const revenueType = this.getRevenueTypes().find(type => type.containsNumber(accountNumber));
        if (typeof revenueType === 'undefined') {
            console.error(`missing revenue type for account number: ${accountNumber}`);
            return null;
        }

        switch (revenueSource.type) {
            case AccountNumberGroup.Types.MarriageRevenueSource:
                return this.getMarriageRevenueRow(accountItems, revenueType, revenueSource);
            case AccountNumberGroup.Types.EngagementRevenueSource:
                return this.getEngagementRevenueRow(accountItems, revenueType, revenueSource);
            default:
                throw new Error(`invalid forecastRevenueType "${revenueSource.type}"`);
        }
    }

    getMarriageRevenueRow(accountItems, revenueType, revenueSource) {
        const onChangeValue = (value, index) => {
            const accountItem = accountItems[index];
            const parsedValue = Utils.parseMoney(value);

            if (isNaN(parsedValue) === false) {
                accountItem.value = parsedValue;
            }

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const onFillValue = (index) => {
            const sourceItem = accountItems[index];
            const targetItems = accountItems.slice(index + 1);

            targetItems.forEach(item => { item.value = sourceItem.value; });

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const accountNumber = accountItems[0].account_number;

        return (
            <div className="row deletable-row-wrapper">
                <div className="col-xs-11">
                    <MultiMonthValuesRow
                        titleColContent={revenueType.name}
                        columnValues={accountItems.map(item => item.value)}
                        rowType="money"
                        displayTotalCalc
                        onChange={onChangeValue}
                        onFillAcross={onFillValue}
                        className={classNames(MultiMonthValuesRow.ClassNames.noBorder)}
                    />
                </div>
                <div className="col-xs-1">
                    <IconButton
                        buttonType="delete"
                        className="pull-right"
                        onClick={() => this.handleDeleteAccountNumberLine(accountNumber, revenueSource)}
                    />
                </div>
            </div>
        );
    }

    getEngagementRevenueRow(accountItems, revenueType, revenueSource) {
        const onChangeValue = (value, index) => {
            const accountItem = accountItems[index];
            const parsedValue = Utils.parseMoney(value);

            if (isNaN(parsedValue) === false) {
                const existingFormula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
                accountItem.value_formula = ForecastFormula.percentValue(existingFormula.percent, value);
            }

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const onFillValue = (index) => {
            const sourceItem = accountItems[index];
            const targetItems = accountItems.slice(index + 1);

            const sourceFormula = ForecastFormula.evaluatePercentValue(sourceItem.value_formula);

            targetItems.forEach(accountItem => {
                const existingFormula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
                accountItem.value_formula = ForecastFormula.percentValue(existingFormula.percent, sourceFormula.value);
            });

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const accountNumber = accountItems[0].account_number;

        const formulaValues = accountItems.map(item => ForecastFormula.evaluatePercentValue(item.value_formula));

        const accountItemLines = revenueSource.account_numbers
            .filter(_accountNumber => this.isAccountNumberHistorical(_accountNumber) === false);

        // show engagement weighting row here if this is the only revenue line in the revenue source
        const engagementWeightingRow = this.RevenueSourceType === AccountNumberGroup.Types.EngagementRevenueSource
            && accountItemLines.length === 1
            ? this.getRevenueSourceWeightingRow(revenueSource) : null;

        return (
            <div className="row deletable-row-wrapper">
                <div className="col-xs-11">
                    <div className="row">
                        <MultiMonthValuesRow
                            titleColContent={revenueType.name}
                            columnValues={formulaValues.map(formula => formula.value)}
                            rowType="money"
                            displayTotalCalc
                            onChange={onChangeValue}
                            onFillAcross={onFillValue}
                            className={classNames(MultiMonthValuesRow.ClassNames.noBorder)}
                        />
                        {engagementWeightingRow}
                        <MultiMonthValuesRow
                            titleColContent="Weighted Revenue"
                            columnValues={formulaValues.map(formula => formula.result)}
                            rowType="money"
                            displayTotalCalc
                            className={classNames(
                                MultiMonthValuesRow.ClassNames.noBorder,
                                MultiMonthValuesRow.ClassNames.noBold,
                            )}
                        />
                    </div>
                </div>
                <div className="col-xs-1">
                    <IconButton
                        buttonType="delete"
                        className="pull-right"
                        onClick={() => this.handleDeleteAccountNumberLine(accountNumber, revenueSource)}
                    />
                </div>
            </div>
        );
    }

    getDefaultEngagementFormulas() {
        return new Array(12).fill(ForecastFormula.percentValue(80, 0));
    }

    setEngagementPercentageAsAWhole(percent, revenueSource) {
        const parsedValue = Utils.parseMoney(percent);

        if (isNaN(parsedValue) === false) {
            const itemRows = this.getForecastRevenueLinesForRevenueSource(revenueSource);

            itemRows.forEach(row =>
                row.forEach(accountItem => {
                    const existingFormula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
                    accountItem.value_formula = ForecastFormula.percentValue(percent, existingFormula.value); // eslint-disable-line no-param-reassign
                })
            );
        }

        // update even if input is invalid so that displayed value is reset
        this.forceUpdate();
    }

    /**
     * Returns array of weighting percentages for each month for the first line in an Engagement Revenue Source.
     * All lines in an Engagement Revenue Source are assumed to have the same percentages in corresponding months.
     * @param {AccountNumberGroup} revenueSource
     * @returns {number[] | null} length 12
     */
    getEngagementWeightingPercentages(revenueSource) {
        const revenueLines = this.getForecastRevenueLinesForRevenueSource(revenueSource);

        if (revenueLines.length === 0) {
            return null;
        }

        return revenueLines[0].map(accountItem => ForecastFormula.evaluatePercentValue(accountItem.value_formula).percent);
    }

    getRevenueSourceWeightingRow(revenueSource) {
        const { engagementWeightingOption } = this.state;
        const revenueLines = this.getForecastRevenueLinesForRevenueSource(revenueSource);

        if (revenueLines.length === 0) {
            return null;
        }

        const monthPercentages = this.getEngagementWeightingPercentages(revenueSource);

        // update percentages for all revenue items for this month in the revenue source
        const onChangePercentage = (percent, index) => {
            const month = this.props.forecastMonths[index];

            const itemsForMonth = this.getForecastRevenueItemsForRevenueSourceForMonth(revenueSource, month);

            const parsedValue = Utils.parseMoney(percent);

            if (isNaN(parsedValue) === false) {
                itemsForMonth.forEach(accountItem => {
                    const existingFormula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
                    accountItem.value_formula = ForecastFormula.percentValue(percent, existingFormula.value); // eslint-disable-line no-param-reassign
                });
            }

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const onFillForwardPercentage = (index) => {
            const monthsToUpdate = this.props.forecastMonths.slice(index + 1);
            const itemsToUpdate = monthsToUpdate.map(month => this.getForecastRevenueItemsForRevenueSourceForMonth(revenueSource, month));

            const percent = this.getEngagementWeightingPercentages(revenueSource)[index];

            itemsToUpdate.forEach(monthItems => {
                monthItems.forEach(accountItem => {
                    const existingFormula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
                    accountItem.value_formula = ForecastFormula.percentValue(percent, existingFormula.value); // eslint-disable-line no-param-reassign
                });
            });

            // update even if input is invalid so that displayed value is reset
            this.forceUpdate();
        };

        const options = [{
            value: EngagementWeightingOptions.AsAWhole,
            name: 'Certainty (All)',
        }, {
            value: EngagementWeightingOptions.Individually,
            name: 'Certainty (Monthly)',
        }];

        const rowHeader = (
            <DropDownSelector
                onSelectOption={option => this.onSelectWeightingOption(option.value)}
                selectedOption={options.find(option => option.value === engagementWeightingOption)}
                options={options}
            />
        );

        let onChange;
        let editableColumns;

        const isAsAWhole = engagementWeightingOption === EngagementWeightingOptions.AsAWhole;

        if (isAsAWhole) {
            onChange = (val) => this.setEngagementPercentageAsAWhole(val, revenueSource);
            editableColumns = new Array(12).fill(false);
            editableColumns[0] = true;
        } else {
            onChange = onChangePercentage;
            editableColumns = new Array(12).fill(true);
        }

        return (
            <MultiMonthValuesRow
                titleColContent={rowHeader}
                columnValues={monthPercentages}
                rowType="percentage"
                inputType="number"
                onChange={onChange}
                onFillAcross={isAsAWhole ? undefined : onFillForwardPercentage}
                totalColumnContent=""
                className={classNames(MultiMonthValuesRow.ClassNames.noBorder)}
                editableColumns={editableColumns}
                maxNumber={100}
            />
        );
    }

    // TODO: remove, for debug only
    getDebugRevenueTypeRows() {
        const { revenueTypes } = this.state;

        const getNumberDescription = (number) => {
            const item = AccountItemQuery.number(this.props.forecastMonths[0].account_items, number);

            let name;

            if (item) {
                name = item.name;
            } else {
                const historicalItem = AccountItemQuery.number(this.props.historicalMonths[11].account_items, number);
                if (historicalItem) {
                    name = `${historicalItem.name} (historical)`;
                } else {
                    name = 'UNKNOWN';
                }
            }

            return (
                <tr>
                    <td>
                        {name}
                    </td>
                    <td>
                        {number}
                    </td>
                </tr>
            );
        };

        return (
            <div className="col-xs-12">
                <h4>(Debug) Revenue Types</h4>
                {
                    this.getRevenueTypes().map((type, index) => {
                        const isHistorical = type.account_numbers.find(num => this.isAccountNumberHistorical(num)) !== undefined;
                        return ( // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="row" style={{ border: '1px solid #000', padding: '5px' }}>
                                <div className="col-xs-4">
                                    {type.name} {isHistorical ? '(historical)' : ''}
                                    <strong>{revenueTypes.deletedItems.indexOf(type) >= 0 ? '(will be deleted)' : ''}</strong>
                                </div>
                                <div className="col-xs-7">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>name</strong>
                                                </td>
                                                <td>
                                                    <strong>acc. no.</strong>
                                                </td>
                                            </tr>
                                            {type.account_numbers.map(getNumberDescription)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-xs-1">id: {type.id}</div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    /**
     * returns all Rrevenue Types, including those that have been marked for deletion in the current session
     * @returns {Array.<AccountNumberGroup>}
     */
    getRevenueTypes() {
        const { revenueTypes } = this.state;
        return revenueTypes.items.concat(revenueTypes.deletedItems);
    }

    getRevenueSources() {
        const forecastRevenueType = this.RevenueSourceType;

        return this.state.revenueSources.Items
            // filter out items being converted to marriage on engagement screen
            .filter(revenueSource => revenueSource.type === forecastRevenueType);
    }

    getConvertToMarriageRevenueSource() {
        const { convertToMarriageRevenueSource } = this.state;

        const onCancel = () => {
            this.closeConvertToMarriagePopup();
        };

        const onConvert = () => {
            this.convertEngagementRevenueSourceToMarriage(convertToMarriageRevenueSource);
            this.closeConvertToMarriagePopup();
        };

        return (
            <Popup showCloseIcon={false}>
                <div>
                    <h4>Convert to Marriage Revenue</h4>
                    <p>Do you want to convert "{convertToMarriageRevenueSource.name}" into Marriage Revenue?</p>
                    <p>This will apply a 100% of the total and move it to the Marriage revenue section. This can't be undone.</p>
                    <div className="row">
                        <div className="col-xs-6 marginBothBtn text-center">
                            <button
                                className="btn btn-lg btn-action"
                                onClick={onConvert}
                            >
                                Convert
                            </button>
                        </div>
                        <div className="col-xs-6 marginBothBtn text-center">
                            <button
                                className="btn btn-lg btn-primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    getPopup() {
        const { editingRevenueSource, deletingRevenueSource, convertToMarriageRevenueSource } = this.state;

        if (convertToMarriageRevenueSource !== null) {
            return this.getConvertToMarriageRevenueSource();
        }

        if (editingRevenueSource !== null) {
            return this.getEditRevenueSourcePopup();
        }

        if (deletingRevenueSource !== null) {
            return this.getDeleteRevenueSourcePopup();
        }

        return null;
    }

    getEditRevenueSourcePopup() {
        const { editingRevenueSource } = this.state;

        const onCancel = () => {
            this.closeEditPopup();
            this.discardLocalChanges();
        };

        const updateName = (targetSource, val) => {
            targetSource.name = val; // eslint-disable-line no-param-reassign
            this.setState({
                revenueSources: this.state.revenueSources,
            });
        };

        if (this.RevenueSourceType !== editingRevenueSource.type) {
            throw new Error(`screen type "${this.RevenueSourceType}" doesn't match revenue source type "${editingRevenueSource.type}"`);
        }

        const accountItemLines = editingRevenueSource.account_numbers
            .filter(accountNumber => this.isAccountNumberHistorical(accountNumber) === false);
        const accountNumberRows = accountItemLines.map(accountNumber => this.getAccountNumberRow(accountNumber, editingRevenueSource));

        const hasRows = accountNumberRows.length > 0;

        const monthHeadingRows = hasRows
            ? (
                <div className="row">
                    <div className="col-xs-11">
                        <div className="row">
                            <div className="col-xs-12">
                                <MultiMonthValuesRow
                                    titleColContent=""
                                    columnValues={generateTwelveMonthHeadings(this.props.forecastMonths[0].Date)}
                                    totalColumnContent="Total"
                                    className={MultiMonthValuesRow.ClassNames.noBorder}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1" />
                </div>
            ) : null;

        // show engagement weighting row ABOVE revenue lines if there More Than One revenue line in the revenue source
        const engagementWeightingRow = this.RevenueSourceType === AccountNumberGroup.Types.EngagementRevenueSource
            && accountItemLines.length > 1
            ? (
                <div className="row deletable-row-wrapper">
                    <div className="col-xs-11">
                        <div className="row">
                            {this.getRevenueSourceWeightingRow(editingRevenueSource)}
                        </div>
                    </div>
                </div>
            ) : null;

        return (
            <PopupWithButtons
                className="edit-revenue-source-popup"
                onCancel={onCancel}
                onDone={() => this.saveRevenueSource(editingRevenueSource)}
            >
                <div className="col-xs-12 z-index-10">
                    <div className="row">
                        <div className="col-xs-12 revenue-source-row">
                            <div className="col-xs-12 no-padding">
                                <Debounce
                                    className="col-xs-5"
                                    value={editingRevenueSource.name}
                                    onChange={val => updateName(editingRevenueSource, val)}
                                />
                            </div>
                        </div>
                    </div>
                    {monthHeadingRows}
                    {engagementWeightingRow}
                    {accountNumberRows}
                    {this.getRevenueTypeInputRow(editingRevenueSource)}
                </div>
            </PopupWithButtons>
        );
    }

    getDeleteRevenueSourcePopup() {
        const { deletingRevenueSource } = this.state;

        const onDelete = () => {
            this.closeDeleteRevenueSourcePopup();
            this.deleteRevenueSource(deletingRevenueSource);
        };

        return (
            <Popup showCloseIcon={false}>
                <div>
                    <h4>Delete Revenue Source</h4>
                    <p>Are you sure you want to delete "{deletingRevenueSource.name}?"</p>
                    <p>This can't be undone.</p>
                    <div className="row">
                        <div className="col-xs-6 marginBothBtn text-center">
                            <button
                                className="btn btn-lg btn-danger"
                                onClick={onDelete}
                            >
                                Delete
                            </button>
                        </div>
                        <div className="col-xs-6 marginBothBtn text-center">
                            <button
                                className="btn btn-lg btn-primary"
                                onClick={() => this.closeDeleteRevenueSourcePopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }

    /**
     * Returns the result of deleting account number lines from a revenue source.
     * DOES NOT update the state, but the returned data can be used to do such.
     * @param accountNumbers account numbers to delete from the Revenue Source
     * @param revenueSource Revenue Source to delete items from from
     * @returns {{
     *     accountItems: {BatchUpdateContainer},
     *     revenueSources: {BatchUpdateContainer},
     *     revenueTypes: {BatchUpdateContainer},
     * }}
     */
    getDeleteAccountNumberLinesResult(accountNumbers, revenueSource) {
        const { revenueSources } = this.state;
        let { revenueTypes, accountItems } = this.state;

        accountNumbers.forEach(accountNumber => {
            if (!revenueSource.account_numbers.includes(accountNumber)) {
                throw new Error(`account number "${accountNumber}" not found in revenueSource "${revenueSource.name}"`);
            }

            // remove account number from Revenue Source
            // delete account items in the account number line
            // delete the revenue type if there are no lines left in it

            const accountItemsToDelete = AccountNumberQuery.allWithNumber(accountItems.items, accountNumber);

            revenueSource.removeAccountNumber(accountNumber);

            const revenueTypesIncludingLine = revenueTypes.items.filter(type => type.account_numbers.includes(accountNumber));

            revenueTypesIncludingLine.forEach(type => type.removeAccountNumber(accountNumber));

            const revenueTypesToRemove = revenueTypesIncludingLine.filter(revenueType => {
                // revenue types with historical lines were previously not deleted,
                // they are now treated the same

                // if (revenueType.account_numbers.find(number => this.isAccountNumberHistorical(number))) {
                //     return false;
                // }

                const accountItemsForRevenueType = revenueType.getItems(accountItems.items);
                const revenueItemsForRevenueType = AccountItemQuery.allForCode(accountItemsForRevenueType, TagCode.IS_Revenue_Revenue);

                // delete revenue types that have no forecast revenue lines left
                return revenueItemsForRevenueType.length === 0;
            });

            accountItems = accountItems.deleteItems(accountItemsToDelete);
            revenueTypes = revenueTypes.deleteItems(revenueTypesToRemove);
        });

        return {
            accountItems,
            revenueSources: new BatchUpdateContainer(revenueSources),
            revenueTypes,
        };
    }

    getSubtitle() {
        const { forecastRevenueType } = this.props;

        switch (forecastRevenueType) {
            case EditRevenueScreen.ForecastRevenueTypes.Marriage:
                return (
                    <p>
                        Enter currently known Revenue Sources.<br />
                        Click the + button to add a new Marriage Revenue Source, such as a newly signed Contract.<br />
                        Add Revenue lines to the new Revenue Source to specify the different types of Revenue it will include. Select a historical revenue line, or enter a new type of Revenue.<br />
                    </p>
                );
            case EditRevenueScreen.ForecastRevenueTypes.Engagement:
                return (
                    <p>
                        Enter potential Revenue Sources.<br />
                        Click the + button to add a new Engagement Revenue Source, such as a potential new sales lead.<br />
                        Add Revenue lines to the new Revenue Source to specify the different types of Revenue it will include. Select a historical revenue line, or enter a new type of Revenue.<br />
                    </p>
                );
            default:
                return null;
        }
    }

    getRevenueSourceHeaderRow() {
        const revenueSources = this.getRevenueSources()
            .filter(revenueSource => revenueSource.id !== null);

        // hide header if there are no saved Revenue Sources
        if (revenueSources.length === 0) {
            return null;
        }

        if (this.props.forecastRevenueType === EditRevenueScreen.ForecastRevenueTypes.Engagement) {
            return (
                <div className="revenue-source-row-container">
                    <div className="col-xs-12">
                        <div className="row revenue-source-row-header">
                            <div className="col-xs-12">
                                <div className="revenue-source-row-content">
                                    <div className="revenue-source-row-content-flexible">
                                        <div className="label-column" />
                                        <div className="value-column">
                                            Full Value
                                        </div>
                                        <div className="value-column">
                                            Weighted
                                        </div>
                                        <div className="value-column">
                                            Weighted Total
                                        </div>
                                    </div>
                                    <div className="revenue-source-row-content-fixed engagement" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    get RevenueSourceType() {
        const { forecastRevenueType } = this.props;

        switch (forecastRevenueType) {
            case EditRevenueScreen.ForecastRevenueTypes.Marriage:
                return AccountNumberGroup.Types.MarriageRevenueSource;
            case EditRevenueScreen.ForecastRevenueTypes.Engagement:
                return AccountNumberGroup.Types.EngagementRevenueSource;
            default:
                throw new Error(`invalid forecastRevenueType "${forecastRevenueType}"`);
        }
    }

    get RevenueTypeName() {
        const { forecastRevenueType } = this.props;

        switch (forecastRevenueType) {
            case EditRevenueScreen.ForecastRevenueTypes.Marriage:
            case EditRevenueScreen.ForecastRevenueTypes.Engagement:
                return forecastRevenueType;
            default:
                throw new Error(`invalid forecastRevenueType "${forecastRevenueType}"`);
        }
    }

    convertEngagementRevenueSourceToMarriage(revenueSource) {
        if (revenueSource.type !== AccountNumberGroup.Types.EngagementRevenueSource) {
            throw new Error(`can't convert Revenue Source of type "${revenueSource.type}" into Marriage Revenue`);
        }

        const accountItemLines = this.getForecastRevenueLinesForRevenueSource(revenueSource);

        const accountItems = accountItemLines.reduce((accum, line) => accum.concat(line), []);

        accountItems.forEach(accountItem => {
            const formula = ForecastFormula.evaluatePercentValue(accountItem.value_formula);
            accountItem.value_formula = null;
            accountItem.value = formula.value;
        });

        revenueSource.type = AccountNumberGroup.Types.MarriageRevenueSource;

        this.saveRevenueSource(revenueSource);
    }

    isAccountNumberHistorical(accountNumber) {
        const { historicalMonths } = this.props;

        return historicalMonths.reduce((accum, month) => accum.concat(month.account_items), [])
            .filter(accountItem => accountItem.account_number === accountNumber)
            .length > 0;
    }

    /**
     * Discards Revenue Sources, Revenue Types and Account Items in the state.
     * Reloads these values from props.
     * @returns {Promise<void>}
     */
    discardLocalChanges() {
        this.setState({
            revenueSources: this.getDefaultRevenueSources(),
            revenueTypes: this.getDefaultRevenueTypes(),
            accountItems: this.getDefaultAccountItems(),
        });
    }

    /**
     * Saves all of the Revenue Sources, Revenue Types, and Account Items in the state.
     * This becomes slow with increasing numbers of Revenue Sources, saveRevenueSource(revenueSource) instead.
     */
    saveRevenueSources() {
        const { handleSaveData } = this.props;
        const { revenueSources, revenueTypes, accountItems } = this.state;

        // TODO: possibly add warning when a Revenue Type will be deleted

        const updatePayload = {
            account_number_groups: {
                update: revenueSources.Items.concat(revenueTypes.Items),
                delete: revenueSources.DeleteIds.concat(revenueTypes.DeleteIds),
            },
            account_items: {
                update: accountItems.items,
                // TODO: delete direct costs for deleted Revenue Types
                delete: accountItems.deletedItems.map(item => item.id),
            },
        };

        this.closeEditPopup();
        handleSaveData(updatePayload);
    }

    /**
     * @param {AccountNumberGroup} revenueSource
     */
    saveRevenueSource(revenueSource) {
        const { handleSaveData } = this.props;
        const { revenueSources, revenueTypes, accountItems } = this.state;

        // TODO: possibly add warning when a Revenue Type will be deleted

        // only update the given revenue source, unless it is to be deleted
        const updatedRevenueSources = revenueSources.items.filter(item => item === revenueSource);

        // only save items in the given revenue source
        const accountItemsForSource = revenueSource.getItems(accountItems.items);

        // save account items for newly created revenue types
        const accountItemsForNewRevenueTypes = revenueTypes.items.filter(revType => revType.id === null)
            .reduce((accum, curr) => accum.concat(curr.getItems(accountItems.items)), []);

        // save account items for newly created revenue types
        const accountItemsForDeletedRevenueTypes = revenueTypes.deletedItems
            .reduce((accum, curr) => accum.concat(curr.getItems(accountItems.items)), []);

        // use BatchUpdateContainer to remove duplicates
        const accountItemsToSave = new BatchUpdateContainer()
            .addItems(accountItemsForSource)
            .addItems(accountItemsForNewRevenueTypes);

        const updatePayload = {
            account_number_groups: {
                update: updatedRevenueSources.concat(revenueTypes.Items),
                delete: revenueSources.DeleteIds.concat(revenueTypes.DeleteIds),
            },
            account_items: {
                update: accountItemsToSave.items,
                delete: accountItems.deletedItems.concat(accountItemsForDeletedRevenueTypes).map(item => item.id),
            },
        };

        this.closeEditPopup();
        handleSaveData(updatePayload);
    }

    createRevenueSource() {
        const { revenueSources } = this.state;

        const newItem = new AccountNumberGroup({
            name: `New ${this.RevenueTypeName} Revenue Source`,
            type: this.RevenueSourceType,
        });

        this.setState({
            revenueSources: revenueSources.addItems(newItem),
            editingRevenueSource: newItem,
            addRevenueLineTextInput: null,
            engagementWeightingOption: EngagementWeightingOptions.AsAWhole,
        });
    }

    deleteRevenueSource(deletedSource) {
        const { revenueSources, accountItems, revenueTypes } = this.getDeleteAccountNumberLinesResult(deletedSource.account_numbers, deletedSource);

        this.setState({
            accountItems,
            revenueSources: revenueSources.deleteItems(deletedSource),
            revenueTypes,
        }, () => this.saveRevenueSource(deletedSource));
    }

    handleDeleteAccountNumberLine(accountNumber, revenueSource) {
        const { revenueSources, revenueTypes, accountItems } =
            this.getDeleteAccountNumberLinesResult([accountNumber], revenueSource);

        this.setState({
            revenueSources,
            revenueTypes,
            accountItems,
        });
    }

    createRevenueLine(revenueSource, revenueType) {
        const { forecastMonths } = this.props;
        const { accountItems, revenueTypes } = this.state;

        const tagId = TagCategoryStore.getTagForCode(TagCode.IS_Revenue_Revenue).id;

        let formulas = null;
        if (revenueSource.type === AccountNumberGroup.Types.EngagementRevenueSource) {
            const revenueSourcePercentages = this.getEngagementWeightingPercentages(revenueSource);

            if (revenueSourcePercentages !== null) {
                formulas = revenueSourcePercentages.map(percent => ForecastFormula.percentValue(percent, 0));
            } else {
                formulas = this.getDefaultEngagementFormulas();
            }
        }

        const prototypeItem = new AccountItem({
            name: revenueType.name,
            tag_id: tagId,
            account_number: AccountItem.generateUuid(),
        });

        // create account items for each month
        const createdItems = forecastMonths.map((month, index) => {
            const monthItem = new AccountItem(prototypeItem);

            if (formulas !== null) {
                monthItem.value_formula = formulas[index];
            }

            monthItem.month_id = month.id;
            return monthItem;
        });

        let updatedAccountItems = accountItems.addItems(createdItems);

        // add the line to the Revenue Type
        revenueType.addAccountNumber(prototypeItem.account_number);

        // add the Revenue Type if it is not in the list yet
        let updatedRevenueTypes = revenueTypes;
        if (!revenueTypes.items.includes(revenueType)) {
            // create direct costs
            const directCostsLines = this.createDirectCostsLines();
            const directCostsItems = directCostsLines.reduce((all, line) => all.concat(line), []);

            updatedAccountItems = updatedAccountItems.addItems(directCostsItems);

            directCostsLines.forEach(line => {
                revenueType.addAccountNumber(line[0].account_number);
            });

            updatedRevenueTypes = revenueTypes.addItems(revenueType);
        }

        // add the line to the Revenue Source
        revenueSource.addAccountNumber(prototypeItem.account_number);

        this.setState({
            accountItems: updatedAccountItems,
            revenueTypes: updatedRevenueTypes,
        });
    }

    createDirectCostsLines() {
        const { forecastMonths, historicalMonths } = this.props;

        const directCostsTagCodes = [
            TagCode.IS_DirectCosts_Wages,
            TagCode.IS_DirectCosts_Materials,
            TagCode.IS_DirectCosts_Depreciation,
            TagCode.IS_DirectCosts_Other,
        ];

        const directCostsTags = directCostsTagCodes.map(tagCode => TagCategoryStore.getTagForCode(tagCode));

        const prototypeItems = directCostsTags.map(tag => {
            const historicalPercentage = ForecastCalculator.getHistoricalDirectCostsSubcategoryPercentageOfRevenue(historicalMonths, tag.code);
            const formula = ForecastFormula.percentRevenue(historicalPercentage);

            return new AccountItem({
                name: tag.name,
                tag_id: tag.id,
                account_number: AccountItem.generateUuid(),
                value_formula: formula,
            });
        });

        const directCostsLines = prototypeItems.map(prototypeItem =>
            forecastMonths.map(month => {
                const item = new AccountItem(prototypeItem);
                item.month_id = month.id;
                return item;
            })
        );

        return directCostsLines;
    }

    isEngagementRevenueSourcePercentageSetIndividually(revenueSource) {
        const engagementPercentages = this.getEngagementWeightingPercentages(revenueSource);

        if (engagementPercentages === null) {
            return false;
        }

        // check if percentages are all the same
        const differentItem = engagementPercentages.find((current, index) => {
            if (index === 0) {
                return false;
            }

            const prev = engagementPercentages[index - 1];

            return prev !== current;
        });

        return typeof differentItem !== 'undefined';
    }

    showEditPopup(revenueSource) {
        let engagementWeightingOption = EngagementWeightingOptions.AsAWhole;

        if (revenueSource.type === AccountNumberGroup.Types.EngagementRevenueSource) {
            const isSetIndividually = this.isEngagementRevenueSourcePercentageSetIndividually(revenueSource);

            if (isSetIndividually) {
                engagementWeightingOption = EngagementWeightingOptions.Individually;
            }
        }

        this.setState({
            editingRevenueSource: revenueSource,
            addRevenueLineTextInput: null,
            engagementWeightingOption,
        });
    }

    showConvertToMarriagePopup(revenueSource) {
        this.setState({
            convertToMarriageRevenueSource: revenueSource,
        });
    }

    closeConvertToMarriagePopup() {
        this.setState({
            convertToMarriageRevenueSource: null,
        });
    }

    closeEditPopup() {
        this.setState({
            editingRevenueSource: null,
            addRevenueLineTextInput: null,
        });
    }

    showDeleteRevenueSourcePopup(revenueSource) {
        this.setState({
            deletingRevenueSource: revenueSource,
        });
    }

    closeDeleteRevenueSourcePopup() {
        this.setState({
            deletingRevenueSource: null,
        });
    }

    render() {
        const { onDone } = this.props;

        const popup = this.getPopup();

        const explanation = this.getSubtitle();

        let videoLink = VideoLinks.Forecast.MarriageRevenue;
        if (this.RevenueTypeName === 'Engagement') {
            videoLink = VideoLinks.Forecast.EngagementRevenue;
        }

        return (
            <BaseForecastScreen
                buttonTextRight="Done"
                title={`${this.RevenueTypeName} Revenue`}
                onRightButtonClick={() => onDone()}
                videoLink={videoLink}
            >
                <div className="edit-revenue-sources">
                    {explanation}
                    <div className="col-xs-12 revenue-sources">
                        <div className="row">
                            {this.getRevenueSourceHeaderRow()}
                            {this.getRevenueSourceList()}
                            <div className="row">
                                <div className="col-xs-12">
                                    <button
                                        className="btn btn-action"
                                        onClick={() => this.createRevenueSource()}
                                    >
                                        + add new revenue source
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {popup}
                </div>
            </BaseForecastScreen>
        );
    }
}

EditRevenueScreen.ForecastRevenueTypes = {
    Marriage: 'Marriage',
    Engagement: 'Engagement',
};

EditRevenueScreen.propTypes = {
    accountNumberGroups: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberGroup)).isRequired,
    forecastMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)).isRequired,
    forecastRevenueType: PropTypes.oneOf([
        EditRevenueScreen.ForecastRevenueTypes.Marriage,
        EditRevenueScreen.ForecastRevenueTypes.Engagement,
    ]).isRequired,
    handleSaveData: PropTypes.func.isRequired,
    historicalMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)).isRequired,
    onDone: PropTypes.func.isRequired,
    storesReady: PropTypes.bool,
};
