let Month = require('../models/Month');
let Actions = require('../actions/MonthActions');
let Ajax = require('../endpoints/ajax');
import Formatter from "../../Formatter"

var MonthSource = {
    performFlush() {
        return {
            remote(state) {
                return Promise.resolve(true)
            },

            local() {
                return null;
            },

            success: Actions.flush,
            loading: Actions.flushing,
            error: Actions.flushingFailed
        }
    },

    fetchMonths() {
        return {
            remote(state, start, end) {
                if (typeof start != 'string') {
                    start = Formatter.momentToServerDateFormat(start);
                }

                if (typeof end != 'string') {
                    end = Formatter.momentToServerDateFormat(end);
                }

                return Ajax.getMonthsRange(start, end);
            },

            local() {
                return null;
            },

            success: Actions.receivedItems,
            loading: Actions.loadingItems,
            error: Actions.fetchingItemsFailed
        }
    },

    fetchAllMonths() {
        return {
            remote(state) {
                return Ajax.getAllMonths();
            },

            local() {
                return null;
            },

            success: Actions.receivedItems,
            loading: Actions.loadingItems,
            error: Actions.fetchingItemsFailed
        }
    },

    fetchAllMonthsIfExists() {
        return {
            remote(state) {
                return Ajax.getAllMonths()
                    .catch(err => {
                        if (err.status && err.status === 404) {
                            return [];
                        }
                        throw err;
                    });
            },

            local() {
                return null;
            },

            success: Actions.receivedItems,
            loading: Actions.loadingItems,
            error: Actions.fetchingItemsFailed
        }
    },

    fetchMonth() {
        return {
            remote(state, date) {
                if (typeof date != 'string') {
                    date = Formatter.momentToServerDateFormat(date);
                }
                return Ajax.getMonthsRange(date, date);
            },

            local(state, date) {
                return null;
            },

            success: Actions.monthReceived,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    queryMonth() {
        return {
            remote(state, date) {
                if (typeof date != 'string') {
                    date = Formatter.momentToServerDateFormat(date);
                }
                return Ajax.queryMonthsRange(date, date);
            },

            local(state, date) {
                return null;
            },

            success: Actions.monthIgnore,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    fetchLastHistoricMonth() {
        return {
            remote(state) {
                return Ajax.getMonthLastHistoric();
            },

            local() {
                return null;
            },

            success: Actions.lastHistoricMonthReceived,
            loading: Actions.lastHistoricMonthReceiving,
            error: Actions.lastHistoricMonthReceivingFailed
        }
    },

    fetchHistoricMonths() {
        return {
            remote(state) {
                return Ajax.getHistoricMonths();
            },

            local() {
                return null;
            },

            success: Actions.historicMonthsReceived,
            loading: Actions.historicMonthsReceiving,
            error: Actions.historicMonthsReceivingFailed
        }
    },

    fetchForecastMonths() {
        return {
            remote(state) {
                return Ajax.getForecastMonths()
                    .catch(err => {
                        if (err.status === 404) {
                            return [];
                        }
                        throw err;
                    });
            },

            local() {
                return null;
            },

            success: Actions.forecastMonthsReceived,
            loading: Actions.forecastMonthsReceiving,
            error: Actions.forecastMonthsReceivingFailed
        }
    },

    performReplaceForecastMonths() {
        return {
            remote(state, months) {
                return Promise.resolve(months);
            },

            local() {
                return null;
            },

            success: Actions.forecastMonthsReceived,
            loading: Actions.forecastMonthsReceiving,
            error: Actions.forecastMonthsReceivingFailed
        }
    },

    performReplaceMonth() {
        return {
            remote(state, month) {
                return Promise.resolve(month);
            },

            local() {
                return null;
            },

            success: Actions.monthReceived,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    performSaveMonths() {
        return {
            /**
             * @param state
             * @param {Month[]} months
             */
                remote(state, months) {
                return Ajax.saveMonths(months);
            },
            local() {
                return null;
            },
            success: Actions.itemsSaved,
            loading: Actions.itemsSaving,
            error: Actions.itemsSavingFailed
        }
    },

    performCreateOrUpdateMonths() {
        return {
            /**
             * @param state
             * @param {Month[]} months
             */
                remote(state, months) {
                return Ajax.createOrUpdateMonths(months);
            },
            local() {
                return null;
            },
            success: Actions.itemsSaved,
            loading: Actions.itemsSaving,
            error: Actions.itemsSavingFailed
        }
    },

    performSave() {
        return {
            /**
             * @param state
             * @param {Month} month
             */
                remote(state, month) {
                return Ajax.saveMonth(month);
            },
            local() {
                return null;
            },
            success: Actions.monthReceived,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    performDeleteAll() {
        return {
            remote() {
                return Ajax.deleteMonths();
            },
            local() {
                return null;
            },
            success: Actions.dataCleared,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    performDeleteSingle() {
        return {
            remote(state, month) {
                return Ajax.deleteMonth(month.id);
            },
            local() {
                return null;
            },
            success: Actions.monthIgnore,
            loading: Actions.monthReceiving,
            error: Actions.monthReceivingFailed
        }
    },

    performSaveMonthSnapshots() {
        return {
            /**
             * @param state
             * @param {MonthSnapshot[]} snapshots
             */
                remote(state, snapshots) {
                return Ajax.saveMonthSnapshots(snapshots);
            },
            local() {
                return null;
            },
            success: Actions.monthSnapshotsSaved,
            loading: Actions.monthSnapshotsSaving,
            error: Actions.monthSnapshotsSavingFailed,
        }
    },
};

module.exports = MonthSource;
