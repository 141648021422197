"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/ThreeWTagActions');
var Source = require('../sources/ThreeWTagSource');
var ThreeWTag = require("../models/ThreeWTag");

class ThreeWTagStore {
    constructor() {
        // State
        this.name = null;
        this.names = [];
        this.errorMessage = null;

        this.bindListeners({
            itemReceived: Actions.itemReceived,
            itemReceiving: Actions.itemReceiving,
            itemReceivingFailed: Actions.itemReceivingFailed,
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemDeleted: Actions.itemDeleted,
            itemDeleting: Actions.itemDeleting,
            itemDeletingFailed: Actions.itemDeletingFailed
        });

        this.exportPublicMethods({
            getNames: this.getNames
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemReceived(data) {
        this.setState({
            name: new ThreeWTag(data),
            errorMessage: null
        });
    }

    itemReceiving() {
        // pass typed letters and return matching tags perhaps? Otherwise..
        // Do nothing
    }

    itemReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsReceived(data) {
        this.setState({
            names: data.map(item => new ThreeWTag(item)),
            errorMessage: null
        });

    }

    itemsReceiving() {
        // Do nothing for now.
    }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemDeleted(data) {
        this.setState({
            names: this.names.filter(item => item.name == data.name)
        });
    }

    itemDeleting() {
        // Do nothing for now.
    }

    itemDeletingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    // ---- PUBLIC ----

    getNames() {
        return this.getState().names;
    }

    /**
     * Gets an ThreeWTag from the state.
     * @param {string} name
     * @returns {AccountNumberTag|null}
     */
    //getThreeWTag(name) {
    //    // TODO: Check that the state received is a copy. If it's a reference, the below code is wrong.
    //    return this.getState()
    //        .names
    //        .filter(item => item.name == name)
    //        .shift();
    //}
}

module.exports = alt.createStore(ThreeWTagStore);
