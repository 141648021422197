import Formatter from "../Formatter";

class Utils {
    static toClassName(string) {
        if (typeof string !== "string") {
            // console.warn(`Utils > toClassName, first parameter is not a string.`, string);
            return '';
        }
        return string.toLowerCase().replace(/[^A-Za-z0-9\s]/g, '').replace(/\s/g, '-');
    }


    static generateUuid() {
        var d = new Date().getTime();

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    static parseMoney(value) {
        if (typeof value == 'string') {
            return Number(value.replace(/[^0-9\.-]+/g,""));
        }

        if (typeof value == 'number') {
            return value;
        }
        return NaN;
    }

    /**
     * Parses the value, converts to string if not already a string
     */
    static parseMoneyAsString(value) {
        var valueString = value;
        if (typeof value == 'number') {
            valueString = value.toString();
        }

        return this.parseMoney(valueString);
    }

    static removePrecisionAndGetCommas(value){
        if (typeof value == 'string') {
            value = parseInt(value);
        }
        if (typeof value == 'number') {
            value = Math.floor(value);
        }
        return this.getCommas(value);
    }

    static getCommasWithPrecision(value) {
        if (typeof value === 'number') {
            value = value.toString();
        }
        let components = value.split(".");
        let integer = this.getCommas(components[0]);
        let fraction = components.length>1 ? "." + components[1] : "";
        return integer + fraction;
    }

    static getCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static parseNumberParentheses(value) {
        const formattedNumber = Utils.removePrecisionAndGetCommas(value);
        if (formattedNumber.charAt(0) === '-') {
            return `(${formattedNumber.slice(1)})`
        }
        return formattedNumber;
    }

    /**
     *
     * @param startDate string
     * @param endDate string
     * @param joiner string, default " - "
     * @returns {*}
     */
    static formatDateOrRange(startDate, endDate, joiner) {
        if (startDate === endDate) {
            return startDate;
        }
        const _joiner = joiner || ' - ';

        return `${startDate}${_joiner}${endDate}`;
    }

    static indexFromEnd(array, optionalIndex) {
        if (!Array.isArray(array)) {
            throw new Error('expected an array, but got something else');
        }

        const index = optionalIndex || 0;

        const indexFromStart = array.length - 1 - index;

        if (indexFromStart < 0) {
            throw new Error(`Array index "${indexFromStart}" ("${index}" from end) out of bounds`);
        }

        return array[indexFromStart];
    }

    static last(array) {
        return this.indexFromEnd(array, 0);
    }

    static repeat(iterations, func) {
        if (typeof iterations !== 'number' || iterations < 1) {
            return;
        }

        for (let index = 0; index < iterations; index += 1) {
            func(index);
        }
    }

    static isNullOrUndefined(val) {
        return val === null || typeof val === 'undefined';
    }

    static toFixedDots(val) {
        return val.toFixed(2) + '...';
    }

    static isInt(val) {
        const floatVal = parseFloat(val);
        return Math.round(floatVal) === floatVal;
    }

    static toFixedDotsOrInt(val) {
        return Utils.isInt(val) ? val : Utils.toFixedDots(val);
    }
}


module.exports = Utils;
