import React from 'react';
import {SpecialAccountItems} from '../../models/Enums';
export default {
    get SelectFilesDescription() {
        return (
            <p>
                CSV files come in all shapes and sizes! Prior to importing your CSV files, we suggest you take a moment to ensure that they have the right layout for simple and effective import into the RealTimeCEO app.
            </p>
                //TODO: add link to more info
                //Please look at this example and comments and spend a little time preparing your file to make the process easier.
        );
    },
    get TagColumnsThisYearIncomeStatement() {
        return (
            <div>
                <h2>Tag Columns - Current Year (Income Statement)</h2>
                <p>In this step, we need to define which columns relate to the account name and value for the period.</p>
            </div>
        );
        //account number tagging is disabled
        /*return (
            <div>
                <h2>Tag Columns - Current Year (Income Statement)</h2>
                <p>In this step, we need to define which columns relate to the account number, account name and value for the period.
                    Click on a tag, then click on the column that lists that data. (Dont worry about tagging account numbers if you dont have any!)</p>
            </div>
        );*/
    },

    get TagColumnsThisYearBalanceSheet() {
        return (
            <div>
                <h2>Tag Columns - Current Year (Balance Sheet)</h2>
                <p>In this step, we need to define which columns relate to the account name and value at period end.</p>
            </div>
        );
        //account number tagging is disabled
        /*return (
            <div>
                <h2>Tag Columns - Current Year (Balance Sheet)</h2>
                <p>In this step, we need to define which columns relate to the account number, account name and value at period end.
                    Click on a tag, then click on the column that lists that data. (Dont worry about tagging account numbers if you dont have any!)</p>
            </div>
        );*/
    },

    get Tagging() {
        return (
            <div>
                <p> 'Tagging' maps your chart of accounts to the RTC format. Tagging occurs at three levels:</p>
                <ul>
                    <li><strong>"Header Tags"</strong> These identify the total of the section. These must be done first.
                        While some find it unusual to start with the total, it will make the process easier.
                        Below you will see that you then use “Sub Tags” or “Optional Tags” for more detail.
                        Any remaining component of the total not Sub Tagged or Optional Tagged will be listed in that section as “{SpecialAccountItems.AutomaticallyAllocated.Name}”.
                    </li>
                    <li><strong>"Sub Tags"</strong> These are necessary as the RTC app requires certain information to perform the RTC calculations.</li>
                    <li><strong>"Optional Tags"</strong> These are voluntary options for additional detail you may want include in your data, but not needed by RTC app calculations.</li>
                </ul>
                <p>To tag an item, click on the tag then click on the relevant line in your chart of accounts.</p>
                <p>The app will import the value from that account for each month.</p>
                <p>If you make a mistake, you can remove a tag by clicking on it again at any time.</p>
            </div>
        );
    },

    get PreviewCurrentYearIncomeStatement() {
        return (
            <div>
                <h2>Current Year Income Statement</h2>
                <p>Now that you have tagged your income statement csv file, let's check to see what that tagging looks like in terms of current year figures.</p>
                <p>If these do not look right, please go back and check your tagging.</p>
            </div>
        );
    },

    get PreviewCurrentYearBalanceSheet() {
        return (
            <div>
                <h2>Current Year Balance Sheet</h2>
                <p>Now that you have tagged your balance sheet csv file, let's check to see what that tagging looks like in terms of current year figures.</p>
                <p>If these do not look right, please go back and check your tagging.</p>
            </div>
        );
    },

    get PreviewPreviousYearIncomeStatement() {
        return (
            <div>
                <h2>Previous Year Income Statement</h2>
                <p>Now that you have tagged your income statement csv file, let's check to see what that tagging looks like in terms of previous year figures.</p>
                <p>If these do not look right, please go back and check your tagging.</p>
            </div>
        );
    },

    get PreviewPreviousYearBalanceSheet() {
        return (
            <div>
                <h2>Previous Year Balance Sheet</h2>
                <p>Now that you have tagged your balance sheet csv file, let's check to see what that tagging looks like in terms of previous year figures.</p>
                <p>If these do not look right, please go back and check your tagging.</p>
            </div>
        );
    },

    get PreviewStartMonthBalanceSheet() {
        return (
            <div>
                <h2>Starting Month Balance Sheet</h2>
                <p>Now that you have tagged your balance sheet csv file, let's check to see what that tagging looks like in terms of this months figures.</p>
                <p>If these do not look right, please go back and check your tagging.</p>
            </div>
        );
    },

    TagRows: {
        get TaggingExplanation() {
            return (
                <p>
                    To tag an item, click on the tag then click on the relevant mapped account.
                    <br/>Once you are finished with each section, select the next section tab and continue.
                    <br/>If you make a mistake, you can you can remove a tag by clicking on it again at any time.
                </p>
            );
        },

        get ["Revenue"]() {
            return (
                <div>
                    <h2>Tag Revenue</h2>
                    <p>Header Tag: Tag your <em>Total Revenue</em> line. This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines.
                        If the total revenue line includes any <em>interest revenue,</em> then use the Sub Tag to define that line in your chart of accounts.
                        If <em>interest revenue</em> is listed elsewhere in your chart of accounts, do NOT use the Sub Tag in this section.
                    </p>
                    <p>
                        Optional Tags: If you want to bring in more detail regarding your revenue lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.
                        This is encouraged especially in the revenue section.
                    </p>
                </div>
            );
        },

        get ["Direct Costs"]() {
            return (
                <div>
                    <h2>Tag Direct Costs</h2>
                    <p>
                        Traditionally, the chart of accounts listed costs as “Cost of Goods Sold” and “Overheads”.
                        As it became apparent that understanding costs was critical to understanding business, the definition evolved into “Direct” and “Indirect” costs.
                        For more insight into the reason for this transition click here:
                    </p>
                    <p>Header Tag: Tag your <em>Total Direct Costs</em> line. This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines.
                        Please use the Sub Tags to select any <em>material, wages</em> or <em>depreciation</em> lines.
                        If <em>depreciation</em> is listed elsewhere in your chart of accounts, do NOT use the Sub Tag in this section.
                    </p>
                    <p>Optional Tags: If you want to bring in more detail regarding your direct costs lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.</p>
                </div>
            );
        },

        get ["Indirect Costs"]() {
            return (
                <div>
                    <h2>Tag Indirect Costs</h2>
                    <p>Header Tag: Tag your <em>Total Indirect Costs</em> line. This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines.
                        Please use the Sub Tags to select any <em>wages, depreciation</em> or <em>interest</em> lines.
                        If <em>depreciation</em> or <em>interest</em> is listed elsewhere in your chart of accounts, do NOT use the Sub Tag in this section.
                    </p>
                    <p>Optional Tags: If you want to bring in more detail regarding your indirect costs lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.</p>
                </div>
            );
        },

        get ["Profit"]() {
            return (
                <div>
                    <h2>Tag Net Profit</h2>
                    <p>
                        To complete the income statement we need to tag some miscellaneous components.
                        As miscellaneous items do not always have their own total, we are going to do this a slightly different way.
                        First we are going to Tag the final <em>Net Profit.</em>
                        The app will then calculate the <em>Total Miscellaneous</em> value based on <em>Revenue, Direct Costs, Indirect Costs</em> and <em>Net Profit</em> data already entered.
                        Then in the next step we will tag the required miscellaneous items.
                    </p>
                    <p>Header Tag: Tag your <em>Net Profit.</em></p>
                </div>
            );
        },

        getMisc(miscTotal) {
            return (
                <div>
                    <h2>Tag Miscellaneous</h2>
                    <h4> Miscellaneous Total: {miscTotal}</h4>
                    <p>
                        The app has calculated the <em>Total Miscellaneous</em> value based on <em>Revenue, Direct Costs, Indirect Cost</em> and <em>Net Profit</em> data already entered so there is no Header Tag in this step.
                        Just Tag the required miscellaneous items.
                    </p>
                    <p>Sub Tags: <em>Interest Revenue, Interest Expense</em> and <em>Depreciation</em> if they are listed in this section.</p>
                </div>
            );
        },

        get ["Distributions"]() {
            return (
                <div>
                    <h2>Tag Distributions</h2>
                    <p>
                        The final tagging required in the Income Statement are for any items after <em>Net Profit</em> and before <em>Change in Retained Earnings</em>.
                        Typically these would be Federal tax payments or distributions to owners. Once again, we do not use a Header Tag.
                        Just tag the appropriate <em>distribution</em> lines after <em>Net Profit</em> and before <em>Change in Retained Earnings</em>.
                    </p>
                    <p>Sub Tags: Distributions</p>
                </div>
            );
        },

        get ["Total Assets"]() {
            return (
                <div>
                    <h2>Tag Assets</h2>
                    <p>Header Tag: Tag your <em>Total Assets.</em> This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines.
                        Please use the Sub Tags to select any bank balances, accounts receivable, inventory, fixed assets, non interest bearing related party loans or interest bearing related party loans.
                    </p>
                    <p>
                        Optional Tags: If you want to bring in more detail regarding your asset lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.
                    </p>
                </div>
            );
        },

        get ["Total Liabilities"]() {
            return (
                <div>
                    <h2>Tag Liabilities</h2>
                    <p>Header Tag: Tag your <em>Total Liabilities.</em> This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines. Please use the Sub Tags to select any interest bearing debt, accounts payable, federal tax payable, dividends payable, deferred or prepaid revenue, non interest bearing related party loans and interest bearing related party loans.
                    </p>
                    <p>
                        Optional Tags: If you want to bring in more detail regarding your liability lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.
                    </p>
                </div>
            );
        },

        get ["Total Equity"]() {
            return (
                <div>
                    <h2>Tag Equity</h2>
                    <p>Header Tag: Tag your <em>Total Equity.</em> This must be done first.</p>
                    <p>
                        Sub Tags: The total may be the sum of many lines. Please use the Sub Tags to select any initial capital, retained earnings. Retained earnings should include all prior year retained earnings AND the current year profit.
                    </p>
                    <p>
                        Optional Tags: If you want to bring in more detail regarding your equity lines, use the <em>Optional User Defined Tags</em> to define individual lines in your chart of accounts.
                    </p>
                </div>
            );
        },

        get BalanceSheetThisYear() {
            return (
                <div>
                    <h2>Tag Rows, Balance Sheet - Current Year</h2>
                    <p>This step maps your accounts to the RTC format.</p>
                    <p>
                        <strong>The first tag of each section must be the header tag which maps to the total of that
                            section.</strong>
                        <br />Then use the sub-tags to identify the accounts that should be included in that
                        sub-tag.
                        <br />We only require you to sub-tag the items we need for specific RTC calculations so
                        there will not be a sub-tag for every account in your chart of accounts. Furthermore, you
                        may not need to use all the sub-tags if you don’t have that type of account in the section.
                        <br />Later you will be able to split any accounts that were not sub tagged if you believe
                        that will add value.
                    </p>
                    {this.TaggingExplanation}
                </div>
            );
        },

        get IncomeStatementPriorYear() {
            return (
                <div>
                    <h2>Tag Rows, Income Statement - Prior Year</h2>
                    <p>Earlier in the wizard we tagged the current period income statement and balance sheet.
                        Those tags have now been applied to the Prior Year. Check they are still correct and then proceed.</p>
                    {this.TaggingExplanation}
                </div>
            );
        },

        get BalanceSheetPriorYear() {
            return (
                <div>
                    <h2>Tag Rows, Balance Sheet - Prior Year</h2>
                    <p>Earlier in the wizard we tagged the current period income statement and balance sheet.
                        Those tags have now been applied to the Prior Year. Check they are still correct and then proceed.</p>
                    {this.TaggingExplanation}
                </div>
            );
        },

        get OpeningBalanceSheetPriorYear() {
            return (
                <div>
                    <h2>Tag Rows, Opening Balance Sheet - Prior Year</h2>
                    <p>Earlier in the wizard we tagged the current and prior period Balance Sheets.
                        Those tags have now been applied to the Opening Balance Sheet for the Prior Year. Check they are still correct and then proceed.</p>
                    {this.TaggingExplanation}
                </div>
            );
        },

        get OpeningBalanceSheetStartMonth() {
            return (
                <div>
                    <h2>Tag Rows, Opening Balance Sheet - Start Month</h2>
                    <p>Earlier in the wizard we tagged the current and prior period Balance Sheets.
                        Those tags have now been applied to the Opening Balance Sheet for the starting months. Check they are still correct and then proceed.</p>
                    {this.TaggingExplanation}
                </div>
            );
        },

    }
}