

class ForecastSummary {
    constructor(options) {
        var fields = [
            'aspiration_formula',
            'aspiration_amount',
            'material_dating_percent',
            'wages_dating_percent'
        ];

        fields.forEach(fieldName => {
            if (options != null && options.hasOwnProperty(fieldName)) {
                var value = options[fieldName];

                //switch (fieldName) {
                //  handle special cases here if necessary (ie need to change data type)
                //}

                this[fieldName] = value;
            }
        });

        return this;
    }

    static createNewForecastSummary() {
        return new ForecastSummary({
            aspiration_formula: null,
            aspiration_amount: 0,
            material_dating_percent: 0,
            wages_dating_percent: 0,
        });
    }

    clone() {
        return new ForecastSummary({
            aspiration_formula: this.aspiration_formula,
            aspiration_amount: this.aspiration_amount,
            material_dating_percent: this.material_dating_percent,
            wages_dating_percent: this.wages_dating_percent,
        });
    }
}

export default ForecastSummary;