"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNotNull = exports.filterMapArray = exports.Filter = exports.padItems = exports.getDistinctItems = exports.groupItemsByKey = void 0;
function groupItemsByKey(items, getKey) {
    const itemMap = {};
    items.forEach(it => {
        const key = getKey(it);
        if (!itemMap[key]) {
            itemMap[key] = [];
        }
        itemMap[key].push(it);
    });
    return Object.keys(itemMap).map(key => ({ key, items: itemMap[key] }));
}
exports.groupItemsByKey = groupItemsByKey;
function getDistinctItems(items) {
    return items.filter((item, index) => items.indexOf(item) === index);
}
exports.getDistinctItems = getDistinctItems;
function padItems(items, padder) {
    return items.reduce((accum, item) => {
        if (accum.length === 0) {
            return [item];
        }
        return accum.concat(padder, item);
    }, []);
}
exports.padItems = padItems;
class Include {
    constructor(value) {
        this.__include = true;
        this.value = value;
    }
}
class Exclude {
    constructor() {
        this.__include = false;
    }
}
var Filter;
(function (Filter) {
    function include(value) {
        return new Include(value);
    }
    Filter.include = include;
    function exclude() { return new Exclude(); }
    Filter.exclude = exclude;
})(Filter = exports.Filter || (exports.Filter = {}));
function filterMapArray(source, func) {
    return source.reduce((accum, item, index) => {
        const result = func(item, index);
        if (result.__include) {
            return accum.concat(result.value);
        }
        return accum;
    }, []);
}
exports.filterMapArray = filterMapArray;
function isNotNull(it) {
    return it !== null;
}
exports.isNotNull = isNotNull;
