"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GraphColors;
(function (GraphColors) {
    GraphColors.DarkBlue = '#6a9eba';
    GraphColors.Blue = '#29adde';
    GraphColors.LightBlue = '#A9D4EA';
    GraphColors.Green = '#A0d468';
    GraphColors.Orange = '#ed872f';
    GraphColors.Red = '#ED5565';
    GraphColors.Purple = '#9b74ea';
})(GraphColors || (GraphColors = {}));
exports.default = GraphColors;
