import React from 'react';
import PropTypes from 'prop-types';

import Content from './Content';

class Row extends React.Component {
    render() {
        const { name, style, videoId,
            leftData,
            centerData,
            rightData,
        } = this.props;

        return (
            <div className="col-xs-12 row">
                <Content
                    name={name}
                    style={style}
                    leftData={leftData}
                    centerData={centerData}
                    rightData={rightData}
                    videoId={videoId}
                />
            </div>
        );
    }
}

const cellDataShape = PropTypes.shape({
    value: PropTypes.string,
    percentage: PropTypes.string,
});

Row.propTypes = {
    centerData: cellDataShape,
    leftData: cellDataShape,
    name: PropTypes.string,
    rightData: cellDataShape,
    style: PropTypes.string,
    videoId: PropTypes.string,
};

export default Row;
