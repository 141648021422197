"use strict";
class ThreeWTag {
    constructor(item) {
        this.name = null;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }
}

module.exports = ThreeWTag;