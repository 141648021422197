"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverviewTableRowData = void 0;
class OverviewTableRowData {
    constructor(data) {
        this.name = null;
        this.columns = null;
        this.rowIndex = null;
        this.accountNumberTag = null;
        this.className = null;
        this.groupData = null;
        if (data) {
            if (data.name !== undefined)
                this.name = data.name;
            if (data.rows !== undefined)
                this.columns = data.rows;
            if (data.rowIndex !== undefined)
                this.rowIndex = data.rowIndex;
            if (data.accountNumberTag !== undefined)
                this.accountNumberTag = data.accountNumberTag;
            if (data.className !== undefined)
                this.className = data.className;
            if (data.groupData !== undefined)
                this.groupData = data.groupData;
        }
    }
    get accountNumber() {
        return this.accountNumberTag ? this.accountNumberTag.account_number : null;
    }
    get isTotal() {
        return (this.accountNumberTag && this.accountNumberTag.tag) ? this.accountNumberTag.tag.isTotal : null;
    }
    get sectionCode() {
        return (this.accountNumberTag && this.accountNumberTag.tag) ? this.accountNumberTag.tag.code : null;
    }
    get groupId() {
        return this.groupData ? this.groupData.groupId : null;
    }
}
exports.OverviewTableRowData = OverviewTableRowData;
