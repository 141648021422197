import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const AccountItemActions = alt.generateActions(
    'itemSaved',
    'itemSaving',
    'itemSavingFailed',

    'itemReceived',
    'itemReceiving',
    'itemReceivingFailed',

    'itemDeleted',
    'itemDeleting',
    'itemDeletingFailed',

    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',

    'itemsSaved',
    'itemsSaving',
    'itemsSavingFailed',

    'itemsDeleted',
    'itemsDeleting',
    'itemsDeletingFailed',

    'wipedMonth',
    'wipingMonth',
    'wipingMonthFailed',

    'multiMonthItemsReceived'
);

module.exports = AccountItemActions;
