"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PanelOpenEvents;
(function (PanelOpenEvents) {
    const PanelOpenEventName = 'App:PanelOpen';
    function addOnPanelOpenListener(listener) {
        window.addEventListener(PanelOpenEventName, listener);
    }
    PanelOpenEvents.addOnPanelOpenListener = addOnPanelOpenListener;
    function removeOnPanelOpenListener(listener) {
        window.removeEventListener(PanelOpenEventName, listener);
    }
    PanelOpenEvents.removeOnPanelOpenListener = removeOnPanelOpenListener;
    function onPanelOpen() {
        window.dispatchEvent(new Event(PanelOpenEventName));
    }
    PanelOpenEvents.onPanelOpen = onPanelOpen;
})(PanelOpenEvents || (PanelOpenEvents = {}));
exports.default = PanelOpenEvents;
