/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
class TableRow extends React.Component {
    render() {
        return <div title={this.props.title} className={`table-row ${this.props.className}`} onClick={this.props.onClick}>
          {this.props.content}
        </div>
    }
}

TableRow.propTypes = {
    content: PropTypes.oneOf([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired)]).isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};

export default TableRow;