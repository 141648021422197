/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
class ProgressBar extends React.Component {

    render() {
        const { type } = this.props;
        const content = type === 'number'
            ? <h3>{this.getPercentage()}%</h3>
            : <div className="outer">
                <div className="inner" style={{ width: `${this.getPercentage()}%`}}> </div>
            </div>;
        return <div className="rtc-progress-bar">
            {content}
        </div>
    }

    getPercentage() {
        const { totalValue, currentValue } = this.props;
        return Math.round(currentValue / totalValue * 100);
    }
}

ProgressBar.propTypes = {
    currentValue: PropTypes.number,
    totalValue: PropTypes.number,
    type: PropTypes.oneOf(['number', 'bar'])
};

export default ProgressBar;