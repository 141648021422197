/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
class TabItem extends React.Component {
    render() {
        return <div {...this.props}>
            {this.props.children}
        </div>
    }
}

export default TabItem;