"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/AccountItemActions');
var Source = require('../sources/AccountItemSources');
var AccountItem = require('../models/AccountItem');

class AccountItemStore {
    constructor() {
        // State
        this.accountItem = new AccountItem;
        this.accountItems = [];
        this.errorMessage = null;

        this.bindListeners({
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            itemReceived: Actions.itemReceived,
            itemReceiving: Actions.itemReceiving,
            itemReceivingFailed: Actions.itemReceivingFailed,
            itemDeleted: Actions.itemDeleted,
            itemDeleting: Actions.itemDeleting,
            itemDeletingFailed: Actions.itemDeletingFailed,
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemsSaved: Actions.itemsSaved,
            itemsSaving: Actions.itemsSaving,
            itemsSavingFailed: Actions.itemsSavingFailed,
            itemsDeleted: Actions.itemsDeleted,
            itemsDeleting: Actions.itemsDeleting,
            itemsDeletingFailed: Actions.itemsDeletingFailed,
            wipedMonth: Actions.wipedMonth,
            wipingMonth: Actions.wipingMonth,
            wipingMonthFailed: Actions.wipingMonthFailed,
            multiMonthItemsReceived: Actions.multiMonthItemsReceived,
        });

        this.exportPublicMethods({
            getAccountItem: this.getAccountItem,
            getAccountItemsForMonth: this.getAccountItemsForMonth,
            getAccountItemForMonth: this.getAccountItemForMonth
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemSaved(data) {
        this.itemReceived(data);
        this.errorMessage = null;
    }

    itemSaving() {
        // Do nothing
    }

    itemSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemReceived(data) {
        var accountItem = new AccountItem(data);
        var accountItems = this.accountItems.slice();

        if (accountItems.filter(item => item.id == accountItem.id).length == 0) {
            accountItems.push(accountItem);
        } else {
            accountItems = accountItems.map(item => {
                if (item.id == accountItem.id) {
                    return accountItem;
                }

                return item;
            });
        }

        this.setState({
            accountItems: accountItems,
            errorMessage: null
        });
    }

    itemReceiving() {
        // Do nothing
    }

    itemReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemDeleted(data) {
        var accountItem = new AccountItem(data);

        this.setState({
            accountItems: this.accountItems.filter(item => item.id != accountItem.id)
        });
    }

    itemDeleting(data) {
        // Do nothing
    }

    itemDeletingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsReceived(data) {
        this.setState({
            accountItems: data.map(item => new AccountItem(item)),
            errorMessage: null
        });
        for (var item in this.accountItems){
            this.itemSaved(this.accountItems[item]);
        }
    }

    itemsReceiving() {
        // Do nothing
    }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsSaved(data) {
        this.itemsReceived(data);
    }

    itemsSaving() {
        // Do nothing
    }

    itemsSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsDeleted(data) {
        var accountItem = AccountItem(data);

        this.setState({
            accountItems: this.accountItems.filter(item => item.id != accountItem.id)
        });
    }

    itemsDeleting() {
        // Do nothing
    }

    itemsDeletingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }


    wipedMonth(data) {
        this.itemsReceived([]);
    }

    wipingMonth() {
        // Do nothing
    }

    wipingMonthFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    multiMonthItemsReceived() {
        // Do nothing
    }

    // ---- PUBLIC ----
    // Don't access their from private functions.
    getAccountItem(id) {
        var { accountItems } = this.getState();

        return accountItems
            .filter(item => item.id == id)
            .shift();
    }

    getAccountItems() {
        return this.getState().accountItems;
    }

    getAccountItemsForMonth(monthId) {
        return this.getState().accountItems.filter(item => item.month_id == monthId);
    }

    getAccountItemForMonth(id, monthId) {
        var { accountItems } = this.getState().accountItems.filter(item => item.month_id == monthId);

        return accountItems
            .filter(item => item.id == id)
            .shift();
    }
}

module.exports = alt.createStore(AccountItemStore, 'AccountItemStore');
