var SonQuestion = require('../models/SonQuestion');
var Actions = require('../actions/SonQuestionActions');
var Ajax = require('../endpoints/ajax');

var temp = [];
var SonQuestionSource = {
    fetchData(){
        return {
            remote(state){
                return Ajax.getSonQuestions();
            },
            local(){
                return null;
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    performSave() {
        return {
            remote(state, question){
                return Ajax.saveSonQuestion(question);
            },
            local(state, question){
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },

    performDelete() {
        return {
            remote(state, question){
                return Ajax.deleteSonQuestion(question);
            },
            local(state, question){
                return null;
            },

            success: Actions.itemDeleted,
            loading: Actions.itemDeleting,
            error: Actions.itemDeletingFailed
        }
    }
};

module.exports = SonQuestionSource;

