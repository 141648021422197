import alt from '../alt.ts';

const AccountNumberGroupActions = alt.generateActions(
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',

    'itemSaved',
    'itemSaving',
    'itemSavingFailed',

    'itemsSaved',
    'itemsSaving',
    'itemsSavingFailed',

    'groupsAndAccountItemsUpdated',
    'groupsAndAccountItemsUpdating',
    'groupsAndAccountItemsUpdatingFailed',
);

module.exports = AccountNumberGroupActions;
