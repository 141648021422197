import React from 'react';
import PropTypes from 'prop-types';

import AltContainer from 'alt-container';

const DataStore = require('../datastore');
const AccountStore = DataStore.AccountStore;
const UserStore = DataStore.UserStore;
import Popup from "../components/Popup"
import BillingScreen from "../account/BillingScreen"

class OnboardingFullScreenContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: null,
            restartPopupShown: false,
        };
    }

    closePopup() {
        this.setState({
            popup: null,
        });
    }

    showAccountSettingsPopup() {
        AccountStore.fetchAccount()
            .then(() => {
                const stores = [
                    UserStore,
                    AccountStore
                ];

                this.setState({
                    popup: (
                        <Popup
                            addBottomPadding={true}
                            showCloseIcon={true}
                            onDismiss={() => this.closePopup()}
                        >
                            <AltContainer stores={stores}>
                                <div className="text-left">
                                    <BillingScreen
                                        endpoint={DataStore.Ajax}
                                        onAction={() => this.closePopup()}
                                    />
                                </div>
                            </AltContainer>
                        </Popup>
                    ),
                });
            })
            .catch(err => {
                console.error(err);
                throw err;
            });
    }

    render() {
        const { showSubscription, children } = this.props;
        let subscriptionButton = null;

        if (showSubscription) {
            subscriptionButton = (
                <div
                    className="underline-on-hover pull-right"
                    style={{marginRight: '20px', color: '#1d8ab8', cursor: 'pointer'}}
                    onClick={() => this.showAccountSettingsPopup()}
                >
                    Account
                </div>
            );
        }

        const restartButton = this.props.onClickRestartWizard ? (
            <div
                className="underline-on-hover pull-left"
                style={{ marginLeft: '20px', color: '#1d8ab8', cursor: 'pointer' }}
                onClick={() => this.props.onClickRestartWizard()}
            >
                Restart Wizard
            </div>
        ) : null;

        return (
            <div className="full-screen-container">
                <div className="full-screen-content-wrapper">
                    <div className="full-screen-content-container">
                        <div className="login-form-header">
                            <div style={{ flexGrow: 1 }}>
                                {restartButton}
                                {subscriptionButton}
                            </div>
                        </div>
                        <div className="full-screen-content">
                            {children}
                        </div>
                        {this.state.popup}
                    </div>
                </div>
            </div>
        );
    }
}

OnboardingFullScreenContainer.propTypes = {
  showSubscription: PropTypes.bool,
  onClickRestartWizard: PropTypes.func,
};

export default OnboardingFullScreenContainer;