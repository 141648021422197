"use strict";
var GroupAccountUtils;
(function (GroupAccountUtils) {
    function isSubscribed(account) {
        return account.active_subscription !== null;
    }
    GroupAccountUtils.isSubscribed = isSubscribed;
    function hasData(account) {
        return account.IsOnboarding === false;
    }
    GroupAccountUtils.hasData = hasData;
    function getAccountErrorMessage(account) {
        if (!isSubscribed(account)) {
            return 'No Active Subscription';
        }
        if (!hasData(account)) {
            return 'Data not Onboarded';
        }
        return null;
    }
    GroupAccountUtils.getAccountErrorMessage = getAccountErrorMessage;
    /**
     * Sort order:
     *
     *      accounts with data
     *          most recent date first
     *              alphabetically for same date
     *      accounts with no data
     *          alphabetically
     *
     *
     * @param {LinkedAccountData[]} accounts
     * @return {LinkedAccountData[]}
     */
    function getSortedAccounts(accounts) {
        return accounts.slice().sort((accountA, accountB) => {
            const errorA = getAccountErrorMessage(accountA.account);
            const errorB = getAccountErrorMessage(accountB.account);
            // sort accounts with no data lower
            if (errorA && !errorB) {
                return 1;
            }
            if (errorB && !errorA) {
                return -1;
            }
            // sort accounts with no data alphabetically
            if (errorA && errorB) {
                return accountA.account.business_name.localeCompare(accountB.account.business_name);
            }
            // sort accounts with data by date, then alphabetically
            const dateA = accountA.flattenedMonths.slice(-1)[0].date;
            const dateB = accountB.flattenedMonths.slice(-1)[0].date;
            const dateOrder = -dateA.localeCompare(dateB);
            if (dateOrder !== 0) {
                return dateOrder;
            }
            return accountA.account.business_name.localeCompare(accountB.account.business_name);
        });
    }
    GroupAccountUtils.getSortedAccounts = getSortedAccounts;
})(GroupAccountUtils || (GroupAccountUtils = {}));
module.exports = GroupAccountUtils;
