// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import SortedRow from "./SortedRow"
import { RowStruct } from "../models/RealTimeCeo"
import { InputTypes, SpecialAccountItems } from "../models/Enums"

var DataStore = require('../datastore');
var AccountItemStore = DataStore.AccountItemStore;
var AccountNumberTagsStore = DataStore.AccountNumberTagsStore
var TagCategoryStore = DataStore.TagCategoryStore;
import Utils from "../utilities/Utils"
import { ButtonTypes, PictureButton } from "../../../common/Buttons"

import CategoryUtils from "../utilities/CategoryUtils"
import { TagCode } from '../models/EnumsTs';
import AccountItem from '../datastore/models/AccountItem';
import Tag from '../datastore/models/Tag';
import Month from '../datastore/models/Month';

const nonEditableAccountNumbers = [
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.IS_Revenue_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.IS_DirectCosts_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.IS_IndirectCosts_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.IS_Misc_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.IS_Distributions_Other,

    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.BS_TotalAssets_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.BS_TotalLiabilities_Other,
    SpecialAccountItems.AutomaticallyAllocated.Number + TagCode.BS_TotalEquity_Other,
];

/**
 * @deprecated
 */
class SubCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: true
        };
    }

    onExpandToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    getCollapseIcon(subCategories) {
        var iconClass = this.state.expanded ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";
        return subCategories.length > 0 ? <span className={iconClass}></span> : null;
    }

    //special case notes to user
    getSubtitle() {
        let message = null;
        //misc expenses
        if (this.props.editing === true) {
            let expenseCodes = CategoryUtils.NegativeMiscItems;
            if (expenseCodes.indexOf(this.props.tag.code) >= 0) {
                message = "Enter as a positive number";
            }
        }

        if (message != null) {
            return <span className="accordion-subtitle">{message}</span>
        } else return null;
    }

    getAccountItems() {
        var { tag, month } = this.props;

        let accountItems = month.account_items;//AccountItemStore.getState().accountItems;

        // let getParent = (item) => {
        //     let parent = accountItems.find((elem) => elem.account_number == item.pre_split_name );
        //     if (typeof parent == "undefined")
        //         return null;
        //     return parent;
        // };

        return accountItems
            .filter(item =>
                item.month_id == month.id && item.tag_id == tag.id
                // if (item.month_id != month.id) {
                //     return false;
                // }
                // let parent = getParent(item);
                // if (parent == null) {
                //     return (item.tag_id == tag.id);
                // } else {
                //     return (parent.tag_id == tag.id);
                // }
                // }
            );
    }

    /* Return all of the account number tags for the current tag */
    getAccountNumberTags() {
        var { tag } = this.props;

        return AccountNumberTagsStore
            .getAccountNumberTags();
            // .filter(item => item.tag_id = tag.id);
    }

    onLabelChange(accountItem, newName) {
        accountItem.name = newName;
        AccountItemStore.performSave(accountItem);
    }

    onAmountChange(accountItem, newAmount) {
        const { onChangeAccountItem } = this.props;

        accountItem.NetActivity = Utils.parseMoney(newAmount);
        onChangeAccountItem(accountItem);
    }

    onPercentageChange(accountItem, newAmount) {
        const { onChangeAccountItem } = this.props;

        accountItem.Percentage = newAmount;
        onChangeAccountItem(accountItem);
    }

    onRemoveRow(item) {
        this.props.onRemoveRow(item);
    }

    handleAddPressed() {
        const { tag, month, onAddAccountItem } = this.props;
        var accountItem = new AccountItem({
            name: tag.name + " new",
            month_id: month.id,
            tag_id: tag.id,
            value: 0,
            pre_split_name: tag.name + " new",
            account_number: Utils.generateUuid()
        });

        onAddAccountItem(accountItem);
    }

    getAddButton() {
        const { inputType } = this.props;

        if (inputType == InputTypes.Manual) {
            return <PictureButton buttonType={ButtonTypes.Plus} onClick={() => this.handleAddPressed()}/>;
        }

        return null;
    }

    /*
        Takes parent id, and returns the total net activity of children
    */
    getNetActivityForChildren(id) {
        var accountItems = this.getAccountItems();
        var netActivity = 0
        //For parent rows we must calculate net activity based on the child rows. I think we do this here..
        //Get the children AccountNumberTags, then find the children account numbers
        var children = AccountNumberTagsStore.getAccountNumberTags().filter(item => item.split_root_id == id);
        var childrenAccountNumbers = children.map(item => item.account_number);
        var childrenAccounts = accountItems.filter(item => childrenAccountNumbers.indexOf(item.account_number) > -1);
        for (var i in childrenAccounts) {
            var childAccount = childrenAccounts[i];
            netActivity = netActivity + childAccount.NetActivity;
        }
        return netActivity;
    }


    getRows() {
        const { editing, onRemoveRow, onReTag, onSplit, inputType, parentAccountItems } = this.props;
        var accountItems = this.getAccountItems();

        return accountItems.map(item => {
            var title = item.name;
            var netActivity = item.NetActivity;
            var percentageAmount = item.Percentage;
            var isParentRow = false;

            if (parentAccountItems) {
                var parents = parentAccountItems();
                //Check to see if the row is a parent. the tag_id must be in the parents dict, and the split_root_id must be null.
                var accountNumberTag = AccountNumberTagsStore.getAccountNumberTag(item.account_number);
                if (accountNumberTag) {
                    if (accountNumberTag.id in parents && accountNumberTag.split_root_id == null) {
                        isParentRow = true;
                        netActivity = netActivity - this.getNetActivityForChildren(accountNumberTag.id);  //change the value of the row accordingly
                    }
                } else {
                    console.log("No AccountNumberTag for item: " + JSON.stringify(item));
                }
            }
            let onSplitItem = () => onSplit(item);
            let onReTagItem = () => onReTag(item);
            let onDeleteRow = () => onRemoveRow(item);
            let onLabelChange = newName => this.onLabelChange(item, newName);
            let onPercentageChange = newAmount => this.onPercentageChange(item, newAmount);
            const onAmountChange = newAmount => this.onAmountChange(item, newAmount);

            const editValueOnly = nonEditableAccountNumbers.indexOf(item.account_number) > -1;
            if (editValueOnly) {
                onSplitItem = null;
                onReTagItem = null;
                onDeleteRow = null;
                onPercentageChange = null;
                onLabelChange = null;
            }

            return <SortedRow
                key={item.id}
                title={title}
                amount={netActivity}
                percentageAmount={percentageAmount}
                editing={editing}
                accountItem={item}
                onSplit={onSplitItem}
                onReTag={onReTagItem}
                onRemoveRow={onDeleteRow}
                onPercentageChange={onPercentageChange}
                onLabelChange={onLabelChange}
                onAmountChange={onAmountChange}
                inputType={inputType}
                isParent={isParentRow}
                />
        });
    }


    /** 
     * Total "amount" in all of the accountItems.
     * We need to take into account rows with children.
     */
    getTotal() {
        const {parentAccountItems, tag} = this.props;

        var accountItems = this.getAccountItems();
        var total = 0;
        var parents = {};

        if (parentAccountItems) {
            parents = parentAccountItems();
            // var accountNumberTag = AccountNumberTagsStore.getAccountNumberTag(item.account_number);
        }
        for (var i in accountItems) {
            var item = accountItems[i];
            var accountNumberTag = AccountNumberTagsStore.getAccountNumberTag(item.account_number);
            if (accountNumberTag) {
                //subtract the total from all children
                //Get the parent items
                if (accountNumberTag.id in parents && accountNumberTag.split_root_id == null) {
                    var childrenTotal = this.getNetActivityForChildren(accountNumberTag.id);
                    total = total - childrenTotal;
                }
                total = total + parseFloat(item.value);
            }
        }
        return total; //Returns a float
    }

    render() {
        const { tag } = this.props;
        const { expanded } = this.state;

        var total = this.getTotal();
        var rows = this.getRows();
        var addAction = this.getAddButton();

        return <div>
            <div className="col-xs-12 line-item">
                <div className="col-xs-7 no-padding" onClick={() => this.onExpandToggle()}>
                    {this.getCollapseIcon(rows)}
                    <span className="heading">{tag.name}{this.getSubtitle()}</span>
                </div>
                
                <div className="col-xs-4 text-right no-padding total">
                    {/* could total ever be a -'ve number?*/}
                    <span>{total > 0 ? `Subtotal $${Utils.getCommas(~~total)}` : null}</span>
                </div>

               <div className="pull-right"> {addAction}</div>
            </div>

            {expanded ? rows : null}
        </div>
    }
}

SubCategory.propTypes = {
    //accountItems: PropTypes.arrayOf(PropTypes.instanceOf(AccountItem)),
    //isHistorical: PropTypes.bool.isRequired,
    //structuredTag: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    //onSplit: PropTypes.func,
    onReTag: PropTypes.func,
    onRemoveRow: PropTypes.func,
    //onLabelChange: PropTypes.func,
    //onAmountChange: PropTypes.func,
    //onCreate: PropTypes.func,
    inputType: PropTypes.string,
    //onChangeAmount1: PropTypes.func,
    //onChangeAmount2: PropTypes.func
    //accountItemStore: PropTypes.object,
    //accountNumberTagsStore: PropTypes.object,
    tag: PropTypes.instanceOf(Tag),
    onChangeAccountItem: PropTypes.func.isRequired,
    month: PropTypes.instanceOf(Month).isRequired,
    onAddAccountItem: PropTypes.func.isRequired,
     /* TODO: Should this be an array, or func? */
    parentAccountItems: PropTypes.func
};

export default SubCategory;
