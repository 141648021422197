import React from 'react';

/**
 *
 * @param basePrice price with no discount applied
 * @param coupon entered coupon code
 * @param couponData response from token validation, null indicated invalid
 * @param checkingCoupon flag that token is being validated
 * @returns {*}
 */
export function getCouponDescriptionText(basePrice, coupon, couponData, checkingCoupon) {
    const introText = 'Valid Coupon! Your reduced price is $';

    if (coupon) {
        if (checkingCoupon) {
            return <span>Checking coupon...</span>;
        }

        if (couponData === null) {
            return <span className="invalid-coupon-text">Invalid coupon!</span>;
        } else {

            let discountPrice = basePrice;
            if (couponData.percent_off === null) {
                //amount is in cents
                const amountOff = couponData.amount_off / 100;
                discountPrice = basePrice - amountOff;
            } else {
                discountPrice = basePrice - (basePrice * parseFloat(couponData.percent_off / 100.0));
            }

            let outText;

            if (couponData.duration === 'forever') {
                outText = introText + discountPrice.toFixed(0) + ' / month';
            } else if (couponData.duration === 'once') {
                outText = introText + discountPrice.toFixed(0) + ' for the first month';
            } else {
                const numMonths = couponData.duration_in_months;
                if (numMonths === 1) {
                    outText = introText + discountPrice.toFixed(0) + ' for the first month';
                } else {
                    outText = introText + discountPrice.toFixed(0) + ' / month for the first ' + numMonths + ' months';
                }
            }

            return <span className="valid-coupon-text">{outText}</span>;
        }
    }

    return `Your price is $${basePrice} / month`;
}
