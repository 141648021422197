import React from 'react';
import PropTypes from 'prop-types';
import PageSubHeading from "../PageSubHeading"
import Popup from "../components/Popup"
var DataStore = require('../datastore');
var AccountStore = DataStore.AccountStore;
var Ajax = DataStore.Ajax;
import PaymentInfo from "../../../realtime-ceo-login/login/Payments"
var moment = require('moment');
import Formatter from "../Formatter"
import LoadingIndicator from '../components/LoadingIndicator';
import { getCouponDescriptionText } from '../../../common/subscriptionUtils';

const CardKeys = {
    CardNumber: "number",
    ExpiryMonth: "exp_month",
    ExpiryYear: "exp_year",
    CVC: "cvc",
    Coupon: "coupon"
};

class Payment {
    constructor(params) {
        this.number = '';
        this.exp_year = '';
        this.exp_month = '';
        this.cvc = '';
        this.coupon = '';
        this.timerId = null;

        if (params != null) {
            for (var i in params) {
                if (this.hasOwnProperty(i)) {
                    this[i] = params[i];
                }
            }
        }

        if (this.coupon == "") {
            delete this.coupon;
        }
    }
}

class BillingScreen extends React.Component {
    constructor(props) {
        super(props);

        this.endpoint = this.props.endpoint;
        this.state = {
            account: {},
            popupChangeCardDetails: false,
            popupRenewSubscription: false,
            popupCancelSubscription: null,
            popupMessage: null,
            requestingSubscriptionChange: false,
            newPaymentDetails: new Payment(),
            cardDetailsError: null,
            newSubscriptionBasePrice: null,
            couponData: null,
        };
    }

    getBasePrice() {
        return this.state.newSubscriptionBasePrice;
    }

    onDismissCancelSubscriptionPopup() {
        this.setState({
            popupCancelSubscription: null
        });
    }

    onSubscribeOrCancel() {
        if (this.props.onAction) {
            this.props.onAction();
        }
    }

    getPopupCancelSubscription(content, onOk, onCancel) {
        var okButton = onOk != null ?
            <button
                className="user-profile btn btn-primary marginRBtn"
                onClick={() => onOk()}>Yes</button> : null;

        var cancelButton = onCancel != null ?
            <button
                className="user-profile btn btn-danger"
                onClick={() => onCancel()}>No</button> : null;

        var body = <div>
            {content}
            <div className="popup">
                {okButton}
                {cancelButton}</div>
        </div>;
        return <Popup>
            {body}
        </Popup>
    }

    onCancelSubscriptionPressed() {
        var onOkPressed = () => {
            this.onDismissCancelSubscriptionPopup();
            this.props.endpoint.cancelSubscription()
                .then(() => {
                    this.setState({
                        popupMessage: this.popupMessage("Your subscription has been cancelled",null)
                    });
                })
                .catch(err => {
                    return err.json()
                    .then(res => {
                        this.setState({
                            popupMessage: this.popupMessage("ERROR!\n" + res.message,null)
                    });
                })
                //not ideal, but we have to wait for stripe to hit the hook to update the subscription in the db
                .then(() => setTimeout(() => AccountStore.fetchAccount(), 1000));
            });
        };

        var popupCancelSubscription = this.getPopupCancelSubscription(<div>
            <div className="col-xs-12 no-padding">
                <div className="col-xs-12 no-padding">
                    <PageSubHeading title="Cancel Subscription" icon="/images/icon_subscription.svg"/>
                </div>
            </div>
            <div className="col-xs-12 no-padding hr">
            </div>
            <div className="user-profile">Are you sure you want to cancel your subscription?</div>
        </div>, onOkPressed, () => this.onDismissCancelSubscriptionPopup());

        this.setState({
            popupCancelSubscription: popupCancelSubscription
        });

    }

    onDismissChangeCardDetailsPopup() {
        this.clearCardDetails();
        this.setState({
            popupChangeCardDetails: false
        });
    }

    clearCardDetails() {
        this.setState( {
            newPaymentDetails: new Payment()
        });
        this.setCardErrorMessage(null);
    }

    getCardErrorMessage() {
        const { cardDetailsError } = this.state;

        if (cardDetailsError == null) {
            return null;
        }

        return <div className="col-xs-12 no-padding">
                    <div className="error message-ticker margin-bottom-20">{cardDetailsError}</div>
                </div>
    }

    getPopupChangeCardDetails(content, onOk, onCancel) {
        var okButton = onOk != null ?
            <button
                className="user-profile btn btn-primary marginRBtn"
                onClick={() => onOk()}>Update</button> : null;

        var cancelButton = onCancel != null ?
            <button
                className="user-profile btn btn-danger"
                onClick={() => onCancel()}>Cancel</button> : null;

        var body = <div>
            {content}
            <div className="popup">
                {okButton}
                {cancelButton}
            </div>
        </div>;

        return <Popup>
            {body}
        </Popup>
    }

    onSubscriptionInfoChange(key, newValue) {
        var info = new Payment(this.state.newPaymentDetails);
        info[key] = newValue;

        this.setState({
            newPaymentDetails: info,
        });

        if (key === CardKeys.Coupon) {
            this.validateCouponCode(newValue);
        }
    }

    onChangeCardPressed() {

        this.setState({
            popupChangeCardDetails: true
        });
    }

    popupMessage(message, onClick) {

        let handleClick = (onClick) => {
            this.setState({
                popupMessage: null
            });
            if (onClick != null) {
                onClick();
            }
            this.onSubscribeOrCancel();
        };

        return (
            <Popup>
                <div>
                   <div className="col-xs-12 no-padding text-center margin-bottom-20">
                       <div className="col-xs-12 no-padding">
                           <p>{message}</p>
                       </div>
                       <div className="col-xs-12 no-padding">
                          <button className="btn btn-primary user-profile" onClick={() => handleClick(onClick)}>
                            OK
                          </button>
                      </div>
                   </div>
               </div>
           </Popup>
        );
    }

    checkCardDetails() {
        let payment = this.state.newPaymentDetails;
        let numberPattern = /^\d+$/;
        let threeNumberPattern = /^\d{3}$/;
        if (!payment.number.match(numberPattern)) {
            this.setCardErrorMessage("Please enter your credit card number.");
            return false;
        }
        if (!payment.exp_month.match(numberPattern)) {
            this.setCardErrorMessage("Please select your credit card expiry month.");
            return false;
        }
        if (!payment.exp_year.match(numberPattern)) {
            this.setCardErrorMessage("Please select your credit card expiry year.");
            return false;
        }
        if (!payment.cvc.match(threeNumberPattern)) {
            this.setCardErrorMessage("Please enter your credit security code");
            return false;
        }

        this.setCardErrorMessage(null);
        return true;
    }

    checkSubscriptionDetails() {
        let payment = this.state.newPaymentDetails;
        let cardDetails = this.checkCardDetails();
        if (!cardDetails) {
            return false;
        } else {
            if (payment.tc != "on") {
                this.setCardErrorMessage("Please accept the terms and conditions.");
                return false;
            }

        }
        this.setCardErrorMessage(null);
        return true;
    }

    setCardErrorMessage(message) {
        this.setState({
            cardDetailsError: message
        });
    }

    get PopupChangeCardDetailsForm () {
        let errorMessage = this.getCardErrorMessage();

        var onOkPressed = () => {


            if (this.checkCardDetails()) {

                this.setState({
                    requestingSubscriptionChange: true
                });

                let payment = this.state.newPaymentDetails;
                this.endpoint.setSubscriptionInfo(payment)
                    .then(() => {
                        this.setState({
                            popupMessage: this.popupMessage("Details updated.", null)
                        });
                        this.clearCardDetails();
                        this.onDismissChangeCardDetailsPopup();
                    })
                    .catch(response => {
                        let retVal;
                        if (response.status == 500) {
                            retVal = "An error has occurred";
                        } else {
                            retVal = response.json()
                                .then(parsed => parsed.message);
                        }
                        return Promise.resolve(retVal)
                            .then(message => {
                                this.setState({
                                    popupMessage: this.popupMessage(message, () => this.setState({popupChangeCardDetails: true}))
                                });
                            })
                            .then(() => {
                                this.onDismissChangeCardDetailsPopup();
                                this.setState({
                                    requestingSubscriptionChange: false
                                });

                            });
                    });
            }
        };

        let onCancelPressed = () => {
            this.onDismissChangeCardDetailsPopup();
            this.clearCardDetails();
        };

        return this.getPopupChangeCardDetails(
                this.getCardDetailsForm(
                    errorMessage,
                    "Update Card",
                    "/images/icon_creditcard.svg"
                ),
                onOkPressed,
                onCancelPressed
        );

    }

    getCardDetailsForm(errorMessage, title, icon, showSubscribe) {
        let payment = this.state.newPaymentDetails;
        let onSubscriptionInfoChange = this.onSubscriptionInfoChange;

        return <div>
                <div className="col-xs-12 no-padding">
                    <div className="col-xs-12 no-padding">
                        <PageSubHeading title={title}
                                        icon={icon}/>
                    </div>
                </div>
                <div className="col-xs-12 no-padding hr">
                </div>
                {errorMessage}
                {showSubscribe ? this.getSubscriptionOptionsInputs() : null}

                <div>
                    <div className="col-xs-12  no-padding">
                        <label className="user-profile" htmlFor="card-number">Card
                            Number</label>
                        <input type="text" className="user-profile sessionstack-sensitive" name="card-number"
                               id="card-number"
                               placeholder="Debit/Credit Card Number"
                               value={payment.number}
                               onChange={e => this.onSubscriptionInfoChange(CardKeys.CardNumber, e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className="col-xs-12  no-padding">
                        <label className="user-profile" htmlFor="expiry-month">Expiration Date</label>
                        <select className="user-profile sessionstack-sensitive sml" name="expiry-month"
                                id="expiry-month" value={payment[CardKeys.ExpiryMonth]}
                                onChange={e => this.onSubscriptionInfoChange(CardKeys.ExpiryMonth, e.target.value)}>
                            <option>Month</option>
                            <option value="01">Jan (01)</option>
                            <option value="02">Feb (02)</option>
                            <option value="03">Mar (03)</option>
                            <option value="04">Apr (04)</option>
                            <option value="05">May (05)</option>
                            <option value="06">June (06)</option>
                            <option value="07">July (07)</option>
                            <option value="08">Aug (08)</option>
                            <option value="09">Sep (09)</option>
                            <option value="10">Oct (10)</option>
                            <option value="11">Nov (11)</option>
                            <option value="12">Dec (12)</option>
                        </select>
                        <select className="user-profile sml sessionstack-sensitive" name="expiry-year"
                                value={payment[CardKeys.ExpiryYear]}
                                onChange={e => this.onSubscriptionInfoChange(CardKeys.ExpiryYear, e.target.value)}>
                            <option value="">Year</option>
                            {
                                // generate dates for the next 25 years
                                new Array(25)
                                    .fill(null)
                                    .map((_, index) => {
                                        const year = moment().add(index, 'years');
                                        return (
                                            <option key={year.format('YYYY')} value={year.format('YY')}>{year.format('YYYY')}</option>
                                        )
                                    })
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <div className="col-xs-12  no-padding">
                        <label className="user-profile" htmlFor="cvv">Card CVV</label>
                        <input type="text" className="user-profile sessionstack-sensitive" name="cvv" id="cvv"
                               maxLength="3" placeholder="Security Code"
                               value={payment[CardKeys.CVC]}
                               onChange={e => this.onSubscriptionInfoChange(CardKeys.CVC, e.target.value)}/>
                    </div>
                </div>
                {showSubscribe ? this.getExtraSubscriptionInputs() : null}
            </div>
    }

    getPricingText() {
        const { newPaymentDetails, couponData, validatingCoupon } = this.state;
        return (
            <p>
                {getCouponDescriptionText(this.getBasePrice(), newPaymentDetails.coupon, couponData, validatingCoupon)}
                <br />(plus 10% GST if you are located in Australia)
            </p>
        );
    }

    getExtraSubscriptionInputs() {
        let payment = this.state.newPaymentDetails;
        return [
            <div className="col-xs-12  no-padding">
                <label className="user-profile" htmlFor="coupon">Coupon Code</label>
                <input type="text" className="user-profile" name="coupon" id="coupon"
                       placeholder="Coupon Code"
                       value={payment[CardKeys.Coupon]}
                       onChange={e => this.onSubscriptionInfoChange(CardKeys.Coupon, e.target.value)}/>
            </div>,
            this.getPricingText(),
            <div className="col-xs-12  no-padding">
                <input required id="tc" type="checkbox" onChange={e => this.onSubscriptionInfoChange("tc", e.target.value)}/>
                <label htmlFor="tc">I have read and agree to the <a href="">RealTime CEO T&Cs</a></label>
            </div>
        ]
    }

    getSubscriptionOptionsInputs() {
        return null;
    }

    onDismissRenewSubscriptionPopup() {
             this.setState({
             popupRenewSubscription: false,
             newPaymentDetails: new Payment()
             });
    }

    getPopupRenewSubscription(content, onOk, onCancel) {
        let okButton = null;

        let cancelButton = onCancel != null ?
            <button
                className="user-profile btn btn-danger"
                onClick={() => onCancel()}>Cancel</button> : null;

        let body = <div>
            {content}
            <div className="popup">
                {okButton}
                {cancelButton}</div>
        </div>;

        return <Popup>
            {body}
        </Popup>
    }

    onRenewSubscriptionPressed() {
        this.setState({
            fetchingDefaultPrice: true,
            popupRenewSubscription: true,
        });

        this.endpoint.getSubscriptionPrice()
            .then(response => {
                this.setState({
                    //price is in cents
                    newSubscriptionBasePrice: response.amount / 100,
                });
            })
            .catch(err => console.error('error getting default price', err))
            .then(() => {
                this.setState({
                    fetchingDefaultPrice: false,
                });
            });
    }

    validateCouponCode(couponCode) {
        this.setState({
            validatingCoupon: true,
        });

        this.endpoint.checkCoupon(couponCode)
            .then(result => this.setState({
                couponData: result,
            }))
            .catch(err => {
                if (err.status !== 400) {
                    console.error('error checking coupon', err)
                }
            })
            .then(() => this.setState({
                validatingCoupon: false,
            }));
    }

    onSubmitSubscription() {
        if (this.checkSubscriptionDetails()) {
            Ajax.setSubscriptionInfo(new Payment(this.state.newPaymentDetails))
            .then(() => AccountStore.fetchAccount())
            .then(() => {
                this.onDismissRenewSubscriptionPopup();
                this.setState({
                    popupMessage: this.popupMessage(
                        "You have been successfully subscribed.",
                        () =>
                            this.setState({
                                popupMessage: null
                            }))
                    }
                )
            })
            .catch((error) => {
                this.onDismissRenewSubscriptionPopup();
                this.setState(
                {
                    popupMessage: this.popupMessage("There was an error Subscribing",
                        () => {
                            this.setState({
                                popupMessage: null
                            });
                            this.onRenewSubscriptionPressed();
                        })
                }
            )});
        } else {
            this.onRenewSubscriptionPressed();
        }
    }

    getRenewSubscription() {
        const { fetchingDefaultPrice } = this.state;

        if (fetchingDefaultPrice) {
            return (
                <LoadingIndicator alwaysDisplay fullscreen />
            );
        }

        let waiting = false;
        let onSubmit = () => {
            this.onSubmitSubscription();
        };
        let errorMessage = this.getCardErrorMessage();
        let onChange = (key, value) => this.onSubscriptionInfoChange(key, value);
        let payment = this.state.newPaymentDetails;

        var onOkPressed = () => {
            this.onDismissRenewSubscriptionPopup();
            // TODO: link to subscription renewal page or display the information needed
        };

        let onCancelPressed = () => this.onDismissRenewSubscriptionPopup();

        return this.getPopupChangeCardDetails(
            this.getCardDetailsForm(
                errorMessage,
                "Subscribe",
                "/images/icon_subscription.svg",
                true
            ),
            onSubmit,
            onCancelPressed
        );
    }

    get Account() {
        return AccountStore.getState().account;
    }

    get SubscriptionContent() {
        var account = this.Account;

        if (account === null) {
            return null;
        }

        let subscription = account.active_subscription;

        if (subscription === null) {
            return this.NoSubscription;
        }

        let currentPeriodEnd = Formatter.momentToDisplayFormat(moment(account.active_subscription.current_period_end));
        let subscriptionDetails = null;

        if (AccountStore.isSubscriptionCancelled()) {
            return (
                <div>
                    <div className="user-profile"> {subscriptionDetails}</div>
                    <div className="user-profile"> Your subscription has been cancelled, and will end on {currentPeriodEnd}</div>
                </div>
            );
        }
        return (
            <div>
                <div className="user-profile"> {subscriptionDetails}</div>
                <div className="user-profile margin-bottom-20"> You will be billed on {currentPeriodEnd}</div>
                <div className="user-profile">
                    <button className="btn btn-danger" onClick={() => this.onCancelSubscriptionPressed()}>
                        Cancel Subscription
                    </button>
                    {this.state.popupCancelSubscription}</div>
            </div>
        );

    }

    get NoSubscription() {
        return <div>
            <div className="user-profile">No Active Subscription </div>

            <div className="user-profile">
                <button className="btn btn-primary user-profile" onClick={() => this.onRenewSubscriptionPressed()}>
                    <span> Subscribe </span>
                </button>
                {this.state.popupRenewSubscription ? this.getRenewSubscription() : null}</div>
        </div>
    }

    get CardDetails() {
        const account = this.Account;

        if (account == null || account.active_subscription == null) {
            return (
                <div className="user-profile">No Card on File.<br/></div>
            );
        }

        let last4 = account.last_four_digits;

        return (
            <div>
                <div className="user-profile margin-bottom-20">
                    Current Card on File <br/>
                    **** **** **** {last4}
                </div>
                <div className="user-profile">
                    <button className="btn btn-primary" onClick={() => this.onChangeCardPressed()}>
                        Update Card
                    </button>
                </div>
            </div>
        );
    }

    getSubscriptionPlanContent() {
        return (
            <div>
                <div className="col-xs-12 no-padding">
                    <PageSubHeading title="Your Subscription Plan" icon="/images/icon_subscription.svg"/>
                </div>
                <div className="col-xs-12 no-padding">
                    <hr/>
                </div>
                <div className="col-xs-12">
                    <div className="row">
                        <div className="user-profile">{this.SubscriptionContent}</div>
                    </div>
                </div>
            </div>
        );
    }

    getCardDetailsContent() {
        return (
            <div>
                <div className="col-xs-12 no-padding">
                    <div className="col-xs-12 no-padding">
                        <PageSubHeading title="Credit Card Information" icon="/images/icon_creditcard.svg"/>
                    </div>
                </div>
                <div className="col-xs-12 no-padding">
                    <hr/>
                </div>
                {this.CardDetails}
            </div>
        );
    }

    render() {
        let popupChangeCardDetails = null;
        if (this.state.popupChangeCardDetails) {
            popupChangeCardDetails = this.PopupChangeCardDetailsForm;
        }

        let popupMessage = this.state.popupMessage;

        return (
            <div className="BillingScreen">
                {this.getSubscriptionPlanContent()}
                {this.getCardDetailsContent()}
                {popupChangeCardDetails}
                {popupMessage}
            </div>
        );

    }
}


BillingScreen.propTypes = {
    endpoint: PropTypes.object.isRequired,
    onAction: PropTypes.func,
};


export default BillingScreen;
