/**
 * Temporary structure for displaying data within Historical Data Entry
 */
class RowStruct {
    constructor(row, rowIndex, date) {
        this.row = row;
        this.rowIndex = rowIndex;
        this.date = date;
    }

    getAccountNumber(columns) {
        return this.getValueForColumnWithName("Account Number", columns);
    }

    getName(columns) {
        return this.getValueForColumnWithName("Account Name", columns);
    }

    getValueForColumn(columnId, columns) {
        var column = columns.filter(col => col.id === columnId).shift();

        if (column == null || column.hasOwnProperty('columnIndex') == false || this.row[column.columnIndex] == undefined) {
            return null;
        }

        return this.row[column.columnIndex];
    }

    getValueForColumnWithName(columnText, columns) {
        var column = columns.filter(col => col.name == columnText).shift();

        if (column == null || column.hasOwnProperty('columnIndex') == false || this.row[column.columnIndex] == undefined) {
            return null;
        }

        return this.row[column.columnIndex];
    }

    static parseMoney(amountString) {
        if (typeof amountString === "string") {
            return Number(amountString.replace(/[^0-9\.]+/g, ""));
        }

        return NaN;
    }

    static getParentTag(accountNumber, accountNumberTags, tags) {
        var childTag = RowStruct.getTag(accountNumber, accountNumberTags, tags);

        var matchingParentTag = tag => tag.id == childTag.tag_category_id;

        if (childTag) {
            if (typeof tags == "object") {
                for (var i in tags) {
                    var parentTag = tags[i];
                    var matches = parentTag.filter(matchingParentTag);

                    if (matches.length > 0) {
                        return matches.shift();
                    }
                }
            } else {
                return tags.filter(matchingParentTag).shift();
            }
        }

        return null;
    }

    static getTag(accountNumber, accountNumberTag, tags) {
        var map = RowStruct.getTagMapping(accountNumber, accountNumberTag);
        var parentTags = [];

        for (var i in tags) {
            parentTags = parentTags.concat(tags[i]);
        }

        return parentTags
            .reduce((prev, curr) => prev.concat(curr.tags), [])
            .filter(tag => tag.id == map.tagId)
            .shift();
    }

    static getTagMapping(accountNumber, accountNumberTags) {
        var matches = accountNumberTags.filter(ant => ant.accountNumber == accountNumber);

        if (matches.length > 0) {
            return matches.shift();
        }

        return null;
    }
}

export default RowStruct;