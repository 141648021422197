import React from 'react';
import PropTypes from 'prop-types';
import FileSelector from "../components/FileSelector"
import { InputTypes, UploadTypes, CsvType } from "../models/Enums"
import Popup from "../components/Popup"
import {TagWarningIcon} from "./importIcons";

class ScreenSelectCsvFiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: null
        };
    }

    onFileLoaded(filename, text, importType, csvType) {
        this.props.onFileLoaded(filename, text, importType, csvType);

        this.setState({
            text: text
        });
    }

    getFileImport(children, importType) {
        const { datePicker, displayDate, filenames } = this.props;

        switch (importType) {
            case UploadTypes.Single:
                return (
                    <div className="col-xs-10">
                        <h3>Import Files</h3>

                        <p>Import .csv files of your {displayDate.format("MMMM YYYY")} Monthly Balances</p>
                        {datePicker}

                        <p><strong>Income Statement</strong></p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FileSelector
                                key="2"
                                onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, importType, CsvType.ProfitLoss)}
                                hideUploadButton={true}
                                fileName={filenames[UploadTypes.Single][CsvType.ProfitLoss] || null}
                            />

                            {
                                !!this.props.fileErrors[UploadTypes.Single][CsvType.ProfitLoss] && (
                                    <div className="tag-warning-text" style={{ marginBottom: '30px' }}>
                                        <TagWarningIcon marginLeft={20} marginRight={10} /> This file has naming conflicts
                                    </div>
                                )
                            }
                        </div>

                        <p><strong>Balance Sheet</strong></p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FileSelector
                                key="1"
                                onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, importType, CsvType.BalanceSheet)}
                                hideUploadButton={true}
                                fileName={filenames[UploadTypes.Single][CsvType.BalanceSheet] || null}
                            />

                            {
                                !!this.props.fileErrors[UploadTypes.Single][CsvType.BalanceSheet] && (
                                    <div className="tag-warning-text" style={{ marginBottom: '30px' }}>
                                        <TagWarningIcon marginLeft={20} marginRight={10} /> This file has naming conflicts
                                    </div>
                                )
                            }
                        </div>

                        {children}
                    </div>
                );

            case UploadTypes.Multiple:
                return (
                    <div className="col-xs-10">
                        <hr />
                        <h3>Import Files</h3>

                        <p>Import .csv files of your 13 Month Balances</p>
                        <small>Select the date of the last month of your import.</small>
                        {datePicker}

                        <p><strong>Income Statement</strong></p>
                        <FileSelector key="4" onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, importType, CsvType.ProfitLoss)} hideUploadButton={true}/>

                        <p><strong>Balance Sheet</strong></p>
                        <FileSelector key="3" onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, importType, CsvType.BalanceSheet)} hideUploadButton={true}/>

                        {children}
                    </div>
                );
        }

        return null
    }

    render() {
        let nextButton = (
            <button className="btn btn-lg btn-primary"
                onClick={this.props.onImportSelected}
                disabled={!this.props.showNextButton}
            >
                Next
            </button>
        );
        
        let cancelButton = (
            <button
                className="btn btn-lg btn-primary"
                onClick={this.props.returnToBaseScreen}
            >
                Cancel
            </button>
        );

        let buttonsDiv = (
            <div className="row">
                <div className="col-xs-1"> {cancelButton}</div>
                <div className="col-xs-10"> </div>
                <div className="col-xs-1"> {nextButton}</div>
            </div>
        );

        return (
            <div className="screen-input-choice row">
                {this.getFileImport(buttonsDiv, UploadTypes.Single)}
                {this.state.popup}
            </div>
        );
    }
}

ScreenSelectCsvFiles.propTypes = {
    inputType: PropTypes.string,
    onFileLoaded: PropTypes.func.isRequired,
    onManualSelected: PropTypes.func.isRequired,
    onImportSelected: PropTypes.func.isRequired,
    showNextButton: PropTypes.bool.isRequired,
    filenames: PropTypes.shape({
        [UploadTypes.Single]: PropTypes.shape({
            [CsvType.BalanceSheet]: PropTypes.string,
            [CsvType.ProfitLoss]: PropTypes.string,
            [CsvType.GeneralLedger]: PropTypes.string,
        }),
    }),
    fileErrors: PropTypes.shape({
        [UploadTypes.Single]: PropTypes.shape({
            [CsvType.BalanceSheet]: PropTypes.bool,
            [CsvType.ProfitLoss]: PropTypes.bool,
            [CsvType.GeneralLedger]: PropTypes.bool,
        }),
    }),
    datePicker: PropTypes.object,
    returnToBaseScreen: PropTypes.func.isRequired
};

export default ScreenSelectCsvFiles;
