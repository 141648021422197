"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllInitialData = exports.initialUserDefinedData = exports.initialInternalSystemsData = exports.initialFixedAssetsData = exports.initialWorkingCapitalData = exports.initialOtherCostData = exports.initialMaterialsData = exports.initialPeopleData = exports.initialRevenueData = void 0;
const SonSectionRow_1 = require("./types/SonSectionRow");
const uuid_1 = require("uuid");
function createUUID() {
    return (0, uuid_1.v4)();
}
function createNewSingleValueRow(name, subSectionName, valueType) {
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
        id: createUUID(),
        name: name,
        valueType: valueType,
        value: '',
        comments: '',
        completed: false,
        subSectionName: subSectionName || '',
    });
}
function createProgressRow(name, subSectionName) {
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.progressRow,
        id: createUUID(),
        name: name,
        value: '',
        subSectionName: subSectionName,
        completed: false
    });
}
function createNotesRow(name) {
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.notes,
        id: createUUID(),
        subSectionName: '',
        name: name,
        value: '',
    });
}
function initialRevenueData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'revenue',
            sectionTemplateId: 'revenue-drivers',
            rows: [
                createNewSingleValueRow('Total Opportunity', '', 'stability_dropdown'),
                createNewSingleValueRow('Market Share', '', 'stability_dropdown'),
                createNewSingleValueRow('Price', '', 'stability_dropdown')
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'revenue',
            sectionTemplateId: 'revenue-streamMix',
            rows: [
                createNewSingleValueRow('Description', '', 'percentage')
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'revenue',
            sectionTemplateId: 'revenue-productOpportunity',
            rows: [
                createProgressRow('Description', '')
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'revenue',
            sectionTemplateId: 'revenue-general',
            rows: [createNotesRow('General Revenue Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialRevenueData = initialRevenueData;
function initialPeopleData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'people',
            sectionTemplateId: 'people-staff-v2',
            rows: [
                createNewSingleValueRow('Number of Staff Added', 'Description', 'number'),
                createNewSingleValueRow('Number of Staff Departed', 'Description', 'number'),
                createNewSingleValueRow('Number of Staff', 'Description', 'number'),
                createNewSingleValueRow('Busy Rating', 'Description', 'number'),
                createNewSingleValueRow('Efficiency Rating', 'Description', 'number'),
                createNewSingleValueRow('Morale Rating', 'Description', 'text'),
                createNewSingleValueRow('Wage Pressure', 'Description', 'stability_dropdown'),
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'people',
            sectionTemplateId: 'people-direct-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'people',
            sectionTemplateId: 'people-indirect-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'people',
            sectionTemplateId: 'people-attention',
            rows: [
                createProgressRow('Description', ''),
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'people',
            sectionTemplateId: 'people-general',
            rows: [createNotesRow('General People Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialPeopleData = initialPeopleData;
function initialMaterialsData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'materials',
            sectionTemplateId: 'materials-materials',
            rows: [
                createNewSingleValueRow('Price', 'Description', 'stability_dropdown'),
                createNewSingleValueRow('Availability', 'Description', 'stability_dropdown'),
                createNewSingleValueRow('Reliability', 'Description', 'stability_dropdown')
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'materials',
            sectionTemplateId: 'materials-general',
            rows: [createNotesRow('General Materials Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialMaterialsData = initialMaterialsData;
function initialOtherCostData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'other',
            sectionTemplateId: 'other-costs-v2',
            rows: [
                createNewSingleValueRow('Price', 'Description', 'stability_dropdown'),
                createNewSingleValueRow('Availability', 'Description', 'stability_dropdown'),
                createNewSingleValueRow('Reliability', 'Description', 'stability_dropdown')
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'other',
            sectionTemplateId: 'other-direct-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'other',
            sectionTemplateId: 'other-indirect-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'other',
            sectionTemplateId: 'other-general',
            rows: [createNotesRow('General Other Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialOtherCostData = initialOtherCostData;
function initialWorkingCapitalData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'capital',
            sectionTemplateId: 'capital-receivable',
            rows: [createNewSingleValueRow('Client payment behaviour', '', 'text')],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'capital',
            sectionTemplateId: 'capital-inventory',
            rows: [createNewSingleValueRow('Inventory holding strategy', '', 'text')],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'capital',
            sectionTemplateId: 'capital-payable',
            rows: [createNewSingleValueRow('Supply chain pressure', '', 'text')],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'capital',
            sectionTemplateId: 'capital-general',
            rows: [createNotesRow('General Working Capital Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialWorkingCapitalData = initialWorkingCapitalData;
function initialFixedAssetsData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'assets',
            sectionTemplateId: 'assets-likelyPurchase',
            rows: [{
                    kind: SonSectionRow_1.SonSectionRowKind.fixedAssetRow,
                    id: createUUID(),
                    name: 'Description',
                    subSectionName: '',
                    when: '',
                    howMuch: '',
                    likelyFunding: '',
                }],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'assets',
            sectionTemplateId: 'assets-likelyDisposals',
            rows: [{
                    kind: SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow,
                    id: createUUID(),
                    name: 'Description',
                    subSectionName: '',
                    when: '',
                    likelyFunding: '',
                    howMuch: '',
                }],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'assets',
            sectionTemplateId: 'assets-general',
            rows: [createNotesRow('General Fixed Assets Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialFixedAssetsData = initialFixedAssetsData;
function initialInternalSystemsData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'internal',
            sectionTemplateId: 'internal-systems-v2',
            rows: [
                createProgressRow('Description', ''),
            ],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'internal',
            sectionTemplateId: 'internal-direct-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'internal',
            sectionTemplateId: 'internal-indirect-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'internal',
            sectionTemplateId: 'internal-fixed-v2',
            rows: [],
            accountId: accountId,
            month: month,
        }, {
            responsiblePerson: '',
            notes: '',
            pageId: 'internal',
            sectionTemplateId: 'internal-general',
            rows: [createNotesRow('General Internal Systems Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialInternalSystemsData = initialInternalSystemsData;
function initialUserDefinedData(month, accountId) {
    return [{
            responsiblePerson: '',
            notes: '',
            pageId: 'user-defined',
            sectionTemplateId: 'user-defined',
            rows: [createNotesRow('Enter General Notes')],
            accountId: accountId,
            month: month,
        }];
}
exports.initialUserDefinedData = initialUserDefinedData;
function getAllInitialData(month, accountId) {
    if (accountId) {
        const allData = [
            ...initialRevenueData(month, accountId),
            ...initialPeopleData(month, accountId),
            ...initialMaterialsData(month, accountId),
            ...initialOtherCostData(month, accountId),
            ...initialWorkingCapitalData(month, accountId),
            ...initialFixedAssetsData(month, accountId),
            ...initialInternalSystemsData(month, accountId),
            ...initialUserDefinedData(month, accountId)
        ];
        return allData;
    }
    return [];
}
exports.getAllInitialData = getAllInitialData;
