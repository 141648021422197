import React from 'react';
import PropTypes from 'prop-types';
var ThreeWStore = require('../datastore').ThreeWStore;
var ThreeW = require('../datastore').ThreeW;
import Entry from "./Entry"
import Formatter from "../Formatter"

import { ButtonTypes, PictureButton } from "../../../common/Buttons"
import PanelOpenEvents from '../utilities/PanelOpenEvents';


var Screen = {
    EntryScreen: 0,
    SaveScreen: 1,
    DoneScreen: 2
};

class ThreeWPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentEntry: null,
        };
    }

    componentWillUpdate(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            PanelOpenEvents.onPanelOpen();
        }
    }

    componentDidMount() {
        this.newEntry();
    }

    newEntry() {
        this.setState({currentEntry: new ThreeW()});
    }


    entryValueChanged(entry, key, value) {
        entry[key] = value;

        ThreeWStore.updateStore(entry); // Updates local state only.
    }

    onSavePressed(entry, shouldClose) {
        const { onCancelPressed } = this.props;
        const data = {
            id: entry.id,
            what: entry.what,
            when: typeof entry.when == "string" ? entry.when : Formatter.momentToServerFormat(entry.when),
            who: entry.who,
            done: false
        };

        ThreeWStore
            .saveItem(data)
            .then(() => {
                this.newEntry();

                // Update the list of items.
                ThreeWStore.fetchData();

                if (shouldClose === true) {
                  onCancelPressed();
                }
            });
    }

    render() {
        const {onCancelPressed, visible} = this.props;
        const {currentEntry} = this.state;
        let content;

        const closeButton = (
            <button
                className="btn btn-flat light cross-close-button"
                onClick={onCancelPressed}
            >
                <span className="glyphicon glyphicon-remove" />
            </button>
        );

        if (currentEntry == null || visible !== true) {
            content = null;
        } else {
            content = (
                <Entry
                    vertical={true}
                    entry={currentEntry}
                    onChange={(entry, key, value) => this.entryValueChanged(entry, key, value)}
                    onSavePress={(entry, shouldClose) => this.onSavePressed(entry, shouldClose)}
                    onDeleteTag={tag => this.onDeleteTag(tag)}
                />
            );
        }

        return (
            <div className={"panel right-panel" + (visible ? '' : ' right-panel-hidden')}>
                <div className="threew-main-panel no-padding">
                    <div className="col-xs-12 no-padding">
                        <div className="col-xs-12 header no-margin no-padding">
                            <div className="text-right">{closeButton}</div>
                            <h1>+3W</h1>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        );
    }
}

ThreeWPanel.propTypes = {
    onCancelPressed: PropTypes.func,
    visible: PropTypes.bool,
};

export default ThreeWPanel;
