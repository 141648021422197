/**
 * Class representing a batch of create/update and delete operations
 * Tracks a list of items to be created or updated,
 * and a list of items that need to be explicitly deleted.
 *
 * Items are assumed to be saved or not saved based on the
 * presence of absence of the property "id".
 */
export default class BatchUpdateContainer {
    constructor(options) {
        this.items = [];
        this.deletedItems = [];

        if (typeof options === 'object' && options !== null) {
            Object.keys(options).forEach(optionKey => {
                if (this.hasOwnProperty(optionKey)) {
                    this[optionKey] = options[optionKey].slice();
                } else {
                    console.warn(`BatchUpdateContainer: unknown paramter "${optionKey}"`);
                }
            });
        }
    }

    /**
     * return a duplicated BatchUpdateContainer instance with the new item(s) added
     * @param items Object or Array<Object> the item(s) to add
     * @returns {BatchUpdateContainer}
     */
    addItems(items) {
        items = [].concat(items); // eslint-disable-line no-param-reassign
        const itemsNotAlreadyAdded = items.filter(item => this.items.indexOf(item) === -1);
        const updatedItems = this.items.concat(itemsNotAlreadyAdded);
        const updatedDeletedItems = this.deletedItems.filter(deletedItem => itemsNotAlreadyAdded.indexOf(deletedItem) === -1);

        return new BatchUpdateContainer({
            items: updatedItems,
            deletedItems: updatedDeletedItems,
        });
    }

    /**
     * returns a duplicated BatchUpdateContainer instance with the item(s) removed
     * if the item has an id, it is added to the deleted list to mark it as needing explicit deletion
     * @param deletedItems Object or Array<Object> the item(s) to delete
     * @returns {BatchUpdateContainer}
     */
    deleteItems(deletedItems) {
        const deletedItemsArray = [].concat(deletedItems);

        const itemsToDeleteWithIds = deletedItemsArray.filter(item => item.id);

        const updatedItems = this.items.filter(item => deletedItemsArray.indexOf(item) === -1);
        const updatedDeletedItems = this.deletedItems.concat(itemsToDeleteWithIds);

        return new BatchUpdateContainer({
            items: updatedItems,
            deletedItems: updatedDeletedItems,
        });
    }

    get Items() {
        return this.items.slice();
    }

    get DeleteIds() {
        return this.deletedItems.map(item => item.id);
    }
}
