import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const ThreeWTagActions = alt.generateActions(
    'itemReceived',
    'itemReceiving',
    'itemReceivingFailed',
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',
    'itemDeleted',
    'itemDeleting',
    'itemDeletingFailed'
);

module.exports = ThreeWTagActions;
