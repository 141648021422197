/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
class ProgressNavItem extends React.Component {
    render() {
        var activeClassName = this.props.active ? "active" : null;
        var disabledClass = this.props.disabled ? "disabled" : null;
        var onClick = this.props.disabled ? null : this.props.onClick;

        return <div className={`progress-nav-item ${activeClassName} ${disabledClass}`} onClick={onClick}>
            <span>{this.props.text}</span>
        </div>
    }
}

ProgressNavItem.propTypes = {
    text: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
};

export default ProgressNavItem;