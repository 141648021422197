"use strict";
var TagClass = require("../models/Tag");

class AccountNumberTag {
    constructor(item) {
        this.id = null;
        this.tag_id = null;
        this.account_number = null;
        this.split_root_id = null;
        this.tag = null;
        this.disabled = false;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }

    /**
     * @returns {boolean}
     */
    get Disabled() {
        return this.disabled === true;
    }

    /**
     *
     * @returns {TagClass|null}
     */
    get Tag() {
        return new TagClass(this.tag);
    }
}

module.exports = AccountNumberTag;
