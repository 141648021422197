import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonTypes, PictureButton } from '../../../common/Buttons.tsx';

class SuggestionList extends React.Component {
    render() {
        const { tags, onItemClick, onDeleteTag, highlightFirst, className } = this.props;
        return (
            <ul className={classNames('suggestion-list', { 'highlight-first': highlightFirst }, className)}>
                {tags.map((tag, index) => {
                    const pictureButton = onDeleteTag
                        ? (
                            <PictureButton
                                buttonType={ButtonTypes.Delete}
                                className="delete-tag-button"
                                onClick={() => onDeleteTag(tag)}
                            />
                        ) : null;
                    return (
                        <li key={tag.name + index}>
                            <div
                                className="tag"
                                onClick={() => onItemClick(tag)}
                                role="none"
                            >{tag.displayName || tag.name}
                            </div>
                            {pictureButton}
                        </li>
                    );
                })}
            </ul>
        );
    }
}

SuggestionList.propTypes = {
    className: PropTypes.string,
    highlightFirst: PropTypes.bool,
    onDeleteTag: PropTypes.func,
    onItemClick: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string.isRequired,
        displayName: PropTypes.node,
    })).isRequired,
};

export default SuggestionList;
