import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const MonthActions = alt.generateActions(
    'receivedItems',
    'loadingItems',
    'fetchingItemsFailed',
    'itemsSaved',
    'itemsSaving',
    'itemsSavingFailed',
    'monthReceived',
    'monthReceiving',
    'monthReceivingFailed',
    'lastHistoricMonthReceived',
    'lastHistoricMonthReceiving',
    'lastHistoricMonthReceivingFailed',
    'historicMonthsReceived',
    'historicMonthsReceiving',
    'historicMonthsReceivingFailed',
    'forecastMonthsReceived',
    'forecastMonthsReceiving',
    'forecastMonthsReceivingFailed',
    'wipedMonth',
    'wipingMonth',
    'wipingMonthFailed',
    'flush',
    'flushing',
    'flushingFailed',
    'monthIgnore',
    'dataCleared',
    'accountNumberTagsUpdated',
    'monthSnapshotsSaved',
    'monthSnapshotsSaving',
    'monthSnapshotsSavingFailed',
);

module.exports = MonthActions;
