"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonthIndices = exports.YearType = exports.WizardPagesTotal = exports.WizardPages = void 0;
exports.WizardPages = {
    Welcome: { pageId: 0 },
    SelectOnboardingMethod: { pageId: 1 },
    Manual: {
        SelectDate: { pageId: 11, pageNum: 1 },
        IncomeStatement: { pageId: 12, pageNum: 2 },
        Revenue: { pageId: 13, pageNum: 3 },
        RevenueClasses: { pageId: 14, pageNum: 4 },
        DirectCosts: { pageId: 15, pageNum: 5 },
        IndirectCosts: { pageId: 16, pageNum: 6 },
        OtherIndirectCosts: { pageId: 17, pageNum: 7 },
        Miscellaneous: { pageId: 18, pageNum: 8 },
        BalanceSheet: { pageId: 19, pageNum: 9 },
        TotalAssets: { pageId: 20, pageNum: 10 },
        TotalLiabilities: { pageId: 21, pageNum: 11 },
        TotalEquity: { pageId: 22, pageNum: 12 },
    },
    Import: {
        SelectFiles: { pageId: 101, pageNum: 1 },
        CurrentYearTagColumnsIncomeStatement: { pageId: 102, pageNum: 2 },
        CurrentYearTagColumnsBalanceSheet: { pageId: 103, pageNum: 3 },
        ImportErrors: { pageId: 150, pageNum: 150 },
        Tagging: { pageId: 104, pageNum: 4 },
        CurrentYearTagIncomeStatementRevenue: { pageId: 105, pageNum: 5 },
        CurrentYearTagIncomeStatementDirectCosts: { pageId: 106, pageNum: 6 },
        CurrentYearTagIncomeStatementIndirectCosts: { pageId: 107, pageNum: 7 },
        CurrentYearTagIncomeStatementProfit: { pageId: 108, pageNum: 8 },
        CurrentYearTagIncomeStatementMisc: { pageId: 109, pageNum: 9 },
        CurrentYearTagIncomeStatementDistributions: { pageId: 110, pageNum: 10 },
        PreviewCurrentYearIncomeStatement: { pageId: 111, pageNum: 11 },
        CurrentYearTagBalanceSheetAssets: { pageId: 112, pageNum: 12 },
        CurrentYearTagBalanceSheetLiabilities: { pageId: 113, pageNum: 13 },
        CurrentYearTagBalanceSheetEquity: { pageId: 114, pageNum: 14 },
        PreviewCurrentYearBalanceSheet: { pageId: 115, pageNum: 15 },
        PreviousYearTagIncomeStatement: { pageId: 116, pageNum: 16 },
        PreviewPreviousYearIncomeStatement: { pageId: 117, pageNum: 17 },
        PreviousYearTagBalanceSheet: { pageId: 118, pageNum: 18 },
        PreviewPreviousYearBalanceSheet: { pageId: 119, pageNum: 19 },
        StartMonthTagBalanceSheet: { pageId: 120, pageNum: 20 },
        PreviewStartMonthBalanceSheet: { pageId: 121, pageNum: 21 },
    },
    AdjustmentsOwnersSalary: { pageId: 200 },
    AdjustmentsNonBusinessExpenses: { pageId: 201 },
    SeasonalAdjustments: { pageId: 202 },
    progressTotal: 0,
    progressCurrent: 0,
};
// Stores total pages per import type
exports.WizardPagesTotal = {
    Manual: Object.keys(exports.WizardPages.Manual).length,
    Import: Object.keys(exports.WizardPages.Import).length,
};
// Used for storing uploaded files in appropriate Import class
var YearType;
(function (YearType) {
    YearType[YearType["CurrentYear"] = 1] = "CurrentYear";
    YearType[YearType["LastYear"] = 2] = "LastYear";
    YearType[YearType["StartMonth"] = 3] = "StartMonth";
})(YearType = exports.YearType || (exports.YearType = {}));
exports.MonthIndices = {
    first: 0,
    lastYear: 12,
    thisYear: 24,
};
