import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const FieldMappingActions = alt.generateActions(
    'receivedItem',
    'loadingItem',
    'fetchingItemFailed',
    'itemSaved',
    'itemSaving',
    'itemSavingFailed'
);

module.exports = FieldMappingActions;
