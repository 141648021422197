"use strict";
import alt from '../alt.ts';
var Actions = require('./../actions/FieldMappingActions');
var Source = require('./../sources/FieldMappingSource');
var FieldMapping = require('./../models/FieldMapping');

class FieldMappingStore {
    constructor() {
        // State
        this.fieldMapping = {};
        this.errorMessage = null;

        this.bindListeners({
            receivedItem: Actions.receivedItem,
            loadingItem: Actions.loadingItem,
            fetchingItemFailed: Actions.fetchingItemFailed,
            save: Actions.itemSaved,
            saving: Actions.itemSaving,
            savingFailed: Actions.itemSavingFailed
        });

        this.exportPublicMethods({
            getFieldMapping: this.getFieldMapping,
            onSave: this.onSave,
            save: fieldMapping => this.onSave(fieldMapping)

        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    receivedItem(data) {
        this.setState({
            fieldMapping: new FieldMapping(data),
            errorMessage: null
        });
    }

    loadingItem() {
        // Do nothing
    }

    fetchingItemFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    save(data) {
        this.receivedItem(data);
    }

    saving() {
        // Do nothing
    }

    savingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    // ---- PUBLIC ----
    getFieldMapping() {
        return this.getState().fieldMapping;
    }

    onSave(fieldMapping) {
        if (!this.getInstance().isLoading()) {
            this.getInstance().performSave(fieldMapping);
        }
    }
}

module.exports = alt.createStore(FieldMappingStore, 'FieldMappingStore');
