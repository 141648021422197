"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermission = exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["Owner"] = "Owner";
    UserType["Contributor"] = "Contributor";
})(UserType = exports.UserType || (exports.UserType = {}));
var UserPermission;
(function (UserPermission) {
    UserPermission["ViewHistorical"] = "ViewHistorical";
    UserPermission["EditHistorical"] = "EditHistorical";
    UserPermission["EditForecasting"] = "EditForecasting";
})(UserPermission = exports.UserPermission || (exports.UserPermission = {}));
