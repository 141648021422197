"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function downloadCsvFile(data, filename) {
    const link = document.createElement('a');
    link.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(data);
    document.body.appendChild(link);
    link.target = '_blank';
    link.download = `${filename}.csv`;
    link.click();
    link.remove();
}
exports.default = downloadCsvFile;
