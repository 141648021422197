// @ts-check
const sha1 = require('sha1');
import CSVToArray from "../third-party/CSVToArray"
import { RowStruct } from "../models/RealTimeCeo"
import Formatter from "../Formatter"
import { CsvType,LedgerTypes,UploadTypes, SpecialAccountItems, TagCategoryName, Fields } from "../models/Enums"
import Utils from "../utilities/Utils"
import CategoryUtils from "../utilities/CategoryUtils";

const DataStore = require('../datastore');
const MonthStore = DataStore.MonthStore;
const FieldStore = DataStore.FieldStore;
const TagCategoryStore = DataStore.TagCategoryStore;
const AccountItemStore = DataStore.AccountItemStore;
const AccountNumberTagsStore = DataStore.AccountNumberTagsStore;
const AccountItem = DataStore.AccountItem;
const AccountNumberTag = DataStore.AccountNumberTag;
import Field from '../datastore/models/Field';

import { getAccountItemsFromRowStruct, validateDuplicates, validateImportFromRows } from './ImportTs.ts';


function valueExists(value) {
    return typeof value === 'number'
        || ( typeof value === 'string' && value.trim().length > 0 );
}

class Import {
    constructor() {
        this.rows = this.getInitialRows();
        this.filenames = this.getInitialFilenames();
        this.fileErrors = {
            [UploadTypes.Single]: {
                [CsvType.BalanceSheet]: false,
                [CsvType.ProfitLoss]: false,
                [CsvType.GeneralLedger]: false,
            },
            [UploadTypes.Multiple]: {
                [CsvType.BalanceSheet]: false,
                [CsvType.ProfitLoss]: false,
                [CsvType.GeneralLedger]: false,
            },
        };
    }
    
    getInitialRows() {
        let rows = {};
        rows[CsvType.BalanceSheet] = [];
        rows[CsvType.ProfitLoss] = [];
        rows[CsvType.GeneralLedger] = [];

        return rows;
    }

    getInitialFilenames() {
        return {
            [UploadTypes.Single]: {
                [CsvType.BalanceSheet]: null,
                [CsvType.ProfitLoss]: null,
                [CsvType.GeneralLedger]: null,
            },
            [UploadTypes.Multiple]: {
                [CsvType.BalanceSheet]: null,
                [CsvType.ProfitLoss]: null,
                [CsvType.GeneralLedger]: null,
            },
        };
    }

    /**
     * 
     * @param {'IS_NUMBER' | 'IS_NAME' | 'VALUE' | 'BS_NUMBER' | 'BS_NAME' | 'BALANCE'} fieldToFind 
     */
    getFieldMappingColumnIndex(fieldToFind) {
        const field = this.getFields().find(field => field.code === fieldToFind);

        if (!field) {
            return -1;
        }

        const mapping = field.FieldMapping;

        return mapping ? mapping.index : -1;
    }

  /**
   * account numbers are generated as a hash of the account name and ledger type
   * if the account name column tagging changes, these need to be regenerated
   */
    regenerateAccountNumbers() {
        const incomeStatementNameColumn = this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountName);
        const balanceSheetNameColumn = this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountName);

        //try to fail gracefully
        if (incomeStatementNameColumn === -1) {
            console.error("income statement account name mapping missing!");
            return;
        }

        if (balanceSheetNameColumn === -1) {
            console.error("balance sheet account name mapping missing!");
            return;
        }

        const updateAccountNumbers = (csvType, accountNameColumnIndex) => {
            const rows = this.rows[csvType];
            rows.forEach(rowStruct => {
                let currentAccountName = rowStruct.row[accountNameColumnIndex];

                const newAccountNumber = this.sanitizeAccountNumber(currentAccountName, csvType);
                rowStruct.row[0] = newAccountNumber;
                rowStruct.name = currentAccountName;
            })
        };

        updateAccountNumbers(CsvType.BalanceSheet, balanceSheetNameColumn);
        updateAccountNumbers(CsvType.ProfitLoss, incomeStatementNameColumn);
    }

    setFileHasErrors(importType, csvType) {
        this.fileErrors[importType][csvType] = true;
    }

    // importType: UploadTypes.Single or Multiple
    updateRows(filename, fileData, importType, csvType) {
        var replacementRows = this.getRows();
        var filenames = this.getFilenames();

        replacementRows[csvType] = this.parseImportText(fileData, csvType);
        filenames[importType][csvType] = filename;
        this.fileErrors[importType][csvType] = false;

        // Update internal variables
        this.rows = replacementRows;
        this.filenames = filenames;
    }

    parseImportText(text, csvType) {
        // If the first character is a comma, then we prepend some quotes because of an issue with the parser.
        if (text.length > 0 && text.charAt(0) == ',') {
            text = '""' + text;
        }

        var parsed = CSVToArray(text);
        var rows = [];

        // ** Importing a CSV with a bunch of extra empty columns on the right breaks importing :(
        // Out of all the rows, find the column most right with actual data.
        let rightMostColumnWithData = 1;
        parsed.forEach(row => {
            const rightMostColumnWithDataInRow = this.getRightMostColumnIndexWithData(row);
            if (rightMostColumnWithDataInRow > rightMostColumnWithData) {
                rightMostColumnWithData = rightMostColumnWithDataInRow;
            }
        });

        // Strip all rows of empty columns to the right of the last column with data
        parsed.forEach((row, i) => {
            parsed[i] = this.removeExtraColumns(row, rightMostColumnWithData);
        });

        parsed.forEach((row, rowIndex) => {
            rows.push(new RowStruct(row, rowIndex));
        });

        return this.parseQuickBooksMultiMonth(rows, csvType);
    }

    // *****************************************************************************************************************
    // Parsing helper functions below

    // Find the rightmost column that contains data
    // Assumption: At the very least we assume 2 columns of valid data (hence returning 1 in the default case)
    getRightMostColumnIndexWithData(row) {
        for (let i = row.length - 1; i >= 0; i--) {
            if (row[i].length > 0) {
                return i
            }
        }
        return 1;
    }

    removeExtraColumns(row,columnIndexWithData) {
        if (row.length - 1 <= columnIndexWithData) {
            return row
        } else {
            return row.slice(0, columnIndexWithData + 1);  // INCLUDE the column with data
        }
    }

    parseQuickBooksMultiMonth(rows,csvType) {
        rows = this.multiMonthAddAdditionalColumnInfo(rows, csvType);
        return this.multiMonthFilterNonAccountItems(rows);
    }

    /**
     * This adds an extra column for the Account Number
     *
     * It also creates a field on the rowStruct called "parents", which shows the depth of the item.
     * The parents are an extra level of parsing because imported multiple month rows are not all account items,
     * they're a nested structure that has had it's structure data flattened.
     *
     * • Rows with no values are considered opening scope rows.
     * • Items that are prefixed with 'total ' are considered closing scope rows.
     * @param {RowStruct[]} rows
     * @param csvType type of ledger
     * @returns {RowStruct[]}
     */

    multiMonthAddAdditionalColumnInfo(rows, csvType) {
        let parents = [];

        return rows.map((rowStruct, index) => {
            if (index == 0) {
                rowStruct.row.unshift(null); // Empty column for account number.
                return rowStruct;
            }
            if (this.isHeadingRow(rowStruct.row)) {
                parents.push(rowStruct.row[0]);
            } else {
                if (this.isEndOfHeadingRow(rowStruct.row)) {
                    parents.pop();
                }

                //We still add the row even if it is a 'total' row.

                rowStruct.parents = parents.slice(0);
                rowStruct.name = rowStruct.row[0] == null ? '' : rowStruct.row[0];

                const updatedAccountName = rowStruct.name;

                rowStruct.row.unshift(this.sanitizeAccountNumber(updatedAccountName, csvType));
            }

            return rowStruct;
        });
    }

    /**
     *
     * Returns only Account Item Row Structs
     * Things that are removed are opening scope and closing scope rows.
     *
     * @param {RowStruct[]} rows
     * @returns {RowStruct[]}
     */
    multiMonthFilterNonAccountItems(rows) {
        let parents = [];

        return rows.filter((rowStruct, index) => {
            if (index === 0) {
                return true;
            }
            if (this.isHeadingRow(rowStruct.row)) {
                parents.push(rowStruct.row[0]);
            } else if (this.isEndOfHeadingRow(rowStruct.row)) {
                parents.pop();
                return true; //still return - these are the "total" rows.
            } else {
                return true;
            }

            return false;
        });
    }

    sanitizeAccountNumber(value,importType) {
        let lowerCase = value.toLowerCase();
        let safeCharacters = lowerCase.replace(/[^0-9a-zA-Z]+/g, '');

        // Handle BS / IS having the same name (will be tagged the same :( )
        if(importType === CsvType.BalanceSheet) {
            safeCharacters += 'BS';
        } else if (importType === CsvType.ProfitLoss) {
            safeCharacters += 'IS';
        } else {
            safeCharacters += 'GL';  // General Ledger
        }

        let hashed = sha1(safeCharacters);
        let trimmed = hashed.substring(0, 10);
        
        return trimmed;
    }

    isHeadingRow(row) {
        return row.reduce((prev, curr, index) => {
                if (index !== 0 && curr != null) {
                    return prev + curr;
                }

                return prev;
            }, '').length === 0;
    }

    isEndOfHeadingRow(row) {
        return row[0] != null && row[0].toLowerCase().substring(0, 6) === 'total ';
    }

    getAccountItemsFromCSV(optionalLedgerName) {
        let currentDate = Formatter.serverDateFormatToMoment(this.getCurrentMonth().date);
        const { itemsToSave } = this.getAccountItemsFromRowStruct(this.getMonthsRows(currentDate), optionalLedgerName);
        return itemsToSave;
    }

    getAccountItemsFromRowStruct(monthRows, optionalLedgerName) {
        let accountNumberTags = this.getAccountNumberTags();

        const incomeStatementAccountNumberColumnIndex = 0;//this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountNumber);
        const incomeStatementNameColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountName);
        const incomeStatementValueColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.ValueForTheMonth);

        const balanceSheetAccountNumberColumnIndex = 0;//this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountNumber);
        const balanceSheetNameColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountName);
        const balanceSheetValueColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.ValueAtMonthEnd);

        const isMappings = {
            accountNumber: incomeStatementAccountNumberColumnIndex,
            name: incomeStatementNameColumnIndex,
            value: incomeStatementValueColumnIndex,
        };

        const bsMappings = {
            accountNumber: balanceSheetAccountNumberColumnIndex,
            name: balanceSheetNameColumnIndex,
            value: balanceSheetValueColumnIndex,
        };

        const tagCategories = TagCategoryStore.getTagImportCategories();

        const months = MonthStore.getMonths();

        return {
            itemsToSave: getAccountItemsFromRowStruct(
                monthRows,
                accountNumberTags,
                months,
                isMappings,
                bsMappings,
                tagCategories,
                optionalLedgerName
            ),
            accountNumberTagsToSave: [],
        };
    }

    /**
     * @param {LedgerName?} optionalLedgerName
     * @return {TaggingError.TagCodeError[] | null}
     */
    validateImportTagging(optionalLedgerName) {
        let currentDate = Formatter.serverDateFormatToMoment(this.getCurrentMonth().date);
        const monthRows = this.getMonthsRows(currentDate);

        const accountNumberTags = this.getAccountNumberTags();

        const incomeStatementAccountNumberColumnIndex = 0;//this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountNumber);
        const incomeStatementNameColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountName);
        const incomeStatementValueColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.ValueForTheMonth);

        const balanceSheetAccountNumberColumnIndex = 0;//this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountNumber);
        const balanceSheetNameColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountName);
        const balanceSheetValueColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.ValueAtMonthEnd);

        const isMappings = {
            accountNumber: incomeStatementAccountNumberColumnIndex,
            name: incomeStatementNameColumnIndex,
            value: incomeStatementValueColumnIndex,
        };

        const bsMappings = {
            accountNumber: balanceSheetAccountNumberColumnIndex,
            name: balanceSheetNameColumnIndex,
            value: balanceSheetValueColumnIndex,
        };

        const tagCategories = TagCategoryStore.getTagImportCategories();

        return validateImportFromRows(
            monthRows[0],
            accountNumberTags,
            isMappings,
            bsMappings,
            tagCategories,
            optionalLedgerName
        );
    }

    getCurrentMonth() {
        return MonthStore.getState().month; // getCurrentMonth();
    }

    /**
     * @return {Field[]}
     */
    getFields() {
        return FieldStore.getFields();
    }

    getAccountNumberTags() {
        return AccountNumberTagsStore.getEnabledAccountNumberTags();
    }

    getMonthIdForDate(date) {
        return MonthStore.getMonthIdForDate(date);
    }

    getAccountItems() {
        return this.getCurrentMonth().account_items;//AccountItemStore.getState().accountItems; // getAccountItems
    }

    getTagsFromTagCategories() {
        var categories = TagCategoryStore.getTagImportCategories();
        var tags = [];
        categories.map(category => {
            tags.push.apply(tags, category.tags);
        });
        return tags;
    }

    /**
     * Do a join between accounItems and their tags - a little bit nasty
     * if accountItems is specified, uses these account items. If not, uses default account items
     */
    getAccountItemsWithTags(accountItems) {
        var items = [];
        if (!accountItems) {
            accountItems = this.getAccountItems();
        }
        var tags = this.getTagsFromTagCategories();

        //TODO: we should probably optimize this

        //Join each account item with its tag - this is just to find the correct category
        accountItems.map(accountItem => {
            //We need to get the category for each item
            var tag = tags.filter(tag => tag.id == accountItem.tag_id).shift();
            accountItem.tag = tag;
            //If we don't find a tag, dont add to the account items
            if (typeof tag !== "undefined") {
                items.push(accountItem)
            }
        });
        return items;
    }

    //this function handles any special behavior for totalling categories
    getAccountItemSubtotalValue(accountItem) {
        //misc expense items count as negative towards the misc total
        let expenseIds = this.getMiscExpenseTagIds();
        if (expenseIds.indexOf(accountItem.tag_id) >= 0) {
            return - parseFloat(accountItem.value);
        }
        return parseFloat(accountItem.value);
    }

    getMiscExpenseTagIds() {
        return CategoryUtils.NegativeMiscItems
            .map(code => TagCategoryStore.getTagForCode(code).id);
    }

    // *****************************************************************************************************************
    // TagRows and TagColumns Helpers

    // a 'pair' is a object containing a columnIndex and a date
    // I think the idea was an import was columns of data (month 1 = 1, month 2 = 2 ... etc)

    getPairs(currentMonthColumnIndex) {
        let pairs = [];
        if (typeof currentMonthColumnIndex === "number") {
            pairs = this.getMonthIndexAndDatePairs(currentMonthColumnIndex);
        } else if(typeof currentMonthColumnIndex === "undefined") {
            pairs = this.getMonthIndexAndDatePairs();
        } else {
            // Here currentMonthColumnIndex should be a Month object
            pairs = this.getMonthIndexandDatePairs_withInputMonth(currentMonthColumnIndex)
        }
        return pairs;
    }

    getBalanceSheetRowsForTagColumns(currentMonthColumnIndex) {
        return this.getSpecificRowsForTagColumns(currentMonthColumnIndex, this.getRowsBalanceSheet(), LedgerTypes.BalanceSheet);
    }

    getIncomeStatementRowsForTagColumns(currentMonthColumnIndex) {
        return this.getSpecificRowsForTagColumns(currentMonthColumnIndex, this.getRowsProfitLoss(), LedgerTypes.IncomeStatement);
    }

    getSpecificRowsForTagColumns(currentMonthColumnIndex, rows, ledgerType) {
        const pairs = this.getPairs(currentMonthColumnIndex);
        let months = [];

        pairs.forEach(pair => {
            let date = pair.date.clone();
            let newMonthRowStructs = [];

            rows.forEach(rowStruct => {
                rowStruct.ledgerType = ledgerType;
                rowStruct.date = date;
                newMonthRowStructs.push(rowStruct);
            });

            months.push(newMonthRowStructs);
        });

        return months[months.length - 1];
    }

    getRowsForTagColumns(currentMonthColumnIndex) {
        let monthsRows = this.getMonthsRows(currentMonthColumnIndex);
        return monthsRows[monthsRows.length - 1];
    }

    getRowsForTagRows(currentMonthColumnIndex) {
        return this.getRowsForTagColumns(currentMonthColumnIndex);
    }

    /**
     * Gets all account items grouped by months.
     * @returns {Array}
     */
    getMonthsRows(currentMonthColumnIndex) {
        const pairs = this.getPairs(currentMonthColumnIndex);

        let balanceSheet = this.getFilteredRowsBalanceSheet();
        let profitLoss = this.getFilteredRowsProfitLoss();
        let months = [];

        pairs.forEach(pair => {
            let date = pair.date.clone();
            let monthIndex = pair.index;
            let newMonthRowStructs = [];

            profitLoss.forEach(rowStruct => {
                rowStruct.ledgerType = LedgerTypes.IncomeStatement;
                rowStruct.date = date;
                newMonthRowStructs.push(rowStruct);
            });

            balanceSheet.forEach(rowStruct => {
                rowStruct.ledgerType = LedgerTypes.BalanceSheet;
                rowStruct.date = date;
                newMonthRowStructs.push(rowStruct);
            });

            months.push(newMonthRowStructs);
        });

        return months;
    }


    getMonthIndexAndDatePairs() {
        const rows = this.getRowsBalanceSheet();
        if (rows.length == 0) {
            return [];
        }

        let currentMonth = MonthStore.getCurrentMonth();
        let currentMonthDate = currentMonth.Date.clone();
        let pairs = [];

        for (let i = 0; i <= this.getMaxRowSize(); i++) {
            pairs.unshift({
                index: i,
                date: currentMonthDate.clone()
            });
        }

        return pairs;
    }

    getMonthIndexandDatePairs_withInputMonth(currentMonth) {
        // This assumes a one month import (this fn used in the wizard)
        // Therefore we can assume the index is 2 (as per above in getMonthIndexAndDatePairs)

        let rows = this.getRowsBalanceSheet();
        if (rows.length == 0) {
            return [];
        }


        let pairs = [];
        pairs.unshift({
            index: 2,
            date: currentMonth.clone()
        });

        return pairs;
    }

    // *****************************************************************************************************************
    // Getters

    getMaxRowSize() {
        const rows = this.getRowsBalanceSheet();

        let maxRowSize = 0;
        rows.forEach(row => {
            const rightmostRowWithDataColumnIndex = this.getRightMostColumnIndexWithData(row.row);
            if (rightmostRowWithDataColumnIndex > maxRowSize) {
                maxRowSize = rightmostRowWithDataColumnIndex;
            }
        });

        return maxRowSize;
    }

    getRows() {
        return this.rows;
    }

    getRowsProfitLoss() {
        return this.getRows()[CsvType.ProfitLoss];
    }

    getRowsBalanceSheet() {
        return this.getRows()[CsvType.BalanceSheet];
    }

    filterRows(rows, nameColumnIndex, valueColumnIndex) {
        return rows.filter(rowStruct => {
            return valueExists(rowStruct.row[nameColumnIndex])
                && valueExists(rowStruct.row[valueColumnIndex]);
        })
    }

    getFilteredRowsProfitLoss() {
        const incomeStatementNameColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountName);
        const incomeStatementValueColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.ValueForTheMonth);

        return this.filterRows(
            this.getRowsProfitLoss(),
            incomeStatementNameColumnIndex,
            incomeStatementValueColumnIndex
        );
    }

    getFilteredRowsBalanceSheet() {
        const balanceSheetNameColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountName);
        const balanceSheetValueColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.ValueAtMonthEnd);

        return this.filterRows(
            this.getRowsBalanceSheet(),
            balanceSheetNameColumnIndex,
            balanceSheetValueColumnIndex
        );
    }

    getFilenames() {
        return this.filenames;
    }

    getValidationRows() {
        const incomeStatementAccountNumberColumnIndex = 0;
        const incomeStatementNameColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.AccountName);
        const incomeStatementValueColumnIndex = this.getFieldMappingColumnIndex(Fields.IncomeStatement.ValueForTheMonth);

        const balanceSheetAccountNumberColumnIndex = 0;
        const balanceSheetNameColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.AccountName);
        const balanceSheetValueColumnIndex = this.getFieldMappingColumnIndex(Fields.BalanceSheet.ValueAtMonthEnd);

        if (!incomeStatementNameColumnIndex || !balanceSheetNameColumnIndex) {
            throw new Error('columns are not mapped!');
        }

        const rows = this.getFilteredRowsProfitLoss()
            .filter(it => valueExists(it.row[incomeStatementAccountNumberColumnIndex]) && valueExists(it.row[incomeStatementNameColumnIndex]))
            .map(row => ({
                ...row,
                ledgerType: LedgerTypes.IncomeStatement,
            }))
            .concat(
                this.getFilteredRowsBalanceSheet()
                .filter(it => valueExists(it.row[balanceSheetNameColumnIndex]) && valueExists(it.row[balanceSheetNameColumnIndex]))
                    .map(row => ({
                        ...row,
                        ledgerType: LedgerTypes.BalanceSheet,
                    }))
            )
            .map(it => it);


        return rows.map(it => {
            const {row} = it;
            const accountNumberColumnIndex = it.ledgerType === LedgerTypes.BalanceSheet ? balanceSheetAccountNumberColumnIndex : incomeStatementAccountNumberColumnIndex;
            const nameColumnIndex = it.ledgerType === LedgerTypes.BalanceSheet ? balanceSheetNameColumnIndex : incomeStatementNameColumnIndex;
            const valueColumnIndex = it.ledgerType === LedgerTypes.BalanceSheet ? balanceSheetValueColumnIndex : incomeStatementValueColumnIndex;

            const accountNumber = row[accountNumberColumnIndex];

            if (!accountNumber) {
                return null;
            }

            const filename = it.ledgerType.name === LedgerTypes.IncomeStatement.name
                ? this.filenames[UploadTypes.Single][CsvType.ProfitLoss]
                : this.filenames[UploadTypes.Single][CsvType.BalanceSheet];

            return {
                num: row[accountNumberColumnIndex],
                name: row[nameColumnIndex],
                value: row[valueColumnIndex],
                rowIndex: it.rowIndex,
                filename,
                ledgerName: it.ledgerType.name
            };
        })
            .filter(it => it !== null);
    }

    validateDuplicates(rows) {
        return validateDuplicates(rows);
    }
}




export default Import;