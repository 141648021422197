var ThreeWTag = require("../models/ThreeWTag");
var Actions = require('../actions/ThreeWTagActions');
var Ajax = require('../endpoints/ajax');

var ThreeWTagSource = {
    fetchNames() {
        return {
            remote(state, string) {
                if (string.length == 0) {
                    return Promise.resolve([]);
                } else {
                    return Ajax.getThreeWTags(string);
                }
            },

            local() {
                return null;
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    clearTags() {
        return {
            remote(state, string) {
                return Promise.resolve([])
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    fetchTag() {
        return {
            remote(state, name) {
                return Ajax.getThreeWTag(name);
            },

            local() {
                return null;
            },

            success: Actions.itemReceived,
            loading: Actions.itemReceiving,
            error: Actions.itemReceivingFailed
        }
    },

    performDelete() {
        return {
            remote(state, tag) {
                return Ajax.deleteThreeWTag(tag.name);
            },

            local() {
                return null;
            },

            success: Actions.itemDeleted,
            loading: Actions.itemDeleting,
            error: Actions.itemDeletingFailed
        }
    }


};

module.exports = ThreeWTagSource;

