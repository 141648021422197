import React from 'react';
import LabelledInput from "./LabelledInput"
import LoginForm from "./LoginForm"

const CardKeys = {
    Name: "name",
    CardNumber: "number",
    ExpiryMonth: "exp_month",
    ExpiryYear: "exp_year",
    CVC: "cvc",
    Coupon: "coupon"
};

class Payments extends React.Component {
    constructor(props) {
        super(props);

    }

    getWaitingIndicator() {
        const { waiting }  = this.props;

        if (waiting) {
            return <div className="login-form-cover">
                <img src="/images/login_loading_indicator.gif"/>
            </div>
        }

        return null;
    }

    onCouponChange(value) {
        const { onChange } = this.props;
        onChange(CardKeys.Coupon, value);
        //this.gimmeCouponData();
    }

    render() {
        const { onSubmit, errorMessage, onChange, payment, info_text, couponMessage } = this.props;
        var error = '';

        if (errorMessage !== null) {
            error = <div className="error message-ticker margin-bottom-20">Error: {errorMessage}</div>
        }

        return (
            <LoginForm
                title="Register"
                onSubmit={onSubmit}>
                {error}
                {info_text}
                <hr></hr>
                <p className="text-center"> If you have a coupon code, please enter it here:</p>
                <LabelledInput
                    name="Coupon Code"
                    id="coupon"
                    type="text"
                    onChange={(value) => this.onCouponChange(value)}
                    value={payment[CardKeys.Coupon]}
                    debounce
                />
                <p className="text-center"> {couponMessage} </p>
                <hr></hr>

                <LabelledInput
                    name="Name on Card"
                    id="Name"
                    required
                    type="text"
                    onChange={value => onChange(CardKeys.Name, value)}
                    value={payment[CardKeys.Name]}
                    className="sessionstack-sensitive"
                />
                <LabelledInput
                    name="Card Number"
                    id="cardNumber"
                    required
                    type="text"
                    onChange={value => onChange(CardKeys.CardNumber, value)}
                    value={payment[CardKeys.CardNumber]}
                    className="sessionstack-sensitive"
                />

                <LabelledInput name="mm/yy" id="expiryMonth">
                    <input
                        required
                        style={{width: "100px"}}
                        id="expiryMonth"
                        type="text"
                        onChange={e => onChange(CardKeys.ExpiryMonth, e.target.value)}
                        value={payment[CardKeys.ExpiryMonth]}
                        className="sessionstack-sensitive"
                    />
                    <span style={{padding:"0px 10px"}}>/</span>
                    <input
                        required
                        style={{width: "100px"}}
                        id="expiryYear"
                        type="text"
                        onChange={e => onChange(CardKeys.ExpiryYear, e.target.value)}
                        value={payment[CardKeys.ExpiryYear]}
                        className="sessionstack-sensitive"
                    />
                </LabelledInput>

                <LabelledInput
                    name="CVV"
                    required
                    id="cvv"
                    type="text"
                    onChange={value => onChange(CardKeys.CVC, value)}
                    value={payment[CardKeys.CVC]}
                    className="sessionstack-sensitive"
                />


                <div className="row row-10m">
                    <div className="col-xs-2 text-right no-padding">
                        <input required id="tc" type="checkbox" onChange={e => onChange("tc", e.target.value)}/>
                    </div>
                    <div className="col-xs-8 text-center" style={{verticalAlign:"middle"}}>
                        <label htmlFor="tc">I have read and agree to the <a title="RealTime CEO Terms and Conditions" target="_blank" href="http://realtimeceo.com/wp-content/uploads/TermsandConditions.pdf">Terms and Conditions</a>
                            <br/>and <a title="RealTime CEO Privacy Policy" target="_blank" href="http://realtimeceo.com/wp-content/uploads/PrivacyPolicy.pdf">Privacy Policy</a> of RealTime CEO.</label>
                    </div>
                </div>


                <div className="row row-10m">
                    <div className="col-xs-4 text-center">
                    </div>
                    <div className="col-xs-4 text-center">
                        <button type="submit" className="btn btn-login btn-action">Register</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <img src="/images/poweredbystripe.png"/>
                    </div>
                </div>
                {this.getWaitingIndicator()}
            </LoginForm>
        );
    }
}

export default Payments;