import NumberFormatter from "../utilities/Number"
import RowStruct from "../models/RowStruct"
import RevenueForecast from "../models/RevenueForecast"

class AccountItem {
    constructor(options) {
        AccountItem.getFields().forEach(fieldName => {
            switch (fieldName) {
                case "value":
                    this.valueUnparsed = options[fieldName];
                    break;

                case "revenue_forecast":
                    if(options[fieldName] != null) {
                        this.revenue_forecast = new RevenueForecast(options[fieldName]);
                    }
                    else {
                        this.revenue_forecast = null;
                    }
                    break;

                default:
                    this[fieldName] = options[fieldName];
                    break;
            }
        });
    }

    static getFields() {
        return [
            'id',
            'month_id',
            'tag_id',
            'account_number',
            'name',
            'value',
            'value_formula',
            'revenue_forecast',
        ];
    }

    /**
     * Re-calculates all derived fields
     */
    reCalculate() {
        this.revenue_forecast.reCalculate();
    }

    calculateFormulas(lastMonth, lastYear) {

        var applyFormulaForProperty = (formulaPropertyName, amountPropertyName) => {
            var formula = this.revenue_forecast[formulaPropertyName];
            if (formula != null && formula.length > 0) {
                var formulaComponents = formula.split('*');
                var percent = parseInt(formulaComponents[0]);
                var monthVariable = formulaComponents[1];
                var multiplier = 0;
                if (monthVariable == "LASTMONTH" && lastMonth != null) {
                    multiplier = parseFloat(lastMonth.revenue_forecast[amountPropertyName]);
                }
                else if (monthVariable == "LASTYEAR" && lastYear != null) {
                    multiplier = parseFloat(lastYear.revenue_forecast[amountPropertyName]);
                }
                this.revenue_forecast[amountPropertyName] = multiplier * percent/100;
            }
        };

        applyFormulaForProperty("marriage_formula", "marriage_amount");
        applyFormulaForProperty("engagement_formula", "engagement_amount");
    }

    set valueUnparsed(value) {
        this.value = AccountItem.parseMoney(value);
    }

    static createNewAccountItem() {
        return new AccountItem({
            id: null,
            month_id: null,
            value_formula: null,
            value: 0,
            // tag_id: null,
            account_number: AccountItem.generateUuid(),
            name: "",
            revenue_forecast: null,
        });
    }

    clone(monthId) {
        return new AccountItem({
            id: null,
            month_id: monthId,
            value_formula: this.value_formula,
            value: this.value,
            // tag_id: this.tag_id,
            account_number: this.account_number,
            name: this.name,
            revenue_forecast: this.revenue_forecast == null ? null : this.revenue_forecast.clone(),
        });
    }

    static parseMoney(value) {
        return Math.floor(NumberFormatter.parseMoney(value));
    }

    static newAccountItem(name, tagId, monthId) {
        var accountItem = AccountItem.createNewAccountItem();

        accountItem.month_id = monthId;
        // accountItem.tag_id = tagId;
        accountItem.name = name;

        return accountItem;
    }

    static generateUuid() {
        var d = new Date().getTime();

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    static AccountItemFromRowStruct(rowStruct, columns, accountNumberTags, monthId, defaultTagId) {
        var row = rowStruct.row;
        var accountNumberColumnIndex = columns.filter(col => col.name == "Account Number").shift().columnIndex;
        var accountNameColumnIndex = columns.filter(col => col.name == "Account Name").shift().columnIndex;
        var valueForMonthColumnIndex = columns.filter(col => col.name == "Value for the Period").shift().columnIndex;
        var valueAtMonthEndColumnIndex = columns.filter(col => col.name == "Value at Period end").shift().columnIndex;

        var accountItem = AccountItem.createNewAccountItem();
        accountItem.item_number = row[accountNumberColumnIndex];
        accountItem.name = row[accountNameColumnIndex];
        accountItem.original_name = accountItem.name;
        accountItem.netActivity = row[valueForMonthColumnIndex];
        accountItem.originalNetActivity = accountItem.netActivity;
        accountItem.netActivityPercentage = 100;
        accountItem.totalEndBalance = row[valueAtMonthEndColumnIndex];
        accountItem.month_id = monthId;
        // accountItem.tag_id = defaultTagId;
        accountItem.is_split = false;

        accountNumberTags.forEach(map => {
            if (map.accountNumber == accountItem.item_number) {
                // accountItem.tag_id = map.tagId;
            }
        });

        return accountItem;
    }

    static AccountItemsFromRowStructs(rows, columns, accountNumberTags, monthId, defaultTagId) {
        /**
         *
         * Constructs AccountItem from RowStructs.
         * Assumes first item is the header row, so that's filtered out.
         *
         * Things we need are
         *
         * Name
         * Account Number
         * TagId
         * Net Activity
         * Total End Balance
         */
        return rows
            .filter((row, index) => index !== 0)
            .map(row => AccountItem.AccountItemFromRowStruct(row, columns, accountNumberTags, monthId, defaultTagId));
    }

    static SplitAccountItem(accountItem, splitCount) {
        var newAccountItem = AccountItem.createNewAccountItem();

        for (var key in accountItem) {
            if (key === "id") {
                continue;
            }

            if (accountItem.hasOwnProperty(key) && newAccountItem.hasOwnProperty(key)) {
                newAccountItem[key] = accountItem[key];
            }
        }

        newAccountItem.parent_account_item_id = accountItem.id;
        newAccountItem.is_split = true;
        newAccountItem.original_name = accountItem.original_name;
        newAccountItem.name = newAccountItem.original_name + " " + splitCount;

        return newAccountItem;
    }

    static getLeafOfSplit(accountItems, node) {
        if (node.parent_account_item_id == null) {
            return node;
        }

        var findChild = child => {
            var results = accountItems.filter(item => item.id == child.parent_account_item_id);

            if (results.length > 0) {
                return findChild(results.shift());
            }

            return child;
        }
    }

    static getRootOfSplit(node, accountItems) {
        var find = (nodes, child) => {
            if (child.parent_account_item_id == null && child.is_split === true) {
                return child;
            } else {
                return find(nodes, nodes.filter(item => item.isRootSplit()).shift());
            }
        };

        return find(accountItems, node);
    }


    /**
     * Get all items.
     */
    static getFamily(node, accountItems) {

        if (node.is_split) {
            var list = [node];

            // Add all left items
            var findLeft = id => {
                if (id == null) {
                    return;
                }

                accountItems
                    .filter(item => item.id == id)
                    .forEach(item => {
                        list.unshift(item);
                        findLeft(item.parent_account_item_id)
                    });
            };

            findLeft(node.parent_account_item_id);

            // Add all right items.
            var findRight = id => {
                accountItems
                    .filter(item => item.parent_account_item_id == id)
                    .forEach(item => {
                        list.push(item);

                        findRight(item.id);
                    });
            };

            findRight(node.id);

            return list;
        }

        return [node];
    }

    isSplit() {
        return this.is_split;
    }

    isRootSplit() {
        return this.is_split && this.parent_account_item_id == null;
    }

    /**
     *
     * @param availableTags object
     */
    getTag(availableTags) {
        // var tagId = this.tag_id;
        //
        // for (var i in availableTags) {
        //     var parentTags = availableTags[i];
        //     var tag = parentTags
        //         .reduce((prev, curr) => prev.concat(curr.tags), [])
        //         .filter(cTag => cTag.id == tagId)
        //         .shift();
        //
        //     if (tag != null) {
        //         return tag;
        //     }
        // }

        return null;
    }

    getParentTag(availableTags) {
        var tag = this.getTag(availableTags);

        for (var i in availableTags) {
            var parentTags = availableTags[i];
            var parentTag = parentTags
                .filter(pTag => pTag.id == tag.tag_category_id)
                .shift();

            if (parentTag != null) {
                return parentTag;
            }
        }

        return null;
    }

    /**
     * Checks if value_formula exists, returns true if so, else false
     * @returns Boolean
     */
    hasValueFormula() {
        if (!this.value_formula || value_formula === '') {
            return false;
        }
        
        return true;
    }
}

export default AccountItem;
