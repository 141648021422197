import React from 'react';
import PropTypes from 'prop-types';
import { RowStruct } from "../models/RealTimeCeo"
import RealTimeTable from "../components/RealTimeTable"
import { Fields, LedgerTypes, ImportRenamedTags, ImportTagSubSections } from "../models/Enums"
import TagHelper from "../datastore/helpers/TagHelper"


const DataStore = require('../datastore');
// Models
const FieldStore = DataStore.FieldStore;
const AccountNumberTag = DataStore.AccountNumberTag;
const Tag = DataStore.Tag;

// Stores
const TagCategoryStore = DataStore.TagCategoryStore;
const AccountNumberTagsStore = DataStore.AccountNumberTagsStore;

import { getAccountNumberForTag, getImportAccountNumberTag } from '../utilities/AccountNumberTagUtils.ts';
import TaggingTabSelector from './TaggingTabSelector.tsx';
import {TaggingError} from "../import/ImportTs";

class TagRows extends React.Component {
    constructor(props) {
        super(props);

        const initialCategory = this.getCategories()[0];
        const tags = this.getTagsForCategory(initialCategory);

        this.state = {
            activeTag: tags[0] || null,
            selectedCategoryId: initialCategory.id,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCategoryId !== this.state.selectedCategoryId) {
            const category = TagCategoryStore.getTagImportCategories().find(it => it.id.toString() === this.state.selectedCategoryId);
            if (!category) {
                this.setState({
                    selectedTagCode: null,
                    activeTag: null,
                });
            } else {
                const tags = this.getTagsForCategory(category);
                this.setState({
                    selectedTagCode: tags[0].code,
                    activeTag: tags[0] || null,
                });
            }
        }

        if (prevProps.selectedLedgerType !== this.props.selectedLedgerType) {
            const category = this.getCategories()[0];
            this.setState({
                selectedCategoryId: category.id,
            });
        }

        if (prevProps.lockedSelection !== this.props.lockedSelection) {
            const category = this.getCategories().find(it => it.id === this.props.lockedSelection);
            const tags = this.getTagsForCategory(category);
            this.setState({
                activeTag: tags[0] || null,
            });
        }
    }

    onRowClick(rowStruct) {
        const { selectedLedgerType } = this.props;

        const fieldToMatch = selectedLedgerType === LedgerTypes.IncomeStatement
            ? Fields.IncomeStatement.AccountNumber
            : Fields.BalanceSheet.AccountNumber;

        const fields = this.getFields();
        const field = fields.filter(field => field.code == fieldToMatch).shift();

        // account number column mapping defaults to the hidden column 0 if not manually tagged
        let accountNumberMappingIndex = 0;

        if (
            field
            && field.filtered_field_mapping
            && field.filtered_field_mapping[0]
            && field.filtered_field_mapping[0].index !== -1
        ) {
            const map = field.filtered_field_mapping[0];
            accountNumberMappingIndex = map.index;
        }

        const accountNumber = rowStruct.row[accountNumberMappingIndex];

        // create Account Number Tag if it doesn't exist, else delete it
        if (TagHelper.getTag(accountNumber) == null) {
            if (this.state.activeTag != null) {
                this.onAttachTagToRowIndex(this.state.activeTag, accountNumber);
            }
        } else {
            this.onRemoveTagFromRow(accountNumber);
        }
    }

    /**
     * Display a table of data, including only those that are mapped by fields.
     *
     * @returns {*}
     */
    getTable() {
        const { rows, selectedLedgerType, hideFirstColumn } = this.props;
        var fields = this.getFields();

        let filteredRows = rows.filter( row => {
            if('ledgerType' in row) {
                return (row.ledgerType.name == selectedLedgerType);
            } else {
                return true;    // no ledgertype property means this is a header row (want to keep those..)
            }
        });

        const getIndexForFieldCode = function(code, fields) {
            const fieldMappings = fields.filter(item => item.code === code);
            if (fieldMappings.length === 0) return -1;

            const fieldMapping = fieldMappings[0].filtered_field_mapping;
            if (fieldMapping.length === 0) return -1;

            return fieldMapping[0].index;
        };

        let removedColumnAndFilteredRows = filteredRows.map( rowStruct => {
            let accountNumberCode = Fields.IncomeStatement.AccountNumber;
            let accountNameCode = Fields.IncomeStatement.AccountName;
            let valueCode = Fields.IncomeStatement.ValueForTheMonth;

            if (selectedLedgerType === LedgerTypes.BalanceSheet.name) {
                accountNumberCode = Fields.BalanceSheet.AccountNumber;
                accountNameCode = Fields.BalanceSheet.AccountName;
                valueCode = Fields.BalanceSheet.ValueAtMonthEnd;
            }

            const accountNumberMappingIndex = getIndexForFieldCode(accountNumberCode, fields);
            const accountNameMappingIndex = getIndexForFieldCode(accountNameCode, fields);
            const valueMappingIndex = getIndexForFieldCode(valueCode, fields);

            let accountNumber = rowStruct.row[0];
            if (accountNumberMappingIndex !== -1) {
                accountNumber = rowStruct.row[accountNumberMappingIndex];
            }
            let accountName = rowStruct.row[accountNameMappingIndex];
            let value = rowStruct.row[valueMappingIndex];

            return new RowStruct([accountNumber, accountName, value], rowStruct.rowIndex, rowStruct.date);
        });

        return <RealTimeTable
            hideFirstColumn={hideFirstColumn}
            rows={removedColumnAndFilteredRows}
            maxRows={removedColumnAndFilteredRows.length}
            fields={fields}
            onRowClick={rowStruct => this.onRowClick(rowStruct)}
            />;
    }

    getFields() {
        return FieldStore.getState().fields;
    }

    getEnabledAccountNumberTags() {
        return AccountNumberTagsStore.getEnabledAccountNumberTags();
    }

    getAccountNumberTags() {
        return AccountNumberTagsStore.getAccountNumberTags();
    }

    /**
     * @param tag {Tag}
     * @param accountNumber {string}
     */
    onAttachTagToRowIndex(tag, accountNumber) {
        const importAccountNumber = getAccountNumberForTag(accountNumber, tag, this.getAccountNumberTags())

        let params = {
            account_number: importAccountNumber,
            tag_id: tag.id,
        };

        const accountNumberTag = new AccountNumberTag(params);
        AccountNumberTagsStore.performSave(accountNumberTag);
    }

    /**
     * @param accountNumber {string}
     */
    onRemoveTagFromRow(accountNumber) {
        const accountNumberTag = getImportAccountNumberTag(accountNumber, this.getEnabledAccountNumberTags());

        AccountNumberTagsStore.performDisable(accountNumberTag);
    }

    getTagsForCategory(category) {
        return TaggingTabSelector.getTagsForCategory(category)
    }

    getCategories() {
        return TagCategoryStore.getTagImportCategories()
            .filter(cat => cat.ledger_type === this.props.selectedLedgerType);
    }

    render() {
        const { selectedLedgerType, lockedSelection } = this.props;

        const categories = this.getCategories();

        return (
            <div className="TagRows">
                <TaggingTabSelector
                    tagErrors={this.props.tagErrors || []}
                    selectedTagCode={this.state.activeTag ? this.state.activeTag.code : null}
                    tagCategories={categories}
                    selectedCategoryId={lockedSelection !== undefined ? lockedSelection : this.state.selectedCategoryId}
                    lockedCategory={lockedSelection !== undefined}
                    onClickTab={id => this.setState({ selectedCategoryId: id })}
                    onClickTag={code => {
                        this.setState({ activeTag: categories.reduce((all, it) => all.concat(it.tags), []).find(t => t.code === code) || null })
                    }}
                    ledgerType={selectedLedgerType}
                />

                <div className="tag-mapping-table" key={selectedLedgerType}>
                    {this.getTable()}
                </div>
            </div>
        );
    }
}

TagRows.propTypes = {
    autoSelectFirstTag: PropTypes.bool,
    rows: PropTypes.arrayOf(PropTypes.instanceOf(RowStruct)).isRequired, // The raw imported data in the form of RowStruct
    onAttachTagToRowIndex: PropTypes.func,
    selectedLedgerType: PropTypes.string,
    hideFirstColumn: PropTypes.bool,
    categoryIdsToShow: PropTypes.arrayOf(
      PropTypes.string
    ),
    lockedSelection: PropTypes.string, // id of a section to lock as selected
    tagErrors: PropTypes.arrayOf(PropTypes.instanceOf(TaggingError.TagCodeError)),
};

export default TagRows;
