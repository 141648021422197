import React from 'react';
export default {
    RevenueRemainder: {
        get Body() {
            return (
                <div>
                    <h3>Revenue not defined by Class</h3>
                    <p>
                        <strong>Some of your total revenue is not allocated to a revenue class.</strong>
                        <br />You can do this allocation now, or later or not at all!
                        If you don’t do it now, the balance will be allocated to a "Unallocated Remainder" line.
                    </p>
                </div>
            );
        },
        OK: 'Proceed without further allocation',
        Cancel: 'Allocate more to revenue classes now',
    },

    IndirectCostsRemainder: {
        get Body() {
            return (
                <div>
                    <h3>Indirect Costs not defined</h3>
                    <p>
                        <strong>The balance of your indirect costs is currently listed in a line called "Remainder."</strong>
                        <br />You can enter more detail now if you think it would add value to your management
                        conversations or you can proceed with no further allocation.
                    </p>
                </div>
            );
        },
        OK: 'Proceed without further allocation',
        Cancel: 'Allocate more Indirect Costs now',
    },

    MiscRemainder: {
        get Body() {
            return (
                <div>
                    <h3>Miscellaneous items not defined</h3>
                    <p>
                        <strong>The balance of your miscellaneous items are currently listed in a line called "Remainder."</strong>
                        <br/>If you have identified all interest revenue, interest expense and depreciation select "Proceed."
                        If not, please allocate now.
                    </p>
                </div>
            );
        },
        OK: 'Proceed without further allocation',
        Cancel: 'Allocate more Miscellaneous Items now',
    }
};