"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SonPages = exports.SonSubtitle = void 0;
const MaterialsTemplates_1 = require("./templates/MaterialsTemplates");
const PeopleTemplates_1 = require("./templates/PeopleTemplates");
const UserDefinedTemplates_1 = require("./templates/UserDefinedTemplates");
const InternalSystemsTemplates_1 = require("./templates/InternalSystemsTemplates");
const OtherTemplates_1 = require("./templates/OtherTemplates");
const RevenueTemplates_1 = require("./templates/RevenueTemplates");
const WorkingCapitalTemplates_1 = require("./templates/WorkingCapitalTemplates");
const FixedAssetsTemplates_1 = require("./templates/FixedAssetsTemplates");
const VideoLinks_1 = require("../models/VideoLinks");
exports.SonSubtitle = 'What information has come to light in the last 30 days that changes our view of the future?';
exports.SonPages = [{
        name: 'Revenue',
        route: 'revenue',
        id: 'revenue',
        sectionTemplates: RevenueTemplates_1.RevenueTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'R',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Revenue,
    }, {
        name: 'Costs: People',
        route: 'people',
        id: 'people',
        sectionTemplates: PeopleTemplates_1.PeopleTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'P',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.People,
    }, {
        name: 'Costs: Materials',
        route: 'materials',
        id: 'materials',
        sectionTemplates: MaterialsTemplates_1.MaterialsTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'M',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Materials,
    }, {
        name: 'Costs: Other',
        route: 'other',
        id: 'other',
        sectionTemplates: OtherTemplates_1.OtherTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'O',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Other,
    }, {
        name: 'Working Capital',
        route: 'capital',
        id: 'capital',
        sectionTemplates: WorkingCapitalTemplates_1.WorkingCapitalTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'C',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Capital,
    }, {
        name: 'Fixed Assets',
        route: 'assets',
        id: 'assets',
        sectionTemplates: FixedAssetsTemplates_1.FixedAssetsTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'F',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Assets,
    }, {
        name: 'Internal Systems',
        route: 'internal',
        id: 'internal',
        sectionTemplates: InternalSystemsTemplates_1.InternalSystemsTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'I',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.Internal,
    }, {
        name: 'User Defined',
        route: 'user-defined',
        id: 'user-defined',
        sectionTemplates: UserDefinedTemplates_1.UserDefinedTemplates,
        description: exports.SonSubtitle,
        monthSelector: true,
        letter: 'U',
        videoId: VideoLinks_1.VideoLinks.SONCommunication.UserDefined,
    }];
