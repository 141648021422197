var AccountNumberTag = require("../models/AccountNumberTag");
var Actions = require('../actions/AccountNumberTagsActions');
var Ajax = require('../endpoints/ajax');

var AccountNumberTagsSource = {
    fetchData() {
        return {
            remote(state) {
                return Ajax.accountNumberTags();
            },

            local() {
                return null;
            },

            success: Actions.receivedItems,
            loading: Actions.loadingItems,
            error: Actions.fetchingItemsFailed
        };
    },

    performSave() {
        return {
            /**
             * @param state
             * @param {AccountNumberTag} accountNumberTag
             */
            remote(state, accountNumberTag) {
                return Ajax.saveAccountNumberTag(accountNumberTag);
            },
            local() {
                return null;
            },
            success: Actions.save,
            loading: Actions.saving,
            error: Actions.savingFailed
        };
    },

    performBatchSave() {
        return {
            /**
             * @param state
             * @param {AccountNumberTag[]} accountNumberTags
             */
            remote(state, accountNumberTags) {
                return Ajax.batchSaveAccountNumberTag(accountNumberTags);
            },
            local() {
                return null;
            },
            success: Actions.batchSave,
            loading: Actions.saving,
            error: Actions.savingFailed
        };
    },

    performDisable() {
        return {
            /**
             * @param state
             * @param {AccountNumberTag} accountNumberTag
             */
            remote(state, accountNumberTag) {
                return Ajax.disableAccountNumberTag(accountNumberTag.account_number);
            },
            local() {
                return null;
            },
            success: Actions.itemDisabled,
            loading: Actions.saving,
            error: Actions.savingFailed
        };
    },

    performDelete() {
        return {
            /**
             * @param state
             * @param {AccountNumberTag} accountNumberTag
             */
            remote(state, accountNumberTag) {
                return Ajax.deleteAccountNumberTag(accountNumberTag.account_number);
            },
            local() {
                return null;
            },
            success: Actions.itemDeleted,
            loading: Actions.itemDeleting,
            error: Actions.itemDeletingFailed
        }
    },

    performDeleteWithAccountNumber() {
        return {
            /**
             * @param state
             * @param {String} accountNumber
             */
                remote(state, accountNumber) {
                return Ajax.deleteAccountNumberTag(accountNumber);
            },
            local() {
                return null;
            },
            success: Actions.itemDeleted,
            loading: Actions.itemDeleting,
            error: Actions.itemDeletingFailed
        }
    }
};

module.exports = AccountNumberTagsSource;
