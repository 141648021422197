"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Note {
    constructor(params) {
        this.section_code = '';
        this.body = '';
        if (params) {
            if (typeof params.section_code === 'string') {
                this.section_code = params.section_code;
            }
            if (typeof params.body === 'string') {
                this.body = params.body;
            }
        }
    }
}
exports.default = Note;
