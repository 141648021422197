"use strict";
import alt from '../alt.ts';
var FieldMappingActions = require('./../actions/FieldMappingActions');
var FieldMapping = require('../models/FieldMapping');
var Actions = require('./../actions/FieldActions');
var Source = require('./../sources/FieldSource');
var Field = require('./../models/Field');

class FieldStore {
    constructor() {

        this.state = {
            fields: [],
            errorMessage: null
        };

        this.bindListeners({
            receivedItems: Actions.receivedItems,
            loadingItems: Actions.loadingItems,
            fetchingItemsFailed: Actions.fetchingItemsFailed,
            receivedMapping: FieldMappingActions.receivedItem,
            savedMapping: FieldMappingActions.itemSaved
        });

        this.exportPublicMethods({
            getField: this.getField,
            getFields: this.getFields
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    receivedItems(data) {
        this.setState({
            fields: data.map(item => new Field(item)),
            errorMessage: null
        });
    }

    loadingItems() {
        // Do nothing
    }

    fetchingItemsFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    updateMapping(data) {
        var mapping = new FieldMapping(data);

        this.setState({
            fields: this.state.fields.map(item => {
                if (item.id == mapping.field_id) {
                    item.filtered_field_mapping = [mapping];
                }

                return item;
            })
        });
    }

    receivedMapping(data) {
        this.updateMapping(data);
    }

    savedMapping(data) {
        this.updateMapping(data);
    }

    // ---- PUBLIC ----
    getField(id) {
        var { fields } = this.getState();
        return fields
            .filter(field => field.id == id)
            .shift();
    }

    getFields() {
        return this.getState().fields;
    }
}

module.exports = alt.createStore(FieldStore, 'FieldStore');