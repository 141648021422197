import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InfoButton from '../components/InfoButton.tsx';
import RowContentCell from './RowContentCell';

import { isNullOrUndefined } from '../utilities/Utils';

class Content extends Component {

    get NumColumns() {
        const { centerData, leftData, rightData } = this.props;
        return [centerData, leftData, rightData].filter(item => !isNullOrUndefined(item)).length;
    }

    get RightColumnClass() {
        if (this.NumColumns < 2) {
            return 'col-xs-4';
        }
        if (this.NumColumns === 2) {
            return 'col-xs-3';
        }
        return 'col-xs-2';
    }

    get Description() {
        const { name, videoId } = this.props;

        const helpIcon = videoId ? <InfoButton tooltipDirection="right" small videoId={videoId} /> : null;

        return (
            <div
                className={classNames(this.NumColumns === 1 ? 'col-xs-8' : 'col-xs-6', 'description')}
            >
                {name}{helpIcon}
            </div>
        );
    }

    get CenterColumn() {
        const { centerData } = this.props;

        return (
            <RowContentCell
                className={classNames(this.RightColumnClass, 'historical')}
                {...centerData}
            />
        );
    }

    get LeftColumn() {
        const { leftData } = this.props;

        return (
            <RowContentCell
                className={classNames(this.RightColumnClass, 'historical')}
                {...leftData}
            />
        );
    }

    get RightColumn() {
        const { rightData } = this.props;
        if (rightData === null) {
            return null;
        }

        return (
            <RowContentCell
                className={classNames(this.RightColumnClass, 'forecast')}
                {...rightData}
            />
        );
    }

    render() {
        const { style } = this.props;

        return (
            <div className={classNames('Content', style)}>
                {this.Description}
                {this.LeftColumn}
                {this.CenterColumn}
                {this.RightColumn}
            </div>
        );
    }
}

const cellDataShape = PropTypes.shape({
    value: PropTypes.string,
    percentage: PropTypes.string,
});

Content.propTypes = {
    centerData: cellDataShape,
    leftData: cellDataShape,
    name: PropTypes.string,
    rightData: cellDataShape,
    style: PropTypes.string,
    videoId: PropTypes.string,
};

export default Content;
