import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactClickOutside from 'react-click-outside';
import classNames from 'classnames';

import SuggestionList from './SuggestionList';

class DropDownSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsVisible: false,
        };
    }

    getOptions() {
        const { options, onSelectOption } = this.props;
        const { optionsVisible } = this.state;

        if (optionsVisible !== true) {
            return null;
        }

        return (
            <SuggestionList
                tags={options}
                onItemClick={option => {
                    this.setOptionsVisible(false);
                    onSelectOption(option);
                }}
            />
        );
    }

    setOptionsVisible(visible) {
        this.setState({
            optionsVisible: visible,
        });
    }

    // react-click-outside
    handleClickOutside() {
        this.setOptionsVisible(false);
    }

    render() {
        const { selectedOption } = this.props;
        const { optionsVisible } = this.state;

        return (
            <div className={classNames('dropdown-selector', { open: optionsVisible })}>
                <div className="dropdown-container">
                    <div className="selected-option" role="none" onClick={() => this.setOptionsVisible(!optionsVisible)}>
                        {selectedOption.displayName || selectedOption.name} <span className="up-down-arrow glyphicon glyphicon-chevron-down pull-right" />
                    </div>
                    <div className={classNames('options-container', { open: optionsVisible })}>
                        {this.getOptions()}
                    </div>
                </div>
            </div>
        );
    }
}

const optionType = PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
});

DropDownSelector.propTypes = {
    onSelectOption: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(optionType).isRequired,
    selectedOption: optionType.isRequired,
};

module.exports = ReactClickOutside(DropDownSelector);
