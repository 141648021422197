"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSonForecastingNotes = void 0;
const SonDB_1 = require("../SonDB");
function updateSonForecastingNotes(sectionUid, sectionTemplateId, month, accountId, forecastingNotes) {
    return (0, SonDB_1.getSonSectionCollection)()
        .doc(sectionUid)
        .update({ 'notes': forecastingNotes });
}
exports.updateSonForecastingNotes = updateSonForecastingNotes;
