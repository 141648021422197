"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * a structure representing account items for each period for an account line,
 * or a calculated value for each period derived from such items
 */
class MultiPeriodValues {
    constructor(thisYearValue, lastYearValue, startMonthValue) {
        this.thisYear = {
            value: thisYearValue || 0,
        };
        this.lastYear = {
            value: lastYearValue || 0,
        };
        this.startMonth = startMonthValue
            ? { value: startMonthValue }
            : null;
    }
    clone() {
        var _a;
        return new MultiPeriodValues(this.thisYear.value, this.lastYear.value, (_a = this.startMonth) === null || _a === void 0 ? void 0 : _a.value);
    }
    set ThisYearValue(val) {
        this.thisYear.value = val;
    }
    set LastYearValue(val) {
        this.lastYear.value = val;
    }
    set StartMonthValue(val) {
        if (val === null || val === undefined) {
            this.startMonth = null;
        }
        else if (this.startMonth != null) {
            this.startMonth.value = val;
        }
        else {
            this.startMonth = {
                value: val,
            };
        }
    }
}
exports.default = MultiPeriodValues;
