"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SonCreateNewMonthData = void 0;
const SonSectionRow_1 = require("./types/SonSectionRow");
function createSingleValueRowFromPreviousMonthData(previousRow) {
    var _a;
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
        id: previousRow.id,
        name: previousRow.name,
        valueType: previousRow.valueType,
        value: '',
        comments: '',
        completed: false,
        subSectionName: (_a = previousRow.subSectionName) !== null && _a !== void 0 ? _a : '',
    });
}
function createProgressRowFromPreviousMonthData(previousRow) {
    var _a;
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.progressRow,
        id: previousRow.id,
        name: previousRow.name,
        value: '',
        subSectionName: (_a = previousRow.subSectionName) !== null && _a !== void 0 ? _a : '',
        completed: false
    });
}
function createNotesRowFromPreviousMonthData(previousRow) {
    var _a;
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.notes,
        id: previousRow.id,
        subSectionName: (_a = previousRow.subSectionName) !== null && _a !== void 0 ? _a : '',
        name: previousRow.name,
        value: '',
    });
}
function createFixedAssetRowFromPreviousMonthData(previousRow) {
    var _a;
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.fixedAssetRow,
        id: previousRow.id,
        name: previousRow.name,
        subSectionName: (_a = previousRow.subSectionName) !== null && _a !== void 0 ? _a : '',
        when: !previousRow.completed ? previousRow.when : '',
        howMuch: !previousRow.completed ? previousRow.howMuch : '',
        likelyFunding: !previousRow.completed ? previousRow.likelyFunding : '',
        completed: false
    });
}
function createFixedAssetNoFundingRowFromPreviousMonthData(previousRow) {
    var _a;
    return ({
        kind: SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow,
        id: previousRow.id,
        name: previousRow.name,
        subSectionName: (_a = previousRow.subSectionName) !== null && _a !== void 0 ? _a : '',
        when: !previousRow.completed ? previousRow.when : '',
        howMuch: !previousRow.completed ? previousRow.howMuch : '',
        likelyFunding: '',
        completed: false
    });
}
function createSectionFromPreviousMonthData(previousSection, currentMonth) {
    return ({
        responsiblePerson: previousSection.responsiblePerson,
        notes: '',
        pageId: previousSection.pageId,
        sectionTemplateId: previousSection.sectionTemplateId,
        rows: previousSection.rows
            .filter(row => {
            if (row.kind === SonSectionRow_1.SonSectionRowKind.progressRow
                || row.kind === SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow
                || row.kind === SonSectionRow_1.SonSectionRowKind.fixedAssetRow) {
                //can be false or undefined
                if (row.completed !== true) {
                    return row;
                }
            }
            else {
                return row;
            }
        }).map(row => {
            switch (row.kind) {
                case SonSectionRow_1.SonSectionRowKind.singleValueRow:
                    return createSingleValueRowFromPreviousMonthData(row);
                case SonSectionRow_1.SonSectionRowKind.progressRow:
                    return createProgressRowFromPreviousMonthData(row);
                case SonSectionRow_1.SonSectionRowKind.fixedAssetRow:
                    return createFixedAssetRowFromPreviousMonthData(row);
                case SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow:
                    return createFixedAssetNoFundingRowFromPreviousMonthData(row);
                case SonSectionRow_1.SonSectionRowKind.notes:
                    return createNotesRowFromPreviousMonthData(row);
            }
        }),
        accountId: previousSection.accountId,
        month: currentMonth,
    });
}
function SonCreateNewMonthData(allPreviousMonthData, currentMonth) {
    return allPreviousMonthData.map(section => createSectionFromPreviousMonthData(section, currentMonth));
}
exports.SonCreateNewMonthData = SonCreateNewMonthData;
