import React from 'react';
import PropTypes from 'prop-types';
import SubCategory from "./SortedSubcategory"
import { AccountItem } from "../models/RealTimeCeo"
import BaseAccordion from "./BaseAccordion.js"
import Utils from "../utilities/Utils"
import DetailedAccordion from "../components/DetailedAccordion"
import DebounceInput from "../components/Debounce"
import { InputTypes } from "../models/RealTimeCeo"



var DataStore = require('../datastore');
var TagCategory = DataStore.TagCategory;
var Month = DataStore.Month;

class Accordion extends React.Component {
    constructor(props) {
        super(props);
    }

    getCollapseIcon(subCategories) {
        var iconClass = this.state.expanded ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";
        return subCategories.length > 0 ? <span className={iconClass}></span> : null;
    }

    /**
     * Returns the extra rows - depending on ledger type and category name
     * Ideally this would be done from ./historical.js, but that doesn't seem to be working.
     */
    getDifferenceRow() {
      const { category, getDifferenceForID } = this.props;
      var heading = "Remainder";

      //If balance sheet, display - "value of all x not split out above"
//      if (category.ledger_type === "Balance Sheet") {
//          switch (category.name) {
//            case "Total Assets":
//              heading = "Value of all Assets not split out above";
//              break;
//
//            case "Total Liabilities":
//              heading = "Value of all Liabilities not split out above";
//              break;
//
//            default:
//              heading = "Rest of Equity";
//          }
//      }

      return  <div>
                <DetailedAccordion heading={heading}
                                   value={"$" + Utils.removePrecisionAndGetCommas(getDifferenceForID(category.id))}/>
              </div>;
    }

    /**
     *  Get the tagged total edit row
     *  Only displays if editing and manual input 
     */
    getEditTotal() {
      const { total, editing, category, inputType, onTotalEditingFinished} = this.props;

      if (!editing) {
        return null;
      }

        return <div className={"col-xs-12 sub-item"}>
                   <div className="col-xs-7">
                       <span style={ {lineHeight:"40px"} }>{"Specified Total"}</span>
                   </div>
                   <div className="col-xs-2">
                       <DebounceInput
                           type="text"
                           className="sorted-input short-input"
                           updateOnlyOnBlur
                           value={Utils.removePrecisionAndGetCommas(total(category.id))}
                           onChange={value => this.onTotalChange(value)}
                       />
                   </div>
               </div>;
    }


    onTotalChange(value) {
      const { category, onTotalEditingFinished } = this.props;

      //send new value and accountItem back to historical...
      var id = category.id;
      onTotalEditingFinished(id, Utils.parseMoney(value));
    }


    render() {
        const { category, editing, onRemoveRow, onReTag, onSplit, onChangeAccountItem, inputType, month, onAddAccountItem, parentAccountItems, total, getTotalForID, getDifferenceForID} = this.props;
        
        //Remove the total tags from category.tags
        var filteredTags = category.tags.filter(tag => tag.isTotal == false);

        var className = Utils.toClassName(category.name);
        return <BaseAccordion className={`category ${className}`} heading={category.name} total={getTotalForID(category.id)} getDifferenceForID={getDifferenceForID(category.id)}>
            {this.getEditTotal()}
            {filteredTags.map(tag => <SubCategory key={tag.id}
                                                   tag={tag}
                                                   onChangeAccountItem={onChangeAccountItem}
                                                   editing={editing}
                                                   onRemoveRow={onRemoveRow}
                                                   onReTag={onReTag}
                                                   onSplit={onSplit}
                                                   inputType={inputType}
                                                   month={month}
                                                   onAddAccountItem={onAddAccountItem}
                                                   parentAccountItems={parentAccountItems}

                />)}

            {/* Calculated Total Row at Bottom */}
            {this.getDifferenceRow()}
        </BaseAccordion>;
    }
}

Accordion.propTypes = {
    editing: PropTypes.bool.isRequired,
    onReTag: PropTypes.func,
    onRemoveRow: PropTypes.func,
    inputType: PropTypes.string,
    category: PropTypes.instanceOf(TagCategory),
    onChangeAccountItem: PropTypes.func.isRequired,
    month: PropTypes.instanceOf(Month).isRequired,
    onAddAccountItem: PropTypes.func.isRequired,
    parentAccountItems: PropTypes.func,
    total: PropTypes.func,
    getTotalForID: PropTypes.func,
    getDifferenceForID: PropTypes.func,
    onTotalEditingFinished: PropTypes.func
};

export default Accordion;
