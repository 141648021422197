"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalSystemsTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
const percentageAndText = ['text', 'percentage'];
exports.InternalSystemsTemplates = [{
        title: 'Internal Systems',
        id: 'internal-systems-v2',
        allowedValueTypes: percentageAndText,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'Internal Systems (Direct Costs)',
        id: 'internal-direct-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'Internal Systems (Indirect Costs)',
        id: 'internal-indirect-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'Internal Systems (Fixed Assets)',
        id: 'internal-fixed-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'General Internal Systems Notes',
        id: 'internal-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }];
