var DataStore = require('../datastore');
var MonthStore = DataStore.MonthStore;
var AccountStore = DataStore.AccountStore;
var moment = require('moment');

class Date {
    static get thisMonth() {
        return moment(1,"DD");
    }

    static get lastMonth() {
        return moment(1,"DD").subtract(1,"months");
    }

    static get finalMonth() {
        let lastHistoricDate = this.lastHistoric;
        if (lastHistoricDate === null) {
            return null;
        } else if (AccountStore.isForecast()) {
            return lastHistoricDate.add(12,"months");
        } else {
            return lastHistoricDate;
        }
    }

    static get lastHistoric() {
        let month = MonthStore.state.lastHistoricMonth;
        if (month === null) {
            return null;
        } else {
            return moment(month.date);
        }
    }

    static get selectedMonth() {
        let month = MonthStore.state.month;
        if (month === null) {
            return null;
        } else {
            return moment(month.date);
        }
    }
};

export default Date;