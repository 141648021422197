"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayFormatValueTypes = void 0;
const SonRowValueTypesLabels = [{
        type: 'text',
        label: 'Text'
    }, {
        type: 'percentage',
        label: 'Percentage'
    }, {
        type: 'number',
        label: 'Number'
    }, {
        type: 'stability_dropdown',
        label: 'Picklist'
    }, {
        type: 'date',
        label: 'Date'
    }];
function displayFormatValueTypes(givenType) {
    var _a;
    return ((_a = SonRowValueTypesLabels.find(label => label.type === givenType)) === null || _a === void 0 ? void 0 : _a.label) || '';
}
exports.displayFormatValueTypes = displayFormatValueTypes;
