import React from 'react';
import PropTypes from 'prop-types';
import InfoButton from './components/InfoButton.tsx';

class PageHeading extends React.Component {
    render() {
        const videoLink = this.props.videoId ? (
            <InfoButton videoId={this.props.videoId} />
        ) : null;

        return (
            <div className="page-heading">
                <div className="heading-icon">
                    <img src={this.props.icon}/>
                </div>
                <h1>
                    <span className="heading-text">{this.props.title}</span>
                </h1>
                <div className="flex-float-right">
                    {this.props.children}
                    {videoLink}
                </div>
            </div>
        );
    }
}

PageHeading.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    videoId: PropTypes.string,
};

export default PageHeading;
