const Actions = require('../actions/AccountNumberGroupActions');
const Ajax = require('../endpoints/ajax');

const AccountNumberGroupSource = {
    fetchAccountNumberGroups() {
        return {
            remote(state) {
                return Ajax.getAccountNumberGroups();
            },

            local() {
                return null;
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed,
        }
    },

    saveAccountNumberGroup() {
        return {
            remote(state, accountNumberGroup) {
                return Ajax.updateAccountNumberGroup(accountNumberGroup);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed,
        }
    },

    batchSaveAccountNumberGroups() {
        return {
            remote(state, accountNumberGroups) {
                return Ajax.batchUpdateAccountNumberGroups(accountNumberGroups);
            },

            local() {
                return null;
            },

            success: Actions.itemsSaved,
            loading: Actions.itemsSaving,
            error: Actions.itemsSavingFailed,
        }
    },

    batchUpdateAccountNumberGroupsAndAccountItems() {
        return {
            remote(state, accountNumberGroups) {
                return Ajax.batchUpdateAccountNumberGroupsAndAccountItems(accountNumberGroups);
            },

            local() {
                return null;
            },

            success: Actions.groupsAndAccountItemsUpdated,
            loading: Actions.groupsAndAccountItemsUpdating,
            error: Actions.groupsAndAccountItemsUpdatingFailed,
        }
    },
};

module.exports = AccountNumberGroupSource;
