import React from 'react';
import PropTypes from 'prop-types';
import { AccountItem } from "../models/RealTimeCeo"
import { SplitTypes } from "../models/Enums"
import Utils from "../utilities/Utils"
import TagHelper from "../datastore/helpers/TagHelper"
import { ButtonTypes, PictureButton } from "../../../common/Buttons"
import DebounceInput from "../components/Debounce"

var RowTypes = {
    Root: "Root",
    RootEditable: "RootEditable",
    Remainder: "Remainder",
    Other: "Other"
};

/**
 * @deprecated
 */
class SplitRow extends React.Component {
    handleNameChange(newValue) {
        var { accountItem, onChangeAccountItem } = this.props;
        accountItem.name = newValue;
        onChangeAccountItem(accountItem);
    }

    handleNetActivityChange(newValue, rootNetActivity) {
        var { accountItem, onChangeAccountItem } = this.props;
        accountItem.NetActivity = newValue;
        accountItem.Percentage = newValue / rootNetActivity * 100;
        onChangeAccountItem(accountItem);
    }

    handlePercentageChange(newValue, rootNetActivity) {
        var { accountItem, onChangeAccountItem } = this.props;
        accountItem.Percentage = newValue;
        accountItem.NetActivity = rootNetActivity * (newValue / 100);
        onChangeAccountItem(accountItem);
    }

    /**
     * Root item, no editing: This happens when the item has not yet been split.
     *
     * @returns {XML}
     */
    getRootRow() {
        const { accountItem, onSplit } = this.props;
        // var value = Math.floor(accountItem.value);

        return <div>
            <div className="col-xs-7 name">
                <span>{accountItem.name}</span>
            </div>
            <div className="col-xs-4 price">
                <span className="col-xs-12 no-padding split-readyonly">{`SPLIT $ ${Utils.removePrecisionAndGetCommas(accountItem.value)}`}</span>
            </div>
            <div className="col-xs-1 action">
                <PictureButton buttonType={ButtonTypes.Split} onClick={onSplit}/>
            </div>
        </div>
    }

    /**
     * Root item, name + net activity editable
     *
     * @returns {XML}
     */
    getRootEditableRow() {
        const { accountItem, onSplit } = this.props;

        return <div>
            <div className="col-xs-7 name">
                <span>{accountItem.pre_split_name}</span> <span className="label-remainder">Remainder</span>
            </div>
            <div className="col-xs-4 price">
                <span className="col-xs-6 no-padding split-readyonly">{Math.floor(accountItem.value)}</span>
            </div>
            <div className="col-xs-1 action">
                <PictureButton buttonType={ButtonTypes.Split} onClick={onSplit}/>
            </div>
        </div>
    }

    /**
     * Remainder Item, name editable, remainder values shown
     * @returns {XML}
     */
    getRemainderRow() {
        const { remainderNetActivity, remainderPercentage, accountItem } = this.props;

        var valueOffset = remainderNetActivity;
        var valuePercent = remainderPercentage;

        return <div>
            <div className="col-xs-7 name">
                <div className="input-group">
                    <DebounceInput type="text"
                           className="form-control"
                           updateOnlyOnBlur
                           value={accountItem.name}
                           onChange={value => this.handleNameChange(value)}
                        />
                    <span className="label-remainder">Remainder</span>
                </div>
            </div>
            <div className="col-xs-4 price">
                <span className="col-xs-6 no-padding split-readyonly">{Math.floor(valuePercent)}%</span>
                <span className="col-xs-6 no-padding split-readyonly">${Utils.removePrecisionAndGetCommas(valueOffset)}</span>
            </div>
            <div className="col-xs-1 action"></div>
        </div>
    }

    /**
     * Every other item editable depending on split type.
     * @returns {XML}
     */
    getOtherRow() {
        const { accountItem, splitType, onDelete, rootAccountItem } = this.props;

        var rootNetActivity = rootAccountItem.NetActivity;
        var netActivity = accountItem.NetActivity;
        var percentage = accountItem.Percentage;
        var editableFields = null;

        switch (splitType.name) {
            case SplitTypes.Percentage:
            {
                var offset = rootNetActivity * (percentage / 100);

                editableFields = <div>
                    <DebounceInput
                        className="split-input"
                        updateOnlyOnBlur
                        type="number"
                        value={Math.floor(percentage)}
                        onChange={value => this.handlePercentageChange(value, rootNetActivity)}/>
                    <span className="col-xs-6 no-padding split-readonly">${Utils.removePrecisionAndGetCommas(offset)}</span>
                </div>;
            }
                break;

            case SplitTypes.Offset:
            {
                var percent = (netActivity / rootNetActivity * 100).toFixed(1);

                editableFields = <div>
                    <span className="col-xs-6 no-padding split-readonly">{Math.floor(percent)}%</span>
                    <DebounceInput
                        className="split-input"
                        updateOnlyOnBlur
                        type="number"
                        value={Math.floor(netActivity)}
                           onChange={value => this.handleNetActivityChange(value, rootNetActivity)}/>
                </div>;
            }
                break;
        }

        return <div>
            <div className="col-xs-7 name">
                <DebounceInput
                    type="text"
                    className="form-control"
                    updateOnlyOnBlur
                    value={accountItem.name}
                    onChange={value => this.handleNameChange(value)}
                />
            </div>
            <div className="col-xs-4 price">
                {editableFields}
            </div>
            <div className="col-xs-1 action">
                <PictureButton buttonType={ButtonTypes.Delete} onClick={() => onDelete(accountItem)}/>
            </div>
        </div>
    }

    getRow() {
        const { onSplit, onChangeAccountItem, remainderNetActivity, remainderPercentage } = this.props;

        if (onSplit != null) {
            if (onChangeAccountItem == null) {
                return this.getRootRow();
            }

            return this.getRootEditableRow();
        }

        //What's remainderNetActivity and remainderPercentage?
        if (remainderNetActivity != null && remainderPercentage != null) {
            return this.getRemainderRow();
        }

        return this.getOtherRow();
    }

    render() {
        const { showOriginalName, accountItem } = this.props;

        var tagCategory = TagHelper.getTagCategory(accountItem.account_number);
        var className = "col-xs-12 item " + Utils.toClassName(tagCategory.name);

        //Header Row
        if (showOriginalName === true || accountItem.is_split === false) {
            //Remove the category name - this row needs to be white
            className = "col-xs-12 header " + Utils.toClassName(tagCategory.name); 
        }

        return <div className={className}>
            {this.getRow()}
        </div>
    }
}

SplitRow.propTypes = {
    showOriginalName: PropTypes.bool,
    splitType: PropTypes.object,
    rootAccountItem: PropTypes.instanceOf(AccountItem).isRequired,
    accountItem: PropTypes.instanceOf(AccountItem).isRequired,
    onSplit: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onChangeAccountItem: PropTypes.func.isRequired,
    remainderNetActivity: PropTypes.number,
    remainderPercentage: PropTypes.number
};

export default SplitRow;