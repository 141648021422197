// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OptionSelector from '../../components/OptionSelector';

import Utils from '../../utilities/Utils';
import { AccountItemQuery } from '../../utilities/AccountItemQuery';

import { TagCategoryName } from '../../models/Enums';
import { VideoLinks } from '../../models/VideoLinks';
import BaseForecastScreen from './BaseForecastScreen';
import MultiMonthValuesRow from '../MultiMonthValuesRow';
import {
    AspirationalRevenueScreens,
    AspirationSplittingOptions,
    AspirationalInputMethod,
} from './AspirationalRevenue';
import EnterAspirationValue from '../EnterAspirationValue';
import ForecastFormula from '../ForecastFormula';
import Headings from '../generateTwelveMonthHeadings';

import ForecastCalculator from '../ForecastCalculatorInstance';
import { TagCode } from '../../models/EnumsTs';
import Month from '../../datastore/models/Month';

class AspirationalRevenueScreen extends Component {
    constructor(props) {
        super(props);

        const screen = this.getScreenForForecastMonths();

        this.state = {
            screen,
            selectedAspirationInputMethod: AspirationalInputMethod.keyManual,
            selectedOptionForSplitting: AspirationSplittingOptions.keyMonthlyTargets,
            aspirationValue: ForecastCalculator.getYearTotalRevenue(this.getLast12HistoricMonths()),
            aspirationPercent: 100,
            forecastMonths: this.props.forecastMonths.map(month => new Month(month)),
        };

        this.handlePercentUpdate = this.handlePercentUpdate.bind(this);
        this.handleMoneyUpdate = this.handleMoneyUpdate.bind(this);
    }

    getLast12HistoricMonths() {
        return this.props.historicalMonths.slice(-12);
    }

    getScreenForForecastMonths() {
        const hasSetAspiration = this.hasSetAspiration();

        if (hasSetAspiration) {
            return AspirationalRevenueScreens.applyMonthlyTargets;
        }
        return AspirationalRevenueScreens.setAspirationalRevenue;
    }


    /**
     * Get the forecast months for a given tag.
     * if no forecastMonths provided, get from state
     */
    getForecastAccountItemsForTag(tag, forecastMonths) {
        const months = forecastMonths || this.state.forecastMonths;

        return months.map(month => AccountItemQuery.code(month.account_items, tag));
    }

    getCurrentTargetArray() {
        const forecastAccountItems = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue);
        return forecastAccountItems.map(accountItem => this.getFormulaValue(accountItem.value_formula));
    }


    getSet12MonthsAspirationScreen(historicalMonths) {
        const total = (ForecastCalculator.getYearTotalRevenue(historicalMonths));
        return (
            <div className="aspiration-box">
                <p>Over the past 12 months your revenue was</p>
                <p>${Utils.removePrecisionAndGetCommas(total)}</p>
                <p>What's your aspirational revenue for the next 12 months?</p>
                <EnterAspirationValue
                    selectedKey={this.state.selectedAspirationInputMethod}
                    handleOnSelect={(selectedOption) => this.handleSelectInputMethod(selectedOption)}
                    percent={this.state.aspirationPercent}
                    totalMoneyValue={this.state.aspirationValue}
                    handleMoneyUpdate={this.handleMoneyUpdate}
                    handlePercentUpdate={this.handlePercentUpdate}
                    totalHistorical={total}
                />
            </div>
        );
    }

    getSplittingMonthlyAspirationScreen() {
        const options = [
            { label: 'Set monthly targets evenly', key: AspirationSplittingOptions.keyMonthlyTargets },
            { label: 'Set to seasonal shape', key: AspirationSplittingOptions.keySeasonal },
        ];
        return (
            <div className="splitting-monthly-screen">
                <div className="aspiration-box">
                    <p>In Splitting your total aspirational revenue per month targets.</p>
                    <p>Do you want to evenly split the total, or shape (e.g seasonal) your revenue inline with earnings from the previous 12 months.</p>
                    <OptionSelector
                        onSelectOption={(selectedOption) => this.handleSelectSplitMethod(selectedOption)}
                        options={options}
                        selectedKey={this.state.selectedOptionForSplitting}
                    />

                </div>
            </div>
        );
    }

    getScreen() {
        const { screen } = this.state;
        const historicalMonths = this.getLast12HistoricMonths();
        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
                return this.getSet12MonthsAspirationScreen(historicalMonths);
            case AspirationalRevenueScreens.setMonthlySplitting:
                return this.getSplittingMonthlyAspirationScreen();
            case AspirationalRevenueScreens.applyMonthlyTargets:
                return this.applyMonthlyTargetsScreen();
            default:
                throw new Error(`invalid screen "${screen}"`);
        }
    }

    getLeftText() {
        const { screen } = this.state;
        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
            case AspirationalRevenueScreens.applyMonthlyTargets:
                return 'Cancel';
            case AspirationalRevenueScreens.setMonthlySplitting:
            default:
                return 'Back';
        }
    }

    getRightText() {
        const { screen } = this.state;
        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
                return 'Next';
            case AspirationalRevenueScreens.setMonthlySplitting:
            case AspirationalRevenueScreens.applyMonthlyTargets:
            default:
                return 'Accept & Save';
        }
    }

    getLeftButtonClick() {
        const { screen } = this.state;
        const { goToSummaryScreen } = this.props;
        const hasSetAspiration = this.hasSetAspiration();

        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
                if (hasSetAspiration) {
                    return () => this.goToScreen(AspirationalRevenueScreens.applyMonthlyTargets);
                }
                return goToSummaryScreen;
            case AspirationalRevenueScreens.setMonthlySplitting:
                return () => this.goToScreen(AspirationalRevenueScreens.setAspirationalRevenue);
            case AspirationalRevenueScreens.applyMonthlyTargets:
            default:
                return goToSummaryScreen;
        }
    }

    getRightButtonClick() {
        const { screen } = this.state;
        const { goToSummaryScreen } = this.props;

        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
                return (() => this.goToScreen(AspirationalRevenueScreens.setMonthlySplitting));
            case AspirationalRevenueScreens.setMonthlySplitting:
                return () => this.splitForecastMonths()
                    .then(() => this.saveAspirationRevenue())
                    .then(() => this.goToScreen(AspirationalRevenueScreens.applyMonthlyTargets));
            case AspirationalRevenueScreens.applyMonthlyTargets:
            default:
                return () => this.saveAspirationRevenue()
                    .then(() => goToSummaryScreen());
        }
    }

    getFormulaArray() {
        const forecastAccountItems = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue, null);
        return forecastAccountItems.map(accountItem => accountItem.value_formula);
    }

    get AspirationRevenueTotal() {
        return this.state.aspirationValue;
    }

    /**
     * check if all total items have formulas
     */
    hasSetAspiration() {
        const { forecastMonths } = this.props;

        const accountItems = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue, forecastMonths);
        const hasSetAspiration = accountItems.reduce((hasSet, accountItem) => {
            if (hasSet === null) {
                return accountItem.hasValueFormula();
            }

            if (hasSet !== accountItem.hasValueFormula()) {
                throw new Error('accountItem.value_formula is set for some Items, but not all!');
            }

            return hasSet;
        }, null);

        return hasSetAspiration;
    }

    saveAspirationRevenue() {
        const { saveAspirationRevenue } = this.props;

        const aspirationRevenueItems = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue);

        return saveAspirationRevenue(aspirationRevenueItems);
    }

    applyMonthlyTargetsScreen() {
        const { forecastMonths } = this.props;
        const historicalMonths = this.getLast12HistoricMonths();
        const monthHeadings = Headings(forecastMonths[0].Date);

        const targetArray = this.getCurrentTargetArray();
        const formulaArray = this.getFormulaArray();

        const percentageValues = formulaArray.map(formula => {
            if (formula.indexOf('%') !== -1) {
                return ForecastFormula.evaluatePercentValue(formula).percent;
            }

            return 0;
        });

        const plainValues = formulaArray.map(formula => {
            if (formula.indexOf('%') !== -1) {
                return ForecastFormula.evaluatePercentValue(formula).result;
            }

            return ForecastFormula.evaluateValueAndValue(formula).value1;
        });

        const percentageColumns = formulaArray.map(formula => formula.indexOf('%') !== -1);
        const valueColumns = formulaArray.map(formula => formula.indexOf('%') === -1);

        const historicalValues = historicalMonths.map(month => ForecastCalculator.getTotalRevenueForMonth(month));

        return (
            <div className="apply-monthly-targets">
                <div className="top-row-with-button col-xs-12 no-padding">
                    <div className="col-xs-9 text-section">
                        <p>You can adjust the Revenue Aspiration for each month in two different ways: </p>
                        <ul>
                            <li>tweak the Initial Target value by percentage in the <em>Percentage Adjustment</em> row</li>
                            <li>override the Initial Target value with a new value in the <em>Final Target</em> row</li>
                        </ul>
                        <p>You can switch between these two options by clicking the cell in the row you would like to use.</p>
                        <p>To reset your Initial Target values, click  &quot;Reset Aspiration.&quot;</p>
                    </div>
                    <div className="col-xs-3 reset-button-container">
                        <button
                            className="btn btn-primary forecast-button pull-right"
                            onClick={() => this.resetAspiration()}
                        >
                            Reset Aspiration
                        </button>

                    </div>
                </div>
                <MultiMonthValuesRow
                    columnValues={monthHeadings}
                    className={classNames('asp-revenue', 'first-in-group', MultiMonthValuesRow.ClassNames.noBorder)}
                    totalColumnContent="Total"
                    titleColContent=""
                />
                <MultiMonthValuesRow
                    titleColContent="Initial Target"
                    columnValues={targetArray}
                    displayTotalCalc
                    rowType="money"
                />
                <div className="col-xs-12">
                    <div className={classNames('edit-togglable', 'row')}>
                        <MultiMonthValuesRow
                            className={classNames('noBorder', 'light-title', 'percentage-editable')}
                            columnValues={percentageValues}
                            inputType="number"
                            onChange={(value, index) => this.updatePercentage(value, index)}
                            rowType="percentage"
                            titleColContent="Percentage adjustment"
                            totalColumnContent=""
                            canBeNegative
                            editableColumns={percentageColumns}
                            onClick={(index) => this.updatePercentage(100, index)}
                        />
                        <MultiMonthValuesRow
                            className={classNames('noBorder', 'money-editable', Utils.toClassName(TagCategoryName.Revenue), 'light')}
                            columnValues={plainValues}
                            focusCellOnEditingEnabled
                            rowType="money"
                            titleColContent="Final Target"
                            onChange={(value, index) => this.updateValue(value, index)}
                            displayTotalCalc
                            editableColumns={valueColumns}
                            onClick={(index) => this.updateValue(plainValues[index], index)}
                            canBeNegative
                        />
                        <MultiMonthValuesRow
                            className={classNames(MultiMonthValuesRow.ClassNames.borderTop)}
                            columnValues={historicalValues}
                            rowType="money"
                            titleColContent={(
                                <div>
                                    Revenue <br /> (same month, prior year)
                                </div>
                            )}
                            canBeNegative
                            displayTotalCalc
                        />
                    </div>
                </div>
            </div>
        );
    }

    updatePercentage(percentage, index) {
        const forecastMonths = this.state.forecastMonths;
        const accountItem = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue, forecastMonths)[index];
        const currentValue = this.getFormulaValue(accountItem.value_formula);
        accountItem.value_formula = ForecastFormula.percentValue(percentage, currentValue);
        this.setState({ forecastMonths });
    }

    /**
     * gets the initial aspiration value, regardless of formula type
     * @param {string} formula
     */
    getFormulaValue(formula) {
        if (formula.indexOf('%') !== -1) {
            return ForecastFormula.evaluatePercentValue(formula).value;
        }

        return ForecastFormula.evaluateValueAndValue(formula).value2;
    }

    updateValue(value, index) {
        const forecastMonths = this.state.forecastMonths;
        const accountItem = this.getForecastAccountItemsForTag(TagCode.IS_Revenue_TotalRevenue, forecastMonths)[index];
        const currentValue = this.getFormulaValue(accountItem.value_formula);
        accountItem.value_formula = ForecastFormula.valueAndValue(value, currentValue);
        this.setState({ forecastMonths });
    }

    goToScreen(screen) {
        switch (screen) {
            case AspirationalRevenueScreens.setAspirationalRevenue:
            case AspirationalRevenueScreens.setMonthlySplitting:
            case AspirationalRevenueScreens.applyMonthlyTargets:
                this.setState({
                    screen,
                });
                return;
            default:
                throw new Error(`invalid screen "${screen}"`);
        }
    }

    handleMoneyUpdate(input) {
        const formatted = Utils.parseMoney(input);
        this.setState({
            aspirationValue: formatted,
        });
    }

    handlePercentUpdate(input) {
        const historicalMonths = this.getLast12HistoricMonths();
        const aspirationValue = ForecastCalculator.getYearTotalRevenue(historicalMonths);
        const formatted = Utils.parseMoney(input);
        const newTotal = (aspirationValue * (input / 100));
        this.setState({
            aspirationValue: newTotal,
            aspirationPercent: formatted,
        });
    }

    resetAspiration() {
        //We don't update the forecast months
        //otherwise, there is no way for the user to 'cancel' a reset
        this.setState({
            selectedAspirationInputMethod: AspirationalInputMethod.keyManual,
            selectedOptionForSplitting: AspirationSplittingOptions.keyMonthlyTargets,
            aspirationValue: (ForecastCalculator.getYearTotalRevenue(this.getLast12HistoricMonths())),
            aspirationPercent: 100,
        });
        this.goToScreen(AspirationalRevenueScreens.setAspirationalRevenue);
    }


    handleSelectSplitMethod(selectedOption) {
        const key = selectedOption.key;
        this.setState({ selectedOptionForSplitting: key });
    }

    handleSelectInputMethod(selectedOption) {
        const key = selectedOption.key;
        this.setState({ selectedAspirationInputMethod: key });
    }

    splitForecastMonths() {
        const { selectedOptionForSplitting, aspirationValue, forecastMonths } = this.state;
        const historicalMonths = this.getLast12HistoricMonths();

        const useSeasonalShape = selectedOptionForSplitting === AspirationSplittingOptions.keySeasonal;

        const updatedForecastMonths = ForecastCalculator.calculateBaseMonthlyAspirationalRevenue(historicalMonths, forecastMonths, aspirationValue, useSeasonalShape);

        return new Promise(resolve =>
            this.setState({ forecastMonths: updatedForecastMonths }, resolve)
        );
    }

    render() {
        return (
            <BaseForecastScreen
                title="Aspirational Revenue"
                buttonTextLeft={this.getLeftText()}
                buttonTextRight={this.getRightText()}
                onLeftButtonClick={this.getLeftButtonClick()}
                onRightButtonClick={this.getRightButtonClick()}
                videoLink={VideoLinks.Forecast.AspirationalRevenue}
            >
                <div className="aspirational-revenue-screen">
                    {this.getScreen()}
                </div>
            </BaseForecastScreen>
        );
    }
}

AspirationalRevenueScreen.propTypes = {
    forecastMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)),
    goToSummaryScreen: PropTypes.func,
    historicalMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)),
    saveAspirationRevenue: PropTypes.func,
};

export default AspirationalRevenueScreen;
