"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeopleTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
const multipleValues = ['text', 'stability_dropdown', 'number'];
exports.PeopleTemplates = [{
        title: 'People',
        id: 'people-staff-v2',
        allowedValueTypes: multipleValues,
        showComments: true,
        showCompletedCheckbox: false,
        showSubsections: true,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Direct People',
        id: 'people-direct-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Indirect People',
        id: 'people-indirect-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Attention Needed',
        id: 'people-attention',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: false,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'General People Notes',
        id: 'people-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }];
