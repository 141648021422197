/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import DebounceInput from "./Debounce";

/**
 * A Generic component that handles opening and closing when clicking the header.
 */
class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        }
    }

    onExpandToggle() {
        if (this.props.collapsible) {
            this.setState({
                expanded: !this.state.expanded
            });
        }
    }

    isExpanded() {
        if (!this.props.collapsible)
            return true;
        return this.state.expanded;
    }

    getCollapseIcon() {
        if (this.props.collapsible) {
            var iconClass = this.state.expanded ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";
            return React.Children.count(this.props.children) > 0 ? <span className={iconClass}></span> : null;
        } else {
            return null;
        }
    }

    getValueSection(value, onValueChange, label, small) {
        if (typeof value === "undefined") {
            return <div className="col-xs-6"></div>;
        }

        let content;
        if (typeof onValueChange === "function") {
            content = <DebounceInput onChange={val => onValueChange(val)} value={value} updateOnlyOnBlur/>;
        } else {
            content = <span className="heading">{value}</span>
        }

        const wholeClassName = `col-xs-${small ? '5' : '6'} no-padding`;
        const rightClassName = `col-xs-${small ? '4' : '5'} no-padding`;

        if (label) {
            return [<div key="label" className="col-xs-1 text-center no-padding">
                <span>{label}</span>
            </div>,
                <div key="content" className={rightClassName}>
                    {content}
                </div>]
        } else {
            return <div className={wholeClassName}>
                {content}
            </div>
        }
    }

    getValue1() {
        let {value, onValueChange, label} = this.props;
        return this.getValueSection(value, onValueChange, label);
    }

    getValue2() {
        let {value2, onValueChange2, label2} = this.props;
        return this.getValueSection(value2, onValueChange2, label2);
    }

    getValue3() {
        let {value3, onValueChange3, label3} = this.props;
        if (typeof value3 === "undefined") {
            return null;
        }

        const small = true;
        return this.getValueSection(value3, onValueChange3, label3, small);
    }

    isCollapsible() {
        return React.Children.count(this.props.children) > 0 && this.props.collapsible !== false;
    }

    render() {

        const value3 = this.getValue3();

        const titleSection = value3 === null
        ? (
            <div className="col-xs-6">
                {this.getCollapseIcon()}
                <span className="heading">
                    {this.props.heading}
                </span>
            </div>
        ) : (
            <div className="col-xs-6">
                <div className="row">
                    <div className="col-xs-7">
                        {this.getCollapseIcon()}
                        <span className="heading">
                            {this.props.heading}
                        </span>
                    </div>
                    {value3}
                </div>
            </div>
        );

        return (
            <div className={`${this.props.className} accordion row`}>
                <div className={`col-xs-12 parent-row ${this.isCollapsible() ? "collapsible" : ""}`} onClick={() => this.onExpandToggle()}>
                    <div className="row">
                        { titleSection }
                        <div className="col-xs-6">
                            {this.getValue1()}
                            {this.getValue2()}
                        </div>
                    </div>
                </div>
                {this.isExpanded() ? this.props.children : null}
            </div>
        );
    }
}

Accordion.defaultProps = {
    collapsible: true
};


Accordion.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    collapsible: PropTypes.bool,
};

export default Accordion;