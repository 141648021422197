/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
class Clicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };
    }

    click(amount) {
        let newValue = this.Value + amount;
        //clamp to 0-100
        if (newValue < this.props.minValue) {
            newValue = this.props.minValue;
        }
        if (newValue > this.props.maxValue) {
            newValue = this.props.maxValue;
        }
        //trigger only if changed
        if (newValue !== this.Value) {
            if (this.props.stateless !== true) {
                this.setState({
                    value: this.Value + amount,
                });
            }
            this.props.onChange(this.Value + amount);
        }
    }

    get isDownEnabled() {
        const newValue = this.Value - 1;
        return newValue >= this.props.minValue;
    }

    get isUpEnabled() {
        const newValue = this.Value + 1;
        return newValue <= this.props.maxValue;
    }

    get Value() {
        if (this.props.stateless === true) {
            return this.props.value;
        }
        return this.state.value;
    }

    render() {
        return (
            <div className="clicker">
                <input
                    disabled
                    type="text"
                    value={this.props.preLabel + this.Value + this.props.postLabel}
                />
                <button
                    className="btn btn-primary blue-disabled"
                    disabled={this.isDownEnabled === false}
                    onClick={() => this.click(-1)}
                >
                    <span className="glyphicon glyphicon-triangle-bottom"/>
                </button>
                <button
                    className="btn btn-primary blue-disabled"
                    disabled={this.isUpEnabled === false}
                    onClick={() => this.click(1)}
                >
                    <span className="glyphicon glyphicon-triangle-top"/>
                </button>
            </div>
        );
    }
}

Clicker.propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    onChange: PropTypes.func,
    postLabel: PropTypes.string,
    preLabel: PropTypes.string,
    stateless: PropTypes.bool,
    value: PropTypes.number.isRequired,
};

Clicker.defaultProps = {
    onChange: () => {},
    preLabel: '',
    postLabel: '',
    minValue: 0,
    maxValue: 100,
};

export default Clicker;
