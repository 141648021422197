"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/ThreeWActions');
var Source = require('../sources/ThreeWSource');
var ThreeW = require("../models/ThreeW");

class ThreeWStore {
    constructor() {
        // State
        this.threeWs = [];
        this.threeW = new ThreeW();
        this.isLoading = false;

        this.errorMessage = null;

        this.bindListeners({
            receivedItem: Actions.receivedItem,
            loadingItem: Actions.loadingItem,
            fetchingItemFailed: Actions.fetchingItemFailed,
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            itemStored: Actions.itemStored,
            itemStoring: Actions.itemStoring,
            itemStoringFailed: Actions.itemStoringFailed
        });

        this.exportPublicMethods({
            getThreeWs: this.getThreeWs,
            getThreeW: this.getThreeW
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemsReceived(data) {
        this.setState({
            threeWs: data.map(item => new ThreeW(item)),
            errorMessage: null,
            isLoading: false,
        });
    }

    itemsReceiving() {
        this.setState({
            isLoading: true,
        })
    }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    receivedItem(data) {
        this.setState({
            threeW: new ThreeW(data),
            errorMessage: null
        });
    }

    loadingItem() {
        // pass typed letters and return matching tags perhaps? Otherwise..
        // Do nothing
    }

    fetchingItemFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemSaved(data) {
        let threeW = new ThreeW(data);
        let threeWs = this.threeWs.slice(); // Clone

        if (threeWs.filter(item => item.id == threeW.id).length > 0) {
            // Replace
            let index = null;

            threeWs.forEach((item, i) => {
                if (item.id === threeW.id) {
                    index = i;
                }
            });

            if (index !== null) {
                threeWs[index] = threeW;
            }
        } else {
            threeWs.push(threeW);
        }

        this.setState({
            threeW: threeW,
            threeWs: threeWs
        });
    }

    itemSaving() {
        // Do nothing
    }

    itemSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemStored(data) {
        let threeW = new ThreeW(data);

        // Find it in the list and replace it.
        let threeWs = this.threeWs.map(item => {
            if (item.id == threeW.id) {
                return threeW;
            }

            return item;
        });

        this.setState({
            threeW: threeW,
            threeWs: threeWs
        });
    }

    itemStoring() {
        // Do nothing
    }

    itemStoringFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    // ---- PUBLIC ----

    getThreeWs() {
        return this.getState().threeWs;
    }

    getThreeW() {
        return this.getState().threeW;
    }
}

module.exports = alt.createStore(ThreeWStore, 'ThreeWStore');
