var ThreeWTag = require("../models/ThreeW");
var Actions = require('../actions/ThreeWActions');
var Ajax = require('../endpoints/ajax');


var ThreeWSource = {
    fetchData() {
        return {
            remote(state) {
                return Ajax.getThreeWs();
            },

            local() {
                return null;
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    // After saving a item remotely, we use the store actions to save it in the store's state.
    saveItem() {
        return {
            remote(state, item) {
                return Ajax.saveThreeW(item);
            },

            local() {
                return null;
            },

            success: Actions.itemStored,
            loading: Actions.itemStoring,
            error: Actions.itemStoringFailed
        }
    },

    // A store in this case is a local state
    storeItem() {
        return {
            remote(state, item) {
                return Promise.resolve(item);
            },

            success: Actions.itemStored,
            loading: Actions.itemStoring,
            error: Actions.itemStoringFailed

        }
    },

    // A store in this case is a local state
    updateStore() {
        return {
            remote(state, item) {
                return Promise.resolve(item);
            },

            success: Actions.itemStored,
            loading: Actions.itemStoring,
            error: Actions.itemStoringFailed

        }
    }
};

module.exports = ThreeWSource;

