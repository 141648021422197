"use strict";
import alt from '../alt.ts';
const Actions = require('../actions/AccountNumberGroupActions');
const Source = require('../sources/AccountNumberGroupSource');
const AccountNumberGroup = require('../models/AccountNumberGroup');

class AccountNumberGroupStore {
    constructor() {
        this.accountNumberGroups = [];

        this.batchSavingAccountItemsAndGroups = false;

        this.bindListeners({
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            itemsSaved: Actions.itemsSaved,
            itemsSaving: Actions.itemsSaving,
            itemsSavingFailed: Actions.itemsSavingFailed,
            groupsAndAccountItemsUpdated: Actions.groupsAndAccountItemsUpdated,
            groupsAndAccountItemsUpdating: Actions.groupsAndAccountItemsUpdating,
            groupsAndAccountItemsUpdatingFailed: Actions.groupsAndAccountItemsUpdatingFailed,
        });

        this.exportPublicMethods({
            getAccountNumberGroups: this.getAccountNumberGroups,
            getAccountNumberGroupsWithType: this.getAccountNumberGroupsWithType,
            isBatchUpdatingAccountItemsAndGroups: this.isBatchUpdatingAccountItemsAndGroups,
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    addOrUpdateItemsInState(receivedGroups) {
        // filter received items out of current state and replace with received data
        const receivedGroupIds = receivedGroups.map(group => group.id);
        const currentGroups = this.accountNumberGroups;
        const unchangedGroups = currentGroups.filter(group => !receivedGroupIds.includes(group.id));

        const finalGroups = unchangedGroups.concat(receivedGroups).map(item => new AccountNumberGroup(item));

        finalGroups.sort((group1, group2) => parseInt(group1.id, 10) - parseInt(group2.id));

        this.setState({
            accountNumberGroups: finalGroups,
        });
    }

    deleteItemsFromState(deletedGroups) {
        // filter deleted items out of items currently in state
        const deletedGroupIds = deletedGroups.map(group => group.id);
        const currentGroups = this.accountNumberGroups;
        const remainingGroups = currentGroups.filter(group => !deletedGroupIds.includes(group.id));

        this.setState({
            accountNumberGroups: remainingGroups,
        });
    }

    itemsReceived(receivedGroups) {
        this.addOrUpdateItemsInState(receivedGroups);
    }

    itemsReceiving() { }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemSaved(group) {
        this.addOrUpdateItemsInState([group]);
    }

    itemSaving() { }

    itemSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsSaved(groups) {
        this.addOrUpdateItemsInState(groups);
    }

    itemsSaving() { }

    itemsSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    groupsAndAccountItemsUpdated(data) {
        const { updated, deleted } = data.account_number_groups;
        this.addOrUpdateItemsInState(updated);
        this.deleteItemsFromState(deleted);

        this.setState({
            batchSavingAccountItemsAndGroups: false,
        });
    }

    groupsAndAccountItemsUpdating() {
        this.setState({
            batchSavingAccountItemsAndGroups: true,
        });
    }

    groupsAndAccountItemsUpdatingFailed(errorMessage) {
        this.setState({
            batchSavingAccountItemsAndGroups: false,
        });

        this.setErrors(errorMessage);
    }

    /* public methods */

    getAccountNumberGroups() {
        return this.getState().accountNumberGroups;
    }

    getAccountNumberGroupsWithType(groupType) {
        return AccountNumberGroup.getWithType(this.getState().accountNumberGroups, groupType);
    }

    isBatchUpdatingAccountItemsAndGroups() {
        return this.getState().batchSavingAccountItemsAndGroups === true;
    }
}

module.exports = alt.createStore(AccountNumberGroupStore, 'AccountNumberGroupStore');
