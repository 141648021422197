"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/SonQuestionActions');
var Source = require('../sources/SonQuestionSource');
var SonQuestion = require('../models/SonQuestion');

class SonQuestionStore {
    constructor() {
        //State
        this.questions = [];
        this.question = null;
        this.errorMessage = null;

        this.bindListeners({
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            itemDeleted: Actions.itemDeleted,
            itemDeleting: Actions.itemDeleting,
            itemDeletingFailed: Actions.itemDeletingFailed
        });

        this.exportPublicMethods({
            getQuestions: this.getQuestions
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemsReceived(data) {
        this.setState({
            questions: data.map(item => new SonQuestion(item)),
            errorMessage: null
        });
    }

    itemsReceiving() {
        // Do nothing for now.
    }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemSaved(data) {
        let question = new SonQuestion(data);
        let questions = this.questions.slice(); // Clone

        if (questions.filter(item => item.id == question.id).length == 0) {
            questions.push(question);
        } else {
            questions.map(item => {
                if (item.id == question.id) {
                    return question;
                }

                return item;
            });
        }

        this.setState({
            question: question,
            questions: questions,
            errorMessage: null
        })
    }

    itemSaving() {
        //Do Nothing...
    }

    itemSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemDeleted(question) {
        var questions = this.questions.slice(); // clone

        this.setState({
            questions: questions.filter(item => item.id != question.id)
        });
    }

    itemDeleting() {
    }

    itemDeletingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }


    // ----- PUBLIC -----
    getQuestions() {
        return this.getState().questions;
    }
    performDelete(data) {
        itemDeleted(data);
    }

}

module.exports = alt.createStore(SonQuestionStore, 'SonQuestionStore');
