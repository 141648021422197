import trackJs from '../../utilities/trackJs';
import sessionstack from '../../utilities/sessionstack';

import alt from '../alt.ts';
import Actions from '../actions/AccountActions';
import Source from '../sources/AccountSource';
import Account from '../models/Account';
import User from '../models/User';

class AccountStore {
    constructor() {
        //State
        this.account = null;
        this.errorMessage = null;

        this.bindListeners({
            itemReceived: Actions.itemReceived,
            itemReceiving: Actions.itemReceiving,
            itemReceivingFailed: Actions.itemReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            inviteSent: Actions.inviteSent,
            inviteSending: Actions.inviteSending,
            inviteSendingFailed: Actions.inviteSendingFailed,
            userDeleted: Actions.userDeleted,
            userDeleting: Actions.userDeleting,
            userDeletingFailed: Actions.userDeletingFailed,
            userPermissionsdUpdated: Actions.userPermissionsdUpdated,
            userPermissionsdUpdating: Actions.userPermissionsdUpdating,
            userPermissionsdUpdatingFailed: Actions.userPermissionsdUpdatingFailed,
        });

        this.exportPublicMethods({
            isForecast: this.isForecast,
            isSubscribed: this.isSubscribed,
            isSubscribedOrMasquerade: this.isSubscribedOrMasquerade,
            isGroupAccount: this.isGroupAccount,
            isReady: this.isReady,
            getAccount: this.getAccount,
            requireAccount: this.requireAccount,
            isMasquerade: this.isMasquerade,
            isSubscriptionCancelled: this.isSubscriptionCancelled,
        });
        this.exportAsync(Source);
    }

    itemReceived(data) {
        const account = new Account(data);
        this.setState({
            account,
            errorMessage: null
        });
        trackJs.configure(account);
        sessionstack.configureUser(account);
    }

    itemReceiving() {
        // Do nothing
    }

    itemReceivingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemSaved(data) {
        this.itemReceived(data);
    }

    itemSaving() {
        // Do nothing
    }

    itemSavingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    inviteSent(invitedUser) {
        const updatedAccount = new Account(this.account)
        updatedAccount.users.push(new User(invitedUser))

        this.setState({
            account: updatedAccount
        })
    }

    inviteSending() {
        // Do nothing
    }

    inviteSendingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage,
        });
    }

    userDeleted(data) {
        const deletedUser = new User(data);

        if (this.account != null) {
            const updatedAccount = new Account(this.account)
            updatedAccount.users = updatedAccount.users.filter(it => it.id !== deletedUser.id)
    
            this.setState({
                account: updatedAccount,
            });
        }
    }

    userDeleting() {

    }

    userDeletingFailed() {

    }

    userPermissionsdUpdated(data) {
        const updatedUser = new User(data);

        if (this.account != null) {
            const updatedAccount = new Account(this.account)
            updatedAccount.users = updatedAccount.users.map(user => user.id === updatedUser.id ? updatedUser : user)
    
            this.setState({
                account: updatedAccount,
            });
        }
    }

    userPermissionsdUpdating() {

    }

    userPermissionsdUpdatingFailed() {

    }


    // Public
    getAccount() {
        let account = this.state.account;
        return account == null ? null : new Account(this.state.account);
    }

    // Public
    requireAccount() {
        const account = this.state.account
        if (!account) {
            throw Error('AccountStore: account required, but not loaded')
        }

        return account
    }

    isReady() {
        const { account } = this.getState();
        return account !== null;
    }

    isSubscribed() {
        const { account } = this.getState();

        if (account == null) {
            return false;
        }

        if (account.active_subscription == null) {
            return false;
        }

        return account.active_subscription.is_active === true || account.active_subscription.is_active == "True";
    }

    isSubscriptionCancelled() {
        if (this.isSubscribed()) {
          const { account } = this.getState();
          return account.active_subscription.cancel_at_period_end === true;
        }

        return false;
    }

    isForecast() {
        const { account } = this.getState();

        if (account == null) {
            return false;
        }

        return account.ForecastingIsEnabled === true;
    }

    isMasquerade() {
        const { account } = this.getState();
        if (account == null) {
            return false;
        }

        return account.masquerade === true;
    }

    isSubscribedOrMasquerade() {
        return this.isSubscribed() || this.isMasquerade();
    }

    isGroupAccount() {
        return !!(this.state.account && this.state.account.isGroupAccount);
    }

}

module.exports = alt.createStore(AccountStore, 'AccountStore');