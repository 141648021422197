/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckBox from './CheckBox';

class OptionSelector extends Component {
    render() {
        const { selectedKey, options, onSelectOption } = this.props;
        return (
            <div className="option-selector">
                {
                    options.map((option, index) => {
                        const selected = selectedKey === option.key;
                        return (
                            <CheckBox
                                checked={selected}
                                className="option"
                                key={index}
                                label={option.label}
                                onClick={() => onSelectOption(option)}
                            />
                        );
                    })
                }
            </div>
        );
    }
}

OptionSelector.propTypes = {
    onSelectOption: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            key: PropTypes.any,
        })
    ).isRequired,
    selectedKey: PropTypes.any.isRequired,
};

export default OptionSelector;
