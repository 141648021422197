// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import AccountItem from "../models/AccountItem"
import ProgressNav from "../components/ProgressNav"
import TabMenu from "../components/TabMenu"
import Popup from "../components/Popup"
import { LedgerTypes } from "../models/Enums"

var DataStore = require('../datastore');
var TagCategoryStore = DataStore.TagCategoryStore;
var TagCategory = DataStore.TagCategory;
var AccountItemStore = DataStore.AccountItemStore;
var Tag = DataStore.Tag;
import TagHelper from "../datastore/helpers/TagHelper"
import { TagCode } from '../models/EnumsTs';

/**
 * @deprecated
 */
class PopupReTag extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedLedgerType: LedgerTypes.IncomeStatement
        };
    }

    handleLedgerTypeSelected(ledgerType) {
        this.setState({
            selectedLedgerType: ledgerType
        });
    }

    getTagCategories() {
        return TagCategoryStore.getState().tagCategories;
    }

    getParentTag() {
        const { accountItem } = this.props;
        var tagCategories = this.getTagCategories();
        var tagId = accountItem.tag_id;
        var parentTag = null;

        tagCategories.forEach(cat => {
            if (cat.tags.filter(tag => tag.id == tagId).length > 0) {
                parentTag = cat;
            }
        });

        return parentTag;

    }

    getTag(tagId) {
        return Tag.getTag(tagId, this.props.tags);
    }

    onTagSelect(tag) {
        const { accountItem, onTagSelected } = this.props;

        // If it's a tag, then we can call the event.
        if (tag.tag_category_id != null) {
            onTagSelected(accountItem, tag);
        }
    }

    /**
     * filters out tags and categories that users are not allowed to select:
     * Tagged Totals, Net Profit
     * @returns TagCategories structure for TabMenu
     */
    getAvailableTagCategories() {
        const tagCategories = this.getTagCategories();

        const excludedTagCodes = [
            TagCode.IS_NetProfit,
        ];

        const filteredTagCategories = tagCategories.map(cat => {
            const newCat = new TagCategory(cat);
            newCat.tags = newCat.tags.filter(tag => {
                if (tag.isTotal === true) {
                    return false;
                }
                if (excludedTagCodes.indexOf(tag.code) > -1) {
                    return false;
                }
                return true;
            });
            if (newCat.tags.length === 0) {
                return null;
            }
            return newCat;
        })
            .filter(cat => cat !== null);

        const { selectedLedgerType } = this.state;
        const selectedLedgerTypeName = typeof selectedLedgerType === 'string' ? selectedLedgerType : selectedLedgerType.name;

        return TagHelper.tagsToSectionsAndItems(filteredTagCategories, selectedLedgerTypeName);
    }

    render() {
        const { accountItem, onDismissPopup } = this.props;
        const { selectedLedgerType } = this.state;

        const parentTag = this.getParentTag();
        const itemName = accountItem !== null ? accountItem.name : '';

        const ledgerTypes = [
            LedgerTypes.IncomeStatement,
            LedgerTypes.BalanceSheet,
        ];

        return (
            <Popup>
                <h2>Select a new Tag</h2>
                <p>{`${parentTag.name} : ${itemName}`}</p>
                <ProgressNav
                    options={ledgerTypes}
                    onBecameActive={item => {
                        this.handleLedgerTypeSelected(item);
                    }}
                    disabledOptions={[]}
                    selected={selectedLedgerType}
                />
                <TabMenu
                    sections={this.getAvailableTagCategories()}
                    onClick={tag => this.onTagSelect(tag)}
                />
                <button className="btn btn-lg btn-primary" onClick={onDismissPopup}>Done</button>
            </Popup>
        );
    }
}

PopupReTag.propTypes = {
    accountItem: PropTypes.instanceOf(AccountItem).isRequired,
    onTagSelected: PropTypes.func.isRequired,
    onDismissPopup: PropTypes.func,
};

export default PopupReTag;
