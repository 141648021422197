/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
var ReactDOM = require('react-dom');
import autosize from './autosize';
import Debounce from '../components/Debounce';
import ResizeObserver from 'react-resize-detector/build/withPolyfill';

const UPDATE = 'autosize:update',
    DESTROY = 'autosize:destroy',
    RESIZED = 'autosize:resized';

/**
 * https://raw.githubusercontent.com/buildo/react-autosize-textarea/master/src/TextareaAutosize.js
 */
class RawTextareaAutosize extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (
            this.getValue(nextProps) !== this.getValue(this.props)
            || nextProps.measuredWidth !== this.props.measuredWidth
        ) {
            this.dispatchEvent(UPDATE, true);
        }

    }

    getNode() {
        return ReactDOM.findDOMNode(this);
    }

    componentDidMount() {
        var node = this.getNode();

        autosize(node);

        if (this.props.onResize) {
            node.addEventListener(RESIZED, this.props.onResize);
        }
    }

    componentWillUnmount() {
        var node = this.getNode();

        if (this.props.onResize) {
            node.removeEventListener(RESIZED);
        }
        this.dispatchEvent(DESTROY);
    }

    dispatchEvent(EVENT_TYPE, defer) {
        var node = this.getNode();

        const event = document.createEvent('Event');
        event.initEvent(EVENT_TYPE, true, false);
        const dispatch = () => node.dispatchEvent(event);

        if (defer) {
            setTimeout(dispatch);
        } else {
            dispatch();
        }
    }

    getValue(props) {
        if (props) {
            return props.valueLink ? props.valueLink.value : props.value;
        }
    }

    render() {
        const {measuredWidth, ...props} = this.props
        //setting height to 35px forces box to resize when the page is loaded
        return (
            <Debounce
                style={{height: '35px'}}
                multiline
                {...props}
            >
                {this.props.children}
            </Debounce>
        )
    }

}

// Wrap in a ResizeObserver to force size re-evaluation when the width changes
function TextareaAutosize(props) {
    return (
        <ResizeObserver handleWidth>
            {({ width }) => (
                <RawTextareaAutosize
                    {...props}
                    measuredWidth={width}
                />
            )}
        </ResizeObserver>
    );
}

RawTextareaAutosize.propTypes = {
    onResize: PropTypes.func,
    updateOnlyOnBlur: PropTypes.bool,
};

RawTextareaAutosize.DefaultProps = {
    rows: 1
};


export default TextareaAutosize;