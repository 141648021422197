"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSonCommunicationCompletionState = exports.reducePageCompletionStatesToFinalState = exports.PageStates = void 0;
const SonSectionRow_1 = require("./types/SonSectionRow");
const ForecastingNotesStyle_1 = require("./types/ForecastingNotesStyle");
var PageStates;
(function (PageStates) {
    PageStates["tick"] = "tick";
    PageStates["alert"] = "alert";
    PageStates["warning"] = "warning";
    PageStates["loading"] = "loading";
    PageStates["noData"] = "noData";
    PageStates["blank"] = "blank";
})(PageStates = exports.PageStates || (exports.PageStates = {}));
function reducePageCompletionStatesToFinalState(allStates) {
    if (allStates.includes(PageStates.warning)) {
        return PageStates.warning;
    }
    else if (allStates.includes(PageStates.tick) && !allStates.includes(PageStates.alert)) {
        return PageStates.tick;
    }
    else if (allStates.includes(PageStates.alert) && !allStates.includes(PageStates.tick)) {
        return PageStates.alert;
    }
    else if (allStates.includes(PageStates.alert) && allStates.includes(PageStates.tick)) {
        return PageStates.warning;
    }
    else if (allStates.includes(PageStates.noData)) {
        // no data exists
        return PageStates.noData;
    }
    else if (allStates.includes(PageStates.blank)) {
        // no rows to complete, marked as done but doesn't contribute to overall status
        return PageStates.blank;
    }
    else {
        return PageStates.loading;
    }
}
exports.reducePageCompletionStatesToFinalState = reducePageCompletionStatesToFinalState;
function getSonCommunicationCompletionState(page, selectedMonthSections) {
    if (selectedMonthSections === null) {
        return PageStates.loading;
    }
    else if (selectedMonthSections.length === 0) {
        return PageStates.noData;
    }
    const sectionTemplates = page.sectionTemplates.map(template => {
        if (template.showPageStatus) {
            return template.id;
        }
    });
    const pageSections = selectedMonthSections.filter(section => sectionTemplates.includes(section.sectionTemplateId));
    const sectionStatusStates = pageSections.map(section => {
        const rowStatusStates = section.rows.map(row => {
            switch (row.kind) {
                case SonSectionRow_1.SonSectionRowKind.singleValueRow: {
                    return row.value ? PageStates.tick : PageStates.alert;
                }
                case SonSectionRow_1.SonSectionRowKind.notes: {
                    return row.value ? PageStates.tick : PageStates.alert;
                }
                case SonSectionRow_1.SonSectionRowKind.progressRow: {
                    return row.value ? PageStates.tick : PageStates.alert;
                }
                case SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow: {
                    if (row.howMuch && row.when) {
                        return PageStates.tick;
                    }
                    else if (row.howMuch || row.when) {
                        return PageStates.warning;
                    }
                    else {
                        return PageStates.alert;
                    }
                }
                case SonSectionRow_1.SonSectionRowKind.fixedAssetRow: {
                    if (row.howMuch && row.when && row.likelyFunding) {
                        return PageStates.tick;
                    }
                    else if (row.howMuch || row.when || row.likelyFunding) {
                        return PageStates.warning;
                    }
                    else {
                        return PageStates.alert;
                    }
                }
            }
        });
        if (rowStatusStates.length === 0) {
            rowStatusStates.push(PageStates.blank);
        }
        const sectionTemplate = page.sectionTemplates.find(template => template.id === section.sectionTemplateId);
        if (sectionTemplate && sectionTemplate.forecastingNotesStyle !== ForecastingNotesStyle_1.ForecastingNotesStyle.None) {
            if (section.notes) {
                rowStatusStates.push(PageStates.tick);
            }
            else {
                rowStatusStates.push(PageStates.alert);
            }
        }
        const finalState = reducePageCompletionStatesToFinalState(rowStatusStates);
        return finalState;
    });
    return reducePageCompletionStatesToFinalState(sectionStatusStates);
}
exports.getSonCommunicationCompletionState = getSonCommunicationCompletionState;
