"use strict";
class FieldMapping {
    constructor(item) {
        this.index = null;
        this.last_heading = null;
        this.field_id = null;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }
}

module.exports = FieldMapping;