import alt from '../alt.ts';
import Actions from '../actions/UserActions';
import Source from '../sources/UserSource';
import User from '../models/User';

class UserStore{
    constructor(){
        //State
        this.users = [];
        this.errorMessage = null;
        this.permissions = {
            administration: false,
            historical_viewing: false,
            historical_editing: false,
            forecasting_editing: false,
        };

        this.bindListeners({
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed,
            passwordSaved: Actions.passwordSaved,
            passwordSaving: Actions.passwordSaving,
            passwordSavingFailed: Actions.passwordSavingFailed,
            permissionsReceived: Actions.permissionsReceived,
            permissionsReceiving: Actions.permissionsReceiving,
            permissionsReceivingFailed: Actions.permissionsReceivingFailed,
        });

        this.exportPublicMethods({
            getUser: this.getUser,
            getPermissions: this.getPermissions,
        });

        this.exportAsync(Source);
    }

    itemsReceived(data) {
        this.setState({
            users:[new User(data)], // data.map(item => new User(item));
            errorMessage: null
        });
    }

    itemsReceiving() {
        // Do nothing
    }

    itemsReceivingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemReceived(user) {
        var users = this.users.map(item => {
            if (item.id == user.id) {
                return user;
            }
            return item;
        });

        this.setState({
            users: users
        });
    }

    itemSaved(data) {
        this.itemReceived(new User(data));
        this.errorMessage = null;
    }

    itemSaving() {
        // Do nothing
    }

    itemSavingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    passwordSaved(response) {
        // Do nothing.
    }

    passwordSaving() {
        // Do nothing.
    }

    passwordSavingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    permissionsReceived(permissions) {
        this.setState({
            permissions
        })
    }

    permissionsReceiving() { }

    permissionsReceivingFailed() { }

    getPermissions() {
        return this.getState().permissions
    }

    // Public Methods

    getUser() {
        const { users } = this.getState();

        if (users.length == 0) {
            return null;
        }

        return users[0];
    }
}

module.exports = alt.createStore(UserStore, 'UserStore');