import React from 'react';
import PropTypes from 'prop-types';
import Popup from "../components/Popup"
import ProgressNav from "../components/ProgressNav"
import { SplitTypes } from "../models/Enums"
import { RowStruct, AccountItem } from "../models/RealTimeCeo"
import SplitRow from "./SplitRow"
import TagHelper from "../datastore/helpers/TagHelper"
var DataStore = require('../datastore');
var AccountNumberTagStore = DataStore.AccountNumberTagsStore;

/**
 * @deprecated
 */
class PopupSplit extends React.Component {
    constructor(props) {
        super(props);

        var splitTypes = [
            {name: SplitTypes.Percentage},
            {name: SplitTypes.Offset}
        ];

        this.state = {
            splitTypes: splitTypes,
            selectedSplitType: splitTypes[0].name == props.splitType ? splitTypes[1] : splitTypes[0]
        };
    }

    onSelectSplitType(splitType) {
        this.setState({
            selectedSplitType: splitType
        });
    }

    isPercentage() {
        return this.onSelectSplitType.name == SplitTypes.Percentage;
    }

    isOffset() {
        return this.onSelectSplitType.name == SplitTypes.Offset;
    }

    getAccountItem(id) {
        return this.props.accountItems.filter(item => item.id == id).shift();
    }

    getChildAccountItem(parentId) {
        return this.props.accountItems.filter(item => item.parent_account_item_id == parentId).shift();
    }

    /**
     * Get all items, in a linked list.
     */
    getSplitItems() {
        const { selectedAccountItem, accountItems } = this.props;

        // Find children of root.
        var rootAccountNumberTag = AccountNumberTagStore.getAccountNumberTag(selectedAccountItem.account_number);

        if (rootAccountNumberTag == null) {
            return [selectedAccountItem];
        }

        if (rootAccountNumberTag.split_root_id != null) {
            rootAccountNumberTag = AccountNumberTagStore.getAccountNumberTagWithId(rootAccountNumberTag.split_root_id);
        }

        var children = AccountNumberTagStore.getAccountNumberTags().filter(item => item.split_root_id == rootAccountNumberTag.id);

        // Add the children.
        var accountNumbers = children.map(item => item.account_number);

        // Add the root.
        accountNumbers.unshift(rootAccountNumberTag.account_number);

        return accountItems.filter(item => accountNumbers.indexOf(item.account_number) > -1);
    }

    getRootName(splitItems) {
        return splitItems.length > 0 ? splitItems[0].original_name : '';

    }

    getRootNetActivity(splitItems) {
        //What's the difference between NetActivity and net_activity?
        return splitItems.length > 0 ? splitItems[0].NetActivity : 0;
    }

    getRemainderPercentage(splitItems) {
        //Remove the root item
        // var rootItem = splitItems.shift();
        // return splitItems.reduce((prev, curr) => prev - curr.Percentage, 100);

        var percentage = splitItems.reduce(function(previousValue, currentValue, currentIndex, array) {
            if (currentIndex == 0) { //skip the first item
                return previousValue;
            }
            return previousValue - currentValue.Percentage;
        }, 100);
        return percentage;
    }

    getRemainderAmount(splitItems) {
        var max = this.getRootNetActivity(splitItems);
        //Remove the root item
        // var rootItem = splitItems.shift();
        // return splitItems.reduce((prev, curr) => prev - curr.NetActivity, max);

        var remainder = splitItems.reduce(function(previousValue, currentValue, currentIndex, array) {
            if (currentIndex == 0) {
                return previousValue;
            }
            return previousValue - currentValue.NetActivity;
        }, max);
        return remainder;
    }

    onChangeAccountItem(accountItem, shouldRecalculate) {
        var splitItems = this.getSplitItems();
        var rootAmount = this.getRootNetActivity(splitItems);

        /**
         * Validate the new account item.
         * We need the splitItems, with the new item replacing the old, in order to calculate...
         * There may be a better way to do this...
         */
        var index = 0;
        for (var i in splitItems) {
            var item = splitItems[i];
            if (item.account_number === accountItem.account_number) {
                index = i;
                break;
            }
        }
        splitItems[index] = accountItem;
        
        var remainderAmount = this.getRemainderAmount(splitItems);
        //If remainderAmount < 0, this change is invalid!
        //Not using the isValidSplit method - we need to find out how much the split is valid or invalid by...
        if (remainderAmount < 0) {
            //console.log("Error updating account item. Remainder is: " + remainderAmount);
            //console.log("Recalculating max value for new account item.");
            //Find a valid account item. Use the power of mathematics
            //Valid Value = accountItem.NetActivity + remainderAmount
            accountItem.NetActivity = accountItem.NetActivity + remainderAmount;
            accountItem.Percentage = Math.floor(accountItem.NetActivity / rootAmount * 100);
        }


        // TODO: There can be some bugs introduced by saving both values. We should save the net activity ONLY.
        // TODO: We need to recalulate all rows when a single row is changed...
        if (shouldRecalculate === true) {
            // We change the net_activity and percentage on the server, but it does not affect the output
            switch (this.state.selectedSplitType.name) {
                case SplitTypes.Percentage:
                    // Percentage changed, so we update the net_activity
                    accountItem.net_activity = Math.floor(rootAmount * (accountItem.percentage / 100));
                    break;

                case SplitTypes.Offset:
                    // Net Activity changed, so we update the percentage
                    accountItem.percentage = Math.floor(accountItem.net_activity / rootAmount * 100);
                    break;
            }
        }

        this.props.onChangeAccountItem(accountItem);
    }

    /**
     * Gets all the split account items, then recalculates the "other" value based on the Selected Type value.
     *
     * @returns [AccountItem]
     */
    updatedSplitItems() {
        var splitItems = this.getSplitItems();
        var rootAccountItem = splitItems[0];

        return splitItems.map(accountItem => {
                switch (this.state.selectedSplitType.name) {
                    case SplitTypes.Percentage:
                        accountItem.net_activity = Math.floor(rootAccountItem.net_activity * (accountItem.percentage / 100));
                        break;

                    case SplitTypes.Offset:
                        accountItem.percentage = Math.floor(accountItem.net_activity / rootAccountItem.net_activity * 100);
                        break;
                }

                return accountItem;
            }
        );
    }

    /**
     *
     * @returns {boolean}
     */
    isValidSplit() {
        var rows = this.updatedSplitItems();
        var rootAccountItem = rows.shift();
        var remainderPercentageValue = rootAccountItem.percentage - rows.reduce((prev, curr) => prev + curr.percentage, 0);

        return remainderPercentageValue <= 0;
    }

    getTagForAccountItem(accountItem) {
        return TagHelper.getAccountNumberTag(accountItem.account_number);
    }

    getMergeButton(splitItems) {
        const { onMerge } = this.props;

        if (splitItems.length <= 1) {
            return null;
        }

        return <div className="col-xs-12">
            <button className="btn btn-danger btn-lg pull-right marginBtn" onClick={onMerge}>Merge Split</button>
        </div>
    }

    getSplitTypesMenu(splitItems) {
        const { splitTypes, selectedSplitType } = this.state;

        if (splitItems.length <= 1) {
            return null;
        }

        return <ProgressNav
            options={splitTypes}
            onBecameActive={splitType => this.onSelectSplitType(splitType)}
            disabledOptions={[]}
            selected={selectedSplitType}/>

    }

    getHeaderRow() {
        const { onSplit, onOkPressed, onMerge, onDelete } = this.props;
        const { splitTypes, selectedSplitType } = this.state;
        var splitItems = this.updatedSplitItems(); // Return ordered account items for this split.
        var rootAccountItem = splitItems.shift(); // Remove the first item, it's the root.
        var rootTag = this.getTagForAccountItem(rootAccountItem);

        return <SplitRow showOriginalName={true}
                         splitType={selectedSplitType}
                         accountItem={rootAccountItem}
                         onSplit={() => onSplit(rootAccountItem, rootTag)}
                         editing={false}
               />;
    }

    //This is the first row - the text is editable, and it shows the remainder
    getRootRow() {
        const { onSplit, onOkPressed, onMerge, onDelete } = this.props;
        const { splitTypes, selectedSplitType } = this.state;
        var splitItems = this.updatedSplitItems(); // Return ordered account items for this split.

      
        if (splitItems.length <= 1) {
            return null;
        }

        var remainderNetActivity = this.getRemainderAmount(splitItems); // Math.floor(rootAccountItem.NetActivity * (1 - totalPercentage / 100));
        var remainderPercentage = this.getRemainderPercentage(splitItems); //Math.floor(100 - totalPercentage);
        var rootAccountItem = splitItems.shift();
        var rootTag = this.getTagForAccountItem(rootAccountItem);

        return <SplitRow showOriginalName={false}
                         splitType={selectedSplitType}
                         accountItem={rootAccountItem}
                         editing={false}
                         remainderNetActivity={remainderNetActivity}
                         remainderPercentage={remainderPercentage}
               />;
    }

    getChildRows() {
        const { onSplit, onOkPressed, onMerge, onDelete } = this.props;
        const { splitTypes, selectedSplitType } = this.state;

        var splitItems = this.updatedSplitItems(); // Return ordered account items for this split.
        var rootAccountItem = splitItems.shift(); // Remove the first item, it's the root.

        return splitItems.map(item => {
            var ant = AccountNumberTagStore.getAccountNumberTag(item.account_number);
            var tag = ant == null ? null : ant.Tag;

            return <SplitRow splitType={selectedSplitType}
                             rootAccountItem={rootAccountItem}
                             accountItem={item}
                             editing={true}
                             onChangeAccountItem={item => this.onChangeAccountItem(item, true)}
                             onDelete={onDelete}
                             tag={tag}
                />
        });
    }

    getSplitEntry() {
        const { onOkPressed, selectedAccountItem } = this.props;
        const { splitTypes, selectedSplitType } = this.state;

        var splitItems = this.updatedSplitItems(); // Return ordered account items for this split.

        return <div className="col-xs-12">
            <div className="contentScroll">
                {this.getMergeButton(splitItems)}
                <h2>Splitting</h2>
                {/* TODO: Add in the parent and child name. */}
                {/* we have the selectedAccountItem.tag_id. Using this we can get the tag and tag_category */}
                <p>Parent Name:{selectedAccountItem.name} </p>

                {this.getSplitTypesMenu(splitItems)}

                <div className="col-xs-12 splitter">
                    {this.getHeaderRow()}
                    {this.getRootRow()}
                    {this.getChildRows()}
                </div>
                <div className="col-xs-12">
                    <div className="marginBothBtn">
                        <button
                            className="btn btn-primary btn-lg"
                            onClick={onOkPressed}>Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        return <Popup>
            {this.getSplitEntry()}
        </Popup>
    }
}

PopupSplit.propTypes = {
    accountItems: PropTypes.arrayOf(PropTypes.instanceOf(AccountItem)).isRequired,
    editing: PropTypes.bool,
    onChangeAccountItem: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onMerge: PropTypes.func.isRequired,
    onOkPressed: PropTypes.func.isRequired,
    onSplit: PropTypes.func.isRequired,
    selectedAccountItem: PropTypes.instanceOf(AccountItem).isRequired,
    tags: PropTypes.array.isRequired
};


export default PopupSplit;