/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Utils from "../utilities/Utils"

class TableColumn extends React.Component {
    render() {
        const { columns, columnIndex, highlightColumnIndex, onColumnClick, maxRows, getRow } = this.props;

        var getRows = (column, maxRows, getRows, className) => {
            var i = 0;
            var rows = [];

            while (i < maxRows) {
                var row = getRows(i++).row[column];
                rows.push(<div className={className} key={i}>
                    <span title={row}>{row}</span>
                </div>);
            }

            return rows;
        };

        var className = 'cell ';

        if (columnIndex == highlightColumnIndex) {
            className += 'highlight '
        }

        columns.forEach(item => {
            var mapping = item.filtered_field_mapping.length == 0 ? null : item.filtered_field_mapping[0];
            if (mapping != null && mapping.index === columnIndex) {
                className += " " + Utils.toClassName(item.name);
            }
        });

        return <div
            className="table-column"
            onClick={e => onColumnClick(columnIndex)}>
            {getRows(parseInt(columnIndex), maxRows, getRow, className)}
        </div>
    }
}

TableColumn.propTypes = {
    maxRows: PropTypes.number.isRequired,
    getRow: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    columnIndex: PropTypes.number.isRequired,
    onColumnClick: PropTypes.func.isRequired,
    column: PropTypes.string,
    highlightColumnIndex: PropTypes.number
};

export default TableColumn;