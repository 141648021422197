"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoLinks = void 0;
exports.VideoLinks = {
    Dashboard: {
        Overview: 'CJi_2pgQTrI',
        OperationalCashFlow: 'QmwUxcvUvqk?list=PLfrWr6sWiUnt1VESMQvmA640Zsn2K42qF&index=2',
        ROO: 'INH1WgN-ZCY',
        RawMaterialMultiple: 'r9G4XJxcKCk',
        SalaryMultiple: 'wt0Zh2F5ua4',
        OperationalInvestment: 'IJinWwsfacg?index=5&list=PLfrWr6sWiUnt1VESMQvmA640Zsn2K42qF',
        Investment: 'XH0GjsayIV0',
        OperationalProfit: 'RNFlh7_5s34',
        Leverage: 'XH0GjsayIV0',
    },
    HistoricalData: 'tCzwE9V3hK8',
    HistoricalDataImportConflicts: 'P_IiMDnen-E',
    ThreeTypesOfDataEditing: 'QlriKJZHFxE',
    CrystalBall: 'jXnSup-ZQSI',
    SONCommunicationLegacy: 'pCul8n4W64M',
    SONCommunication: {
        Welcome: '7kQ2vZd8ZGc',
        Summary: 'PmpI8GcaElo',
        Revenue: 'sEyyRKiHuW4',
        People: 'DJiTosyM5hk',
        Materials: 'XEDoVoDczCc',
        Other: 'veS7s-FASeI',
        Capital: 'JDkOuF6LjHY',
        Assets: 'aVAUppfqvKA',
        Internal: 'nICHctFl9fk',
        UserDefined: 'evVUsEGMHt4',
    },
    ThreeW: 'P90dSAZrqls',
    ImportNewMonthData: 'yZbnQ5vhqZQ',
    OnboardingWizard: {
        Introduction: 'Q4lbpvBFmkE',
        Import: {
            Part1: 'dl8uR0jvNgc?list=PLfrWr6sWiUnuImH7lGE_HxWgdJGOWVkSM',
            Part2: 'Aep1cpqH_kA',
            TaggingOverview: 'bQq9OLaQtDk',
            TaggingRevenue: '-tUQh_kKbG4',
            TaggingDirectCosts: 'g16t1JUO-EM',
            TaggingIndirectCosts: 'VEl3Ih0nGv0',
            TaggingProfit: 'NqzdsSaa2EA',
            TaggingMiscellaneous: 'KBje5N3dVvY',
            TaggingDistributions: 'i1-AGKv-Nok',
            TaggingAssets: 'E8l-puYVA-A',
            TaggingLiabilities: 'eLz6C_z4RSI',
            TaggingEquity: '_ul6FhLZxmY',
            TaggingPreviousIncomeStatement: 'XFJfZnvpT00',
            TaggingPreviousBalanceSheet: 'Df5SgNFgBvg',
        },
        Manual: {
            Requirements: 'xDr7HGH_mww',
            IncomeStatementTotals: 'rkeHax7wYM4',
            DefiningRevenueClasses: 'mZ6XtlkZrkg',
            SpecifyingDirectCosts: 'dy35TeS8BzE',
            SpecifyingIndirectCosts: '44DPtTo0qk8',
            IndirectCostsAddingDetail: 'zkPjt0pXheg',
            SpecifyingMiscellanous: 'c7NCLYc1FZs',
            BalanceSheetTotals: 'M2fVErDFpbM',
            SpecifyingAssets: 'ck4uVtSy2lg',
            SpecifyingLiabilities: 'aV9SXjkfeBo',
            SpecifyingEquity: 'jz-0H_7he7s',
        },
        Seasonality: '0eboAkGkf5I',
        NormalisationOfOwnersSalary: 'xCMFqZQHJBY',
        NonBusinessAdjustments: 'ITjlD-bIY0E',
    },
    Forecast: {
        EnableForecasting: '0leNcJXMRWE',
        SummaryScreen: 'uWuqkQvxslw',
        AspirationalRevenue: 'kao3aA3n8rQ',
        MarriageRevenue: '4BKEo_5Xzw0',
        EngagementRevenue: 's1h1ifK3blc',
        DirectCosts: '4pJ-ZMDp3hw',
        IndirectCosts: '3e06s9kthw8',
        Miscellaneous: 'kvcKbVHzMc8',
        Distributions: 'qC33LOQTcTU',
        AccountsReceivable: 'OF28zlc7J8E',
        Inventory: '_pHnrZY93gw',
        AccountsPayable: 'odH2Ok4HW84',
        FixedAssets: '2xMhrN3_G7Q',
    },
    Account: {
        UserManagement: 'zUxTuS6_5Fo',
    },
};
