"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/AccountNumberTagsActions');
var Source = require('../sources/AccountNumberTagsSource');
var AccountNumberTag = require("../models/AccountNumberTag");
const MonthActions = require('./../actions/MonthActions');

class AccountNumberTagsStore {
    constructor() {
        // State
        this.accountNumberTags = [];
        this.errorMessage = null;

        this.bindListeners({
            receivedItems: Actions.receivedItems,
            loadingItems: Actions.loadingItems,
            fetchingItemsFailed: Actions.fetchingItemsFailed,
            save: Actions.save,
            batchSave: Actions.batchSave,
            saving: Actions.saving,
            savingFailed: Actions.savingFailed,
            itemDeleted: Actions.itemDeleted,
            itemDeleting: Actions.itemDeleting,
            itemDeletingFailed: Actions.itemDeletingFailed,
            itemDisabled: Actions.itemDisabled,
        });

        this.exportPublicMethods({
            getAccountNumberTag: this.getAccountNumberTag,
            getAccountNumberTagWithId: this.getAccountNumberTagWithId,
            getAccountNumberTags: this.getAccountNumberTags,
            getEnabledAccountNumberTags: this.getEnabledAccountNumberTags,
            getAccountNumberTagsForTagCategory: this.getAccountNumberTagsForTagCategory,
            getTaggedTotalAccountNumberTagForTagCategory: this.getTaggedTotalAccountNumberTagForTagCategory,
            getAccountNumberTagsWithTag: this.getAccountNumberTagsWithTag,
            getRootAccountNumberTag: this.getRootAccountNumberTag,
            getChildren: this.getChildren,
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage,
        });
    }

    receivedItems(data) {
        const accountNumberTags = data.map(item => new AccountNumberTag(item));

        // update MonthStore with new account number tags
        Promise.resolve().then(() => MonthActions.accountNumberTagsUpdated(accountNumberTags));

        this.setState({
            accountNumberTags,
            errorMessage: null
        });
    }

    loadingItems() {
        // Do nothing
    }

    fetchingItemsFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    /**
     * merges received items into an existing list
     * new items are added, existing items are overwritten
     *
     * @param {AccountNumberTag[]} newAccountNumberTags
     * @param {AccountNumberTag[]} existingAccountNumberTags
     * @return {AccountNumberTag[]}
     */
    static mergeInNewAccountNumberTags(newAccountNumberTags, existingAccountNumberTags) {
        let mergedAccountNumberTags = existingAccountNumberTags.slice();

        newAccountNumberTags.forEach(accountNumberTag => {
            if (!mergedAccountNumberTags.find(item => item.id === accountNumberTag.id)) {
                // Add
                mergedAccountNumberTags.push(accountNumberTag);
            } else {
                // Replace
                mergedAccountNumberTags = mergedAccountNumberTags.map(item => {
                    if (item.id === accountNumberTag.id) {
                        return accountNumberTag;
                    }

                    return item;
                });
            }
        });

        return mergedAccountNumberTags;
    }

    save(data) {
        const accountNumberTag = new AccountNumberTag(data);
        let accountNumberTags = AccountNumberTagsStore.mergeInNewAccountNumberTags([accountNumberTag], this.accountNumberTags);

        Promise.resolve().then(() => MonthActions.accountNumberTagsUpdated(accountNumberTags));

        this.setState({
            accountNumberTags,
            errorMessage: null
        });
    }

    /**
     * when an account number tag is disabled, all account number tags with that number are removed,
     * and the disabled tag is added
     * @param data
     */
    itemDisabled(data) {
        const accountNumberTag = new AccountNumberTag(data);
        let accountNumberTags = this.accountNumberTags.filter(ant => ant.account_number !== accountNumberTag.account_number).concat(accountNumberTag);

        Promise.resolve().then(() => MonthActions.accountNumberTagsUpdated(accountNumberTags));

        this.setState({
            accountNumberTags,
            errorMessage: null
        });
    }

    /**
     * @param {AccountNumberTag.ConstructorOptions[]} dataList
     */
    batchSave(dataList) {
        const newAccountNumberTags = dataList.map(data => new AccountNumberTag(data));

        let accountNumberTags = AccountNumberTagsStore.mergeInNewAccountNumberTags(newAccountNumberTags, this.accountNumberTags);

        Promise.resolve().then(() => MonthActions.accountNumberTagsUpdated(accountNumberTags));

        this.setState({
            accountNumberTags,
            errorMessage: null
        });
    }

    saving() {
        // Do nothing
    }

    savingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemDeleted(data) {
        const accountNumberTags = this.accountNumberTags.filter(item => item.account_number != data.account_number);

        Promise.resolve().then(() => MonthActions.accountNumberTagsUpdated(accountNumberTags));

        this.setState({
            accountNumberTags
        });
    }

    itemDeleting() {
        // Do nothing
    }

    itemDeletingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    // ---- PUBLIC ----
    /**
     * Gets an AccountNumberTag from the state.
     * @param {string} accountNumber
     * @returns {AccountNumberTag|null}
     */
    getAccountNumberTag(accountNumber) {
        var { accountNumberTags } = this.getState();
        let found = accountNumberTags
            .filter(ant => ant.account_number == accountNumber)
            .shift();
        return typeof found == "undefined" ? null : found;
    }

    getAccountNumberTagWithId(accountNumberTagId) {
        var { accountNumberTags } = this.getState();
        return accountNumberTags
            .filter(ant => ant.id == accountNumberTagId)
            .shift();
    }

    getAccountNumberTags() {
        return this.getState().accountNumberTags;
    }

    getEnabledAccountNumberTags() {
        return this.getState().accountNumberTags.filter(accountNumberTag => !accountNumberTag.Disabled);
    }

    getAccountNumberTagsForTagCategory(categoryId) {
        var { accountNumberTags } = this.getState();
        return accountNumberTags
            .filter(ant => {
                return ant.tag.tag_category_id == categoryId;

            });
    }

    getAccountNumberTagsWithTag(tagId) {
        var { accountNumberTags } = this.getState();
        return accountNumberTags
            .filter(ant => {
                return ant.tag_id == tagId;

            });
    }

    //there may be multiple account_number_tags for a total,
    // but there should only be one
    // we only want the generated one with an account_number that matches the tag name
    // eg account_number = "Total Assets"
    getTaggedTotalAccountNumberTagForTagCategory(categoryId) {
        var { accountNumberTags } = this.getState();
        var tag = this.getAccountNumberTagsForTagCategory(categoryId)
            .filter(ant => {
                return ant.tag.isTotal == true
                    && ant.account_number === ant.tag.name;
            }).shift();

        if (typeof tag === "undefined") {
            return null;
        }
        return tag;
    }

    getRootAccountNumberTag(accountNumber) {
        var ant = this.getAccountNumberTag(accountNumber);

        if (ant == null) {
            return null;
        }

        if (ant.split_root_id == null) {
            return ant;
        }

        return this.getAccountNumberTagWithId(ant.split_root_id);
    };

    getChildren(accountNumberTag) {
        if (accountNumberTag == null) {
            return [];
        }

        return this.getAccountNumberTags().filter(item => item.split_root_id == accountNumberTag.id);
    }

    /**
     *
     * @param {AccountNumberTag} accountNumberTag
     */
    onSave(accountNumberTag) {
        if (!this.getInstance().isLoading()) {
            this.getInstance().performSave(accountNumberTag);
        }
    }
}

module.exports = alt.createStore(AccountNumberTagsStore, 'AccountNumberTagsStore');
