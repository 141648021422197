"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class User {
    constructor(options) {
        this.id = options.id;
        this.email = options.email;
        this.first_name = options.first_name;
        this.last_name = options.last_name;
        this.roles = Array.isArray(options.roles) ? options.roles : [];
        this.email_confirmed = options.email_confirmed;
        this.needs_activation = options.needs_activation;
    }
    hasRole(role) {
        return this.roles.includes(role);
    }
    getName() {
        return `${this.first_name} ${this.last_name}`;
    }
}
exports.default = User;
