"use strict";
import alt from '../alt.ts';
var Actions = require('../actions/TagActions');
var Source = require('../sources/TagSource');
var Tag = require('../models/Tag');

class TagStore {
    constructor() {
        // State
        this.tag = null;
        this.errorMessage = null;

        this.bindListeners({
            receivedItem: Actions.receivedItem,
            loadingItem: Actions.loadingItem,
            fetchingItemFailed: Actions.fetchingItemFailed
        });

        this.exportPublicMethods({
            getTag: this.getTag
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    receivedItem(data) {
        this.setState({
            tag: new Tag(data),
            errorMessage: null
        });
    }

    loadingItem() {
        // Do nothing
    }

    fetchingItemFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    getTag() {
        return this.getState().tag;
    }
}

module.exports = alt.createStore(TagStore, 'TagStore');
