import React from 'react';
'use strict';

class CalendarControls extends React.Component {

  _onNext() {
    this.props.onNext();
  }

  _onPrev() {
    this.props.onPrev();
  }

  render() {
    return (
      <div className='clndr-controls'>
        <div onClick={() => this._onPrev()} className="back glyphicon glyphicon glyphicon-triangle-left"> </div>
        <div className='current-month'>{this.props.date.format('MMMM YYYY')}</div>
        <div onClick={() => this._onNext()} className="forward glyphicon glyphicon glyphicon-triangle-right"> </div>
      </div>
    );
  }
}

module.exports = CalendarControls;
