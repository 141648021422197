"use strict";
var MathUtils;
(function (MathUtils) {
    /**
     * returns the result of numerator / denominator
     * returns 0 if denominator is 0
     * @param numerator
     * @param denominator
     * @returns {number}
     */
    function divideUnlessZero(numerator, denominator) {
        if (denominator === 0) {
            return 0;
        }
        return numerator / denominator;
    }
    MathUtils.divideUnlessZero = divideUnlessZero;
    /**
     * returns the percentage that fraction is of total
     * @param fraction
     * @param total
     * @returns {number}
     */
    function getPercentage(fraction, total) {
        return divideUnlessZero(fraction, total) * 100;
    }
    MathUtils.getPercentage = getPercentage;
    function toNumber(value) {
        if (typeof value === 'number' && !isNaN(value) && value !== Infinity) {
            return value;
        }
        if (typeof value === 'string') {
            const parsed = parseFloat(value);
            if (!isNaN(parsed) && parsed !== Infinity) {
                return parsed;
            }
        }
        return 0;
    }
    function hasTwoDecimalPlaces(num) {
        // match [optional negative sign][>= 1 digits][optional decimal point and 1 or 2 digits]
        return !!num.toString().match(/^-?\d+(\.\d{1,2})?$/);
    }
    MathUtils.hasTwoDecimalPlaces = hasTwoDecimalPlaces;
    /**
     * Returns the sum of two numbers.
     * Values tha cannot be converted to a number are treated as zero.
     * @param {number} numA
     * @param {number} numB
     * @returns {number}
     */
    function safelySum(numA, numB) {
        const parsedA = toNumber(numA);
        const parsedB = toNumber(numB);
        return parsedA + parsedB;
    }
    MathUtils.safelySum = safelySum;
    /**
     * Returns the sum of an array of values.
     * Values tha cannot be converted to a number are treated as zero.
     * @param {Array} numbers
     * @returns {number}
     */
    function safelySumArray(numbers) {
        return numbers.reduce(safelySum, 0);
    }
    MathUtils.safelySumArray = safelySumArray;
    /**
     * Warning! this may cause precision issues and not work as expected
     * @param {number} num
     * @param {number} decimalPlaces
     * @return {number}
     */
    function round(num, decimalPlaces) {
        if (decimalPlaces < 0) {
            throw new Error('MathUtils.round: decimal places must be 0 or greater');
        }
        const multiplier = Math.pow(10, decimalPlaces);
        return Math.round(num * multiplier) / multiplier;
    }
    MathUtils.round = round;
})(MathUtils || (MathUtils = {}));
module.exports = MathUtils;
