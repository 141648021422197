"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AccountItemQuery_1 = require("../utilities/AccountItemQuery");
const EnumsTs_1 = require("../models/EnumsTs");
function expectOneItemForCode(errors, month, code) {
    const items = AccountItemQuery_1.AccountItemQuery.allForCode(month.account_items, code);
    if (items.length !== 1) {
        errors.push(`${items.length} Account Items found for tag ${code} on month with id ${month.id}`);
    }
}
/**
 * returns whether given Forecast Months are deemed valid
 * @param {Array<Month>} months
 * @returns {boolean}
 */
function validateForecast(months) {
    const errors = [];
    try {
        if (months.length !== 12) {
            errors.push(`expected 12 Forecast months, found ${months.length}`);
        }
        months.forEach(month => {
            if (month.account_items.length === 0) {
                errors.push(`no account items on month with id ${month.id}`);
                // don't do subsequent checks as they will all fail
                return;
            }
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_Revenue_TotalRevenue);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_DirectCosts_TotalDirectCosts);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_IndirectCosts_TotalIndirectCosts);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_Misc_TotalMisc);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_NetProfit);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.IS_Distributions_TotalDistributions);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.BS_TotalAssets_TotalAssets);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.BS_TotalLiabilities_TotalLiabilities);
            expectOneItemForCode(errors, month, EnumsTs_1.TagCode.BS_TotalEquity_TotalEquity);
        });
    }
    catch (error) {
        console.error(error);
        errors.push(error.message);
    }
    if (errors.length > 0) {
        console.error('Errors in forecast data:', errors);
        return false;
    }
    return true;
}
exports.default = validateForecast;
