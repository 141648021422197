var AccountItem = require('../models/AccountItem');
var Actions = require('../actions/AccountItemActions');
var Ajax = require('../endpoints/ajax');

var AccountItemSource = {
    fetchAccountItemsForMonth() {
        return {
            remote(state, monthId) {
                return Ajax.getAccountItemsForMonth(monthId);
            },

            local() {
                return null;
            },

            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    fetchAccountItem() {
        return {
            remote(state, accountItemId) {
                return Ajax.getAccountItem(accountItemId);
            },

            local() {
                return null;
            },

            success: Actions.itemReceived,
            loading: Actions.itemReceiving,
            error: Actions.itemSavingFailed
        }
    },

    performSave() {
        return {
            remote(state, accountItem) {
                return Ajax.saveAccountItem(accountItem);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },


    performBatchSave() {
        return {
            remote(state, accountItems) {
                return Ajax.saveAccountItems(accountItems);
            },
            local() {
                return null;
            },

            success: Actions.itemsSaved,
            loading: Actions.itemsSaving,
            error: Actions.itemsSavingFailed
        }
    },

    /*
        Unlike performBatchSave, performMultiBatchSave does not reload
        the saved items.
        Desirable when saving items for multiple months as
        performBatchSave replaces local items for current month with
        saved items.
    */
    performMultiBatchSave() {
        return {
            remote(state, accountItems) {
                return Ajax.saveAccountItems(accountItems);
            },
            local() {
                return null;
            },

            //success does nothing
            success: Actions.multiMonthItemsReceived,
            loading: Actions.itemsSaving,
            error: Actions.itemsSavingFailed
        }
    },

    performDeleteItem() {
        return {
            remote(state, accountItem) {
                return Ajax.deleteAccountItem(accountItem.id);
            },

            local() {
                return null;
            },

            success: Actions.itemDeleted,
            loading: Actions.itemDeleting,
            error: Actions.itemDeletingFailed
        }
    },

    performDeleteItemsForMonth() {
        return {
            remote(state, monthId) {
                return Ajax.deleteAccountItemsForMonth(monthId);
            },

            local() {
                return null;
            },

            success: Actions.itemsDeleted,
            loading: Actions.itemsDeleting,
            error: Actions.itemsDeletingFailed
        }
    },

    wipeMonth() {
        return {
            remote(state, monthId) {
                return Ajax.wipeMonth(monthId);
                // return Promise.reject('wipeMonth has not been implemented.');
            },
            local() {
                return null
            },
            success: Actions.wipedMonth,
            loading: Actions.wipingMonth,
            error: Actions.wipingMonthFailed
        }
    }
};

module.exports = AccountItemSource;
