/* eslint-disable */
/* @ts-check */
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonTypes, PictureButton } from "../../../common/Buttons"
import DebounceInput from "./Debounce"

/**
 * A Generic component that handles opening and closing when clicking the header.
 */
class DetailedAccordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

    }

    onExpandToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    isExpanded() {
        if (!this.props.collapsible)
            return true;
        return this.state.expanded;
    }

    getCollapseIcon() {
        var iconClass = this.isExpanded() ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";

        if (React.Children.count(this.props.children) > 0 && this.props.collapsible) {
            return <span className={iconClass}></span>;
        }

        return null;
    }

    getValueSection(label, value, onChange) {
        const { editing } = this.props;

        let content = <span>{value}</span>;

        if ( typeof editing !== "undefined") {

            if(editing) {
                content = onChange != null ?
                    <DebounceInput type="text"
                                   updateOnlyOnBlur
                                   className="form-control"
                                   value={value}
                                   onChange={value => onChange(value)}
                                   clearOnChange
                                /> :
                    <span>{value}</span>;
            }

        } else {
            content = onChange != null ?
            <DebounceInput type="text"
                           updateOnlyOnBlur
                           className="form-control"
                           value={value}
                           onChange={value => onChange(value)}
                           clearOnChange
                           /> :
            <span>{value}</span>;
        }

        if (label) {
            return [<div className="col-xs-2 text-center no-padding">
                {label}
            </div>,
                <div className="col-xs-10 no-padding">
                    {content}
                </div>
            ]
        } else {
            return <div className="col-xs-12 no-padding">
                {content}
            </div>
        }

    }

    isCollapsible() {
        return React.Children.count(this.props.children) > 0 && this.props.collapsible !== false;
    }

    render() {
        var addButton = null;
        var formulaButton = null;
        var removeButton = null;
        let {onFormulaPressed, value, label, onChangeAmount, value2, label2, onChangeAmount2 } = this.props;
        if (onFormulaPressed != null) {
            formulaButton = <PictureButton buttonType={ButtonTypes.Formula}
                           onClick={onFormulaPressed}/>;
        }

        if (this.props.isLoading) {
            addButton = <div className="glyphicon glyphicon-hourglass"></div>;//Loading indicator
        }
        else if (this.props.onAddPressed != null) {
            addButton = <PictureButton buttonType={ButtonTypes.Plus} onClick={() => this.props.onAddPressed()}/>;
        }

        else if (this.props.onDeletePressed != null) {
            addButton = <PictureButton buttonType={ButtonTypes.Delete} onClick={() => this.props.onDeletePressed()}/>;
        }

        var className = `col-xs-12 line-item ${this.props.className || ""}`;

        let content =   <div className="col-xs-6 text-left total">
                            <div className="col-xs-5 no-padding total">
                                {this.getValueSection(label,value,onChangeAmount)}
                            </div>
                            <div className="col-xs-1 no-padding"/>
                            <div className="col-xs-5 no-padding">
                                {this.getValueSection(label2,value2,onChangeAmount2)}
                            </div>
                            <div className="col-xs-1 text-right no-padding">
                                {formulaButton}
                                {addButton}
                                {removeButton}
                            </div>
                        </div>


        if (typeof value === "undefined" && typeof value2 !== "undefined") {
            content =   <div className="col-xs-2 col-xs-offset-4 total">
                            <div className="col-xs-12 pull-right">
                                {this.getValueSection(label2,value2,onChangeAmount2)}
                            </div>
                        </div>
        }

        return <div>
            <div className={`${className}`}>
                <div className="row">
                    {this.getLeftColumn()}
                    {content}
                </div>
            </div>

            {this.isExpanded() ? this.props.children : null}
        </div>
    }

    getLeftColumn() {
        let {value3, label3, onChangeAmount3 } = this.props;
        const className = `col-xs-6 truncate-text accordion-heading${this.isCollapsible() ? " collapsible" : ""}`;
        if (value3 === null) {
            return (
                <div className={className} onClick={() => this.onExpandToggle()}>
                    {this.getCollapseIcon()}
                    {this.getHeading()}
                </div>
            );
        } else {
            return (
                <div className={className + " no-padding"} onClick={() => this.onExpandToggle()}>
                    <div className="col-xs-7 truncate-text">
                        {this.getCollapseIcon()}
                        {this.getHeading()}
                    </div>
                    <div className="col-xs-5 no-padding total">
                        {this.getValueSection(label3,value3,onChangeAmount3)}
                    </div>
                </div>
            );
        }
    }

    getHeading() {
        let { onChangeHeading, heading } = this.props;
        if (typeof onChangeHeading === "function"){
            return <DebounceInput type="text"
                           updateOnlyOnBlur
                           className="form-control"
                           value={heading}
                           onChange={value => onChangeHeading(value)}
                           clearOnChange
                        />
        }
        return <span className="heading" title={heading}>
                {heading}
            </span>
    }
}


DetailedAccordion.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    value: PropTypes.string,
    onChangeAmount: PropTypes.func,
    label: PropTypes.string,
    onAddPressed: PropTypes.func,
    isLoading: PropTypes.bool,
    onFormulaPressed: PropTypes.func,
    value2: PropTypes.string,
    onChangeAmount2: PropTypes.func,
    label2: PropTypes.string,
    collapsible: PropTypes.bool,
    onChangeHeading: PropTypes.func,
    editing: PropTypes.bool,
};

DetailedAccordion.defaultProps = {
    isLoading: false,
    onFormulaPressed: null,
    value2: null,
    value3: null,
    collapsible: true
};

export default DetailedAccordion;
