"use strict";
class SonQuestion{
    constructor(item) {
        this.question = null;
        this.id = null;
        this.category = null;
        this.order_index = null;
        this.is_custom = false;

        for(var key in item){
            if (this.hasOwnProperty(key)){
                this[key] = item[key];
            }
        }
    }

    get orderIndex() {
        return this.order_index;
    }

    get isCustom() {
        return this.is_custom;
    }
}

module.exports = SonQuestion;
