var industries = [
    { "industry": "Accommodation, Cafes and Restaurants", "subIndustry": "Accommodation", "code": 5710 },
    { "industry": "Accommodation, Cafes and Restaurants", "subIndustry": "Cafes and Restaurants", "code": 5730 },
    { "industry": "Accommodation, Cafes and Restaurants", "subIndustry": "Clubs (Hospitality)", "code": 5740 },
    { "industry": "Accommodation, Cafes and Restaurants", "subIndustry": "Pubs, Taverns and Bars", "code": 5720 },
    { "industry": "Accommodation, Cafes and Restaurants", "subIndustry": "Not listed above", "code": 5750 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Aerial Agriculture Services", "code": 213 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Apple and Pear Growing", "code": 115 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Aquaculture", "code": 420 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Beef Cattle Farming", "code": 125 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Cotton Ginning", "code": 211 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Cotton Growing", "code": 162 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Crop and Plant Growing not listed above", "code": 169 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Cut Flowers and Flower Seeds Growing", "code": 112 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Dairy Cattle Farming", "code": 130 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Deer Farming", "code": 153 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Finfish Trawling", "code": 413 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Forestry", "code": 301 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Fruit Growing not listed above", "code": 119 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Grain Growing", "code": 121 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Grain-Sheep and Grain-Beef Cattle Farming", "code": 122 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Grape Growing", "code": 114 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Horse Farming", "code": 152 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Hunting and Trapping", "code": 220 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Kiwi Fruit Growing", "code": 117 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Line Fishing", "code": 415 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Livestock Farming not listed above", "code": 159 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Logging", "code": 302 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Marine Fishing not listed above", "code": 419 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Pig Farming", "code": 151 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Plant Nurseries", "code": 111 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Poultry Farming (Eggs)", "code": 142 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Poultry Farming (Meat)", "code": 141 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Prawn Fishing", "code": 412 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Rock Lobster Fishing", "code": 411 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Services to Agriculture not listed above", "code": 219 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Services to Forestry", "code": 303 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Shearing Services", "code": 212 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Sheep Farming", "code": 124 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Sheep-Beef Cattle Farming", "code": 123 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Squid Jigging", "code": 414 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Stone Fruit Growing", "code": 116 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Sugar Cane Growing", "code": 161 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Vegetable Growing", "code": 113 },
    { "industry": "Agriculture, Forestry and Fishing", "subIndustry": "Not listed above", "code": 425 },
    { "industry": "Communication Services", "subIndustry": "Courier Services", "code": 7112 },
    { "industry": "Communication Services", "subIndustry": "Postal Services", "code": 7111 },
    { "industry": "Communication Services", "subIndustry": "Telecommunication Services", "code": 7120 },
    { "industry": "Communication Services", "subIndustry": "Not listed above", "code": 7150 },
    { "industry": "Construction", "subIndustry": " Roofing Services", "code": 4223 },
    { "industry": "Construction", "subIndustry": "Air Conditioning and Heating Services", "code": 4233 },
    { "industry": "Construction", "subIndustry": "Bricklaying Services", "code": 4222 },
    { "industry": "Construction", "subIndustry": "Carpentry Services", "code": 4242 },
    { "industry": "Construction", "subIndustry": "Concreting Services", "code": 4221 },
    { "industry": "Construction", "subIndustry": "Electrical Services", "code": 4232 },
    { "industry": "Construction", "subIndustry": "Fire and Security System Services", "code": 4234 },
    { "industry": "Construction", "subIndustry": "Glazing Services", "code": 4245 },
    { "industry": "Construction", "subIndustry": "House Construction", "code": 4111 },
    { "industry": "Construction", "subIndustry": "Landscaping  Services", "code": 4251 },
    { "industry": "Construction", "subIndustry": "Non-Building Construction not listed above", "code": 4122 },
    { "industry": "Construction", "subIndustry": "Non-Residential Building Construction ", "code": 4113 },
    { "industry": "Construction", "subIndustry": "Painting and Decorating Services", "code": 4244 },
    { "industry": "Construction", "subIndustry": "Plastering and Ceiling Services", "code": 4241 },
    { "industry": "Construction", "subIndustry": "Plumbing Services", "code": 4231 },
    { "industry": "Construction", "subIndustry": "Residential Building Construction not listed above", "code": 4112 },
    { "industry": "Construction", "subIndustry": "Road and Bridge Construction", "code": 4121 },
    { "industry": "Construction", "subIndustry": "Site Preparation Services", "code": 4210 },
    { "industry": "Construction", "subIndustry": "Structural Steel Erection Services", "code": 4224 },
    { "industry": "Construction", "subIndustry": "Tiling and Carpeting Services", "code": 4243 },
    { "industry": "Construction", "subIndustry": "Not listed above", "code": 4259 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Casinos", "code": 9322 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Creative Arts", "code": 9242 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Film and Video Distribution", "code": 9112 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Film and Video Production", "code": 9111 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Gambling Services not listed above", "code": 9329 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Horse and Dog Racing", "code": 9311 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Libraries", "code": 9210 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Lotteries", "code": 9321 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Motion Picture Exhibition", "code": 9113 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Museums ", "code": 9220 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Music and Theatre Productions", "code": 9241 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Performing Arts Venues", "code": 9252 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Radio Services", "code": 9121 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Recreational Parks and Gardens", "code": 9239 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Services to the Arts not listed above", "code": 9259 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Sound Recording Studios", "code": 9251 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Sports and Services to Sports not listed above", "code": 9319 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Sports Grounds and Facilities not listed above", "code": 9312 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Television Services", "code": 9122 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Zoological and Botanic Gardens", "code": 9231 },
    { "industry": "Cultural and Recreational Services", "subIndustry": "Not listed above", "code": 9330 },
    { "industry": "Education", "subIndustry": "Combined Primary and Secondary Education", "code": 8423 },
    { "industry": "Education", "subIndustry": "Higher Education", "code": 8431 },
    { "industry": "Education", "subIndustry": "Preschool Education", "code": 8410 },
    { "industry": "Education", "subIndustry": "Primary Education", "code": 8421 },
    { "industry": "Education", "subIndustry": "Secondary Education", "code": 8422 },
    { "industry": "Education", "subIndustry": "Special School Education", "code": 8424 },
    { "industry": "Education", "subIndustry": "Technical and Further Education", "code": 8432 },
    { "industry": "Education", "subIndustry": "Not listed above", "code": 8440 },
    { "industry": "Electricity, Gas and Water Supply", "subIndustry": "Electricity Supply", "code": 3610 },
    { "industry": "Electricity, Gas and Water Supply", "subIndustry": "Gas Supply", "code": 3620 },
    { "industry": "Electricity, Gas and Water Supply", "subIndustry": "Sewerage and Drainage Services", "code": 3702 },
    { "industry": "Electricity, Gas and Water Supply", "subIndustry": "Water Supply", "code": 3701 },
    { "industry": "Electricity, Gas and Water Supply", "subIndustry": "Not listed above", "code": 3750 },
    { "industry": "Finance and Insurance", "subIndustry": "Banks", "code": 7321 },
    { "industry": "Finance and Insurance", "subIndustry": "Building Societies", "code": 7322 },
    { "industry": "Finance and Insurance", "subIndustry": "Central Bank", "code": 7310 },
    { "industry": "Finance and Insurance", "subIndustry": "Credit Unions", "code": 7323 },
    { "industry": "Finance and Insurance", "subIndustry": "Deposit Taking Financiers not listed above", "code": 7329 },
    { "industry": "Finance and Insurance", "subIndustry": "Financial Asset Broking Services", "code": 7511 },
    { "industry": "Finance and Insurance", "subIndustry": "Financial Asset Investors", "code": 7340 },
    { "industry": "Finance and Insurance", "subIndustry": "General Insurance ", "code": 7422 },
    { "industry": "Finance and Insurance", "subIndustry": "Health Insurance ", "code": 7421 },
    { "industry": "Finance and Insurance", "subIndustry": "Life Insurance ", "code": 7411 },
    { "industry": "Finance and Insurance", "subIndustry": "Money Market Dealers", "code": 7324 },
    { "industry": "Finance and Insurance", "subIndustry": "Other Financiers ", "code": 7330 },
    { "industry": "Finance and Insurance", "subIndustry": "Services to Finance and Investment not listed above", "code": 7519 },
    { "industry": "Finance and Insurance", "subIndustry": "Services to Insurance", "code": 7520 },
    { "industry": "Finance and Insurance", "subIndustry": "Superannuation Funds", "code": 7412 },
    { "industry": "Finance and Insurance", "subIndustry": "Not listed above", "code": 7575 },
    { "industry": "Government Administration and Defence", "subIndustry": "Central Government Administration ", "code": 8111 },
    { "industry": "Government Administration and Defence", "subIndustry": "Defence", "code": 8200 },
    { "industry": "Government Administration and Defence", "subIndustry": "Foreign Government Representation", "code": 8130 },
    { "industry": "Government Administration and Defence", "subIndustry": "Justice", "code": 8120 },
    { "industry": "Government Administration and Defence", "subIndustry": "Local Government Administration ", "code": 8113 },
    { "industry": "Government Administration and Defence", "subIndustry": "State Government Administration ", "code": 8112 },
    { "industry": "Government Administration and Defence", "subIndustry": "Not listed above", "code": 8300 },
    { "industry": "Health and Community Services", "subIndustry": "Accommodation for the Aged", "code": 8721 },
    { "industry": "Health and Community Services", "subIndustry": "Ambulance Services", "code": 8633 },
    { "industry": "Health and Community Services", "subIndustry": "Child Care Services", "code": 8710 },
    { "industry": "Health and Community Services", "subIndustry": "Chiropractic Services", "code": 8636 },
    { "industry": "Health and Community Services", "subIndustry": "Community Health Centres", "code": 8634 },
    { "industry": "Health and Community Services", "subIndustry": "Dental Services", "code": 8623 },
    { "industry": "Health and Community Services", "subIndustry": "General Practice Medical Services", "code": 8621 },
    { "industry": "Health and Community Services", "subIndustry": "Health Services not listed above", "code": 8639 },
    { "industry": "Health and Community Services", "subIndustry": "Hospitals (Except Psychiatric Hospitals)", "code": 8611 },
    { "industry": "Health and Community Services", "subIndustry": "Nursing Homes", "code": 8613 },
    { "industry": "Health and Community Services", "subIndustry": "Optometry and Optical Dispensing", "code": 8632 },
    { "industry": "Health and Community Services", "subIndustry": "Pathology Services", "code": 8631 },
    { "industry": "Health and Community Services", "subIndustry": "Physiotherapy Services", "code": 8635 },
    { "industry": "Health and Community Services", "subIndustry": "Psychiatric Hospitals ", "code": 8612 },
    { "industry": "Health and Community Services", "subIndustry": "Residential Care Services not listed above", "code": 8722 },
    { "industry": "Health and Community Services", "subIndustry": "Specialist Medical Services", "code": 8622 },
    { "industry": "Health and Community Services", "subIndustry": "Veterinary Services", "code": 8640 },
    { "industry": "Health and Community Services", "subIndustry": "Not listed above", "code": 8729 },
    { "industry": "Manufacturing", "subIndustry": "Agricultural Machinery Manufacturing", "code": 2861 },
    { "industry": "Manufacturing", "subIndustry": "Aircraft Manufacturing", "code": 2824 },
    { "industry": "Manufacturing", "subIndustry": "Alumina Production", "code": 2721 },
    { "industry": "Manufacturing", "subIndustry": "Aluminium Rolling, Drawing, Extruding", "code": 2731 },
    { "industry": "Manufacturing", "subIndustry": "Aluminium Smelting", "code": 2722 },
    { "industry": "Manufacturing", "subIndustry": "Architectural Aluminium Product Manufacturing", "code": 2742 },
    { "industry": "Manufacturing", "subIndustry": "Automotive Component Manufacturing not listed above", "code": 2819 },
    { "industry": "Manufacturing", "subIndustry": "Automotive Electrical and Instrument Manufacturing", "code": 2813 },
    { "industry": "Manufacturing", "subIndustry": "Bacon, Ham and Smallgood Manufacturing", "code": 2113 },
    { "industry": "Manufacturing", "subIndustry": "Basic Iron and Steel Manufacturing", "code": 2711 },
    { "industry": "Manufacturing", "subIndustry": "Basic Non-Ferrous Metal Manufacturing not listed above", "code": 2729 },
    { "industry": "Manufacturing", "subIndustry": "Battery Manufacturing", "code": 2853 },
    { "industry": "Manufacturing", "subIndustry": "Beer and Malt Manufacturing", "code": 2182 },
    { "industry": "Manufacturing", "subIndustry": "Biscuit Manufacturing", "code": 2163 },
    { "industry": "Manufacturing", "subIndustry": "Boatbuilding", "code": 2822 },
    { "industry": "Manufacturing", "subIndustry": "Book and Other Publishing", "code": 2423 },
    { "industry": "Manufacturing", "subIndustry": "Bread Manufacturing", "code": 2161 },
    { "industry": "Manufacturing", "subIndustry": "Cake and Pastry Manufacturing", "code": 2162 },
    { "industry": "Manufacturing", "subIndustry": "Cardigan and Pullover Manufacturing", "code": 2232 },
    { "industry": "Manufacturing", "subIndustry": "Cement and Lime Manufacturing", "code": 2631 },
    { "industry": "Manufacturing", "subIndustry": "Ceramic Product Manufacturing not listed above", "code": 2629 },
    { "industry": "Manufacturing", "subIndustry": "Ceramic Product Manufacturing", "code": 2622 },
    { "industry": "Manufacturing", "subIndustry": "Ceramic Tile and Pipe Manufacturing", "code": 2623 },
    { "industry": "Manufacturing", "subIndustry": "Cereal Food and Baking Mix Manufacturing", "code": 2152 },
    { "industry": "Manufacturing", "subIndustry": "Chemical Product Manufacturing not listed above", "code": 2549 },
    { "industry": "Manufacturing", "subIndustry": "Clay Brick Manufacturing", "code": 2621 },
    { "industry": "Manufacturing", "subIndustry": "Clothing Manufacturing not listed above", "code": 2249 },
    { "industry": "Manufacturing", "subIndustry": "Commercial Space Heating and Cooling Equipment Manufacturing", "code": 2867 },
    { "industry": "Manufacturing", "subIndustry": "Computer and Business Machine Manufacturing", "code": 2841 },
    { "industry": "Manufacturing", "subIndustry": "Concrete Pipe and Box Culvert Manufacturing", "code": 2634 },
    { "industry": "Manufacturing", "subIndustry": "Concrete Product Manufacturing not listed above", "code": 2635 },
    { "industry": "Manufacturing", "subIndustry": "Concrete Slurry Manufacturing", "code": 2633 },
    { "industry": "Manufacturing", "subIndustry": "Confectionery Manufacturing", "code": 2172 },
    { "industry": "Manufacturing", "subIndustry": "Copper, Silver, Lead and Zinc Smelting, Refining", "code": 2723 },
    { "industry": "Manufacturing", "subIndustry": "Corrugated Paperboard Container Manufacturing", "code": 2333 },
    { "industry": "Manufacturing", "subIndustry": "Cosmetic and Toiletry Preparation Manufacturing", "code": 2546 },
    { "industry": "Manufacturing", "subIndustry": "Cotton Textile Manufacturing", "code": 2213 },
    { "industry": "Manufacturing", "subIndustry": "Dairy Product Manufacturing not listed above", "code": 2129 },
    { "industry": "Manufacturing", "subIndustry": "Electric Cable and Wire Manufacturing", "code": 2852 },
    { "industry": "Manufacturing", "subIndustry": "Electric Light and Sign Manufacturing", "code": 2854 },
    { "industry": "Manufacturing", "subIndustry": "Electrical Equipment Manufacturing not listed above", "code": 2859 },
    { "industry": "Manufacturing", "subIndustry": "Electronic Equipment Manufacturing not listed above", "code": 2849 },
    { "industry": "Manufacturing", "subIndustry": "Explosive Manufacturing", "code": 2541 },
    { "industry": "Manufacturing", "subIndustry": "Fabricated Metal Product Manufacturing not listed above", "code": 2769 },
    { "industry": "Manufacturing", "subIndustry": "Fabricated Wood Manufacturing", "code": 2322 },
    { "industry": "Manufacturing", "subIndustry": "Fertiliser Manufacturing", "code": 2531 },
    { "industry": "Manufacturing", "subIndustry": "Flour Mill Product Manufacturing", "code": 2151 },
    { "industry": "Manufacturing", "subIndustry": "Food Manufacturing not listed above", "code": 2179 },
    { "industry": "Manufacturing", "subIndustry": "Food Processing Machinery Manufacturing", "code": 2863 },
    { "industry": "Manufacturing", "subIndustry": "Footwear Manufacturing", "code": 2250 },
    { "industry": "Manufacturing", "subIndustry": "Fruit and Vegetable Processing", "code": 2130 },
    { "industry": "Manufacturing", "subIndustry": "Furniture Manufacturing not listed above", "code": 2929 },
    { "industry": "Manufacturing", "subIndustry": "Glass and Glass Product Manufacturing", "code": 2610 },
    { "industry": "Manufacturing", "subIndustry": "Hand Tool and General Hardware Manufacturing", "code": 2761 },
    { "industry": "Manufacturing", "subIndustry": "Hosiery Manufacturing", "code": 2231 },
    { "industry": "Manufacturing", "subIndustry": "Household Appliance Manufacturing", "code": 2851 },
    { "industry": "Manufacturing", "subIndustry": "Ice Cream Manufacturing", "code": 2122 },
    { "industry": "Manufacturing", "subIndustry": "Industrial Gas Manufacturing", "code": 2532 },
    { "industry": "Manufacturing", "subIndustry": "Industrial Machinery and Equipment Manufacturing not listed above", "code": 2869 },
    { "industry": "Manufacturing", "subIndustry": "Ink Manufacturing", "code": 2547 },
    { "industry": "Manufacturing", "subIndustry": "Inorganic Industrial Chemical Manufacturing not listed above", "code": 2535 },
    { "industry": "Manufacturing", "subIndustry": "Iron and Steel Casting and Forging", "code": 2712 },
    { "industry": "Manufacturing", "subIndustry": "Jewellery and Silverware Manufacturing", "code": 2941 },
    { "industry": "Manufacturing", "subIndustry": "Knitting Mill Product Manufacturing not listed above", "code": 2239 },
    { "industry": "Manufacturing", "subIndustry": "Leather and Leather Substitute Product Manufacturing", "code": 2262 },
    { "industry": "Manufacturing", "subIndustry": "Leather Tanning and Fur Dressing", "code": 2261 },
    { "industry": "Manufacturing", "subIndustry": "Lifting and Material Handling Equipment Manufacturing", "code": 2865 },
    { "industry": "Manufacturing", "subIndustry": "Log Sawmilling", "code": 2311 },
    { "industry": "Manufacturing", "subIndustry": "Machine Tool and Part Manufacturing", "code": 2864 },
    { "industry": "Manufacturing", "subIndustry": "Made-Up Textile Manufacturing", "code": 2221 },
    { "industry": "Manufacturing", "subIndustry": "Mattress Manufacturing (Except Rubber)", "code": 2923 },
    { "industry": "Manufacturing", "subIndustry": "Meat Processing", "code": 2111 },
    { "industry": "Manufacturing", "subIndustry": "Medical and Surgical Equipment Manufacturing", "code": 2832 },
    { "industry": "Manufacturing", "subIndustry": "Medicinal and Pharmaceutical Product Manufacturing", "code": 2543 },
    { "industry": "Manufacturing", "subIndustry": "Men's and Boy's Wear Manufacturing", "code": 2241 },
    { "industry": "Manufacturing", "subIndustry": "Metal Coating and Finishing", "code": 2764 },
    { "industry": "Manufacturing", "subIndustry": "Metal Container Manufacturing", "code": 2751 },
    { "industry": "Manufacturing", "subIndustry": "Milk and Cream Processing", "code": 2121 },
    { "industry": "Manufacturing", "subIndustry": "Mining and Construction Machinery Manufacturing", "code": 2862 },
    { "industry": "Manufacturing", "subIndustry": "Motor Vehicle Body Manufacturing", "code": 2812 },
    { "industry": "Manufacturing", "subIndustry": "Motor Vehicle Manufacturing", "code": 2811 },
    { "industry": "Manufacturing", "subIndustry": "Newspaper Printing or Publishing", "code": 2421 },
    { "industry": "Manufacturing", "subIndustry": "Non-Ferrous Metal Casting", "code": 2733 },
    { "industry": "Manufacturing", "subIndustry": "Non-Ferrous Metal Rolling, Drawing, Extruding not listed above", "code": 2732 },
    { "industry": "Manufacturing", "subIndustry": "Non-Ferrous Pipe Fitting Manufacturing", "code": 2765 },
    { "industry": "Manufacturing", "subIndustry": "Non-Metallic Mineral Product Manufacturing not listed above", "code": 2640 },
    { "industry": "Manufacturing", "subIndustry": "Nut, Bolt, Screw and Rivet Manufacturing", "code": 2763 },
    { "industry": "Manufacturing", "subIndustry": "Oil and Fat Manufacturing", "code": 2140 },
    { "industry": "Manufacturing", "subIndustry": "Organic Industrial Chemical Manufacturing not listed above", "code": 2534 },
    { "industry": "Manufacturing", "subIndustry": "Other Periodical Publishing", "code": 2422 },
    { "industry": "Manufacturing", "subIndustry": "Paint Manufacturing", "code": 2542 },
    { "industry": "Manufacturing", "subIndustry": "Paper Bag and Sack Manufacturing", "code": 2334 },
    { "industry": "Manufacturing", "subIndustry": "Paper Product Manufacturing not listed above", "code": 2339 },
    { "industry": "Manufacturing", "subIndustry": "Paper Stationery Manufacturing", "code": 2411 },
    { "industry": "Manufacturing", "subIndustry": "Pesticide Manufacturing", "code": 2544 },
    { "industry": "Manufacturing", "subIndustry": "Petroleum and Coal Product Manufacturing not listed above", "code": 2520 },
    { "industry": "Manufacturing", "subIndustry": "Petroleum Refining", "code": 2510 },
    { "industry": "Manufacturing", "subIndustry": "Photographic and Optical Good Manufacturing", "code": 2831 },
    { "industry": "Manufacturing", "subIndustry": "Plaster Product Manufacturing", "code": 2632 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Bag and Film Manufacturing", "code": 2563 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Blow Moulded Product Manufacturing", "code": 2561 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Extruded Product Manufacturing", "code": 2562 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Foam Product Manufacturing", "code": 2565 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Injection Moulded Product Manufacturing", "code": 2566 },
    { "industry": "Manufacturing", "subIndustry": "Plastic Product Rigid Fibre Reinforced Manufacturing", "code": 2564 },
    { "industry": "Manufacturing", "subIndustry": "Plywood and Veneer Manufacturing", "code": 2321 },
    { "industry": "Manufacturing", "subIndustry": "Poultry Processing", "code": 2112 },
    { "industry": "Manufacturing", "subIndustry": "Prefabricated Building Manufacturing not listed above", "code": 2919 },
    { "industry": "Manufacturing", "subIndustry": "Prefabricated Metal Building Manufacturing", "code": 2911 },
    { "industry": "Manufacturing", "subIndustry": "Prepared Animal and Bird Feed Manufacturing", "code": 2174 },
    { "industry": "Manufacturing", "subIndustry": "Printing", "code": 2412 },
    { "industry": "Manufacturing", "subIndustry": "Professional and Scientific Equipment Manufacturing not listed above", "code": 2839 },
    { "industry": "Manufacturing", "subIndustry": "Pulp, Paper and Paperboard Manufacturing", "code": 2331 },
    { "industry": "Manufacturing", "subIndustry": "Pump and Compressor  Manufacturing", "code": 2866 },
    { "industry": "Manufacturing", "subIndustry": "Railway Equipment Manufacturing", "code": 2823 },
    { "industry": "Manufacturing", "subIndustry": "Recorded Media Manufacturing and Publishing", "code": 2430 },
    { "industry": "Manufacturing", "subIndustry": "Rope, Cordage and Twine Manufacturing", "code": 2223 },
    { "industry": "Manufacturing", "subIndustry": "Rubber Product Manufacturing not listed above", "code": 2559 },
    { "industry": "Manufacturing", "subIndustry": "Rubber Tyre Manufacturing", "code": 2551 },
    { "industry": "Manufacturing", "subIndustry": "Seafood Processing", "code": 2173 },
    { "industry": "Manufacturing", "subIndustry": "Services to Printing", "code": 2413 },
    { "industry": "Manufacturing", "subIndustry": "Sheet Metal Furniture Manufacturing", "code": 2922 },
    { "industry": "Manufacturing", "subIndustry": "Sheet Metal Product Manufacturing not listed above", "code": 2759 },
    { "industry": "Manufacturing", "subIndustry": "Shipbuilding", "code": 2821 },
    { "industry": "Manufacturing", "subIndustry": "Sleepwear, Underwear and Infant Clothing Manufacturing", "code": 2243 },
    { "industry": "Manufacturing", "subIndustry": "Soap and Other Detergent Manufacturing", "code": 2545 },
    { "industry": "Manufacturing", "subIndustry": "Soft Drink, Cordial and Syrup Manufacturing", "code": 2181 },
    { "industry": "Manufacturing", "subIndustry": "Solid Paperboard Container Manufacturing", "code": 2332 },
    { "industry": "Manufacturing", "subIndustry": "Spirit Manufacturing", "code": 2184 },
    { "industry": "Manufacturing", "subIndustry": "Spring and Wire Product Manufacturing", "code": 2762 },
    { "industry": "Manufacturing", "subIndustry": "Steel Pipe and Tube Manufacturing", "code": 2713 },
    { "industry": "Manufacturing", "subIndustry": "Structural Metal Product Manufacturing not listed above", "code": 2749 },
    { "industry": "Manufacturing", "subIndustry": "Structural Steel Fabricating", "code": 2741 },
    { "industry": "Manufacturing", "subIndustry": "Sugar Manufacturing", "code": 2171 },
    { "industry": "Manufacturing", "subIndustry": "Synthetic Fibre Textile Manufacturing", "code": 2212 },
    { "industry": "Manufacturing", "subIndustry": "Synthetic Resin Manufacturing", "code": 2533 },
    { "industry": "Manufacturing", "subIndustry": "Telecommunication, Broadcasting and Transceiving Equipment Manufacturing", "code": 2842 },
    { "industry": "Manufacturing", "subIndustry": "Textile Finishing", "code": 2215 },
    { "industry": "Manufacturing", "subIndustry": "Textile Floor Covering Manufacturing", "code": 2222 },
    { "industry": "Manufacturing", "subIndustry": "Textile Product Manufacturing not listed above", "code": 2229 },
    { "industry": "Manufacturing", "subIndustry": "Timber Resawing and Dressing", "code": 2313 },
    { "industry": "Manufacturing", "subIndustry": "Tobacco Product Manufacturing", "code": 2190 },
    { "industry": "Manufacturing", "subIndustry": "Toy and Sporting Good Manufacturing", "code": 2942 },
    { "industry": "Manufacturing", "subIndustry": "Transport Equipment Manufacturing not listed above", "code": 2829 },
    { "industry": "Manufacturing", "subIndustry": "Wine Manufacturing", "code": 2183 },
    { "industry": "Manufacturing", "subIndustry": "Women's and Girl's Wear Manufacturing", "code": 2242 },
    { "industry": "Manufacturing", "subIndustry": "Wood Chipping", "code": 2312 },
    { "industry": "Manufacturing", "subIndustry": "Wood Product Manufacturing not listed above", "code": 2329 },
    { "industry": "Manufacturing", "subIndustry": "Wooden Furniture and Upholstered Seat Manufacturing", "code": 2921 },
    { "industry": "Manufacturing", "subIndustry": "Wooden Structural Component Manufacturing", "code": 2323 },
    { "industry": "Manufacturing", "subIndustry": "Wool Scouring", "code": 2211 },
    { "industry": "Manufacturing", "subIndustry": "Wool Textile Manufacturing", "code": 2214 },
    { "industry": "Manufacturing", "subIndustry": "Not listed above", "code": 2949 },
    { "industry": "Mining", "subIndustry": "Bauxite Mining", "code": 1312 },
    { "industry": "Mining", "subIndustry": "Black Coal Mining", "code": 1101 },
    { "industry": "Mining", "subIndustry": "Brown Coal Mining", "code": 1102 },
    { "industry": "Mining", "subIndustry": "Construction Material Mining not listed above", "code": 1419 },
    { "industry": "Mining", "subIndustry": "Copper Ore Mining", "code": 1313 },
    { "industry": "Mining", "subIndustry": "Gold Ore Mining", "code": 1314 },
    { "industry": "Mining", "subIndustry": "Gravel and Sand Quarrying", "code": 1411 },
    { "industry": "Mining", "subIndustry": "Iron Ore Mining", "code": 1311 },
    { "industry": "Mining", "subIndustry": "Metal Ore Mining not listed above", "code": 1319 },
    { "industry": "Mining", "subIndustry": "Mineral Exploration (Own Account)", "code": 1513 },
    { "industry": "Mining", "subIndustry": "Mineral Exploration Services", "code": 1514 },
    { "industry": "Mining", "subIndustry": "Mineral Sand Mining", "code": 1315 },
    { "industry": "Mining", "subIndustry": "Nickel Ore Mining", "code": 1316 },
    { "industry": "Mining", "subIndustry": "Oil and Gas Extraction", "code": 1200 },
    { "industry": "Mining", "subIndustry": "Other Mining", "code": 1420 },
    { "industry": "Mining", "subIndustry": "Petroleum Exploration (Own Account)", "code": 1511 },
    { "industry": "Mining", "subIndustry": "Petroleum Exploration Services", "code": 1512 },
    { "industry": "Mining", "subIndustry": "Silver-Lead-Zinc Ore Mining", "code": 1317 },
    { "industry": "Mining", "subIndustry": "Not listed above", "code": 1520 },
    { "industry": "Personal and Other Services", "subIndustry": "Business and Professional Associations", "code": 9621 },
    { "industry": "Personal and Other Services", "subIndustry": "Corrective Centres", "code": 9632 },
    { "industry": "Personal and Other Services", "subIndustry": "Fire Brigade Services", "code": 9633 },
    { "industry": "Personal and Other Services", "subIndustry": "Funeral Directors, Crematoria and Cemeteries", "code": 9524 },
    { "industry": "Personal and Other Services", "subIndustry": "Gardening Services", "code": 9525 },
    { "industry": "Personal and Other Services", "subIndustry": "Hairdressing and Beauty Salons", "code": 9526 },
    { "industry": "Personal and Other Services", "subIndustry": "Interest Groups not listed above", "code": 9629 },
    { "industry": "Personal and Other Services", "subIndustry": "Labour Associations", "code": 9622 },
    { "industry": "Personal and Other Services", "subIndustry": "Laundries and Dry-Cleaners", "code": 9521 },
    { "industry": "Personal and Other Services", "subIndustry": "Personal and Household Goods Hiring not listed above", "code": 9519 },
    { "industry": "Personal and Other Services", "subIndustry": "Personal Services not listed above", "code": 9529 },
    { "industry": "Personal and Other Services", "subIndustry": "Photographic Film Processing", "code": 9522 },
    { "industry": "Personal and Other Services", "subIndustry": "Photographic Studios", "code": 9523 },
    { "industry": "Personal and Other Services", "subIndustry": "Police Services", "code": 9631 },
    { "industry": "Personal and Other Services", "subIndustry": "Private Households Employing Staff", "code": 9700 },
    { "industry": "Personal and Other Services", "subIndustry": "Religious Organisations", "code": 9610 },
    { "industry": "Personal and Other Services", "subIndustry": "Video Hire Outlets", "code": 9511 },
    { "industry": "Personal and Other Services", "subIndustry": "Waste Disposal Services", "code": 9634 },
    { "industry": "Personal and Other Services", "subIndustry": "Not listed above", "code": 9800 },
    { "industry": "Property and Business Services", "subIndustry": "Accounting Services", "code": 7842 },
    { "industry": "Property and Business Services", "subIndustry": "Advertising Services", "code": 7851 },
    { "industry": "Property and Business Services", "subIndustry": "Architectural Services", "code": 7821 },
    { "industry": "Property and Business Services", "subIndustry": "Business Administrative Services", "code": 7854 },
    { "industry": "Property and Business Services", "subIndustry": "Business Management Services", "code": 7855 },
    { "industry": "Property and Business Services", "subIndustry": "Cleaning Services", "code": 7866 },
    { "industry": "Property and Business Services", "subIndustry": "Commercial Art and Display Services", "code": 7852 },
    { "industry": "Property and Business Services", "subIndustry": "Commercial Property Operators and Developers", "code": 7712 },
    { "industry": "Property and Business Services", "subIndustry": "Computers Consultancy Services", "code": 7834 },
    { "industry": "Property and Business Services", "subIndustry": "Computers Maintenance Services", "code": 7833 },
    { "industry": "Property and Business Services", "subIndustry": "Consulting Engineering Services", "code": 7823 },
    { "industry": "Property and Business Services", "subIndustry": "Contract Packing Services not listed above", "code": 7867 },
    { "industry": "Property and Business Services", "subIndustry": "Contract Staff Services", "code": 7862 },
    { "industry": "Property and Business Services", "subIndustry": "Data Processing Services", "code": 7831 },
    { "industry": "Property and Business Services", "subIndustry": "Employment Placement Services", "code": 7861 },
    { "industry": "Property and Business Services", "subIndustry": "Information Storage and Retrieval Services", "code": 7832 },
    { "industry": "Property and Business Services", "subIndustry": "Legal Services", "code": 7841 },
    { "industry": "Property and Business Services", "subIndustry": "Market Research Services", "code": 7853 },
    { "industry": "Property and Business Services", "subIndustry": "Motor Vehicle Hiring", "code": 7741 },
    { "industry": "Property and Business Services", "subIndustry": "Non-Financial Asset Investors", "code": 7730 },
    { "industry": "Property and Business Services", "subIndustry": "Other Transport Equipment Leasing", "code": 7742 },
    { "industry": "Property and Business Services", "subIndustry": "Pest Control  Services", "code": 7865 },
    { "industry": "Property and Business Services", "subIndustry": "Plant Hiring or Leasing", "code": 7743 },
    { "industry": "Property and Business Services", "subIndustry": "Real Estate Agents", "code": 7720 },
    { "industry": "Property and Business Services", "subIndustry": "Residential Property Operators ", "code": 7711 },
    { "industry": "Property and Business Services", "subIndustry": "Scientific Research", "code": 7810 },
    { "industry": "Property and Business Services", "subIndustry": "Secretarial Services", "code": 7863 },
    { "industry": "Property and Business Services", "subIndustry": "Security and Investigative Services (Except Police)", "code": 7864 },
    { "industry": "Property and Business Services", "subIndustry": "Surveying Services", "code": 7822 },
    { "industry": "Property and Business Services", "subIndustry": "Technical Services not listed above", "code": 7829 },
    { "industry": "Property and Business Services", "subIndustry": "Not listed above", "code": 7869 },
    { "industry": "Retail Trade", "subIndustry": "Antique and Used Good Retailing", "code": 5252 },
    { "industry": "Retail Trade", "subIndustry": "Automotive Electrical Services", "code": 5322 },
    { "industry": "Retail Trade", "subIndustry": "Automotive Fuel Retailing", "code": 5321 },
    { "industry": "Retail Trade", "subIndustry": "Automotive Repair and Services not listed above", "code": 5329 },
    { "industry": "Retail Trade", "subIndustry": "Bread and Cake Retailing", "code": 5124 },
    { "industry": "Retail Trade", "subIndustry": "Car Retailing", "code": 5311 },
    { "industry": "Retail Trade", "subIndustry": "Clothing Retailing", "code": 5221 },
    { "industry": "Retail Trade", "subIndustry": "Department Stores", "code": 5210 },
    { "industry": "Retail Trade", "subIndustry": "Domestic Appliance Retailing", "code": 5234 },
    { "industry": "Retail Trade", "subIndustry": "Domestic Hardware and Houseware Retailing", "code": 5233 },
    { "industry": "Retail Trade", "subIndustry": "Fabric and Other Soft Good Retailing", "code": 5223 },
    { "industry": "Retail Trade", "subIndustry": "Floor Covering Retailing", "code": 5232 },
    { "industry": "Retail Trade", "subIndustry": "Flower Retailing", "code": 5254 },
    { "industry": "Retail Trade", "subIndustry": "Footwear Retailing", "code": 5222 },
    { "industry": "Retail Trade", "subIndustry": "Fresh Meat, Fish and Poultry Retailing", "code": 5121 },
    { "industry": "Retail Trade", "subIndustry": "Fruit and Vegetable Retailing", "code": 5122 },
    { "industry": "Retail Trade", "subIndustry": "Furniture Retailing", "code": 5231 },
    { "industry": "Retail Trade", "subIndustry": "Garden Equipment Retailing", "code": 5253 },
    { "industry": "Retail Trade", "subIndustry": "Household Equipment Repair Services (Electrical)", "code": 5261 },
    { "industry": "Retail Trade", "subIndustry": "Household Equipment Repair Services not listed above", "code": 5269 },
    { "industry": "Retail Trade", "subIndustry": "Liquor Retailing", "code": 5123 },
    { "industry": "Retail Trade", "subIndustry": "Marine Equipment Retailing", "code": 5245 },
    { "industry": "Retail Trade", "subIndustry": "Milk Vending ", "code": 5126 },
    { "industry": "Retail Trade", "subIndustry": "Motor Cycle Dealing", "code": 5312 },
    { "industry": "Retail Trade", "subIndustry": "Newspaper, Book and Stationery Retailing", "code": 5243 },
    { "industry": "Retail Trade", "subIndustry": "Pharmaceutical, Cosmetic and Toiletry Retailing", "code": 5251 },
    { "industry": "Retail Trade", "subIndustry": "Photographic Equipment Retailing", "code": 5244 },
    { "industry": "Retail Trade", "subIndustry": "Recorded Music Retailing", "code": 5235 },
    { "industry": "Retail Trade", "subIndustry": "Retailing not listed above", "code": 5259 },
    { "industry": "Retail Trade", "subIndustry": "Smash Repairing", "code": 5323 },
    { "industry": "Retail Trade", "subIndustry": "Specialised Food Retailing not listed above", "code": 5129 },
    { "industry": "Retail Trade", "subIndustry": "Sport and Camping Equipment Retailing", "code": 5241 },
    { "industry": "Retail Trade", "subIndustry": "Supermarket and Grocery Stores", "code": 5110 },
    { "industry": "Retail Trade", "subIndustry": "Takeaway Food Retailing", "code": 5125 },
    { "industry": "Retail Trade", "subIndustry": "Toy and Game Retailing", "code": 5242 },
    { "industry": "Retail Trade", "subIndustry": "Trailer and Caravan Dealing", "code": 5313 },
    { "industry": "Retail Trade", "subIndustry": "Tyre Retailing", "code": 5324 },
    { "industry": "Retail Trade", "subIndustry": "Watch and Jewellery Retailing", "code": 5255 },
    { "industry": "Retail Trade", "subIndustry": "Not listed above", "code": 5350 },
    { "industry": "Transport and Storage", "subIndustry": "Coastal Water Transport", "code": 6302 },
    { "industry": "Transport and Storage", "subIndustry": "Customs Agency Services", "code": 6644 },
    { "industry": "Transport and Storage", "subIndustry": "Freight Forwarding (Except Road)", "code": 6643 },
    { "industry": "Transport and Storage", "subIndustry": "Grain Storage", "code": 6701 },
    { "industry": "Transport and Storage", "subIndustry": "Inland Water Transport", "code": 6303 },
    { "industry": "Transport and Storage", "subIndustry": "International Sea Transport", "code": 6301 },
    { "industry": "Transport and Storage", "subIndustry": "Long Distance Bus Transport", "code": 6121 },
    { "industry": "Transport and Storage", "subIndustry": "Non-Scheduled Air and Space Transport", "code": 6403 },
    { "industry": "Transport and Storage", "subIndustry": "Parking Services", "code": 6611 },
    { "industry": "Transport and Storage", "subIndustry": "Pipeline Transport", "code": 6501 },
    { "industry": "Transport and Storage", "subIndustry": "Port Operators", "code": 6623 },
    { "industry": "Transport and Storage", "subIndustry": "Rail Transport", "code": 6200 },
    { "industry": "Transport and Storage", "subIndustry": "Road Freight Forwarding ", "code": 6642 },
    { "industry": "Transport and Storage", "subIndustry": "Road Freight Transport", "code": 6110 },
    { "industry": "Transport and Storage", "subIndustry": "Scheduled Domestic Air Transport", "code": 6402 },
    { "industry": "Transport and Storage", "subIndustry": "Scheduled International Air Transport", "code": 6401 },
    { "industry": "Transport and Storage", "subIndustry": "Services to Air Transport", "code": 6630 },
    { "industry": "Transport and Storage", "subIndustry": "Services to Road Transport not listed above", "code": 6619 },
    { "industry": "Transport and Storage", "subIndustry": "Services to Transport not listed above", "code": 6649 },
    { "industry": "Transport and Storage", "subIndustry": "Services to Water Transport not listed above", "code": 6629 },
    { "industry": "Transport and Storage", "subIndustry": "Short Distance Bus Transport (Including Tramway)", "code": 6122 },
    { "industry": "Transport and Storage", "subIndustry": "Stevedoring", "code": 6621 },
    { "industry": "Transport and Storage", "subIndustry": "Taxi and Other Road Passenger Transport", "code": 6123 },
    { "industry": "Transport and Storage", "subIndustry": "Transport not listed above", "code": 6509 },
    { "industry": "Transport and Storage", "subIndustry": "Travel Agency Services", "code": 6641 },
    { "industry": "Transport and Storage", "subIndustry": "Water Transport Terminals", "code": 6622 },
    { "industry": "Transport and Storage", "subIndustry": "Not listed above", "code": 6709 },
    { "industry": "Wholesale Trade", "subIndustry": "Book and Magazine Wholesaling ", "code": 4794 },
    { "industry": "Wholesale Trade", "subIndustry": "Building Supplies Wholesaling not listed above", "code": 4539 },
    { "industry": "Wholesale Trade", "subIndustry": "Business Machine Wholesaling not listed above", "code": 4614 },
    { "industry": "Wholesale Trade", "subIndustry": "Car Wholesaling ", "code": 4621 },
    { "industry": "Wholesale Trade", "subIndustry": "Cereal Grain Wholesaling", "code": 4512 },
    { "industry": "Wholesale Trade", "subIndustry": "Chemical Wholesaling ", "code": 4523 },
    { "industry": "Wholesale Trade", "subIndustry": "Clothing Wholesaling ", "code": 4722 },
    { "industry": "Wholesale Trade", "subIndustry": "Commercial Vehicle Wholesaling", "code": 4622 },
    { "industry": "Wholesale Trade", "subIndustry": "Computer Wholesaling", "code": 4613 },
    { "industry": "Wholesale Trade", "subIndustry": "Confectionery and Soft Drink Wholesaling", "code": 4716 },
    { "industry": "Wholesale Trade", "subIndustry": "Dairy Produce Wholesaling ", "code": 4713 },
    { "industry": "Wholesale Trade", "subIndustry": "Electrical and Electronic Equipment Wholesaling not listed above", "code": 4615 },
    { "industry": "Wholesale Trade", "subIndustry": "Farm and Construction Machinery Wholesaling ", "code": 4611 },
    { "industry": "Wholesale Trade", "subIndustry": "Farm Produce and Supplies Wholesaling not listed above", "code": 4519 },
    { "industry": "Wholesale Trade", "subIndustry": "Fish Wholesaling", "code": 4714 },
    { "industry": "Wholesale Trade", "subIndustry": "Floor Covering Wholesaling ", "code": 4733 },
    { "industry": "Wholesale Trade", "subIndustry": "Footwear Wholesaling ", "code": 4723 },
    { "industry": "Wholesale Trade", "subIndustry": "Fruit and Vegetable Wholesaling ", "code": 4715 },
    { "industry": "Wholesale Trade", "subIndustry": "Furniture Wholesaling ", "code": 4732 },
    { "industry": "Wholesale Trade", "subIndustry": "Grocery Wholesaling not listed above", "code": 4719 },
    { "industry": "Wholesale Trade", "subIndustry": "Household Appliance Wholesaling", "code": 4731 },
    { "industry": "Wholesale Trade", "subIndustry": "Household Good Wholesaling not listed above", "code": 4739 },
    { "industry": "Wholesale Trade", "subIndustry": "Jewellery and Watch Wholesaling ", "code": 4792 },
    { "industry": "Wholesale Trade", "subIndustry": "Liquor Wholesaling", "code": 4717 },
    { "industry": "Wholesale Trade", "subIndustry": "Machinery and Equipment Wholesaling not listed above", "code": 4619 },
    { "industry": "Wholesale Trade", "subIndustry": "Meat Wholesaling ", "code": 4711 },
    { "industry": "Wholesale Trade", "subIndustry": "Metal and Mineral Wholesaling ", "code": 4522 },
    { "industry": "Wholesale Trade", "subIndustry": "Motor Vehicle Dismantling and Used Part Dealing", "code": 4624 },
    { "industry": "Wholesale Trade", "subIndustry": "Motor Vehicle New Part Dealing", "code": 4623 },
    { "industry": "Wholesale Trade", "subIndustry": "Paper Product Wholesaling ", "code": 4795 },
    { "industry": "Wholesale Trade", "subIndustry": "Petroleum Product Wholesaling ", "code": 4521 },
    { "industry": "Wholesale Trade", "subIndustry": "Pharmaceutical and Toiletry Wholesaling", "code": 4796 },
    { "industry": "Wholesale Trade", "subIndustry": "Photographic Equipment Wholesaling", "code": 4791 },
    { "industry": "Wholesale Trade", "subIndustry": "Poultry and Smallgood Wholesaling ", "code": 4712 },
    { "industry": "Wholesale Trade", "subIndustry": "Professional Equipment Wholesaling", "code": 4612 },
    { "industry": "Wholesale Trade", "subIndustry": "Textile Product Wholesaling", "code": 4721 },
    { "industry": "Wholesale Trade", "subIndustry": "Timber Wholesaling ", "code": 4531 },
    { "industry": "Wholesale Trade", "subIndustry": "Tobacco Product Wholesaling", "code": 4718 },
    { "industry": "Wholesale Trade", "subIndustry": "Toy and Sporting Good Wholesaling ", "code": 4793 },
    { "industry": "Wholesale Trade", "subIndustry": "Wool Wholesaling", "code": 4511 },
    { "industry": "Wholesale Trade", "subIndustry": "Not listed above", "code": 4799 }
];

export default industries;