import React from 'react';
'use strict';
var moment = require('moment');


class Day extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: ''
    };

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    if (this.props.onClick)
      this.props.onClick(this.props.day.day);
  }

  render() {
    var todayTimestamp = moment().hour(0).minute(0).second(0).unix();
    var isTodayClass = this.props.day.day.unix() == todayTimestamp ? " today" : "";
    var isHighlightedClass = this.props.isHighlighted ? " highlight" : "";
    var beforeToday = this.props.day.day.unix() < todayTimestamp ? " before-today" : "";

    const isValid = this.props.day.day.isValid();

    return (
      <div onClick={this._onClick} className={this.props.day.classes}>
          <span className={'day-number' + isTodayClass + isHighlightedClass + beforeToday }>{isValid ? this.props.day.day.date() : null}</span>
      </div>
    );
  }
}

module.exports = Day;
