"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDefinedTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
exports.UserDefinedTemplates = [{
        title: 'Enter General Notes',
        id: 'user-defined',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }];
