"use strict";
class Tag {
    constructor(item) {
        this.id = null;
        this.name = null;
        this.code = null;
        this.tag_category_id = null;
        this.isTotal = null;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }
}

module.exports = Tag;