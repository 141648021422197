import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const FieldActions = alt.generateActions(
    'receivedItems',
    'loadingItems',
    'fetchingItemsFailed'
);

module.exports = FieldActions;
