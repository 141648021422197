/* eslint-env browser */
/* eslint-disable no-underscore-dangle */

function init() {
    if (process.env.sessionstackToken) {
        !function (a, b) {
            var c = window;
            c.SessionStack = a, c[a] = c[a] || function () {
                c[a].q = c[a].q || [], c[a].q.push(arguments)
            }, c[a].t = b;
            var d = document.createElement("script");
            d.async = 1, d.src = "https://cdn.sessionstack.com/sessionstack.js";
            var e = document.getElementsByTagName("script")[0];
            e.parentNode.insertBefore(d, e);
        }("sessionstack", process.env.sessionstackToken);

        const appData = {
            app: process.env.sessionstackApplicationName,
            version: process.env.appVersion,
        };

        // identify doesn't appear to work, so we log below as well
        window.sessionstack('identify', appData);
        window.sessionstack('log', appData);
    }
}

function configureUser(account) {
    try {
        if (window.sessionstack) {
            const userData = {
                userId: account.id,
                masquerade: account.masquerade,
            };
            // identify doesn't appear to work, so we log below as well
            window.sessionstack('identify', userData);
            window.sessionstack('log', userData);
        }
    } catch (error) {
        console.warn("couldn't set sessionstack user");
    }
}

module.exports = {
    init,
    configureUser,
};
