// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ForecastScreens from './ForecastScreens.ts';
import ForecastSummaryRow from '../ForecastSummaryRow';
import { VideoLinks } from '../../models/VideoLinks.ts';
import { ToggleSwitch } from '../../components/ToggleSwitch.tsx';

import Utils from '../../utilities/Utils';
import Formatter from '../../Formatter.ts';
import MultiMonthValuesRow from '../MultiMonthValuesRow.tsx';
import Headings from '../generateTwelveMonthHeadings.ts';
import RealtimeCeoCalculator from '../../utilities/RealtimeCeoCalculator.ts';

import ForecastCalculator from '../ForecastCalculatorInstance.ts';
import InfoButton from '../../components/InfoButton.tsx';
import { TagCode } from '../../models/EnumsTs.ts';

const Month = require('../../datastore/models/Month.ts');
const AccountNumberGroup = require('../../datastore/models/AccountNumberGroup.ts');

// interface SummaryRowData {
//     title: string;
//     screen: ForecastScreen;
//     color: string;
//     buttonText: string;
//     description: string;
//     monthlyValues: number[];
//     totalValue?: number;
//     noTotal?: boolean;
// }

export default class SummaryScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMonthlySummary: true,
        };
    }

    getIncomeStatementRows()/*: SummaryRowData[] */ {
        const { forecastMonths, historicalMonths, accountNumberGroups, unevaluatedForecastMonths, calculator } = this.props;

        return [{
            title: 'Aspirational Revenue',
            screen: ForecastScreens.AspirationRevenue,
            color: 'blue',
            buttonText: 'Update Aspirational Targets',
            description: 'Set 12 month and individual month targets',
            // get this value unevaluated as it may change if dating is negative;
            // we want to display the original value if there has not been a formula set
            //the monthly values now need to values for each month to display if selected
            monthlyValues: unevaluatedForecastMonths.map(month => ForecastCalculator.getAspirationalRevenueForMonth(month)),
        }, {
            title: 'Marriage Revenue',
            screen: ForecastScreens.MarriageRevenue,
            color: 'light-blue',
            buttonText: 'Update Marriage',
            description: 'Add newly signed contracts and guaranteed sales',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalMarriageRevenueForMonth(month, accountNumberGroups)),
        }, {
            title: 'Engagement Revenue',
            screen: ForecastScreens.EngagementRevenue,
            color: 'light-blue',
            buttonText: 'Update Engagement',
            description: 'Add new sales leads or potential contracts, or change your view of risk',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalEngagementRevenueForMonth(month, accountNumberGroups)),
        }, {
            title: 'Dating Revenue',
            color: 'light-blue',
            description: 'Revenue not defined as Marriage or Engagement',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalDatingRevenueForMonth(month, accountNumberGroups)),
        }, {
            title: 'Direct Costs',
            screen: ForecastScreens.DirectCosts,
            color: 'red',
            buttonText: 'Update Direct Costs',
            description: 'Set the Direct Costs % of Revenue',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_DirectCosts_TotalDirectCosts)),
        }, {
            title: 'Contribution Margin',
            color: 'grey',
            monthlyValues: forecastMonths.map(month => calculator.getContributionMargin(month)),
        }, {
            title: 'Contribution Margin %',
            color: 'grey',
            isPercentage: true,
            totalValue: calculator.getContributionMarginPercent(forecastMonths),
            monthlyValues: forecastMonths.map(month => calculator.getContributionMarginPercent([month])),
        }, {
            title: 'Indirect Costs',
            screen: ForecastScreens.IndirectCosts,
            color: 'orange',
            buttonText: 'Update Indirect Costs',
            description: 'Change monthly indirect costs, or add a one-off cost',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_IndirectCosts_TotalIndirectCosts)),
        }, {
            title: 'Miscellaneous',
            screen: ForecastScreens.Miscellaneous,
            color: 'indigo',
            buttonText: 'Update Miscellaneous',
            description: 'Update monthly Miscellaneous income and expenses',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_Misc_TotalMisc)),
        }, {
            title: 'Profit',
            color: 'green',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_NetProfit)),
        }, {
            title: 'Profit %',
            color: 'grey',
            isPercentage: true,
            totalValue: calculator.getProfitPercentForPeriod(forecastMonths),
            monthlyValues: forecastMonths.map(month => calculator.getProfitPercent(month)),
        }, {
            title: 'Profit (same month, prior year)',
            color: 'grey',
            monthlyValues: historicalMonths.slice(-12).map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_NetProfit)),
        }, {
            title: 'Distributions',
            screen: ForecastScreens.Distributions,
            color: 'yellow',
            buttonText: 'Update Distributions',
            description: 'Set percentage of profit distributed',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.IS_Distributions_TotalDistributions)),
        }, {
            title: 'Change in Retained Earnings',
            color: 'grey',
            totalValue: calculator.getChangeInRetainedEarnings(forecastMonths),
            monthlyValues: forecastMonths.map(month => calculator.getChangeInRetainedEarnings(month)),
        }];
    }

    getBalanceSheetRows()/*: SummaryRowData[] */ {
        const { forecastMonths } = this.props;

        return [{
            title: 'Accounts Receivable',
            screen: ForecastScreens.AccountsReceivable,
            color: 'blue',
            buttonText: 'Update Accounts Receivable',
            description: 'Set the rate of collection of Accounts Receivable',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision)),
        }, {
            title: 'Inventory',
            screen: ForecastScreens.Inventory,
            color: 'blue',
            buttonText: 'Update Inventory',
            description: 'Set the rate of Inventory usage',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.BS_TotalAssets_InventoryNetOfProvision)),
        }, {
            title: 'Accounts Payable',
            screen: ForecastScreens.AccountsPayable,
            color: 'orange',
            buttonText: 'Update Accounts Payable',
            description: 'Set the rate of paying Accounts Payable',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.BS_TotalLiabilities_TradeAccountsPayable)),
        }, {
            title: 'Fixed Assets',
            screen: ForecastScreens.FixedAssets,
            color: 'blue',
            buttonText: 'Update Fixed Assets',
            description: 'Buy or dispose of Assets',
            monthlyValues: forecastMonths.map(month => ForecastCalculator.getTotalForTagCodeForMonth(month, TagCode.BS_TotalAssets_FixedAssetsNetOfProvision)),
        }];
    }

    getRow(options, rowtype) {
        const { editingEnabled } = this.props;
        const { screen, color, buttonText, title, isPercentage, noTotal, totalValue } = options;

        if (!this.state.showMonthlySummary && noTotal) {
            return null;
        }

        let { monthlyValues } = options;

        const { goToScreen } = this.props;
        const onClick = screen ? () => goToScreen(ForecastScreens[screen]) : null;

        let total = null;

        if (!noTotal) {
            if (totalValue !== undefined) {
                total = totalValue;
            } else if (rowtype === 'income') {
                total = monthlyValues.reduce((accum, sum) => accum + sum, 0);
            } else if (rowtype === 'balance') {
                total = monthlyValues[monthlyValues.length - 1];
            } else {
                console.warn('Incorrect Row Type');
            }
        }

        if (!this.state.showMonthlySummary) {
            monthlyValues = null;
        }

        return (
            <ForecastSummaryRow
                key={title}
                buttonText={buttonText || 'edit'}
                className={color}
                onClick={onClick}
                rowName={title}
                monthlyValues={monthlyValues}
                totalValue={total}
                rowType={isPercentage ? 'percentage' : undefined}
                hasAction={editingEnabled}
            />
        );
    }

    get CheckBox() {
        return (
            <ToggleSwitch
                on={this.state.showMonthlySummary}
                labelLeft="Show Summary By Month"
                onChange={() => this.handleShowMonthlySummary()}
            />
        );
    }

    get HeadingRowForIncomeSection() {
        const finalMonthDisplayState = Formatter.toDisplayShortMonthOnlyFormat(Utils.last(this.props.forecastMonths).Date);

        if (this.state.showMonthlySummary) {
            const { forecastMonths } = this.props;
            const headings = Headings(forecastMonths[0].Date);
            return (
                <div className="forecast-summary-heading-row">
                    <MultiMonthValuesRow
                        columnValues={headings}
                        titleColContent=""
                        totalColumnContent="Total"
                        className="noBorder no-padding"
                    />
                </div>
            );
        }
        return (
            <div className="forecast-summary-header">
                Total of 12 Months trailing up to {finalMonthDisplayState}
            </div>
        );
    }

    get HeadingRowForBalanceSection() {
        const finalMonthDisplayState = Formatter.toDisplayShortMonthOnlyFormat(Utils.last(this.props.forecastMonths).Date);
        if (this.state.showMonthlySummary) {
            const { forecastMonths } = this.props;
            const headings = Headings(forecastMonths[0].Date);
            return (
                <div className="forecast-summary-heading-row">
                    <MultiMonthValuesRow
                        columnValues={headings}
                        titleColContent=""
                        totalColumnContent="Balance"
                        className="noBorder no-padding"
                    />
                </div>
            );
        }
        return (
            <div className="forecast-summary-header">
                Balance at {finalMonthDisplayState}
            </div>
        );
    }

    handleShowMonthlySummary() {
        const state = this.state.showMonthlySummary;

        this.setState({ showMonthlySummary: !state });
    }

    render() {
        const incomeStatementTable = this.getIncomeStatementRows().map(row => this.getRow(row, 'income'));
        const balanceSheetTable = this.getBalanceSheetRows().map(row => this.getRow(row, 'balance'));

        return (
            <div className="SummaryScreen col-xs-12 row">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3 className="inline-container">Income Statement</h3>
                                <span className="pull-right">
                                    <InfoButton videoId={VideoLinks.Forecast.SummaryScreen} />
                                </span>
                                <div className="pull-right" style={{ marginRight: '30px' }}>
                                    {this.CheckBox}
                                </div>
                            </div>
                        </div>
                        <p>Projected 12 month trailing income statement based on forecast assumptions</p>
                        <div className={classNames('col-lg-12', 'col-md-12', 'col-xs-10', 'twelve-month-heading')}>
                            <div className="">
                                <div className="col-xs-11 no-padding">
                                    {this.HeadingRowForIncomeSection}
                                </div>
                            </div>
                        </div>
                        {incomeStatementTable}
                    </div>
                    <div className="col-xs-12"><br /></div>
                    <div className="col-xs-12">
                        <h3>Balance Sheet</h3>
                        <p>Projected operational components of the balance sheet based on forecast assumptions</p>
                        <div className="">
                            <div className={classNames('col-lg-12', 'col-md-12', 'col-xs-10', 'twelve-month-heading')}>
                                <div className="">
                                    <div className="col-xs-11 no-padding">
                                        {this.HeadingRowForBalanceSection}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {balanceSheetTable}
                    </div>
                </div>
            </div>
        );
    }
}

SummaryScreen.propTypes = {
    accountNumberGroups: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberGroup)).isRequired,
    calculator: PropTypes.instanceOf(RealtimeCeoCalculator),
    editingEnabled: PropTypes.bool.isRequired,
    forecastMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)).isRequired,
    goToScreen: PropTypes.func.isRequired,
    historicalMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)).isRequired,
    unevaluatedForecastMonths: PropTypes.arrayOf(PropTypes.instanceOf(Month)),
};
