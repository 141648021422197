import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

import DashboardCalculator from '../utilities/DashboardCalculator';
import Formatter from '../Formatter.ts';
import HeaderRow from './HeaderRow';
import Row from './Row';
import { VideoLinks } from '../models/VideoLinks.ts';

import Month from '../datastore/models/Month.ts';
import AccountNumberTag from '../datastore/models/AccountNumberTag';
import AccountNumberGroup from '../datastore/models/AccountNumberGroup.ts';
import TagCategory from '../datastore/models/TagCategory.ts';


class Report extends React.Component {
    get HeaderRow() {
        const { forecast } = this.props;
        const selectedMonth = this.CurrentMonth;

        return (
            <HeaderRow
                showForecast={forecast}
                showHistoricalSingle
                currentDate={selectedMonth.Date}
            />
        );
    }

    get CurrentMonth() {
        const { selectedDate } = this.props;

        if (selectedDate === null) {
            return null;
        }

        const formattedDate = Formatter.momentToServerDateFormat(selectedDate);

        return this.props.months.find(month =>
            Formatter.momentToServerDateFormat(month.Date) === formattedDate
        );
    }

    get Rows() {
        const month = this.CurrentMonth;
        const { months, revenueSources, tagCategories, accountNumberTags } = this.props;

        if (month === null) {
            return [];
        }

        //count number of months available prior to the selected month
        const firstMonth = months[0];
        const numberOfMonthsToCurrentMonth = month.Date.diff(firstMonth.Date, 'months');

        const calc = new DashboardCalculator(month.Date, numberOfMonthsToCurrentMonth, 12, months, revenueSources, tagCategories, accountNumberTags);
        const singleMonthCalculator = new DashboardCalculator(month.Date, numberOfMonthsToCurrentMonth, 1, months, revenueSources, tagCategories, accountNumberTags);

        const nextYear = month.Date.clone().add(12, 'months');
        let forecastCalc = null;

        if (this.isForecast()) {
            const numberOfMonthsToFinalForecastMonth = numberOfMonthsToCurrentMonth + 12;
            forecastCalc = new DashboardCalculator(nextYear, numberOfMonthsToFinalForecastMonth, 12, months, revenueSources, tagCategories, accountNumberTags);
        }

        const percent = Formatter.toPercent;
        const decimal = Formatter.toDecimal;
        const number = Formatter.roundNumberWithCommas;

        /**
         * @param {DashboardCalculator} calculator
         * @return {*[]}
         */
        const getRows = calculator => {
            const isSingleMonth = calculator.periodLength === 1;

            return [
                {
                    name: 'Core Revenue',
                    style: 'secondary',
                    value: number(calculator.Revenue),
                },
                {
                    name: 'Percentage change to previous period',
                    style: 'tertiary',
                    value: percent(calculator.RevenuePercentageChangeOnPreviousYear),
                },
                {
                    name: '(Increase) / Reduction in Accounts Receivable',
                    style: 'secondary',
                    value: number(calculator.IncreaseReductionInAccountsReceivable),
                },
                {
                    name: 'Core Money In',
                    style: 'primary',
                    value: number(calculator.SalesCashIn),
                },
                {
                    name: 'Core Direct Costs (excluding depreciation)',
                    style: 'secondary',
                    value: number(calculator.DirectCostsExcludingDepreciation),
                },
                {
                    name: 'Percentage change to previous period',
                    style: 'tertiary',
                    value: percent(calculator.DirectCostsExcludingDepreciationPercentChangeOnPreviousYear),
                },
                {
                    name: 'Increase / (Reduction) in Inventory',
                    style: 'secondary',
                    value: number(calculator.IncreaseReductionInventory),
                },
                {
                    name: '(Increase) / Reduction in Account Payable',
                    style: 'secondary',
                    value: number(calculator.IncreaseReductionAccountPayable),
                },
                {
                    name: 'Core Direct Cash Out',
                    style: 'primary',
                    value: number(calculator.DirectCashOut),
                },
                {
                    name: 'Cash Contribution Margin',
                    style: 'primary',
                    value: number(calculator.CashContributionMargin),
                },
                {
                    name: 'Core Indirect Costs (excluding depreciation)',
                    style: 'secondary',
                    value: number(calculator.IndirectCostsExcludingDepreciationAndAmortisation),
                },
                {
                    name: 'Percentage change to previous period',
                    style: 'tertiary',
                    value: percent(calculator.IndirectCostsExcludingDepreciationAndAmortisationPercentChangeOnPreviousYear),
                },
                {
                    name: 'Net Miscellaneous Revenue & Expense (excluding depreciation)',
                    style: 'secondary',
                    value: number(calculator.NetMiscellaneous),
                },
                //{
                //    name: 'Miscellaneous Revenue',
                //    style: 'secondary',
                //    historical: number(calculator.MiscellaneousRevenue), // 288,931,
                //    'forecast': number(forecastCalc.MiscellaneousRevenue) // 325,575
                //},
                //{
                //    name: 'Miscellaneous Expenses',
                //    style: 'secondary',
                //    historical: number(calculator.MiscellaneousExpenses), // 231,145,
                //    'forecast': number(forecastCalc.MiscellaneousExpenses) // 260,460
                //},
                {
                    name: 'Cash from / (Cash to) Misc Operating Balance Sheet Movements',
                    style: 'secondary',
                    value: number(calculator.CashFromCashToMiscOperatingBalanceSheetMovements),
                },
                {
                    name: 'Operational Cash Flow (OCF)',
                    style: 'primary',
                    value: number(calculator.OperationalCashFlow), // 533,047, // 3.41%,
                    percentage: percent(calculator.OperationalCashFlowPercent),
                    videoId: VideoLinks.Dashboard.OperationalCashFlow,
                },
                {
                    name: 'Operational Profit %',
                    style: 'primary',
                    value: number(calculator.OperationalProfit), // 491,682,
                    percentage: percent(calculator.OperationalProfitPercent),
                    videoId: VideoLinks.Dashboard.OperationalProfit,
                },
                // hide ROO and Leverage for single month, it confuses people
                {
                    name: 'Leverage',
                    style: 'primary',
                    value: isSingleMonth ? ' ' : decimal(calculator.Leverage),
                    videoId: VideoLinks.Dashboard.Leverage
                },
                {
                    name: 'Return on Operations (ROO) %',
                    style: 'primary',
                    value: isSingleMonth ? ' ' : percent(calculator.RooPercent),
                    videoId: VideoLinks.Dashboard.ROO,
                },
                {
                    name: 'Total Salaries & Wages',
                    style: 'primary',
                    value: number(calculator.TotalSalaries),
                },
                {
                    name: 'Salary & Wage Multiple',
                    style: 'primary',
                    value: decimal(calculator.SalaryMultiple),
                    videoId: VideoLinks.Dashboard.SalaryMultiple,
                },
                {
                    name: 'Total Raw Materials',
                    style: 'primary',
                    value: number(calculator.TotalRawMaterials),
                },
                {
                    name: 'Raw Material Multiple',
                    style: 'primary',
                    value: decimal(calculator.RawMaterialMultiple),
                    videoId: VideoLinks.Dashboard.RawMaterialMultiple,
                },
                {
                    name: 'Operational Investment',
                    style: 'primary',
                    value: number(calculator.OperationalInvestment),
                    videoId: VideoLinks.Dashboard.OperationalInvestment,
                },
            ];
        };

        const historicalRows = getRows(calc);
        const historicalSingleRows = getRows(singleMonthCalculator);
        let forecastRows = null;

        if (this.isForecast()) {
            forecastRows = getRows(forecastCalc);
        }

        const rows = historicalRows.map((historicalRow, index) => {
            const { name, style, videoId } = historicalRow;
            const historicalSingleRow = historicalSingleRows[index];

            const row = {
                name,
                style,
                leftData: {
                    value: historicalRow.value,
                    percentage: historicalRow.percentage,
                },
                centerData: {
                    value: historicalSingleRow.value,
                    percentage: historicalSingleRow.percentage,
                },
                rightData: null,
                videoId,
            };

            if (forecastRows !== null) {
                const forecastRow = forecastRows[index];
                row.rightData = {
                    value: forecastRow.value,
                    percentage: forecastRow.percentage,
                };
            }

            return row;
        });

        return rows.map((row, index) => <Row key={index} {...row} />);
    }

    isForecast() {
        return this.props.forecast === true;
    }

    render() {
        return (
            <div className="col-xs-12 dashboard-report">
                {this.HeaderRow}
                {this.Rows}
            </div>
        );
    }
}

Report.propTypes = {
    accountNumberTags: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberTag)).isRequired,
    forecast: PropTypes.bool.isRequired,
    months: PropTypes.arrayOf(PropTypes.instanceOf(Month)).isRequired,
    revenueSources: PropTypes.arrayOf(PropTypes.instanceOf(AccountNumberGroup)).isRequired,
    selectedDate: MomentPropTypes.momentObj.isRequired,
    tagCategories: PropTypes.arrayOf(PropTypes.instanceOf(TagCategory)).isRequired,
};

export default Report;
