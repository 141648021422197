// @ts-check

/**
 * @typedef { import("moment").Moment } Moment
 * @typedef { import('../datastore/models/Month') } Month
 * @typedef { import('../datastore/models/AccountNumberGroup') } AccountNumberGroup
 * @typedef { import('../datastore/models/TagCategory') } TagCategory
 * @typedef { import('../datastore/models/AccountNumberTag') } AccountNumberTag
 */

import DashboardValuesFromStores from './DashboardValuesFromStores.ts';
import { TagCode } from '../models/EnumsTs';

class DashboardSource {
    /**
     *
     * @param {Moment} selectedDate
     * @param {number} periodLength
     * @param {Month[]} months
     * @param {AccountNumberGroup[]} revenueSources
     * @param {TagCategory[]} tagCategories
     * @param {AccountNumberTag[]} accountNumberTags
     */
    constructor(selectedDate, periodLength, months, revenueSources, tagCategories, accountNumberTags) {
        this.periodLength = periodLength || 12;
        this.selectedDate = selectedDate; // All the stored values should be derived from this.

        this.remote = new DashboardValuesFromStores(selectedDate, periodLength, months, revenueSources, tagCategories, accountNumberTags);

        if (this.selectedDate === null) {
            console.error("Dashboard Datasource must have a valid moment() supplied.");
        }
    }

    /**
     * DirectCostsLastYear
     * LedgerType: Income Statement
     * Reference: L87 == AS200
     * Spreadsheet Value: 10627349
     *  10,627,349
     */
    get DirectCostsRollingLastYear() {
        return this.TotalMaterialsDirectCostsRollingLastYear
            + this.TotalWagesDirectCostsForRollingLastYear
            + this.TotalDepreciationDirectCostsRollingLastYear
            + this.TotalOtherDirectCostsRollingLastYear;
        // return 10627349
    }

    /**
     * IndirectCostsLastYear
     * LedgerType: Income Statement
     * Reference: L87 == AS209
     * Spreadsheet Value: 2832131
     */
    get IndirectCostsRollingLastYear() {
        return this.TotalWagesIndirectCostsRollingLastYear
            + this.TotalDepreciationIndirectCostsRollingLastYear
            + this.TotalOtherIndirectCostsRollingLastYear;
        // return 2832131
    }

    /**
     * Total Materials in Direct Cost
     * LedgerType: Income Statement
     * Reference: AS196
     * Spreadsheet Value: 7036555
     */
    get TotalMaterialsDirectCostsRollingLastYear() {
        // Sum of Row 43 (Mar 2013 to Feb 2014)
        // return 7036555

        // TODO: Get the sum of all total materials in direct costs 12 months from Last year, till last year.
        // Eg. Given that the current month is Feb 15, we need to the sum of Mar 13 - Feb 14.
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_DirectCosts_Materials]);
    }

    /**
     * Total Wages in Direct Cost
     * LedgerType: Income Statement
     * Reference: AS197
     * Spreadsheet Value: 3518277
     */
    get TotalWagesDirectCostsForRollingLastYear() {
        // Sum of Row 51 (Mar 2013 to Feb 2014)
        // return 3518277
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_DirectCosts_Wages]);
    }

    /**
     * Total Depreciation in Direct Cost
     * LedgerType: Income Statement
     * Reference: AS198
     * Spreadsheet Value: 30000
     */
    get TotalDepreciationDirectCostsRollingLastYear() {
        // Sum of Row 57 (Mar 2013 to Feb 2014)
        // return 30000
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_DirectCosts_Depreciation]);
    }

    /**
     * Total Other in Direct Cost
     * LedgerType: Income Statement
     * Reference: AS199
     * Spreadsheet Value: 42517
     */
    get TotalOtherDirectCostsRollingLastYear() {
        // Sum of Row 66 (Mar 2013 to Feb 2014)
        // return 42517
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_DirectCosts_Other]);
    }

    /**
     * Total Wages in Indirect Cost
     * LedgerType: Income Statement
     * Reference: AS206
     * Spreadsheet Value: 847023
     */
    get TotalWagesIndirectCostsRollingLastYear() {
        // Sum of Row 78 (Mar 2013 to Feb 2014)
        // return 847023
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_IndirectCosts_Wages]);
    }

    /**
     * Total Depreciation in Indirect Cost
     * LedgerType: Income Statement
     * Reference: AS207
     * Spreadsheet Value: 60000
     */
    get TotalDepreciationIndirectCostsRollingLastYear() {
        // Sum of Row 84 (Mar 2013 to Feb 2014)
        //return 60000
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_IndirectCosts_Depreciation]);
    }

    /**
     * Total Other in Indirect Cost
     * LedgerType: Income Statement
     * Reference: AS208
     * Spreadsheet Value: 1925108
     */
    get TotalOtherIndirectCostsRollingLastYear() {
        // Sum of Row 91 (Mar 2013 to Feb 2014)
        //return 1925108
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_IndirectCosts_Other])
    }

    /**
     * Total Assets Opening Balance
     * Reference: BE118
     * Spreadsheet Value: 7625096
     */
    get TotalAssetsOpeningBalance() {
        return 0;
//        return this.remote.TotalAssetsOpeningBalance;
    }

    /**
     * Total Assets Opening Balance Last Year
     * Reference: AS118
     * Spreadsheet Value: 6766886
     */
    get TotalAssetsOpeningBalanceLastYear() {
        return 0
//        return this.remote.TotalAssetsOpeningBalanceLastYear;
    }

    /**
     * Total Liabilities
     * Reference: BE134
     * Spreadsheet Value: 5454974
     */
    get TotalLiabilities() {
        return this.remote.TotalLiabilities;
    }

    /**
     * Total Liabilities Last Year
     * Reference: AS134
     * Spreadsheet Value: 4559476
     */
    get TotalLiabilitiesLastYear() {
        return this.remote.TotalLiabilitiesLastYear;
    }

    // Get the balance sheet for the selected month, 1 year ago
    get BalanceSheetSameMonthLastYear() {
        return this.remote.BalanceSheetSameMonthLastYear;
    }

    /**
     * Trailing Revenue, Forecast Revenue
     * Ledger Type: Income Statement
     * Reference: BE193
     * Spreadsheet Value: 15631272
     */
    get TotalRevenueThisYearRolling() {
        // Sum of Row 12 (Mar 2014 to Feb 2015)
        //return 15631272
        return this.remote.TotalRevenueThisYearRolling;
    }

    get TotalRevenueNextYearRolling() {
        return this.remote.CalculateSumOfTagsNextYearRolling(this.remote.RevenueTags);
    }

    /**
     * Revenue Last Year
     * Reference: L74
     * Spreadsheet Value: 14073109
     */
    get TotalRevenueLastYearRolling() {
        // Sum of Row 12 (Mar 2013 to Feb 2014)
        //return 14073109
        return this.remote.CalculateSumOfTagsLastYearRolling([
            TagCode.IS_Revenue_Revenue,
            TagCode.IS_Revenue_Other,
            //TagCode.IS_Revenue_InterestRevenue
        ]);
    }

    /**
     * less 12 month movement in Accounts Receivable
     * Reference: BE240
     * Spreadsheet Value:  543401
     */
    get Less12MonthMovementInAccountsReceivable() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision])
            - this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision]);
    }

    /**
     * Trade Accounts receivable (net of provision) (Feb 2015)
     * LedgerType: Balance Sheet
     * Reference: BE122
     * Spreadsheet Value: 3321269
     */
    get TradeAccountsReceivable() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision]);
    }

    /**
     * Trade Accounts receivable (net of provision) (Feb 2014)
     * LedgerType: Balance Sheet
     * Reference: AS122
     * Spreadsheet Value: 2777867
     */
    get TradeAccountsReceivableSameMonthLastYear() {
        return this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision]);
    }

    /**
     * Total Depreciation in Direct Costs (Mar 2014 - Feb 2015)
     * LedgerType: Income Statement
     * Reference: BE198
     * Spreadsheet Value: 30000
     */
    get TotalDepreciationDirectCostsThisYearRolling() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Depreciation]);
    }

    /**
     * Total Direct Costs
     * LedgerType: Income Statement
     * Reference: BE200
     * Spreadsheet Value: 11794738
     */
    get TotalDirectCostsThisYearRolling() {
        return this.TotalMaterialsDirectCostsThisYearRolling
            + this.TotalWagesDirectCostsThisYearRolling
            + this.TotalDepreciationDirectCostsThisYearRolling
            + this.TotalOtherDirectCostsThisYearRolling;
    }

    get TotalDirectCostsLastYearRolling() {
        return this.TotalMaterialsDirectCostsLastYearRolling
            + this.TotalWagesDirectCostsLastYearRolling
            + this.TotalDepreciationDirectCostsLastYearRolling
            + this.TotalOtherDirectCostsLastYearRolling;
    }

    //get TotalDirectCostsNextYearRolling() {
    //    return this.TotalMaterialsDirectCostsNextYearRolling
    //        + this.TotalWagesDirectCostsNextYearRolling
    //        + this.TotalDepreciationDirectCostsNextYearRolling
    //        + this.TotalOtherDirectCostsNextYearRolling;
    //}

    //get TotalMaterialsDirectCostsNextYearRolling() {
    //    return this.remote.getMaterialsDirectCostsRollingNextYear();
    //}
    //
    //get TotalWagesDirectCostsNextYearRolling() {
    //    return this.remote.getWagesDirectCostsRollingNextYear();
    //}
    //
    //get TotalDepreciationDirectCostsNextYearRolling() {
    //    return this.remote.getDepreciationDirectCostsRollingNextYear();
    //}

    //get TotalOtherDirectCostsNextYearRolling() {
    //    return this.remote.getTotalDirectCostsThisYearRolling([TagCode.IS_DirectCosts_Wages]);
    //    return this.remote.CalculateSumOfTagsNextYearRolling([TagCode.IS_DirectCosts_Other]);
    //}


    /**
     * Total Materials in Direct Costs
     * LedgerType: Income Statement
     * Reference: BE196
     * Spreadsheet Value: 7815636
     */
    get TotalMaterialsDirectCostsThisYearRolling() {
        return this.remote.getTotalDirectCostsThisYearRolling([TagCode.IS_DirectCosts_Materials]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Materials]);
    }

    /**
     * Total Wages in Direct Costs
     * LedgerType: Income Statement
     * Reference: BE197
     * Spreadsheet Value: 3907818
     */
    get TotalWagesDirectCostsThisYearRolling() {
        return this.remote.getTotalDirectCostsThisYearRolling([TagCode.IS_DirectCosts_Wages]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Wages]);
    }

    /**
     * Total Other Direct Costs
     * LedgerType: Income Statement
     * Reference: BE199
     * Spreadsheet Value: 41284
     */
    get TotalOtherDirectCostsThisYearRolling() {
        return this.remote.getTotalDirectCostsThisYearRolling([TagCode.IS_DirectCosts_Other]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Other]);
    }



    get TotalMaterialsDirectCostsLastYearRolling() {
        return this.remote.getTotalDirectCostsLastYearRolling([TagCode.IS_DirectCosts_Materials]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Materials]);
    }

    get TotalWagesDirectCostsLastYearRolling() {
        return this.remote.getTotalDirectCostsLastYearRolling([TagCode.IS_DirectCosts_Wages]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Wages]);
    }

    get TotalOtherDirectCostsLastYearRolling() {
        return this.remote.getTotalDirectCostsLastYearRolling([TagCode.IS_DirectCosts_Other]);
        //return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_DirectCosts_Other]);
    }

    get TotalDepreciationDirectCostsLastYearRolling() {
        return this.remote.CalculateSumOfTagsLastYearRolling([TagCode.IS_DirectCosts_Depreciation]);
    }


    /**
     * less 12 month movement in Inventory
     * LedgerType: Balance Sheet Value
     * Reference: BE241
     * Spreadsheet Value: 98933
     */
    get Less12MonthMovementInInventory() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_InventoryNetOfProvision])
            - this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalAssets_InventoryNetOfProvision]);
    }

    /**
     * add 12 month movement in Accounts Payable
     * LedgerType: Balance Sheet Value
     * Reference: BE242
     * Spreadsheet Value: 368666
     */
    get Add12MonthMovementInAccountsPayable() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_TradeAccountsPayable])
             - this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalLiabilities_TradeAccountsPayable]);
    }

    /**
     * Total Indirect Costs
     * LedgerType: Income Statement
     * Reference: BE209
     * Spreadsheet Value:  3402639
     */
    get TotalIndirectCostsThisYearRolling() {
        return this.TotalWagesIndirectCostsThisYearRolling
            + this.TotalDepreciationIndirectCostsThisYearRolling
            + this.TotalOtherIndirectCostsThisYearRolling
    }

    /**
     * Total Indirect Costs Wages
     * LedgerType: Income Statement
     * Reference: BE206
     * Spreadsheet Value: 954458
     */
    get TotalWagesIndirectCostsThisYearRolling() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_IndirectCosts_Wages]);
    }

    /**
     * Total Indirect Costs Depreciation
     * LedgerType: Income Statement
     * Reference: BE207
     * Spreadsheet Value: 60000
     */
    get TotalDepreciationIndirectCostsThisYearRolling() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_IndirectCosts_Depreciation]);
    }
    /**
     * Total Indirect Costs Other
     * LedgerType: Income Statement
     * Reference: BE208
     * Spreadsheet Value: 2388180
     */
    get TotalOtherIndirectCostsThisYearRolling() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_IndirectCosts_Other]);
    }

    get TotalWagesMiscThisYearRolling() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Wages]);
    }


    //get TotalIndirectCostsNextYearRolling() {
    //    return this.TotalWagesIndirectCostsNextYearRolling
    //        + this.TotalDepreciationIndirectCostsNextYearRolling
    //        + this.TotalOtherIndirectCostsNextYearRolling
    //}
    //
    //get TotalDepreciationIndirectCostsNextYearRolling() {
    //    return this.remote.CalculateSumOfTagsNextYearRolling([TagCode.IS_IndirectCosts_Depreciation]);
    //}
    //
    //get TotalWagesIndirectCostsNextYearRolling() {
    //    return this.remote.CalculateSumOfTagsNextYearRolling([TagCode.IS_IndirectCosts_Wages]);
    //}
    //
    //get TotalOtherIndirectCostsNextYearRolling() {
    //    return this.remote.CalculateSumOfTagsNextYearRolling([TagCode.IS_IndirectCosts_Other]);
    //}

    /**
     * Total Miscellaneous Revenue
     * LedgerType: Income Statement
     * Reference: BE211
     * Spreadsheet Value: 288931
     */
    //get TotalMiscellaneousRevenue() {
    //    return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Revenue]);
    //}

    /**
     * Total Miscellaneous Expenses
     * LedgerType: Income Statement
     * Reference: BE212
     * Spreadsheet Value: 231145
     */
    //get TotalMiscellaneousExpenses() {
    //    return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Expense]);
    //}

    /**
     * Cash movement from Other BS items
     * LedgerType: Balance Sheet
     * Reference: BE246
     * Spreadsheet Value: 225034
     */
    get CashMovementFromOtherBsItems() {
        return this.MovementInOtherOperatingLiabilities - this.MovementInOtherOperatingAssets;
    }

    /**
     * Movement in Other Operating Assets
     * LedgerType: Balance Sheet
     * Reference: BE244
     * Spreadsheet Value: 216660
     */
    get MovementInOtherOperatingAssets() {
        // NOTE: We can't calculate this as we don't have a "Total Assets Opening Balance" value. We only have the sum of assets.
        return this.ValueOfAllOtherAssetsNotSplitOutAbove - this.ValueOfAllOtherAssetsNotSplitOutAboveLastYear;
    }

    /**
     * Value of all other assets not split out above
     * LedgerType: Balance Sheet
     * Reference: BE132
     * Spreadsheet Value: 1298243
     */
    get ValueOfAllOtherAssetsNotSplitOutAbove() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_Other])
    }

    /**
     * Value of all other assets not split out above Last Year
     * LedgerType: Balance Sheet
     * Reference: AS132
     * Spreadsheet Value: 1081584
     */
    get ValueOfAllOtherAssetsNotSplitOutAboveLastYear() {
        return this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalAssets_Other]);
    }

    /**
     * Movement in Other Operating Liabilities
     * LedgerType: Balance Sheet
     * Reference: BE245
     * Spreadsheet Value: 441693
     */
    get MovementInOtherOperatingLiabilities() {
        return this.ValueOfAllOtherLiabilitiesNotSplitOutAbove - this.ValueOfAllOtherLiabilitiesNotSplitOutAboveLastYear;
    }

    /**
     * Value of all other liabilities not split out above
     * LedgerType: Balance Sheet
     * Reference: BE156
     * Spreadsheet Value: 1593533
     */
    get ValueOfAllOtherLiabilitiesNotSplitOutAbove() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_Other]);
    }

    /**
     * Value of all other liabilities not split out above Last Year
     * LedgerType: Balance Sheet
     * Reference: AS156
     * Spreadsheet Value: 1151839
     */
    get ValueOfAllOtherLiabilitiesNotSplitOutAboveLastYear() {
        return this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalLiabilities_Other]);
    }

    /**
     * Movement in Other Operating Liabilities
     * LedgerType: Balance Sheet
     * Reference: BE245
     * Spreadsheet Value: 441693
     */
    get MovementInOtherOperatingEquity() {
        return this.ValueOfAllOtherEquityNotSplitOutAbove - this.ValueOfAllOtherEquityNotSplitOutAboveLastYear;
    }

    /**
     * Value of all other equity not split out above
     * LedgerType: Balance Sheet
     */
    get ValueOfAllOtherEquityNotSplitOutAbove() {
        return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalEquity_InitialCapital]) // BE136
            + this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalEquity_RetainedEarnings]) // BE138
            + this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalEquity_Other]); // BE140
    }

    /**
     * Value of all other equity not split out above Last Year
     * LedgerType: Balance Sheet
     */
    get ValueOfAllOtherEquityNotSplitOutAboveLastYear() {
        return this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalEquity_InitialCapital]) // AS136
            + this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalEquity_RetainedEarnings]) // AS138
            + this.remote.CalculateSumOfTagsLastYear([TagCode.BS_TotalEquity_Other]); // AS140
    }

    /**
     * Contribution Margin
     * LedgerType: Income Statement
     * Reference: BE202
     * Spreadsheet Value: 3836534
     */
    get ContributionMargin() {
        return this.TotalRevenueThisYearRolling - this.TotalDirectCostsThisYearRolling;
    }

    /**
     * Operating Profit
     * LedgerType: Income Statement
     * Reference: BE214
     * Spreadsheet Value: 491682
     */
    get OperatingProfit() {
        return this.ContributionMargin - this.TotalIndirectCostsThisYearRolling + this.NonInterestMiscellaneous;
        //this.TotalMiscellaneousRevenue - this.TotalMiscellaneousExpenses;
    }

    get NonInterestMiscellaneous() {
        return this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Other])
            - this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Depreciation])
            - this.remote.CalculateSumOfTagsThisYearRolling([TagCode.IS_Misc_Wages]);
    }

    get NetMiscellaneous() {
        return this.remote.CalculateSumOfTagsThisYearRolling(DashboardValuesFromStores.PositiveNetMiscellaneousTags)
            - this.remote.CalculateSumOfTagsThisYearRolling(DashboardValuesFromStores.NegativeNetMiscellaneousTags);
    }

    /**
     * Total Salaries
     * LedgerType: Income Statement
     * Reference: BE232
     * Spreadsheet Value: 4862276
     */
    get TotalSalaries() {
        return this.TotalWagesDirectCostsThisYearRolling + this.TotalWagesIndirectCostsThisYearRolling + this.TotalWagesMiscThisYearRolling;
    }

    get TotalWagesIndirectCosts() {
        return this.TotalWagesIndirectCostsThisYearRolling;
    }

    /**
     * Total Raw Materials
     * LedgerType: Income Statement
     * Reference: BE234
     * Spreadsheet Value: 7815636
     */
    get TotalRawMaterials() {
        return this.TotalMaterialsDirectCostsThisYearRolling;
    }

    /**
     * NOA
     * LedgerType: Balance Sheet
     * Reference: BE254
     * Spreadsheet Value: 2253392
     */
    get Noa() {
        let val = this.OperatingAssets - this.OperatingLiabilities;
        return val;
    }

    /**
     * Operating Assets
     * LedgerType: Balance Sheet
     * Reference: BE252
     * Spreadsheet Value: 7122478
     */
    get OperatingAssets() {
        // Changed to reflect actual calculation (we dont know if totals are correct)

        // return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_TotalAssets])
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_BankAccountsAndCashDeposits])
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_NonInterestBearingLoansToRelatedParties])
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalAssets_InterestBearingLoansToRelatedParties]);

        return this.remote.CalculateSumOfTagsThisMonth(DashboardValuesFromStores.OperatingAssetsTagCodes);

    }

    /**
     * Operating Liabilities
     * LedgerType: Balance Sheet
     * Reference: BE253
     * Spreadsheet Value: 4869085
     */
    get OperatingLiabilities() {
        // Changed to reflect actual calculation (we dont know if totals are correct)
        // return this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_TotalLiabilities])
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_BankLiability]) // BE136
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_OtherInterestBearingShortTermLiabilities]) // BE138
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_TaxPayable]) // BE142
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_InterestPayable]) // BE144   (Wasnt included as of 2 May 2016)
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_DividendsPayable]) // BE146
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_DeferredPrepaidRevenue]) // BE148
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_LongTermInterestBearingLiabilities]) // BE150
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_NonInterestBearingLoansFromRelatedParties]) // BE152
        //     - this.remote.CalculateSumOfTagsThisMonth([TagCode.BS_TotalLiabilities_InterestBearingLoansFromRelatedParties]) // BE154

        return this.remote.CalculateSumOfTagsThisMonth(DashboardValuesFromStores.OperatingLiabilitiesTagCodes);
    }

    /**
     * Leverage
     * LedgerType:
     * Reference: BE255
     * Spreadsheet Value: 6.94
     */
    get Leverage() {
        // Leverage is always displayed as a 12 month value
        return this.TotalRevenueThisYearRolling * 12 / this.periodLength / this.Noa;
    }

    /**
     * Operating Profit %
     * LedgerType:
     * Reference: BE215
     * Spreadsheet Value: 3%
     */
    get OperatingProfitPercent() {
        return this.OperatingProfit / this.TotalRevenueThisYearRolling;
    }

    /**
     * ROO %
     * LedgerType:
     * Reference: BE256
     * Spreadsheet Value: 0.22
     */
    get RooPercent() {
        return this.OperatingProfitPercent * this.Leverage;
    }
}

export default DashboardSource;
