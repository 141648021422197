var Tag = require('../models/Tag');
var Actions = require('../actions/TagActions');
var Ajax = require('../endpoints/ajax');

var TagSource = {
    fetchTag() {
        return {
            remote(state, tagId) {
                return Ajax.getTag(tagId);
            },

            local() {
                return null;
            },

            success: Actions.receivedItem,
            loading: Actions.loadingItem,
            error: Actions.fetchingItemFailed
        }
    }
};

module.exports = TagSource;
