import { TagCode } from './EnumsTs.ts'
import { VideoLinks } from './VideoLinks.ts'

class MenuItem {
    constructor(id, name, icon, visible) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.visible = visible;
    }
}

let uploadTypes = {
    Single: "single",
    Multiple: "multiple"
};

let csvType = {
    BalanceSheet: "BalanceSheet",
    ProfitLoss: "ProfitLoss",
    GeneralLedger: "GeneralLedger"
};

var inputTypes = {
    Import: "Import",
    Manual: "Manual"
};

var splitTypes = {
    Percentage: "Percentage",
    Offset: "Offset"
};

var fields = {
    IncomeStatement: {
        AccountNumber: "IS_NUMBER",
        AccountName: "IS_NAME",
        ValueForTheMonth: "VALUE"
    },
    BalanceSheet: {
        AccountNumber: "BS_NUMBER",
        AccountName: "BS_NAME",
        ValueAtMonthEnd: "BALANCE"
    }
};

const specialAccountItems = {
    AutomaticallyAllocated: {
        Name: "Untagged Operational",
        Number: "Automatic",
    },
    AdditionalDistributions: {
        Name: "Additional Distributions",
        Number: "AdditionalDistributions",
    },
    Dating: {
        Name: 'Dating',
    },
    NetProfit: {
        Name: "Net Profit",
        Number: "NetProfit",
    },
    NetProfitRemainder: {
        Name: "Remainder from Net Profit",
        Number: "AutomaticNetProfitRemainder",
    },
    //used in manual onboarding
    TotalOtherIndirectCosts: {
        Name: "Other",
        Number: "Temp Total ISINDROTR",
    },
    RetainedEarningsRollForward: {
        Name: 'Rounding',
        Number: 'RetainedEarningRollForward',
    },
    //direct costs - used for balancing non business expenses adjustments
    DirectNonBusinessAdjustments: {
        Name: 'Non Business Adjustments',
        Number: 'DirectNonBusinessAdjustments',
    },
    //indirect costs - used for balancing non business expenses adjustments
    IndirectNonBusinessAdjustments: {
        Name: 'Non Business Adjustments',
        Number: 'IndirectNonBusinessAdjustments',
    },
    //misc - used for balancing non business expenses adjustments
    MiscNonBusinessAdjustments: {
        Name: 'Non Business Adjustments',
        Number: 'MiscNonBusinessAdjustments',
    },
    //distributions - used for balancing non business expenses and wages adjustments
    DistributionsNonBusinessAdjustments: {
        Name: 'Non Business Adjustments',
        Number: 'DistributionsNonBusinessAdjustments',
    },
    // generated in new NOS calculation
    DirectNos: {
        Name: 'NOS (Direct)',
        Number: 'DirectNormalisationOfOwnsersSalary',
    },
    IndirectNos: {
        Name: 'NOS (Indirect)',
        Number: 'IndirectNormalisationOfOwnsersSalary',
    },
    MiscNos: {
        Name: 'NOS (Misc)',
        Number: 'MiscNormalisationOfOwnsersSalary',
    },
    RevenueNba: {
        Name: 'NBA (Revenue)',
        Number: 'RevenueNBA',
    },
    DirectNba: {
        Name: 'NBA (Direct)',
        Number: 'DirectNBA',
    },
    IndirectNba: {
        Name: 'NBA (Indirect)',
        Number: 'IndirectNBA',
    },
    MiscNba: {
        Name: 'NBA (Misc)',
        Number: 'MiscNBA',
    },
};

const alternateTagNames = {
    UserDefinedRevenue: "Revenue (user defined)",
    UserDefinedOther: "Other (user defined)",
};

const importRenamedTags = {
    [TagCode.IS_Revenue_Revenue]: alternateTagNames.UserDefinedRevenue,
    [TagCode.IS_IndirectCosts_Other]: alternateTagNames.UserDefinedOther,
    [TagCode.IS_DirectCosts_Other]: alternateTagNames.UserDefinedOther,
    [TagCode.BS_TotalAssets_Other]: alternateTagNames.UserDefinedOther,
    [TagCode.BS_TotalLiabilities_Other]: alternateTagNames.UserDefinedOther,
    [TagCode.BS_TotalEquity_Other]: alternateTagNames.UserDefinedOther,
};

const importTagSubSections = {
    HeaderTag: "Header Tag",
    SubTags: "Sub Tags",
    OptionalTags: "Optional Tag",
};

const LedgerName = {
    IncomeStatement: 'Income Statement',
    BalanceSheet: 'Balance Sheet',
};

var ledgerTypes = {
    IncomeStatement: { name: LedgerName.IncomeStatement },
    BalanceSheet: { name: LedgerName.BalanceSheet }
};

var tagCategoryName = {
    Revenue: "Revenue",
    DirectCosts: "Direct Costs",
    IndirectCosts: "Indirect Costs",
    Misc: "Misc", 
    Distributions: "Distributions",
    TotalAssets: "Total Assets", 
    TotalLiabilities: "Total Liabilities",
    TotalEquity: "Total Equity",
    Profit: "Profit"
};

var tagName = {
    Revenue: "Revenue",
    InterestRevenue: "Interest Revenue",
    Materials: "Materials",
    Wages: "Wages",
    Depreciation: "Depreciation",
//    TheRestOfDirectCosts: "The rest of direct costs",
//    TheRestOfIndirectCosts: "The rest of indirect costs",
    Expense: "Expense",
    InterestExpense: "Interest Expense"
};

var overviewType = {
    Historical: "Historical",
    Forecasting: "Forecasting"
};

export {
    uploadTypes as UploadTypes,
    inputTypes as InputTypes,
    splitTypes as SplitTypes,
    fields as Fields,
    ledgerTypes as LedgerTypes,
    LedgerName,
    tagCategoryName as TagCategoryName,
    tagName as TagName,
    csvType as CsvType,
    overviewType as OverviewType,
    specialAccountItems as SpecialAccountItems,
    alternateTagNames as AlternateTagNames,
    importTagSubSections as ImportTagSubSections,
    importRenamedTags as ImportRenamedTags,
    VideoLinks,
};