"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevenueTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
const dropDownAndText = ['text', 'stability_dropdown', 'number'];
const percentageAndText = ['text', 'percentage', 'number'];
exports.RevenueTemplates = [{
        title: 'Revenue Drivers',
        id: 'revenue-drivers',
        allowedValueTypes: dropDownAndText,
        showComments: true,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Revenue Stream Mix',
        id: 'revenue-streamMix',
        allowedValueTypes: percentageAndText,
        showComments: true,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: true,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'New Revenue Opportunity',
        id: 'revenue-productOpportunity',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.progressRow,
    }, {
        title: 'General Revenue Notes',
        id: 'revenue-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }];
