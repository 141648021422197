/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Colour from "../utilities/Colour"
import CustomTable from "./Table"
import { Fields } from "../models/Enums"

class RealTimeTable extends React.Component {
    render() {
        const { fields } = this.props;
        return <CustomTable columns={fields} {...this.props}/>
    }
}

RealTimeTable.propTypes = {
    maxRows: PropTypes.number,
    rows: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    onColumnClick: PropTypes.func,
    onRowClick: PropTypes.func,
    highlightColumnIndex: PropTypes.number,
    hideFirstColumn: PropTypes.bool,
};

export default RealTimeTable;