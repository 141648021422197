import React from 'react';
import PropTypes from 'prop-types';
import RowStruct from "../models/RowStruct"
import AccountItem from "../models/AccountItem"
import { InputTypes } from "../models/Enums"
var DataStore = require('../datastore');
var AccountNumberTagsStore = DataStore.AccountNumberTagsStore;
var MonthStore = DataStore.MonthStore;
import { ButtonTypes, PictureButton } from "../../../common/Buttons"
import DebounceInput from "../components/Debounce"
import Utils from "../utilities/Utils"
var TagCategoryStore = DataStore.TagCategoryStore;

/**
 * @deprecated
 */
class Row extends React.Component {
    getAccountNumberTag() {
        const { accountItem } = this.props;
        var accountNumberTag = AccountNumberTagsStore.getState().accountNumberTags.filter(item => item.account_number == accountItem.account_number).shift();

        return accountNumberTag;
    }

    getParent() {
        if (! this.isSplit()){
            return null;
        }
        const { accountItem } = this.props;
        let parentNumberTagId = this.getAccountNumberTag().split_root_id;
        let parentTag = AccountNumberTagsStore.getState().accountNumberTags
            .filter(tag => tag.id == parentNumberTagId).shift();
            return MonthStore.state.month.account_items
                .filter(item => item.account_number == parentTag.account_number).shift();
    }

    // Check the accountNumberTag for split_root_id != null
    isSplit() {
        var ant = this.getAccountNumberTag();
        return ant != null && ant.split_root_id != null;
    }

    getSplitButton() {
        const { editing, onSplit } = this.props;

        if (editing == false || onSplit == null) {
            return null;
        }

        if (this.isSplit()) {
            return <PictureButton buttonType={ButtonTypes.SplitDisabled} disabled={true}/>;
        }

        return <PictureButton buttonType={ButtonTypes.Split} onClick={onSplit}/>;
    }

    getReTagButton() {
        return null; // moved to Monthly Data screen
        // const { editing, onReTag , isParent} = this.props;
        //
        // if (editing == false || onReTag == null) {
        //     return null;
        // }
        //
        // return <PictureButton buttonType={ButtonTypes.Tag} onClick={onReTag}/>;
    }

    getDeleteButton() {
        const { editing, onRemoveRow, inputType, isParent } = this.props;

        if (editing == false || onRemoveRow == null || inputType != InputTypes.Manual) {
            return null;
        }

        if (this.isSplit() || isParent) {
            return <PictureButton placeholder={true}/>;
        }

        return <PictureButton buttonType={ButtonTypes.Delete} onClick={onRemoveRow}/>;
    }

    getImportActionButtons() {
        //TODO: disable split button here?
        return (
            <div className="col-xs-3 text-right">
                <div>
                    {/* REALTIME-427 splitting is broken, so we disabled for now */}
                    {/* {this.getSplitButton()} */}
                    {this.getReTagButton()}
                </div>
            </div>
        );
    }

    getManualActionButtons() {
        //TODO: disable split button here?
        return (
            <div className="col-xs-3 text-right">
                <div>
                    {/* REALTIME-427 splitting is broken, so we disabled for now */}
                    {/* {this.getSplitButton()} */}
                    {this.getReTagButton()}
                    {this.getDeleteButton()}
                </div>
            </div>
        );
    }

    getActionButtons() {
        const { inputType } = this.props;

        switch (inputType) {
            case InputTypes.Import:
                return this.getImportActionButtons();
                break;

            case InputTypes.Manual:
                return this.getManualActionButtons();
                break;
        }
    }

    // Left (title)
    getField1() {
        const { editing, title, onLabelChange } = this.props;

        if (editing && typeof onLabelChange === "function") {
            return <div className="col-xs-7">
                <DebounceInput
                    type="text"
                    className="sorted-input wide-input"
                    updateOnlyOnBlur
                    value={title}
                    onChange={value => onLabelChange(value)}/>
                    {this.getSubTitle()}
            </div>
        }

        return <div className="col-xs-7 title">
            <span>{title}</span>
            {this.getSubTitle()}
        </div>;
    }

    getSubTitle() {
        if (this.isSplit()) {
            let title = this.getParent().name;
                   return <span className="accordion-subtitle">Split from {title}</span>
        } else {
            return null;
        }
    }

    // Right (value & percentage)
    getField2() {
        const { editing, amount, percentageAmount, onAmountChange, onPercentageChange, isParent } = this.props;

        // Split account items show a percentage amount
        if (this.isSplit()) {
            return <div className="col-xs-2">
                <div className="col-xs-6">
                    <span className="short-input"><span>$</span> {Utils.removePrecisionAndGetCommas(amount)}</span>
                </div>
                <div className="col-xs-6">
                    <span className="short-input">{Math.floor(percentageAmount)} <span>%</span></span>
                </div>
            </div>
        }

        // NOT split view & not parent
        if (editing && !isParent) {
            return <div className="col-xs-2">
                <DebounceInput
                    type="text"
                    className="sorted-input short-input"
                    updateOnlyOnBlur
                    value={Utils.removePrecisionAndGetCommas(amount)}
                    onChange={value => onAmountChange(value)}/>
            </div>
        }

        return <div className="col-xs-2">
            <span><span>$</span> {Utils.removePrecisionAndGetCommas(amount)}</span>
        </div>
    }

    render() {
        return <div className={"col-xs-12 sub-item coloured-accordion" + (this.isSplit() ? " split-item " + this.getCategoryClassForTag(this.props.accountItem.tag_id) : "")}>
            {this.getField1()}
            {this.getField2()}
            {this.getActionButtons()}
        </div>;
    }

    getCategoryClassForTag(tag) {
        return Utils.toClassName(
            TagCategoryStore.getState().tagCategories.find(
            (cat) => typeof cat.tags.find(
                    (searchTag) => searchTag.id==tag
                ) != "undefined"

            ).name
        );
    }
}

Row.propTypes = {
    accountItems: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    editing: PropTypes.bool.isRequired,
    accountItem: PropTypes.instanceOf(AccountItem).isRequired,
    onSplit: PropTypes.func,
    onReTag: PropTypes.func,
    onRemoveRow: PropTypes.func,
    onLabelChange: PropTypes.func,
    onAmountChange: PropTypes.func,
    inputType: PropTypes.string,
    percentageAmount: PropTypes.number,
    onPercentageChange: PropTypes.func,
    //accountNumberTagsStore: PropTypes.object
    isParent: PropTypes.bool
};

export default Row;
