var User = require("../models/User");
var Actions = require("../actions/UserActions");
var Ajax = require("../endpoints/ajax");

var UserSource = {
    fetchUsers(){
        return {
            remote(state){
                return Ajax.getUsers();
            },
            local(){
                return null;
            },
            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },
    fetchPermissions(){
        return {
            remote(state){
                return Ajax.getPermissions();
            },
            local(){
                return null;
            },
            success: Actions.permissionsReceived,
            loading: Actions.permissionsReceiving,
            error: Actions.permissionsReceivingFailed,
        }
    },
    performSave() {
        return {
            remote(state, user) {
                return Ajax.saveUser(user);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },

    performChangePassword() {
        return {
            remote(state, oldPassword, newPassword) {
                return Ajax.changePassword(oldPassword, newPassword);
            },

            local() {
                return null;
            },

            success: Actions.passwordSaved,
            loading: Actions.passwordSaving,
            error: Actions.passwordSavingFailed
        }
    },

    performChangeEmail() {
        return {
            remote(state, password, newEmail) {
                return Ajax.changeEmail(password, newEmail);
            },

            local() {
                return null;
            },

            success: Actions.emailChanged,
            loading: Actions.emailChanging,
            error: Actions.emailChangingFailed
        }
    },
};

module.exports = UserSource;

