import React from 'react';
import Manual from './text/Manual';
import Import from './text/Import';
import Dialog from './text/Dialog';
import InfoButton from '../components/InfoButton';

module.exports = {
    WelcomeScreen: {
        get Introduction() {
            return (
                <div>
                    <p>
                        Data is imported into RealTime CEO via income statement and balance sheet CSV files.    For more information about preparing CSV files for RealTime CEO please watch this tutorial: <InfoButton inline/>
                    </p>
                    <p>    
                        We import data in 2 stages:
                    </p>
                    <ol>
                        <li>On-Boarding historical data to a minimum of 12 months ago and preferably 24.</li>
                        <li>Importing monthly data for the remaining months to the current date.</li>
                    </ol>
                    <p>
                        This wizard takes you through Stage 1 - On-Boarding
                    </p>
                    <p>
                        The On-Boarding process sets up the mapping routine between your chart of accounts and the RealTime CEO software.
                    </p>
                    <p>
                        Having defined your historical data timeframes, there are 5 files required (see examples below for historical data to Jan 2018):
                    </p>
                    <ul style={{listStyleType:'none'}}> 
                        <li>File 1: Opening Balance Sheet. (eg Dec 16)</li>
                        <li>File 2: Full year Income Statement. (eg 2017 year)</li>
                        <li>File 3: Balance Sheet at the end of the period defined in file 2. (eg Dec 17)</li>
                        <li>File 4: Next concurrent Income Statement.  This can be from 1 to 12 months. (eg Jan 18) </li>
                        <li>File 5: Balance Sheet at the end of the period defined in file 4. (eg Jan 18)</li>
                    </ul>
                    <p>
                        For more information about defining historical data timeframes, please watch this tutorial: <InfoButton inline/>
                    </p>
                    <p>
                        After you have completed this wizard, you will enter the remaining monthly data via the “Enter Data” module within the software. 
                    </p>
                </div>
            );
        },
        getDateList: function(dates) {
            const {
                thisYearStart,
                thisYearEnd,
                lastYearStart,
                lastYearEnd,
                startYear,
                numMonthsThisYear,
            } = dates;

            const thisYearText = thisYearStart === thisYearEnd
                ? thisYearEnd
                : `${numMonthsThisYear} months from ${thisYearStart} to ${thisYearEnd}`;

            return (
                <div>
                    <p>Based on the selected date range, you will need data for the following periods:</p>
                    <ul>
                        <li>Opening Balance Sheet at {startYear}</li>
                        <li>Income Statement for 12 months from {lastYearStart} to {lastYearEnd}</li>
                        <li>Balance Sheet at {lastYearEnd}</li>
                        <li>Income Statement for {thisYearText}</li>
                        <li>Balance Sheet at {thisYearEnd}</li>
                    </ul>
                </div>
            );
        },
    },
    Manual,
    Import,
    Dialog,
    get RemainderEqualZero() {
        return 'Remainder (must = 0)';
    },
    get BalanceCheckEqualZero() {
        return 'Balance Check (must = 0)';
    },
    get RetainedEarningsRemainder() {
        return 'Difference in Change of Retained Earnings';
    },
};
