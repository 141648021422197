var Account = require("../models/Account");
var Actions = require("../actions/AccountActions");
var Ajax = require("../endpoints/ajax");

var AccountSource = {
    useToken() {
        return {
            remote(state, token){
                return Ajax.useToken(token);
            },
            local(){
                return null;
            },
            success: Actions.itemReceived,
            loading: Actions.itemReceiving,
            error: Actions.itemReceivingFailed
        }
    },
    fetchAccount(){
        return {
            remote(state){
                return Ajax.getAccount();
            },
            local(){
                return null;
            },
            success: Actions.itemReceived,
            loading: Actions.itemReceiving,
            error: Actions.itemReceivingFailed
        }
    },
    performSave() {
        return {
            remote(state, account) {
                return Ajax.saveAccount(account);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },
    performLinkToGroupAccount() {
        return {
            remote(state, code) {
                return Ajax.linkToGroupAccount(code);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },
    performUnlinkGroupAccount() {
        return {
            remote(state, code) {
                return Ajax.unlinkGroupAccount(code);
            },

            local() {
                return null;
            },

            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },
    performInvite() {
        return {
            remote(state, params) {
                return Ajax.inviteToAccount(params);
            },

            local() {
                return null;
            },

            success: Actions.inviteSent,
            loading: Actions.inviteSending,
            error: Actions.inviteSendingFailed
        }
    },
    performResendInvite() {
        return {
            remote(state, user, roles) {
                return Ajax.resendUserInvite(user.id);
            },

            local() {
                return null;
            },
            success: Actions.inviteResent,
            loading: Actions.inviteResending,
            error: Actions.inviteResendingFailed,
        }
    },
    performEnableForecasting() {
        return {
            remote(state) {
                let account = state.account;
                account.forecasting_enabled = true;

                return Ajax.saveAccount(account);
            },

            local() {
                return null;
            },
            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    },
    performDeleteUser() {
        return {
            remote(state, user) {
                return Ajax.deleteUser(user);
            },

            local() {
                return null;
            },
            success: Actions.userDeleted,
            loading: Actions.userDeleting,
            error: Actions.userDeletingFailed,
        }
    },
    performUpdateUserRoles() {
        return {
            remote(state, user, roles) {
                return Ajax.updateUserPermissions(user.id, roles);
            },

            local() {
                return null;
            },
            success: Actions.userPermissionsdUpdated,
            loading: Actions.userPermissionsdUpdating,
            error: Actions.userPermissionsdUpdatingFailed,
        }
    },
};

module.exports = AccountSource;

