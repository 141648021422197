class NumberFormatter {
    static parseMoney(amountString) {
        if (typeof amountString === "number") {
            return amountString;
        }

        if (typeof amountString === "string") {
            return Number(amountString.replace(/[^0-9\.-]+/g, ""));
        }

        return NaN;
    }
}

export default NumberFormatter;
