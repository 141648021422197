import React from 'react';
import PropTypes from 'prop-types';
var DataStore = require('../datastore');
const ThreeWModel = DataStore.ThreeW;
var ThreeWStore = DataStore.ThreeWStore;
import Entry from "../3wpanel/Entry"
import PageHeading from "../PageHeading"
import Formatter from "../Formatter"
import moment from "moment"
import CheckBox from '../components/CheckBox';
import { VideoLinks } from '../models/VideoLinks.ts';

const SortKeys = {
    Who: 'who',
    When: 'when',
    Done: 'done',
    Number: 'sortIndex',
};

class ThreeW extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            connected: false,
            what: null,
            who: null,
            when: null,
            confirmEntry: null,
            sortKey: SortKeys.Number,
            showDone: false,
            sortAscending: true,
        };
    }

    componentDidMount() {
        this.loadAllEntries()
            .then(() =>
                this.setState({
                    connected: true
                }));
    }

    loadAllEntries() {
        return ThreeWStore.fetchData();
    }

    /**
     * Save the entry into the state and upload to the server.
     * @param entry
     * @return {*}
     */
    save(entry) {
        var saveData = new ThreeW(entry);
        saveData.when = typeof entry.when == "string" ? entry.when : Formatter.momentToServerFormat(entry.when);

        return ThreeWStore.saveItem(entry);
    }

    /**
     * Make a clone of the entry with the given state and save it.
     * @param entry
     * @param key
     * @param value
     * @return {*}
     */
    entryValueChanged(entry, key, value) {
        entry[key] = value;

        return this.save(entry);
    }

    onShowDoneChanged() {
        this.setState({
            showDone: !this.state.showDone
        });
    }

    onDonePressed(entry) {
        this.setState({
            confirmEntry: entry
        });
    }

    onDoneConfirmed() {
        this.entryValueChanged(this.state.confirmEntry, SortKeys.Done, true)
            .then(() => {
                this.setState({
                    confirmEntry: null
                });
            });
    }

    onDialogClose() {
        this.setState({
            confirmEntry: null
        });
    }

    sortBy(sortKey) {
        if (this.state.sortKey == sortKey) {
            this.setState({
                sortAscending: !this.state.sortAscending
            });
        } else {
            this.setState({
                sortKey: sortKey,
                sortAscending: true
            });
        }
    }

    getEntryForm(entry, confirmEntry) {
        var validData = entry.what.length > 0
            && entry.who.length > 0
            && entry.when.length > 0
            && entry.done == false;

        return <Entry
            key={entry.id}
            entry={entry}
            validData={validData}
            editable={entry.done === false}
            confirmEntry={confirmEntry != null && confirmEntry.id == entry.id}
            onDoneConfirmed={() => this.onDoneConfirmed()}
            onDialogClose={() => this.onDialogClose()}
            onChange={(sourceEntry, key, value) => this.entryValueChanged(sourceEntry, key, value)}
            onDonePress={sourceEntry => this.onDonePressed(sourceEntry)}
            />
    }

    getItems() {
        const { sortKey, sortAscending, showDone, confirmEntry } = this.state;
        const threeWs = this.props.threeWs.slice()
            // sort by id initially to display in created order
            .sort((a, b) => a.id > b.id ? 1 : -1 )
            .map((item, index) => {
                const clone = new ThreeWModel(item);
                clone.sortIndex = index + 1;
                return clone;
            });

        if (sortKey !== null) {
            threeWs.sort((a, b) => {
                if (sortKey !== null && a.hasOwnProperty(sortKey) && a[sortKey] !== b[sortKey]) {
                    // Parse value as a date then compare unix timestamp.
                    // NOTE: This might be unnecessary depending on the format of the date string.
                    if (sortKey === SortKeys.When) {
                        const aTimestamp = moment(a[sortKey], moment.ISO_8601).format('X');
                        const bTimestamp = moment(b[sortKey], moment.ISO_8601).format('X');

                        if (sortAscending) {
                            return aTimestamp < bTimestamp ? -1 : 1;
                        }

                        return aTimestamp < bTimestamp ? 1 : -1;
                    }

                    if (sortAscending) {
                        return a[sortKey] < b[sortKey] ? -1 : 1;
                    }

                    return a[sortKey] < b[sortKey] ? 1 : -1;
                }

                return 0;
            });
        }

        return threeWs.filter(item => showDone ? true : item.done === false)
            .map(item => this.getEntryForm(item, confirmEntry));
    }

    render() {
        const { sortKey, sortAscending, showDone } = this.state;

        const chevUp = <span className="glyphicon glyphicon-chevron-up sort-icon pull-right"></span>;
        const chevDown = <span className="glyphicon glyphicon-chevron-down sort-icon pull-right"></span>;
        const chevInactive = <span className="glyphicon glyphicon-chevron-down sort-icon-inactive pull-right"></span>;
        const chevron = sortAscending ? chevDown : chevUp;


        const header = (
            <div className="row">
                <div className="col-xs-12">
                    <PageHeading
                      title="3W Action Register"
                      icon="/images/btn_actionregister.svg"
                      videoId={VideoLinks.ThreeW}
                    />
                    <hr />
                </div>
                <div className="col-xs-12 no-padding text-right checkbox">
                    <CheckBox
                        checked={showDone}
                        label="Show Completed Actions"
                        onClick={() => this.onShowDoneChanged()}
                    />
                </div>
            </div>
        );

        const content = (
            <div>
                <div className="col-xs-12 table-header no-padding">
                    <div className="heading-column number clickable noselect" onClick={() => this.sortBy(SortKeys.Number)}>
                        # {sortKey === SortKeys.Number ? chevron : chevInactive}
                    </div>
                    <div className="heading-column noselect">
                        What
                    </div>
                    <div className="heading-column clickable noselect" onClick={() => this.sortBy(SortKeys.Who)}>
                        Who {sortKey === SortKeys.Who ? chevron : chevInactive}
                    </div>
                    <div className="heading-column clickable noselect" onClick={() => this.sortBy(SortKeys.When)}>
                        When {sortKey === SortKeys.When ? chevron : chevInactive}
                    </div>
                    <div className="heading-column complete clickable noselect" onClick={() => this.sortBy(SortKeys.Done)}>
                        Complete? {sortKey === SortKeys.Done ? chevron : chevInactive}
                    </div>
                </div>
                <div className="col-xs-12 table-body no-padding">
                    {this.getItems()}
                </div>
            </div>
        );

        // var wizardContent = <div><span>
        //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non ultrices nisi. Suspendisse ac erat a dolor porttitor sagittis. Nullam eu pellentesque risus. Pellentesque ullamcorper consequat orci, a convallis purus. Sed id eleifend lacus, eu gravida dui. Pellentesque vitae convallis risus, nec euismod elit. Vivamus malesuada tortor et leo blandit, at sagittis elit lacinia. Mauris a est vel ipsum posuere porttitor tempus ut lorem. In vel lorem massa. Morbi id interdum ipsum, vel fringilla ligula.
        //         </span>
        // </div>;

        const wizard = null;
        //TODO get content for help panel
        //<div className="col-xs-3 wizard pull-right no-padding">
        //              <WizardPanel heading="3W Register" content={wizardContent}/>;
        //          </div>


        return (
            <div className="col-xs-12 threew-page">
                <div className="row">
                    <div className="col-xs-12 no-wizard">
                        {header}
                    </div>
                    <div className="col-xs-12">
                        {content}
                    </div>
                    {wizard}
                </div>
            </div>
        );
    }
}

ThreeW.propTypes = {
    handleDataUpdated: PropTypes.func,
};

export default ThreeW;
