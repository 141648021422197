/* eslint-env browser */
/* eslint-disable no-underscore-dangle */

function configure(account) {
    if (!window.trackJs) {
        return;
    }

    try {
        const trackJs = window.trackJs;
        trackJs.configure({
            userId: account.id,
        });
        trackJs.addMetadata('masquerading', account.masquerade === true);
    } catch (error) {
        console.warn('unable to set trackJs user configuration');
    }
}

function init() {
    if (process.env.trackJsToken) {
        window._trackJs = {
            token: process.env.trackJsToken,
            version: process.env.appVersion,
            application: process.env.trackJsApplicationName,
        };

        require('trackjs');
    }
}

module.exports = {
    configure,
    init,
};
