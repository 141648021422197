import React from 'react';
import PropTypes from 'prop-types';
class PageSubHeading extends React.Component {
    render() {
        return <div className="page-subheading">
            {this.props.title.length > 0 ? <div className="col-xs-12 no-padding">
                <h1>
                    <span className="subheading-icon"><img src={this.props.icon}/></span>
                    <span className="subheading-text">{this.props.title}</span>
                </h1>
            </div> : null}
        </div>
    }
}

PageSubHeading.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageSubHeading;