/* @ts-check */
import React from 'react';
import PropTypes from 'prop-types';
var DataStore = require('../datastore');
var ThreeWStore = DataStore.ThreeWStore;
var ThreeWTagStore = DataStore.ThreeWTagStore;
import InputGroup from "./InputGroup";
import Formatter from "../Formatter";
import CheckBox from "../components/CheckBox";
import DatePicker from 'react-datepicker';
var moment = require('moment');

class Entry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            who: props.entry.who
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            who: nextProps.entry.who
        });
    }

    onChange(entry, key, value) {
        /**
         * If it's the when key, the value will be a 'moment' object.
         */
        if (key == "when") {
            if (value.isValid() == false) {
                return false;
            }

            if (value.unix() < moment().subtract(1, 'days').unix()) {
                // Do nothing
                return false;
            } else {
                this.props.onChange(entry, key, Formatter.momentToServerDateFormat(value));
                this.clearTags();   //should occur on focus not on key change
                return true;
            }
        }

        this.props.onChange(entry, key, value);
        return true;
    }

    onWhoSaved() {
        const { entry, onChange } = this.props;

        if (this.state.who != entry.who) {
            onChange(entry, "who", this.state.who);
            this.clearTags();
        }
    }

    onWhoChanged(tag) {
        // TODO: fix suggestions implementation
        const { entry, onChange } = this.props;

        // if (tag != entry.who) {
        //     onChange(entry, "who", tag);
        // }

        this.setState({
            who: tag
        });

        // this.updateWhoTags(tag)
            // .then(() => {
                
            // })
    }

    clearTags() {
        ThreeWTagStore.clearTags();
    }

    updateWhoTags(value, clearTags) {
        return ThreeWTagStore.fetchNames(value);
    }

    formatDate(dateString) {
        var result = Formatter.toDisplayFormat(dateString);

        if (result == "Invalid date") {
            return "";
        }

        return result;
    }

    onDeleteTag(tag) {
        ThreeWTagStore.performDelete(tag)
            .then(() => this.onWhoChanged(this.props.entry.who));
    }

    onGainedFocus(label) {
        this.setState({
            focus: label
        });

        // if (label === "Who") {
        //     return ThreeWTagStore.fetchNames(this.state.who);
        // }
    }

    isEntryValid(entry) {
        return entry.what.length > 0
            && entry.who.length > 0
            && typeof entry.when == 'string' && entry.when.length > 0
    }

    get Inputs() {
        const { entry, vertical, editable, onDonePress } = this.props;
        const { focus, who } = this.state;

        var tags = ThreeWTagStore.getNames();

        if (editable === false) {
            return <div className="entry-static">
                <div className="entry-cell padding number">{entry.sortIndex}</div>
                <div className="entry-cell padding">{entry.what}</div>
                <div className="entry-cell padding">{entry.who}</div>
                <div className="entry-cell padding">{this.formatDate(entry.when)}</div>
                <div className="entry-cell complete entry-done">
                    <CheckBox
                        checked
                        disabled
                    />
                </div>
            </div>
        } else {
            return <div>
                <div className="entry-cell padding number">{entry.sortIndex}</div>
                <div className="entry-cell">
                    <InputGroup value={entry.what}
                            label="What"
                            placeholder="Type a brief note"
                            type="textarea"
                            vertical={vertical}
                            onChange={value => this.onChange(entry, 'what', value)}
                            onGainedFocus={label => this.onGainedFocus(label)}
                            focus={focus}
                    />
                </div>
                <div className="entry-cell">
                    <InputGroup value={who}
                            label="Who"
                            placeholder="Type a name"
                            vertical={vertical}
                            onChange={tag => this.onWhoChanged(tag)}
                            onDeleteTag={tag => this.onDeleteTag(tag)}
                            // tags={tags} // TODO: fix suggestions implementation
                            tags={[]}
                            onGainedFocus={label => this.onGainedFocus(label)}
                            onBlur={label => this.onWhoSaved()}
                            focus={focus}
                    />
                </div>
                <div className="entry-cell">
                    <div className="input-group">
                        {vertical && <label>When</label>}
                        <DatePicker 
                            className={'date-picker-input'}
                            value={this.formatDate(entry.when)}
                            label="When"
                            placeholder="Select date"
                            vertical={vertical}
                            onChange={value => this.onChange(entry, 'when', moment(value))}
                            onGainedFocus={label => this.onGainedFocus(label)}
                            focus={focus}
                            selected={moment(entry.when).isValid() ? moment(entry.when).toDate() : undefined}
                            minDate={moment().toDate()}
                        />
                    </div>
                </div>
                {vertical ? null : (
                    <div className="entry-cell complete">
                        <CheckBox
                            onClick={() => onDonePress(entry) }
                        />
                    </div>
                )}
            </div>
        }
    }

    get ConfirmEntry() {
        return (
            <div className="col-xs-12 confirm-entry-threew noselect">
                <h1>Mark as complete?</h1>
                <p style={{ marginBottom: '20px' }}><span>This cannot be undone.</span></p>

                <div>
                    <button className="btn btn-danger" onClick={this.props.onDialogClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={this.props.onDoneConfirmed} style={{ marginLeft: '20px' }}>
                        Mark as Complete
                    </button>
                </div>
            </div>
        );
    }

    get IsDisabled() {
        const { entry } = this.props;
        return entry !== null && this.isEntryValid(entry) === false;
    }

    get EntrySaveAndCreateAnotherButton() {
        return (
            <button
                type="button"
                className="btn btn-primary pull-right"
                onClick={() => this.handleSavePress(false)}
                disabled={this.IsDisabled}
            >
               Create another
            </button>
        );
    }

    get EntryDoneButton() {
        return (
            <button
                type="button"
                className="btn btn-primary pull-left"
                onClick={() => this.handleSavePress(true)}
                disabled={this.IsDisabled}
            >
               Done
            </button>
        );
    }

    handleSavePress(shouldClose) {
        const {
            entry,
            onSavePress
        } = this.props;

        if (!this.IsDisabled) {
            onSavePress(entry, shouldClose);
        }
    }

    get Entry() {
        const {
            entry,
            onSavePress
            } = this.props;

        var showSaveButton = onSavePress && entry.done === false;

        return <div className="col-xs-12 threew-entry no-padding">
            {this.Inputs}
            <div className="col-xs-12 margin-top-16">
                {showSaveButton ? this.EntrySaveAndCreateAnotherButton : null}
                {showSaveButton ? this.EntryDoneButton : null}
            </div>
        </div>
    }

    render() {
        if (this.props.confirmEntry) {
            return this.ConfirmEntry
        }

        return this.Entry
    }
}

Entry.propTypes = {
    onChange: PropTypes.func.isRequired,
    endpoint: PropTypes.func,
    entry: PropTypes.object.isRequired,
    confirmEntry: PropTypes.bool,
    onDialogClose: PropTypes.func,
    onDoneConfirmed: PropTypes.func,
    vertical: PropTypes.object,
    onDonePress: PropTypes.func,
    onSavePress: PropTypes.func,
    editable: PropTypes.bool
};

export default Entry;
