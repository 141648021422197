/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckBox extends Component {
    render() {
        const {disabled, label, onClick, checked, className } = this.props;
        const labelElement = label ? (
                <span className="checkbox-label">
                    {label}
                </span>
            ) : null;
        return (
            <div className={`CheckBox ${className ? className : ''}`}>
                <button
                    className={'btn ' + (checked ? 'btn-action' : 'btn-primary')}
                    disabled={disabled === true}
                    onClick={() => onClick()}
                    type="button"
                >
                    <span className={checked ? 'glyphicon glyphicon-ok' : 'glyphicon'}/>
                </button>
                {labelElement}
            </div>
        );
    }
}

CheckBox.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
};

export default CheckBox;
