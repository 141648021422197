// @ts-check
var DataStore = require("../datastore/");
const { TagCategoryStore } = DataStore;
import { AccountItemQuery } from './AccountItemQuery';
import { TagCode } from '../models/EnumsTs';

class CategoryUtils {
    static get RevenueTags() {
        return [
            TagCode.IS_Revenue_Revenue,
            // interest revenue is excluded for most Dashboard and Crystal Ball calculations
            // TagCode.IS_Revenue_InterestRevenue,
            TagCode.IS_Revenue_Other
        ];
    }

    static get DirectCostsTags() {
        return [
            TagCode.IS_DirectCosts_Depreciation,
            TagCode.IS_DirectCosts_Materials,
            TagCode.IS_DirectCosts_Wages,
            TagCode.IS_DirectCosts_Other,
        ];
    }

    static get NegativeMiscItems() {
        return [
            TagCode.IS_Misc_InterestExpense,
            TagCode.IS_Misc_Depreciation,
            TagCode.IS_Misc_Wages,
        ];
    }

    /**
     *
     * @param month
     * @param directCostsTagCodes - default value is all direct costs tags
     * @returns {number} total direct costs for @month in sub-categories denoted by @directCostsTagCodes
     */
    static getTotalDirectCosts(month, directCostsTagCodes) {
        //all direct costs subcategories unless specified
        if (typeof directCostsTagCodes === 'undefined') {
            directCostsTagCodes = this.DirectCostsTags;
        }

        const directCostsTagsIds = this.getTagIds(directCostsTagCodes);
        let monthTotal = 0;

        monthTotal += month.account_items.filter(item => directCostsTagsIds.indexOf(item.tag_id) >= 0)
            .reduce((prevItem, currItem) => {
                return prevItem + parseFloat(currItem.value);
            }, 0);
        return monthTotal;
    }

    /**
     * This method should be used on Dashboard and Crystal Ball only, as it does not include interest.
     * @param month
     * @returns {number}
     */
    static getTotalRevenue(month) {
        const revenueIds = this.getTagIds(this.RevenueTags);
        if (month.is_forecast) {
            // use Total/Aspiration item as dating revenue is not included in Revenue lines.
            return parseFloat(AccountItemQuery.code(month.account_items, TagCode.IS_Revenue_TotalRevenue).value);
        }
        return month.account_items.filter(item => revenueIds.indexOf(item.tag_id) >= 0)
            .reduce((prevItem, currItem) => prevItem + parseFloat(currItem.value), 0);
    }

    static getTagIds(codes) {
        let tagCats = TagCategoryStore.getState().tagCategories;
        return tagCats
            .reduce((prev, curr) => prev.concat(curr.Tags), [])
            .filter(tag => codes.indexOf(tag.code) > -1)
            .reduce((prev, curr) => prev.concat([curr.id]), [])
    }

    static calculateNetProfit(accountItems) {
        const totalRevenue = AccountItemQuery.code(accountItems, TagCode.IS_Revenue_TotalRevenue);
        const totalDirectCosts = AccountItemQuery.code(accountItems, TagCode.IS_DirectCosts_TotalDirectCosts);
        const totalIndirectCosts = AccountItemQuery.code(accountItems, TagCode.IS_IndirectCosts_TotalIndirectCosts);
        const totalMisc = AccountItemQuery.code(accountItems, TagCode.IS_Misc_TotalMisc);

        const calculatedNetProfit = parseFloat(totalRevenue.value)
            - parseFloat(totalDirectCosts.value)
            - parseFloat(totalIndirectCosts.value)
            + parseFloat(totalMisc.value);

        return calculatedNetProfit;
    }
}

export default CategoryUtils;
