"use strict";
var AccountItemQuery;
(function (AccountItemQuery) {
    class AccountItemTagQuery {
        constructor(tagCategories) {
            this.tagCategories = tagCategories;
            this.tags = this.tagCategories
                .reduce((prev, curr) => prev.concat(curr.tags), []);
        }
        findTag(func) {
            let tag = this.tags
                .find(tag => func(tag));
            if (typeof tag == 'undefined') {
                return null;
            }
            return tag;
        }
        getCategoryForTag(tag) {
            const category = this.tagCategories.find(cat => cat.id === tag.tag_category_id) || null;
            if (category === null) {
                throw new Error(`no category found for tag with code ${tag.code} and tag_category_id ${tag.tag_category_id}`);
            }
            return category;
        }
        getTagForCode(code) {
            const tag = this.findTag(tag => tag.code === code);
            if (tag === null) {
                throw new Error(`AccountItemQuery.tagCode([...], ${code}) no tag found for code`);
            }
            return tag;
        }
        getTagForId(id) {
            const tag = this.findTag(tag => tag.id == id);
            if (tag === null) {
                throw new Error(`AccountItemQuery.getTagForId(${id}) no tag found for id`);
            }
            return tag;
        }
        tagCode(accountItems, code) {
            const tag = this.getTagForCode(code);
            return this.tag(accountItems, tag);
        }
        tag(accountItems, tag) {
            const foundItem = accountItems.find(item => item.tag_id == tag.id);
            if (typeof foundItem === 'undefined') {
                return null;
            }
            return foundItem;
        }
        allForTagCode(accountItems, code) {
            const tag = this.getTagForCode(code);
            return this.allForTag(accountItems, tag);
        }
        allForTag(accountItems, tag) {
            return accountItems.filter(item => item.tag_id == tag.id);
        }
    }
    AccountItemQuery.AccountItemTagQuery = AccountItemTagQuery;
    class StrictAccountItemTagQuery extends AccountItemTagQuery {
        constructor(tagCategories) {
            super(tagCategories);
        }
        // override
        tagCode(accountItems, code) {
            const item = super.tagCode(accountItems, code);
            if (item === null) {
                throw new Error(`StrictAccountItemTagQuery.tag: no item found with tag "${code}"`);
            }
            return item;
        }
        // override
        tag(accountItems, tag) {
            const item = super.tag(accountItems, tag);
            if (item === null) {
                throw new Error(`StrictAccountItemTagQuery.tag: no item found with tag "${tag.code}"`);
            }
            return item;
        }
    }
    AccountItemQuery.StrictAccountItemTagQuery = StrictAccountItemTagQuery;
    function accountNumber(accountItems, accountNumber) {
        const item = accountItems.find(item => item.account_number == accountNumber);
        if (typeof item !== 'undefined') {
            return item;
        }
        return null;
    }
    AccountItemQuery.accountNumber = accountNumber;
    class AccountNumberQuery {
        constructor(accountNumberTags) {
            this.accountNumberTags = accountNumberTags;
        }
        static find(accountItems, accountNumber) {
            return accountItems.find(item => item.account_number === accountNumber) || null;
        }
    }
    AccountItemQuery.AccountNumberQuery = AccountNumberQuery;
})(AccountItemQuery || (AccountItemQuery = {}));
module.exports = AccountItemQuery;
