import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */

const SonAnswerActions = alt.generateActions(
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',
    'itemSaved',
    'itemSaving',
    'itemSavingFailed'
);

module.exports = SonAnswerActions;


