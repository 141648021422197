/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import TabItem from './TabItem';
import Utils from '../utilities/Utils'
import TagButton from './TagButton';

class TabSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSection: null,
            selectedItem: null
        }
    }

    setDefaultSelected(newProps) {
        const { sections, lockedSelection } = newProps;
        const { selectedSection } = this.state;

        if (lockedSelection) {
            const section = sections.filter(section => section.id == lockedSelection).shift();
            if (section) {
                const firstItem = section.items[0];

                this.setState({
                    selectedItem: firstItem,
                    selectedSection: section,
                });
                return;
            }
        }

        // If selected section doesn't exist, then reset. Scenario is switching ledger types, entirely new set of sections comes in.
        if (sections != null && sections.filter(section => selectedSection != null && section.id == selectedSection.id).length == 0) {
            var firstSection = sections[0];
            var firstItem = firstSection != null && firstSection.items != null ? firstSection.items[0] : null;

            this.setState({
                selectedItem: firstItem,
                selectedSection: firstSection
            });
        }
    }

    componentDidMount() {
        this.setDefaultSelected(this.props)
    }

    componentWillReceiveProps(newProps) {
        this.setDefaultSelected(newProps)
    }

    shouldShowItems() {
        return this.props.items != null;
    }

    shouldShowSections() {
        return this.props.sections != null;
    }

    handleItemClick(item, section) {
        const { onClickItem } = this.props;

        onClickItem(item);

        var state = {
            selectedItem: item
        };

        if (section != null) {
            state.selectedSection = section;
        }

        this.setState(state)
    }

    /**
     *
     * @returns Array of Nodes
     * items with the property [tagSubSection] will be grouped and displayed with a heading
     * sections are sorted alphabetically
     *
     */
    get TabItems() {
        const { items, notDisplayedItems, sectionData } = this.props;
        const { selectedItem } = this.state;

        const NoSubSection = '__none';

        if (this.shouldShowItems()) {
            const sectionMap = {};
            items.forEach((item, index) => {
                var itemClassName = item.className == null ? Utils.toClassName(item.name) : item.className;

                if( notDisplayedItems.indexOf(item.name) !== -1) {
                    return null;
                }

                const tagSubSection = item.tagSubSection || NoSubSection;

                if (sectionMap[tagSubSection] === undefined) {
                    sectionMap[tagSubSection] = [];
                }

                sectionMap[tagSubSection].push(
                    <TagButton
                        key={index}
                        selected={selectedItem && selectedItem.id === item.id}
                        onClick={() => this.handleItemClick(item, null)}
                        className={`${itemClassName} `}
                        label={item.name}
                    />
                );
            });

            const sectionKeys = Object.keys(sectionMap);

            // if sectionData was provided, sort the subsections, using the given sort order
            if (Array.isArray(sectionData)) {
                const findSectionWithName = name => (sectionData.filter(item => item.name === name)[0] || {});

                sectionKeys.sort((a, b) => {
                    const sectionDataA = findSectionWithName(a);
                    const sectionDataB = findSectionWithName(b);
                    if (sectionDataA.sortOrder > sectionDataB.sortOrder) {
                        return -1;
                    } else if (sectionDataA.sortOrder < sectionDataB.sortOrder) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            //sections with titles will be rendered with headers
            return sectionKeys.map(key => {
                const sectionName = key;
                if (key === NoSubSection) {
                    return sectionMap[key];
                }
                return (
                    <div key={key} className="tag-sub-section">
                        <div className=" no-padding tag-sub-section-title"
                            style={{
                                minWidth: '110px',
                                flexShrink: 0,
                            }}
                        >
                            {sectionName}
                        </div>
                        <div className=" no-padding"
                            style={{
                                flexGrow: '1',
                            }}
                        >
                            {sectionMap[key]}
                        </div>
                    </div>
                )
            });
        }
    }

    get SectionsRow() {
        const { sections, lockedSelection } = this.props;

        if (this.shouldShowSections() == false) {
            return;
        }

        return sections.map((section, index) => {
            const sectionActiveClass = this.state.selectedSection && this.state.selectedSection.id == section.id ? ' active' : '';
            const shouldShowSectionButton = section.name != null;

            const sectionDisabledClass = lockedSelection && lockedSelection !== section.id ? ' disabled' : '';

            return shouldShowSectionButton ? <TabItem
                key={index}
                onClick={() => this.handleItemClick(section, section)}
                className={`tab-item ${Utils.toClassName(section.name)}${sectionActiveClass}${sectionDisabledClass}`}>
                {section.name}
            </TabItem> : null
        });
    }

    get SelectedSectionItems() {
        const { sections, sectionData } = this.props;

        if (this.shouldShowSections()) {
            return sections.map((section, index) => {
                if (this.state.selectedSection && this.state.selectedSection.id == section.id) {
                    var notDisplayedItems = [];
                    if(section.name === "Misc") {
                        notDisplayedItems = ["Total Misc","Other"];
                    }
                    return <TabSection
                        notDisplayedItems={notDisplayedItems}
                        key={section.id}
                        items={section.items}
                        sections={section.sections}
                        sectionData={sectionData}
                        onClickItem={item => this.handleItemClick(item, section)}
                        className={Utils.toClassName(section.name)}
                        />
                }
            })
        }
    }

    get TabSections() {
        return [
            this.SectionsRow,
            this.SelectedSectionItems,
        ];
    }

    render() {
        const { lockedSelection } = this.props;
        const parentClass = this.props.className == null ? '' : this.props.className;
        const lockedSelectionClassName = lockedSelection ? 'locked-selection' : '';
        return <div className={`col-xs-12 tab-row ${parentClass} ${lockedSelectionClassName}`}>
            {this.TabItems}
            {this.TabSections}
        </div>
    }
}

const itemArrayPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ // Tag model with additional fields
      name: PropTypes.string,
      code: PropTypes.string,
      className: PropTypes.string,
      id: PropTypes.string,
      tag_category_code: PropTypes.string,
      tagSubSection: PropTypes.string,
  })
);

TabSection.propTypes = {
    items: itemArrayPropTypes,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            items: itemArrayPropTypes,
        })
    ),
    sectionData: PropTypes.arrayOf( // array of objects matching sub-section names to sort orders
        PropTypes.shape({
            name: PropTypes.string,
            sortOrder: PropTypes.number,
        })
    ),
    onClickItem: PropTypes.func.isRequired,
    notDisplayedItems: PropTypes.array,
    lockedSelection: PropTypes.string, // id of a section to lock as selected
};

TabSection.defaultProps = {
    notDisplayedItems: [],
};

export default TabSection;