import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const ThreeWActions = alt.generateActions(
    'receivedItem',
    'loadingItem',
    'fetchingItemFailed',
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',
    'itemSaved',
    'itemSaving',
    'itemSavingFailed',
    'itemStored',
    'itemStoring',
    'itemStoringFailed'
);

module.exports = ThreeWActions;
