/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import TabSection from './TabSection';

class TabMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClick(node) {
        this.props.onClick(node);
    }

    render() {
        const { sections, sectionData, lockedSelection } = this.props;

        return <div className="tab-menu">
            <TabSection
                items={null}
                sections={sections}
                sectionData={sectionData}
                onClickItem={leaf => this.handleClick(leaf)}
                lockedSelection={lockedSelection}
                />
        </div>
    }
}

/**
 *
 * @type {{categories: *}} { name: string, categories: array of tags, parent: null or tag }
 */
TabMenu.propTypes = {
    sections: PropTypes.array.isRequired, // Sections should contain objects, which items or sections
    sectionData: PropTypes.arrayOf( // array of objects matching sub-section names to sort orders
        PropTypes.shape({
            name: PropTypes.string,
            sortOrder: PropTypes.number,
        })
    ),
    onClick: PropTypes.func,
    lockedSelection: PropTypes.string, // id of a section to lock as selected
};

export default TabMenu;