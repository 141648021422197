/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class DebounceInput extends React.Component {
    constructor(props) {
        super(props);

        this.timerId = null;
        this.element = null;

        this.state = {
            _value: props.value
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                _value: nextProps.value,
            });
        }
    }

    _onChangeDebounce(newValue, time) {
        const { onChange, getDerivedClassName, className } = this.props;

        if (time == null) {
            time = this.props.delay;
        }

        // Queue the debounce.
        return new Promise((resolve, reject) => {
            if (this.timerId != null) {
                clearTimeout(this.timerId);
            }

            this.timerId = setTimeout(_ => resolve(name), time);
        })
            .then(() => {
                // Call the onChange event
                onChange(newValue);
                this.timerId = null;
            });
    }

    onBlur() {
        this.forceOnChange();

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onFocus() {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    forceOnChange() {
        const { onChange, getDerivedClassName, className } = this.props;
        const { _value } = this.state;

        if (this.timerId != null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }

        //only trigger if value has changed from original
        if (_value !== this.props.value) {
            onChange(_value);

            if (this.props.clearOnChange) {
                this.setState({
                    _value: this.props.value,
                })
            }
        }
    }

    handleChange(value, type) {
        //if type is strict-number but input doesn't match regex, don't update
        if (type === 'strict-number' 
            && typeof value === 'string' 
            && !(value.trim().match(RegExp(/^\$?[0-9]{1,3}(,? ?[0-9]{3})*(\.|\,)?[0-9]*%?$/g))
                || value.trim() === ''
            ) 
        ) return 

        this.setState({
            _value: value,
        });

        if (this.props.updateOnlyOnBlur === true) {
            return;
        } else {
            this._onChangeDebounce(value);
        }
    }

    onKeyUp(event) {
        const { multiline, finishOnEnter, autoGrowHeight } = this.props;
        const EnterKeyCode = 13;

        if (multiline) {
            if (autoGrowHeight && this.element) {
                const startHeight = this.element.offsetHeight;
                this.element.style.height = '1px';
                this.element.style.height = `${Math.max(startHeight, this.element.scrollHeight)}px`;
            }
        } else if (event.keyCode === EnterKeyCode && finishOnEnter && this.element) {
            this.element.blur();
        }
    }

    handleReferences(element) {
        const { inputRef } = this.props;
        this.element = element;
        if (inputRef) {
            inputRef(element);
        }
    }

    render() {
        const { multiline, updateOnlyOnBlur, className, inputRef, finishOnEnter, autoGrowHeight, getDerivedClassName, type, ...props } = this.props;

        if (multiline) {
            return (
                <textarea
                    ref={element => this.handleReferences(element)}
                    {...props}
                    type={type === 'strict-number' ? 'text' : type}
                    className={classNames(className, 'DebounceInput')}
                    value={this.state._value}
                    onBlur={() => this.onBlur()}
                    onFocus={() => this.onFocus()}
                    onChange={e => this.handleChange(e.target.value)}
                    onKeyUp={event => this.onKeyUp(event)}
                />
            );
        }


        return (
            <input
                className={classNames(className, getDerivedClassName?.(this.state._value) || '')}
                ref={element => this.handleReferences(element)}
                onKeyUp={event => this.onKeyUp(event)}
                {...props}
                type={type === 'strict-number' ? 'text' : type}
                value={this.state._value}
                onBlur={() => this.onBlur()}
                onFocus={() => this.onFocus()}
                onChange={e => this.handleChange(e.target.value, type)}
            />
        );
    }
}

DebounceInput.propTypes = {
    autoGrowHeight: PropTypes.bool,
    delay: PropTypes.number,
    inputRef: PropTypes.func,
    multiline: PropTypes.bool,
    updateOnlyOnBlur: PropTypes.bool,
    finishOnEnter: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clearOnChange: PropTypes.bool,
    type: PropTypes.string,
};

DebounceInput.defaultProps = {
    delay: 2000,
};

export default DebounceInput;
