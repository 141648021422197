import React from 'react';
import PropTypes from 'prop-types';
import TabMenu from "../components/TabMenu"
import RealTimeTable from "../components/RealTimeTable"
var DataStore = require('../datastore');
var FieldStore = DataStore.FieldStore;
var FieldMappingStore = DataStore.FieldMappingStore;
var FieldMapping = DataStore.FieldMapping;
import { LedgerTypes, Fields} from "../models/Enums"

class TagColumns extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeField: this.getFields().length == 0 ? null : this.getFields()[0],
            activeFieldId: this.getFields().length == 0 ? null : 0
        }
    }

    onFieldSelect(item) {
        this.setState({
            activeField: item,
            activeFieldId: item.id
        });
    }

    onAttachTagToColumnIndex(field, columnIndex, value) {
        var fieldMapping = new FieldMapping({
            index: columnIndex,
            last_heading: value || '',
            field_id: field.id
        });

        FieldMappingStore.performSave(fieldMapping)
            .then(() => FieldStore.fetchData())
            .then(()=>{
                if (typeof this.props.postColumnTagged === 'function') {
                    this.props.postColumnTagged();
                }
            });
    }

    onSelectColumn(columnIndex, value) {
        let fields = this.getFields();
        let selectedField = fields.filter(field => field.id == this.state.activeFieldId).shift();

        let field = selectedField;
        if (field.filtered_field_mapping.length > 0
            && field.filtered_field_mapping[0].index == columnIndex) {
            //remove mapping
            return this.onAttachTagToColumnIndex(selectedField, -1, value)
        } else {
            //remove any other mapping for the same field to prevent duplicates
            fields.filter(field => {
                return field.filtered_field_mapping.length > 0
                    && field.filtered_field_mapping[0].index == columnIndex
            }).map(field => this.onAttachTagToColumnIndex(field, -1, null));
            //save new value
            this.onAttachTagToColumnIndex(selectedField, columnIndex, value);
        }
    }

    getFields() {
        const { type } = this.props;
        var fields = FieldStore.getFields();
        var fieldsToTag = [];
        if (type === LedgerTypes.BalanceSheet) {
            fieldsToTag = [
              // Fields.BalanceSheet.AccountNumber,
              Fields.BalanceSheet.AccountName,
              Fields.BalanceSheet.ValueAtMonthEnd
            ];
        } else {
            fieldsToTag = [
              // Fields.IncomeStatement.AccountNumber,
              Fields.IncomeStatement.AccountName,
              Fields.IncomeStatement.ValueForTheMonth
            ];
        }

        return fieldsToTag
            .map(code => fields.filter(field => field.code === code).shift())
            .filter(item => item !== null);
    }

    render() {
        const { rows, maxRows, hideFirstColumn } = this.props;
        // Modify structure to work with TabMenus
        const fieldsForViewing = this.getFields();
        var sections = [{
            items: fieldsForViewing,
        }];
        
        return (
            <div className="TagColumns">
                <TabMenu
                    sections={sections}
                    onClick={item => this.onFieldSelect(item)}
                />
                <div className="scrollable-table">
                    <RealTimeTable
                        hideFirstColumn={hideFirstColumn}
                        rows={rows}
                        maxRows={maxRows}
                        fields={fieldsForViewing}
                        onColumnClick={columnIndex => this.onSelectColumn(columnIndex, rows[0].row[columnIndex])}
                    />
                </div>
            </div>
        )
    }
}

TagColumns.propTypes = {
    rows: PropTypes.array.isRequired,
    maxRows: PropTypes.number.isRequired,
    postColumnTagged: PropTypes.func,
    type: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    hideFirstColumn: PropTypes.bool,
};

export default TagColumns;
