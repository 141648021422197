//@ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Utils from '../utilities/Utils';
import IconButton from '../components/IconButton';
import MultiMonthValuesRow from './MultiMonthValuesRow.tsx';

class ForecastSummaryRow extends Component {

    get Row() {
        const { onClick, className, hasAction } = this.props;
        let button = null;
        let contentColumnSpan = 12;

        if (hasAction) {
            contentColumnSpan = 11;

            if (onClick) {
                button = (
                    <IconButton
                        buttonType="edit"
                        className="update-forecast-button"
                        onClick={onClick}
                    />
                );
            } else {
                button = (
                    <span className="button-space" />
                );
            }
        }

        return (
            <div className="col-xs-12">
                <div className="row">
                    <div className={classNames('col-lg-12', 'col-md-12', 'col-xs-12', 'forecast-summary-row', className)}>
                        {this.getRowContent(contentColumnSpan)}
                        {
                            button && (
                                <div className="col-xs-1 row-information">
                                    { button }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * @param {number} columnSpan
     */
    getRowContent(columnSpan) {
        const { rowName, totalValue } = this.props;
        let monthlyValues = this.props.monthlyValues;

        let rowType = this.props.rowType || 'money';
        let value = '';

        if (totalValue !== null) {
            value = rowType === 'percentage' ? `${totalValue.toFixed(2)}%` : `$${Utils.removePrecisionAndGetCommas(totalValue)}`;
        }

        if (monthlyValues === null) {
            monthlyValues = new Array(12).fill(' ');
            rowType = 'text';
        }

        return (
            <div className={classNames(`col-xs-${columnSpan}`, 'name-value-row monthly-columns', 'no-padding')}>
                <div>
                    <MultiMonthValuesRow
                        canBeNegative
                        columnValues={monthlyValues}
                        rowType={rowType}
                        className="no-padding noBorder"
                        titleColContent={rowName}
                        totalColumnContent={value}
                        allowPercentageDecimalPlaces
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="row">
                {this.Row}
            </div>
        );
    }
}

ForecastSummaryRow.propTypes = {
    className: PropTypes.oneOf([
        'blue',
        'light-blue',
        'red',
        'orange',
        'indigo',
        'green',
        'yellow',
        'grey',
    ]),
    hasAction: PropTypes.bool,
    monthlyValues: PropTypes.arrayOf(PropTypes.number),
    onClick: PropTypes.func,
    rowName: PropTypes.string,
    rowType: PropTypes.oneOf(['percentage']),
    totalValue: PropTypes.number,
};

export default ForecastSummaryRow;
