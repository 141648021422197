var SonAnswer = require("../models/SonAnswer");
var Actions = require("../actions/SonAnswerActions");
var Ajax = require("../endpoints/ajax");

var SonAnswerSource = {
    fetchDataRange(){
        return {
            remote(state, dates){
                return Ajax.getSonAnswers(dates[0], dates[1]);
            },
            local(){
                return null;
            },
            success: Actions.itemsReceived,
            loading: Actions.itemsReceiving,
            error: Actions.itemsReceivingFailed
        }
    },

    performSave() {
        return {
            remote(state, sonAnswer){
                return Ajax.saveSonAnswer(sonAnswer);
            },
            local(){
                return null;
            },
            success: Actions.itemSaved,
            loading: Actions.itemSaving,
            error: Actions.itemSavingFailed
        }
    }
};

module.exports = SonAnswerSource;

