import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OptionSelector from '../components/OptionSelector';
import DebounceInput from '../components/Debounce';
import Utils from '../utilities/Utils';
import { AspirationalInputMethod } from './screens/AspirationalRevenue.ts';

class EnterAspirationValue extends Component {
    getSelected(selectedIndex) {
        const { percent, totalMoneyValue, handleMoneyUpdate, handlePercentUpdate, totalHistorical } = this.props;
        switch (selectedIndex) {
            case (AspirationalInputMethod.keyManual):
                return (
                    <div className="col-xs-12 input-symbol">
                        $
                        <DebounceInput
                            className="sorted-input money-input"
                            value={Utils.removePrecisionAndGetCommas(totalMoneyValue)}
                            onChange={(value) => handleMoneyUpdate(value)}
                            updateOnlyOnBlur
                        />
                    </div>
                );
            case (AspirationalInputMethod.keyPercentage):
                return (
                    <div className="col-xs-12 input-symbol">
                        <DebounceInput
                            className="sorted-input"
                            value={percent}
                            type="number"
                            onChange={(value) => handlePercentUpdate(value)}
                            delay={0}
                        />
                        %
                        <div className="total">${Utils.removePrecisionAndGetCommas(totalHistorical * (percent / 100))}</div>
                    </div>
                );
            default:
                throw new Error(`invalid AspirationalInputMethod key "${selectedIndex}"`);
        }
    }

    render() {
        const { selectedKey, handleOnSelect } = this.props;
        const options = [
            { label: 'Manual Entry', key: AspirationalInputMethod.keyManual },
            { label: 'Percentage', key: AspirationalInputMethod.keyPercentage },
        ];
        const content = this.getSelected(selectedKey);
        return (
            <div className="enter-aspirational-value">
                <OptionSelector
                    onSelectOption={(selectedOption) => handleOnSelect(selectedOption)}
                    options={options}
                    selectedKey={selectedKey}
                />
                { content }
            </div>
        );
    }
}

EnterAspirationValue.propTypes = {
    handleMoneyUpdate: PropTypes.func,
    handleOnSelect: PropTypes.func,
    handlePercentUpdate: PropTypes.func,
    percent: PropTypes.number,
    selectedKey: PropTypes.number,
    totalHistorical: PropTypes.number,
    totalMoneyValue: PropTypes.number,
};

export default EnterAspirationValue;
