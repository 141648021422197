import Industries from './Industry'

const IndustryCategories = Industries.reduce((prev, curr) => {
  if (prev.indexOf(curr.industry) == -1) {
    prev.push(curr.industry);
  }

  return prev;
}, []);

export default IndustryCategories;