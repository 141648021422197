"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDashboardScreenName = exports.DashboardScreen = void 0;
var DashboardScreen;
(function (DashboardScreen) {
    DashboardScreen["Summary"] = "Summary";
    DashboardScreen["Revenue"] = "Revenue";
    DashboardScreen["Profitability"] = "Profitability";
    DashboardScreen["Leverage"] = "Leverage";
    DashboardScreen["ROOProfitLeverage"] = "ROOProfitLeverage";
    DashboardScreen["Cash"] = "Cash";
    DashboardScreen["SensitivityAnalysis"] = "SensitivityAnalysis";
    DashboardScreen["OperationalStatements"] = "OperationalStatements";
})(DashboardScreen = exports.DashboardScreen || (exports.DashboardScreen = {}));
(function (DashboardScreen) {
    function values() {
        return [
            DashboardScreen.Summary,
            DashboardScreen.Revenue,
            DashboardScreen.Profitability,
            DashboardScreen.Cash,
            DashboardScreen.Leverage,
            DashboardScreen.ROOProfitLeverage,
            DashboardScreen.SensitivityAnalysis,
            DashboardScreen.OperationalStatements,
        ];
    }
    DashboardScreen.values = values;
})(DashboardScreen = exports.DashboardScreen || (exports.DashboardScreen = {}));
function getDashboardScreenName(screen) {
    switch (screen) {
        case DashboardScreen.Summary: return 'Summary';
        case DashboardScreen.Revenue: return 'Revenue';
        case DashboardScreen.Profitability: return 'Profitability';
        case DashboardScreen.Leverage: return 'Leverage';
        case DashboardScreen.ROOProfitLeverage: return 'ROO%';
        case DashboardScreen.Cash: return 'Cashflow';
        case DashboardScreen.SensitivityAnalysis: return 'Sensitivity';
        case DashboardScreen.OperationalStatements: return 'Operational Statements';
    }
}
exports.getDashboardScreenName = getDashboardScreenName;
