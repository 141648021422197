"use strict";
import alt from '../alt.ts';
var Actions = require('./../actions/SonAnswerActions');
var Source = require('./../sources/SonAnswerSource');
var SonAnswer = require('./../models/SonAnswer');

class SonAnswerStore {
    constructor() {
        //State
        this.answers = [];
        this.answer = null;
        this.errorMessage = null;
        this.isLoading = false;

        this.bindListeners({
            itemsReceived: Actions.itemsReceived,
            itemsReceiving: Actions.itemsReceiving,
            itemsReceivingFailed: Actions.itemsReceivingFailed,
            itemSaved: Actions.itemSaved,
            itemSaving: Actions.itemSaving,
            itemSavingFailed: Actions.itemSavingFailed
        });

        this.exportPublicMethods({
            getSonAnswers: this.getSonAnswers
        });

        this.exportAsync(Source);
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    itemSaved(data) {
        var answer = new SonAnswer(data);
        var answers = this.answers.slice(); // Clone

        var match = (a, b) => a.question_id == b.question_id && a.date == b.date;

        if (answers.filter(item => match(item, answer)).length == 0) {
            answers.push(answer);
        }

        answers = answers.map(item => {
            if (match(item, answers)) {
                return answer;
            }

            return item;
        });

        this.setState({
            answers: answers,
            answer: answer,
            errorMessage: null
        });
    }

    itemSaving() {
        // Do nothing for now.
    }

    itemSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsReceived(data) {
        this.setState({
            answers: data.map(item => new SonAnswer(item)),
            errorMessage: null,
            isLoading: false,
        });
    }

    itemsReceiving() {
        this.setState({
            isLoading: true,
        })
    }

    itemsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    // ----- PUBLIC -----
    getSonAnswers() {
        return this.getState().answers;
    }

}

module.exports = alt.createStore(SonAnswerStore, 'SonAnswerStore');
