import TagCategory from '../models/TagCategory';
import AccountNumberTag from '../models/AccountNumberTag';

import alt from '../alt.ts';
const Actions = require('./../actions/MonthActions');
const Source = require('./../sources/MonthSource');
const Month = require("../models/Month");
const AccountItemActions = require('../actions/AccountItemActions');
const AccountNumberGroupActions = require('../actions/AccountNumberGroupActions');
const TagCategoryActions = require('./../actions/TagCategoryActions.ts').default;

import { addTagsToAccountItems } from '../../utilities/AccountNumberTagUtils'

import Formatter from "../../Formatter";
import moment from 'moment';

import AccountItem from '../models/AccountItem';

class MonthStore {
    constructor() {
        // State
        this.months = [];
        this.month = null;
        this.historicMonths = [];
        this.lastHistoricMonth = null;

        this.errorMessage = null;

        this.tagsMap = { };
        this.accountNumberTags = { };

        this.batchSavingAccountItemsAndGroups = false;

        this.bindListeners({
            receivedItems: Actions.receivedItems,
            loadingItems: Actions.loadingItems,
            fetchingItemsFailed: Actions.fetchingItemsFailed,
            itemsSaved: Actions.itemsSaved,
            itemsSaving: Actions.itemsSaving,
            itemsSavingFailed: Actions.itemsSavingFailed,
            monthReceived: Actions.monthReceived,
            monthReceiving: Actions.monthReceiving,
            monthReceivingFailed: Actions.monthReceivingFailed,
            lastHistoricMonthReceived: Actions.lastHistoricMonthReceived,
            lastHistoricMonthReceiving: Actions.lastHistoricMonthReceiving,
            lastHistoricMonthReceivingFailed: Actions.lastHistoricMonthReceivingFailed,
            historicMonthsReceived: Actions.historicMonthsReceived,
            historicMonthsReceiving: Actions.historicMonthsReceiving,
            historicMonthsReceivingFailed: Actions.historicMonthsReceivingFailed,
            forecastMonthsReceived: Actions.forecastMonthsReceived,
            forecastMonthsReceiving: Actions.forecastMonthsReceiving,
            forecastMonthsReceivingFailed: Actions.forecastMonthsReceivingFailed,
            accountItemsBatchSaved: AccountItemActions.itemsSaved,
            groupsAndAccountItemsUpdated: AccountNumberGroupActions.groupsAndAccountItemsUpdated,
            groupsAndAccountItemsUpdating: AccountNumberGroupActions.groupsAndAccountItemsUpdating,
            groupsAndAccountItemsUpdatingFailed: AccountNumberGroupActions.groupsAndAccountItemsUpdatingFailed,
            flush: Actions.flush,
            flushing: Actions.flushing,
            flushingFailed: Actions.flushingFailed,
            monthIgnore: Actions.monthIgnore,
            dataCleared: Actions.dataCleared,

            // TagCategories
            tagCategoriesReceived: TagCategoryActions.receivedItems,

            // AccountNumberTags
            accountNumberTagsReceived: Actions.accountNumberTagsUpdated,

            monthSnapshotsSaved: Actions.monthSnapshotsSaved,
            monthSnapshotsSaving: Actions.monthSnapshotsSaving,
            monthSnapshotsSavingFailed: Actions.monthSnapshotsSavingFailed,
        });

        this.exportPublicMethods({
            getMonth: this.getMonth,
            getCurrentMonth: this.getCurrentMonth,
            getMonthWithDate: this.getMonthWithDate,
            getMonths: this.getMonths,
            getMonthsWithRange: this.getMonthsWithRange,
            saveMonth: month => this.onSaveMonth(month),
            saveMonths: months => this.onSaveMonths(months),
            getMonthIdForDate: this.getMonthIdForDate,
            isBatchUpdatingAccountItemsAndGroups: this.isBatchUpdatingAccountItemsAndGroups,
            // saveMonthSnapshots: this.saveMonthSnapshots,
        });

        this.exportAsync(Source);
    }

    /**
     * updates all month in the new state with the new account number tags
     * @param partialState
     */
    setStateAddTags(partialState) {
        // use the new accountNumberTags if passed in
        const accountNumberTags = partialState.accountNumberTags || this.accountNumberTags;

        [
            'months',
            'month',
            'historicMonths',
            'lastHistoricMonth',
        ].forEach(key => {
            const stateVal = partialState[key];

            if (stateVal !== undefined && stateVal !== null) {
                addTagsToAccountItems(partialState[key], this.tagsMap, accountNumberTags)
            }
        });

        this.setState(partialState)
    }

    clearErrors() {
        this.setState({
            errorMessage: null
        });
    }

    setErrors(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });
    }

    receivedItems(data) {
        this.setStateAddTags({
            months: data.map(item => new Month(item)),
            errorMessage: null
        });
    }

    // action: TagCategoryActions.receivedItems
    tagCategoriesReceived(tagCategoriesData) {
        const tagCategories = tagCategoriesData.map(item => new TagCategory(item));

        const tags = { };

        tagCategories.reduce((accum, cat) => accum.concat(cat.tags), []).forEach(tag => tags[tag.id] = tag);

        this.setState({
            tagsMap: tags,
        });
    }

    // action: Actions.accountNumberTagsUpdated
    accountNumberTagsReceived(accountNumberTags) {
        // const accountNumberTags = accountNumberTagsData.map(item => new AccountNumberTag(item));

        const accountNumberTagsMap = { };

        accountNumberTags.forEach(tag => accountNumberTagsMap[tag.account_number] = tag);

        this.setStateAddTags({
            ...this.state,
            accountNumberTags: accountNumberTagsMap,
        });
    }

    loadingItems() {
        // Do nothing
    }

    fetchingItemsFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    itemsSaved(data) {
        this.setStateAddTags({
            months: data.map(item => new Month(item))
        })
    }

    itemsSaving() {
        // Do nothing
    }

    itemsSavingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    monthReceived(data) {
        if (data == null) {
            this.setStateAddTags({
                month: null
            });
        }
        else if (Array.isArray(data)) {
            var months = data.map(item => new Month(item));

            if (months.length > 0) {
                var month = months.shift();
                var months = this.months;

                if (this.months.filter(item => item.id == month.id).length == 0) {
                    months = months.concat([month]);
                }
                //need to replace existing month if it was reloaded
                else {
                    months.forEach((m, i, a) => {
                        if (m.id == month.id) {
                            a[i] = month;
                        }
                    });
                }
                this.setStateAddTags({
                    month: month,
                    months: months
                });
            }
        }
        else {
            var month = new Month(data);
            var months = this.months;

            if (this.months.filter(item => item.id == month.id).length == 0) {
                months = months.concat([month]);
            }
            //need to replace existing month if it was reloaded
            else {
                months.forEach((m, i, a) => {
                    if (m.id == month.id) {
                        a[i] = month;
                    }
                });
            }
            this.setStateAddTags({
                month: month,
                months: months
            });
        }

        return data;
    }

    dataCleared() {
        this.setStateAddTags({
            months: [],
            month: null,
            historicMonths: [],
            lastHistoricMonth: null,
            errorMessage: null,
        });
    }

    monthReceiving() {
        // do nothing
    }

    monthReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    saving() {
        // Do nothing
    }

    savingFailed(errorMessage) {
        this.errorMessage = errorMessage;
    }

    lastHistoricMonthReceived(data) {
        let month = new Month(data.shift());

        this.setStateAddTags({
            lastHistoricMonth: month
        });

        return month;
    }

    lastHistoricMonthReceiving() {
        // Do nothing.
    }

    lastHistoricMonthReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    historicMonthsReceived(data) {
        let months = data.map(item => new Month(item));
        this.setStateAddTags({
            historicMonths: addTagsToAccountItems(months, this.tagsMap, this.accountNumberTags),
        });

        return months;
    }

    historicMonthsReceiving() {
        // Do nothing
    }

    historicMonthsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    forecastMonthsReceived(data) {
        let months = data.map(raw => new Month(raw));

        this.setStateAddTags({
            months: addTagsToAccountItems(months, this.tagsMap, this.accountNumberTags),
        });

        return months;
    }

    forecastMonthsReceiving() {
        // Do nothing
    }

    forecastMonthsReceivingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    updateItemsForMonth(month, updatedItems, deletedItems) {
        if (month === null) {
            return null;
        }
        const updatedItemsForMonth = updatedItems.filter(item => item.month_id === month.id);
        const deletedItemsForMonth = deletedItems.filter(item => item.month_id === month.id);
        const addedItemsForMonthIds = updatedItemsForMonth.map(item => item.id);
        const deletedItemsForMonthIds = deletedItemsForMonth.map(item => item.id);

        // remove existing items that have been updated
        // remove items that have been deleted
        // add new and updated items
        month.account_items = month.account_items
            .filter(item => !addedItemsForMonthIds.includes(item.id))
            .filter(item => !deletedItemsForMonthIds.includes(item.id))
            .concat(updatedItemsForMonth);
        return month;
    }

    // update account items in Months when batch AccountItem update occurs
    accountItemsBatchSaved(data) {
        const accountItems = data.map(raw => new AccountItem(raw));

        const addAndUpdateItems = month => this.updateItemsForMonth(month, accountItems, []);

        this.setStateAddTags({
            months: this.months.map(addAndUpdateItems),
            month: addAndUpdateItems(this.month),
            historicMonths: this.historicMonths.map(addAndUpdateItems),
            lastHistoricMonth: addAndUpdateItems(this.lastHistoricMonth),
        });
    }

    groupsAndAccountItemsUpdating() {
        this.setState({
            batchSavingAccountItemsAndGroups: true,
        });
    }

    groupsAndAccountItemsUpdatingFailed() {
        this.setState({
            batchSavingAccountItemsAndGroups: false,
        });
    }

    // update AccountItems in Months when batch AccountNumberGroup/AccountItem update occurs
    groupsAndAccountItemsUpdated(data) {
        const { account_items } = data;
        const updated = account_items.updated.map(raw => new AccountItem(raw));
        const deleted = account_items.deleted.map(raw => new AccountItem(raw));
        const addAndUpdateItems = month => this.updateItemsForMonth(month, updated, deleted);

        this.setStateAddTags({
            months: this.months.map(addAndUpdateItems),
            month: addAndUpdateItems(this.month),
            historicMonths: this.historicMonths.map(addAndUpdateItems),
            lastHistoricMonth: addAndUpdateItems(this.lastHistoricMonth),
            batchSavingAccountItemsAndGroups: false,
        });
    }

    isBatchUpdatingAccountItemsAndGroups() {
        return this.getState().batchSavingAccountItemsAndGroups === true;
    }

    flush() {
        this.setStateAddTags({
            months: [],
            month: null,
            lastHistoricMonth: null,
            errorMessage: null
        });
    }

    flushing() {
        // Do nothing.
    }

    flushingFailed(errorMessage) {
        this.setErrors(errorMessage);
    }

    monthIgnore(month) {
        //do nothing
    }

    monthSnapshotsSaved(data) { }

    monthSnapshotsSaving(data) { }

    monthSnapshotsSavingFailed(data) { }

    // ---- PUBLIC ----
    /**
     * Gets a Month from the state using the id
     * @param {string} id
     * @returns {Month|null}
     */
    getMonth(id) {
        var { months } = this.getState();
        return months
            .filter(months => months.id == id)
            .shift();
    }

    getCurrentMonth() {
        var { month } = this.getState();
        return month;
    }

    /**
     * Gets a Month from the state using the date
     * @param {string | Moment} date
     * @returns {Month|null}
     */
    getMonthWithDate(date) {
        var { months } = this.getState();

        if (typeof date != 'string') {
            // If we assume that's it's a moment, then we convert it to a string.
            date = Formatter.momentToServerDateFormat(date);
        }

        // return months
        //     .filter(month => month.date == date)
        //     .shift();

        var month = months.filter(function (month) {
                return month.date == date;
            }).shift();
        
        if (typeof month === "undefined"){
            return null;
        }
        return month;
    }

    getMonthIdForDate(date) {
        var month = this.getMonthWithDate(date);

        if (month == null) {
            return null;
        }

        return month.id;
    }
    

    getMonths() {
        return this.getState().months;
    }

    getMonthsWithRange(start, end) {
        let months = this.getMonths();

        return months.filter(item => !( item.Date.isBefore(moment(start)) || item.Date.isAfter(moment(end)) ) );
    }

    /**
     *
     * @param {Month} month
     */
    onSaveMonth(month) {
        if (!this.getInstance().isLoading()) {
            this.getInstance().performSave(month);
        }
    }

    /**
     *
     * @param {Month[]} months
     */
    onSaveMonths(months) {
        if (!this.getInstance().isLoading()) {
            this.getInstance().performSaveMonths(months);
        }
    }

    // /**
    //  *
    //  * @param {MonthSnapshot.Payload[]} snapshots
    //  */
    // saveMonthSnapshots(snapshots) {
    //     Source.performSaveMonthSnapshots(snapshots)
    // }
}

module.exports = alt.createStore(MonthStore, 'MonthStore');
