"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSonSectionMonthRef = exports.getFilteredSonSectionCollection = exports.getSonSectionCollection = void 0;
const firebase_config_1 = require("./firebase-config");
function getSonSectionCollection() {
    return firebase_config_1.firestore.collection('realtime-ceo').doc('son-communication').collection('sections');
}
exports.getSonSectionCollection = getSonSectionCollection;
function getFilteredSonSectionCollection(sectionTemplateId, month, accountId) {
    return getSonSectionCollection()
        .where('sectionTemplateId', '==', sectionTemplateId)
        .where('month', '==', month)
        .where('accountId', '==', accountId);
}
exports.getFilteredSonSectionCollection = getFilteredSonSectionCollection;
function getSonSectionMonthRef(sectionUid, sectionTemplateId, month, accountId) {
    return getSonSectionCollection()
        // .where('sectionTemplateId', '==', sectionTemplateId)
        // .where('month', '==', month)
        // .where('accountId', '==', accountId)
        .doc(sectionUid);
}
exports.getSonSectionMonthRef = getSonSectionMonthRef;
