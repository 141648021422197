import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

import Content from './Content';

class HeaderRow extends React.Component {

    getHistoricalTitle(formattedDate) {
        return `Actual 12 Months to ${formattedDate}`;
    }

    getHistoricalSingleTitle(formattedDate) {
        if (this.props.showHistoricalSingle) {
            return { value: `Actual Month ${formattedDate}` };
        }
        return null;
    }

    getForecastingTitle(showForecast, formattedDate) {
        if (showForecast === true) {
            return { value: `Forecast 12 Months to ${formattedDate}` };
        }
        return null;
    }

    render() {
        const { currentDate } = this.props;
        let currentMonth;
        let futureMonth;

        if (this.props.currentDate) {
            //For some reason, we have to first convert back to a real date.
            currentMonth = currentDate.format('MMM YYYY');
            futureMonth = currentDate.clone().add(1, 'years').format('MMM YYYY');
        }

        const leftData = { value: this.getHistoricalTitle(currentMonth) };
        const centerData = this.getHistoricalSingleTitle(currentMonth);
        const rightData = this.getForecastingTitle(this.props.showForecast, futureMonth);

        return (
            <div className="col-xs-12 header-row">
                <Content
                    name="Management Report"
                    leftData={leftData}
                    centerData={centerData}
                    rightData={rightData}
                />
            </div>
        );
    }
}

HeaderRow.propTypes = {
    currentDate: MomentPropTypes.momentObj,
    showForecast: PropTypes.bool,
    showHistoricalSingle: PropTypes.bool,
};

export default HeaderRow;
