import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */

const UserActions = alt.generateActions(
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',

    'itemSaved',
    'itemSaving',
    'itemSavingFailed',

    'passwordSaved',
    'passwordSaving',
    'passwordSavingFailed',

    'accountantRemoved',
    'accountantRemoving',
    'accountantRemovedFailed',

    'permissionsReceived',
    'permissionsReceiving',
    'permissionsReceivingFailed',

    'emailChanged',
    'emailChanging',
    'emailChangingFailed',
);

module.exports = UserActions;


