import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const AccountNumberTagsActions = alt.generateActions(
    'receivedItems',
    'loadingItems',
    'fetchingItemsFailed',
    'save',
    'saving',
    'savingFailed',
    'itemDeleted',
    'itemDeleting',
    'itemDeletingFailed',
    'batchSave',
    'itemDisabled',
);

module.exports = AccountNumberTagsActions;
