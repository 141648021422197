import alt from '../alt.ts';

/**
 * Lists of actions stores can subscribe to.
 */

const SonQuestionActions = alt.generateActions(
    'itemsReceived',
    'itemsReceiving',
    'itemsReceivingFailed',
    'itemSaved',
    'itemSaving',
    'itemSavingFailed',
    'itemDeleted',
    'itemDeleting',
    'itemDeletingFailed'
);

module.exports = SonQuestionActions;