import React from 'react';
/* eslint-disable react/no-multi-comp */
export default {
    SelectDate: {
        get Header() {
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <p>
                            Welcome to the Manual Entry Onboarding Wizard.
                            <br/>
                            In the Manual Entry Wizard, we will walk you through each section of the Income Statement and Balance sheet, and you will be prompted to type in values from your records.
                        </p>
                    </div>
                </div>
            );
        },
    },

    get IncomeStatementTotals() {
        return (
            <div>
                <h2>Income Statement</h2>
                <p>
                    <strong>Welcome to the RealTime CEO Manual Onboarding Wizard!
                        <br/>Start by entering the total values for your Income Statement for both periods.</strong>
                    <br/>Please take the time to ensure that the values are correct.
                    <br/>For now, don’t worry about the miscellaneous components of the income statement.. We will deal with them later.
                </p>
            </div>
        );
    },

    get InterestRevenue() {
        return (
            <div>
                <h2>Revenue and Interest Revenue</h2>
                <p>
                    There is an option to be more detailed about revenue in the next screen,
                    but for now, does your revenue total include any interest revenue? If so, please confirm the amount.
                </p>
            </div>
        );
    },

    get RevenueClasses() {
        return (
            <div>
                <h2>Create Revenue Classes</h2>
                <p>RealTime CEO believes understanding revenue is important to business success.
                    <br/><strong>If you already track revenue across multiple classes, we encourage you to add these now.</strong>
                    <br/>Click <img src="/images/btn_add_normal.png" className="inline-icon"/> to add a new revenue line. Remove a revenue line by clicking <img src="/images/btn_delete.png" className="inline-icon"/>
                </p>
                <p>
                    If you haven't considered this before, take some time to think about it to provide a more in-depth breakdown of revenue.
                    <br/>If you don't want to do this now, you may proceed and we will allocate your revenue to "Unallocated Remainder."
                </p>
            </div>
        );
    },

    get DirectCosts() {
        return (
            <div>
                <h2>Total Direct Costs</h2>
                <p>
                    In an earlier step you entered the direct cost total.
                    Now we need a little more information about the make up of that total.
                    Specifically there are some components that we need to perform the RTC calculations.
                    These are materials, wages (and salaries) and depreciation.
                    If your total includes these components, please detail the amounts.
                    The reminder of the total then gets allocated to “remainder”.
                    There will be opportunity later to split the remainder further.
                </p>
            </div>
        );
    },

    get IndirectCosts() {
        return (
            <div>
                <h2>Indirect Costs</h2>
                <p>
                    In an earlier step you entered the indirect cost total.
                    Now we need a little more information about the make up of that total.
                    Specifically there are some components that we need to perform the RTC calculations.
                    These are wages (and salaries), depreciation and interest expense.
                    If your total includes these components, please detail the amounts.
                    The reminder of the total then gets allocated to “remainder”.
                    The next step will provide an opportunity later to split the remainder further.
                </p>
            </div>
        );
    },

    get OtherIndirectCosts() {
        return (
            <div>
                <h2>Other Indirect Costs</h2>
                <p>
                    <strong>You may feel it is important to recognise specific other indirect costs that were not listed, e.g. <em>Marketing.</em></strong>
                    <br/>Click <img src="/images/btn_add_normal.png" className="inline-icon"/> to add a new line. Remove a line by clicking <img src="/images/btn_delete.png" className="inline-icon"/>
                    <br/>If you are not interested in adding more detail here, you can skip this step.
                </p>
            </div>
        );
    },

    get BalanceSheetTotals() {
        return (
            <div>
                <h2>Balance Sheet</h2>
                <p>
                    Please enter the period end values for all 3 years and ensure that the totals are in balance.
                </p>
            </div>
        );
    },

    get MiscWarning() {
        return (
            <span>
                Earlier you entered your Revenue, Direct cost, Indirect costs and Net Profit.
                The system has now calculated the difference in these entries and determined
                that you must have some other items that we will call "Miscellaneous".
                If that miscellaneous balance includes interest revenue, interest expense or depreciation,
                please confirm the amounts here. All 3 can be entered as positive numbers and the system
                will process them depending on their characteristics.
            </span>
        );
    },

    get TotalAssets() {
        return (
            <div>
                <h2>Total Assets</h2>
                <p>
                    In an earlier step you entered the assets total.
                    Now we need a little more information about the make up of that total.
                    Specifically there are some components that we need to perform the RTC calculations.
                    These are detailed in this step. Please confirm the value of these items in your assets total.
                    Once these have been entered, enter the remaining balance into "Other" and select next.
                </p>
            </div>
        );
    },

    get TotalLiabilities() {
        return (
            <div>
                <h2>Balance Sheet</h2>
                <p>
                    In an earlier step you entered the liabilities total.
                    Now we need a little more information about the make up of that total.
                    Specifically there are some components that we need to perform the RTC calculations.
                    These are detailed in this step. Please confirm the value of these items in your liabilities total.
                    Once these have been entered, enter the remaining balance into "Other" and select next.
                </p>
            </div>
        );
    },

    get TotalEquity() {
        return (
            <div>
                <h2>Total Equity</h2>
                <p>Enter your Equity for the end of each period.</p>
            </div>
        );
    },
};