"use strict";
import alt from '../alt.ts';

const Actions = alt.generateActions(
    'finished',
    'finishing',
    'finishingFailed',
    'queued',
    'queuing',
    'queuingFailed'
);

var Source = {
    finished(){
        return {
            remote(state){
                return Promise.resolve(1);
            },
            local(){
                return null;
            },
            success: Actions.finished,
            loading: Actions.finishing,
            error: Actions.finishingFailed
        }
    },
    queue() {
        return {
            remote(state){
                return Promise.resolve(1);
            },
            local(){
                return null;
            },
            success: Actions.queued,
            loading: Actions.queuing,
            error: Actions.queuingFailed
        }
    }
};

class AsyncQueueStore {
    constructor() {
        this.done = true;
        this.errorMessage = null;

        this.bindListeners({
            finished: Actions.finished,
            finishing: Actions.finishing,
            finishingFailed: Actions.finishingFailed,
            queued: Actions.queued,
            queuing: Actions.queuing,
            queuingFailed: Actions.queuingFailed
        });

        this.exportPublicMethods({
            isDone: this.isDone
        });

        this.exportAsync(Source);
    }

    finished() {
        this.setState({ done: true });
    }

    finishing() {

    }

    finishingFailed(errorMessage) {
        this.setState({
            done: true,
            errorMessage: errorMessage
        });
    }

    queued() {
        this.setState({
            done: false
        });
    }

    queuing() {

    }

    queuingFailed(errorMessage) {
        this.setState({
            errorMessage: errorMessage
        });

    }

    isDone() {
        return this.getState().done;
    }
}

module.exports = alt.createStore(AsyncQueueStore, 'AsyncQueueStore');