/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ProgressNavItem from './ProgressNavItem'
var ReactDOM = require('react-dom');

class ProgressNav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            selected: props.options.length > 0 ? 0 : -1
        };
    }

    componentWillUpdate() {
        var node = ReactDOM.findDOMNode(this);

        if (node.scrollTop > 175) {
            if (this.state.fixed === false) {
                this.setState({fixed: true});
            }
        } else {
            if (this.state.fixed === true) {
                this.setState({fixed: false});
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { selected } = this.state;

        if (nextProps.options != null && nextProps.options.length > 0) {
            let forcedSelection = nextProps.forcedSelection;
            if (typeof forcedSelection != "undefined") {
                let selectedOption = nextProps.options
                    .map((option, index) => ({name:option.name, index:index}))
                    .filter(option => option.name === forcedSelection)
                    .shift();
                if (typeof selectedOption != "undefined") {
                    this.setState({
                        selected: selectedOption.index
                    });
                    return;
                }
            }
            if(selected === -1) {
                this.onItemClick(nextProps.options[0], 0);
                this.setState({
                    selected: 0
                });
            }
        }
    }

    onItemClick(option, index) {
        this.onBecameActive(option, index);
    }

    onBecameActive(option, index) {
        this.setState({
            selected: index
        });

        this.props.onBecameActive(option, index);
    }

    get NavItems() {
        const { options, disabledOptions } = this.props;
        const { fixed, selected } = this.state;
        var selectedOption = options[selected];

        return options.map((option, index) => (
            <ProgressNavItem key={index}
                text={option.displayName || option.name}
                onClick={e => this.onItemClick(option, index)}
                disabled={disabledOptions.filter(disabledItem => disabledItem.name == option.name).length > 0}
                active={selectedOption.name == option.name}
            />
        ));

    }

    render() {
        const { options } = this.props;
        const { fixed, selected } = this.state;

        if (selected === -1 || options.length == 0) {
            return <div></div>;
        }

        return <div className={`progress-nav col-xs-12 ${fixed === true ? 'fixed' : 'normal'}`}>
            {this.NavItems}
        </div>
    }
}

ProgressNav.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired, // Options can be an array of strings or an array of { name: }
    disabledOptions: PropTypes.arrayOf(PropTypes.object),
    onBecameActive: PropTypes.func.isRequired,
    forcedSelection: PropTypes.string
};


export default ProgressNav;
