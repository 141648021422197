import Formatter from "../Formatter"
import ForecastSummary from "./ForecastSummary"
import AccountItem from "./AccountItem"

var moment = require('moment');

/**
 *
 * Creates a new RtcMonth
 *
 * @class
 *
 * @property {Object} options - Key value pairs.
 */
class RtcMonth {
    constructor(options) {
        var fields = [
            'id',
            'date',
            'forecast_summary',
            'is_forecast',
            'account_items'
        ];

        fields.forEach(fieldName => {
            if (options != null && options.hasOwnProperty(fieldName)) {
                var value = options[fieldName];

                switch (fieldName) {
                    case "date":
                        if (typeof value === "string") {
                            value = Formatter.serverFormatToMoment(options[fieldName]);
                        }
                        break;

                    case "forecast_summary":
                        value = new ForecastSummary(value);
                        break;

                    case "account_items":
                        // TODO: AccountItem constructor needs to take in "options", same as this
                        if (typeof value == "object") {
                            value = value.map(item => new AccountItem(item));
                        } else {
                            console.error("Expected account_items to be an array, got ", typeof value);
                        }
                        break;
                }

                this[fieldName] = value;
            }
        });

        return this;
    }

    static createMonth(date, isForecast) {
        return new RtcMonth({
            id: null,
            date: date,
            forecast_summary: ForecastSummary.createNewForecastSummary(),
            is_forecast: isForecast,
            account_items: [],
        });
    }

    clone() {
        return new RtcMonth({
            id: this.id,
            date: this.date.clone(),
            forecast_summary: this.forecast_summary.clone(),
            is_forecast: this.is_forecast,
            account_items: this.account_items.map(item => item.clone()),
        });
    }

    findAccountItemByAccountNumber(accountNumber) {
        for (var i=0; i<this.account_items.length; i++) {
            if(this.account_items[i].account_number == accountNumber) {
                return this.account_items[i];
            }
        }
        return null;
    }
}

export default RtcMonth;
