import React from 'react';
import PropTypes from 'prop-types';
import TabMenu from "../components/TabMenu"
import RealTimeTable from "../components/RealTimeTable"
var DataStore = require('../datastore');

class TagMonthColumns extends React.Component {
    onSelectColumn(columnIndex, value) {
        const { currentMonth } = this.props;
        this.props.onAttachTagToColumnIndex(currentMonth, columnIndex, value);
    }

    render() {
        const { rows, maxRows, currentMonthIndex } = this.props;

        if (currentMonthIndex != -1) {
            // We need to highlight all the month and show the month tags we've assigned for them.
        }

        return  <RealTimeTable
                rows={rows}
                maxRows={maxRows}
                fields={[]}
                onColumnClick={columnIndex => this.onSelectColumn(columnIndex, rows[0].row[columnIndex])}
                highlightColumnIndex={currentMonthIndex}
                />
                        
    }
}

export default TagMonthColumns;