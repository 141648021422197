"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedAssetsTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
exports.FixedAssetsTemplates = [{
        title: 'Fixed Assets - Likely Purchases',
        id: 'assets-likelyPurchase',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: false,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.fixedAssetRow,
    }, {
        title: 'Fixed Assets - Likely Disposals',
        id: 'assets-likelyDisposals',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: false,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.fixedAssetNoFundingRow
    }, {
        title: 'General Fixed Assets Notes',
        id: 'assets-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: true,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes
    }];
