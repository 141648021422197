"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newRowFromKind = exports.SonSectionRowKind = void 0;
const uuid_1 = require("uuid");
var SonSectionRowKind;
(function (SonSectionRowKind) {
    SonSectionRowKind["singleValueRow"] = "singleValueRow";
    SonSectionRowKind["fixedAssetRow"] = "fixedAssetRow";
    SonSectionRowKind["progressRow"] = "progressRow";
    SonSectionRowKind["notes"] = "notes";
    SonSectionRowKind["fixedAssetNoFundingRow"] = "fixedAssetNoFundingRow";
})(SonSectionRowKind = exports.SonSectionRowKind || (exports.SonSectionRowKind = {}));
function newRowFromKind(kind, subsection, valueType, rowName) {
    const name = rowName || '';
    switch (kind) {
        case SonSectionRowKind.progressRow:
            return {
                kind: SonSectionRowKind.progressRow,
                id: (0, uuid_1.v4)(),
                name: name,
                subSectionName: subsection || '',
                value: '',
                completed: false
            };
        case SonSectionRowKind.singleValueRow:
            return {
                kind: SonSectionRowKind.singleValueRow,
                id: (0, uuid_1.v4)(),
                name: name,
                subSectionName: subsection || '',
                value: '',
                valueType: valueType || 'text',
                comments: '',
                completed: false
            };
        case SonSectionRowKind.notes:
            return {
                kind: SonSectionRowKind.notes,
                id: (0, uuid_1.v4)(),
                name: name,
                value: ''
            };
        case SonSectionRowKind.fixedAssetRow:
            return {
                kind: SonSectionRowKind.fixedAssetRow,
                id: (0, uuid_1.v4)(),
                name: name,
                subSectionName: subsection || '',
                when: '',
                howMuch: '',
                likelyFunding: '',
                comments: '',
                completed: false
            };
        case SonSectionRowKind.fixedAssetNoFundingRow:
            return {
                kind: SonSectionRowKind.fixedAssetNoFundingRow,
                id: (0, uuid_1.v4)(),
                name: name,
                subSectionName: subsection || '',
                when: '',
                howMuch: '',
                likelyFunding: '',
                comments: '',
                completed: false
            };
        default: return {};
    }
}
exports.newRowFromKind = newRowFromKind;
