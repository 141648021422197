"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialsTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const dropDown = ['text', 'stability_dropdown', 'number'];
const textValue = ['text'];
exports.MaterialsTemplates = [
    {
        title: 'Materials',
        id: 'materials-materials',
        allowedValueTypes: dropDown,
        showComments: true,
        showCompletedCheckbox: false,
        showSubsections: true,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.Visible,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'General Materials Notes',
        id: 'materials-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }
];
