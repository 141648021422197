import React from 'react';
import PropTypes from 'prop-types';
/**
 * A Generic component that handles opening and closing when clicking the header.
 */
import Utils from "../utilities/Utils"

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: true
        }
    }

    onExpandToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    getCollapseIcon() {
        var iconClass = this.state.expanded ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";
        return React.Children.count(this.props.children) > 0 ? <span className={iconClass}></span> : null;
    }

    getTotalLabel() {
        // if (this.props.total <= 0) {
        //     return null;
        // } 
        return <div className="col-xs-5">
                <span className="total">
                    Total: ${Utils.removePrecisionAndGetCommas(this.props.total)}
                </span>
            </div>
    }

    render() {

        return <div className={`${this.props.className}`}>
            
            <div className={`col-xs-12 parent-row`} onClick={() => this.onExpandToggle()}>
            	{/* Heading Label*/}
            	<div className="col-xs-7">
					{this.getCollapseIcon()}
	                <span className="heading">
	                    {this.props.heading}
	                </span>
                </div>
                {/* Total Label */}
                {this.getTotalLabel()}
            </div>
            {this.state.expanded ? this.props.children : null}
        </div>
    }
}


Accordion.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    total: PropTypes.number, 
    calculatedTotal: PropTypes.number
};

export default Accordion;