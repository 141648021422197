import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class IconButton extends Component {
    render() {
        const { buttonType, onClick, className, tooltip } = this.props;

        let buttonClass;
        let glyphClass;

        switch (buttonType) {
            case 'edit':
                buttonClass = 'btn-action';
                glyphClass = 'glyphicon-pencil';
                break;
            case 'delete':
                buttonClass = 'btn-danger';
                glyphClass = 'glyphicon-remove';
                break;
            default:
                break;
        }

        return (
            <button title={tooltip} onClick={onClick} className={classNames('btn', 'icon-button', buttonClass, className)}>
                <span className={classNames('glyphicon', glyphClass)} />
            </button>
        );
    }

}

IconButton.propTypes = {
    buttonType: PropTypes.oneOf([
        'edit',
        'delete',
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
};

IconButton.ButtonType = {
    Edit: 'edit',
    Delete: 'delete',
};
