import DashboardSource from './DashboardSource';

class DashboardCalculator {
    /**
     * @param {moment} selectedDate
     * @param {number} numberOfPrecedingMonths number of exiting months of data before selected month
     *                 used to determine if each calculation can be displayed, based on how many months of data they require
     * @param {number} periodLength number of trailing months to calculator for, default 12
     * @param {Month[]} months
     * @param {AccountNumberGroup[]} revenueSources
     * @param {TagCategory[]} tagCategories
     * @param {AccountNumberTag[]} accountNumberTags
     */
    constructor(selectedDate, numberOfPrecedingMonths, periodLength, months, revenueSources, tagCategories, accountNumberTags) {
        this.periodLength = periodLength || 12;
        this.selectedDate = selectedDate;

        this.source = new DashboardSource(this.selectedDate, periodLength, months, revenueSources, tagCategories, accountNumberTags);
        this.numberOfPrecedingMonths = numberOfPrecedingMonths;
    }

    /**
     * is data available for this month, last year?
     * e.g. DEC 15 - DEC 16
     * 13 months including current month
     * needed for calculations that use last year
     * @returns {boolean}
     */
    get HasLastYear() {
        return this.numberOfPrecedingMonths >= this.periodLength;
    }

    /**
     * is data available for last two years?
     * e.g. JAN 15 - JAN 16 - DEC 16
     * 24 months including current month
     * need for calculations that use last year rolling
     * @returns {boolean}
     */
    get HasLastTwoYears() {
        return this.numberOfPrecedingMonths >= this.periodLength * 2 - 1;
    }

    /**
     * Revenue
     * Reference: D11
     * Spreadsheet Value: 15631272
     *
     * requires 11 prior months
     */
    get Revenue() {
        return this.source.TotalRevenueThisYearRolling;
    }

    get RevenuePreviousYear() {
        return this.source.TotalRevenueLastYearRolling
    }

    /**
     * Revenue (percentage change on previous year)
     * Reference: D12
     * Spreadsheet Value: 0.11
     *
     * requires 12 prior months
     */
    get RevenuePercentageChangeOnPreviousYear() {
        if (!this.HasLastTwoYears) return NaN;
        let revenue = this.Revenue;
        let lastYearsRevenue = this.RevenuePreviousYear;

        return (revenue - lastYearsRevenue) / lastYearsRevenue;
    }

    /**
     * (Increase) / Reduction in Accounts Receivable
     * Reference: D14 / BE240
     * Spreadsheet Value: -543401
     *
     * requires 12 prior months
     */
    get IncreaseReductionInAccountsReceivable() {
        if (!this.HasLastYear) return NaN;
        return -this.source.Less12MonthMovementInAccountsReceivable;
    }

    /**
     * Sales Cash In - Trailing
     * Reference: D16
     * Spreadsheet Value: 15087871
     *
     * requires 12 prior months
     */
    get SalesCashIn() {
        if (!this.HasLastYear) return NaN;
        return this.Revenue + this.IncreaseReductionInAccountsReceivable;
    }

    /**
     * Direct Costs (excluding depreciation)
     * Reference: D18
     * Spreadsheet Value:  11764738
     *
     * requires 11 prior months
     */
    get DirectCostsExcludingDepreciation() {
        return this.source.TotalDirectCostsThisYearRolling - this.source.TotalDepreciationDirectCostsThisYearRolling;
    }

    /*
     * requires 23 prior months
     */
    get DirectCostsExcludingDepreciationLastYear() {
        if (!this.HasLastTwoYears) return NaN;
        return this.source.TotalDirectCostsLastYearRolling - this.source.TotalDepreciationDirectCostsLastYearRolling;
    }

    /**
     * Direct Costs (excluding depreciation) (percentage change on previous year)
     * Reference: D19
     * Spreadsheet Value: 0.11
     *
     * requires 23 prior months
     */
    get DirectCostsExcludingDepreciationPercentChangeOnPreviousYear() {
        if (!this.HasLastTwoYears) return NaN;
        let directCostsRollingLastYear = this.DirectCostsExcludingDepreciationLastYear;//this.source.DirectCostsRollingLastYear;
        return (this.DirectCostsExcludingDepreciation - directCostsRollingLastYear) / directCostsRollingLastYear;
    }

    /**
     * Increase / (Reduction) in Inventory
     * Reference: D21
     * Spreadsheet Value: 98933
     *
     * requires 12 prior months
     */
    get IncreaseReductionInventory() {
        if (!this.HasLastYear) return NaN;
        return this.source.Less12MonthMovementInInventory;
    }

    /**
     * (Increase) / Reduction in Account Payable
     * Reference: D22
     * Spreadsheet Value: -368666
     *
     * requires 12 prior months
     */
    get IncreaseReductionAccountPayable() {
        if (!this.HasLastYear) return NaN;
        return -this.source.Add12MonthMovementInAccountsPayable;
    }

    /**
     * Direct Cash Out
     * Reference: D24
     * Spreadsheet Value:  11495005
     *
     * requires 12 prior months
     */
    get DirectCashOut() {
        if (!this.HasLastYear) return NaN;
        return this.DirectCostsExcludingDepreciation
            + this.IncreaseReductionInventory
            + this.IncreaseReductionAccountPayable;
    }

    /**
     * Cash Contribution Margin
     * Reference: D26
     * Spreadsheet Value: 3592866
     *
     * requires 12 prior months
     */
    get CashContributionMargin() {
        if (!this.HasLastYear) return NaN;
        return this.SalesCashIn - this.DirectCashOut;
    }

    /**
     * Indirect Costs (excluding depreciation and amortisation)
     * Reference: D28
     * Spreadsheet Value: 3342639
     *
     * requires 11 prior months
     */
    get IndirectCostsExcludingDepreciationAndAmortisation() {
        return this.source.TotalIndirectCostsThisYearRolling - this.source.TotalDepreciationIndirectCostsThisYearRolling;
    }

    /**
     * Indirect Costs (excluding depreciation and amortisation) (percentage change on previous year)
     * Reference: D29
     * Spreadsheet Value: 0.18
     *
     * requires 23 prior months
     */
    get IndirectCostsExcludingDepreciationAndAmortisationPercentChangeOnPreviousYear() {
        if (!this.HasLastTwoYears) return NaN;
        let indirectCostsRollingThisYear = this.source.TotalIndirectCostsThisYearRolling - this.source.TotalDepreciationIndirectCostsThisYearRolling;
        let indirectCostsRollingLastYear = this.source.IndirectCostsRollingLastYear - this.source.TotalDepreciationIndirectCostsRollingLastYear;

        return (indirectCostsRollingThisYear - indirectCostsRollingLastYear) / indirectCostsRollingLastYear;
    }

    /**
     * requires 11 prior months
     */
    get NetMiscellaneous() {
        return this.source.NetMiscellaneous;
    }

    /**
     * Miscellaneous Revenue
     * Reference: C31
     * Spreadsheet Value: 288931
     */
    //get MiscellaneousRevenue() {
    //    return this.source.TotalMiscellaneousRevenue;
    //}

    /**
     * Miscellaneous Expenses
     * Reference: D32
     * Spreadsheet Value: 231145
     */
    //get MiscellaneousExpenses() {
    //    return this.source.TotalMiscellaneousExpenses;
    //}

    /**
     * Cash from  / (Cash to) Misc Operating Balance Sheet Movements
     * Reference: D33
     * Spreadsheet Value: 225034
     *
     * requires 12 prior months
     */
    get CashFromCashToMiscOperatingBalanceSheetMovements() {
        if (!this.HasLastYear) return NaN;
        return this.source.CashMovementFromOtherBsItems;
    }

    /**
     * Operational Cash flow
     * Reference: D35
     * Spreadsheet Value: 533047
     *
     * requires 12 prior months
     */
    get OperationalCashFlow() {
        if (!this.HasLastYear) return NaN;
        return this.CashContributionMargin
            - this.IndirectCostsExcludingDepreciationAndAmortisation
            + this.NetMiscellaneous
            //+ this.MiscellaneousRevenue
            //- this.MiscellaneousExpenses
            + this.CashFromCashToMiscOperatingBalanceSheetMovements;
    }

    /**
     * Operational Cash flow %
     * Reference: D36
     * Spreadsheet Value: 0.0341
     *
     * requires 12 prior months
     */
    get OperationalCashFlowPercent() {
        if (!this.HasLastYear) return NaN;
        return this.OperationalCashFlow / this.Revenue;
    }

    /**
     * Operational Profit
     * Reference: D38
     * Spreadsheet Value: 491682
     *
     * requires 11 prior months
     */
    get OperationalProfit() {
        return this.source.OperatingProfit;
    }

    /**
     * Operational Profit %
     * Reference: D39
     * Spreadsheet Value: 0.0315
     *
     * requires 11 prior months
     */
    get OperationalProfitPercent() {
        return this.source.OperatingProfitPercent;
    }

    ///**
    // * Operational Profit %
    // * Reference: D49
    // * Spreadsheet Value: 0.0315
    // */
    //get OperationalProfitPercent() {
    //    return this.source.OperatingProfitPercent;
    //}

    /**
     * Total Salaries
     * Reference: D41
     * Spreadsheet Value: 4862276
     *
     * requires 11 prior months
     */
    get TotalSalaries() {
        return this.source.TotalSalaries;
    }

    /**
     * Salary Multiple
     * Reference: D42
     * Spreadsheet Value: 0.0321
     *
     * requires 11 prior months
     */
    get SalaryMultiple() {
        return this.Revenue / this.TotalSalaries;
    }

    /**
     * Total Raw Materials
     * Reference: D44
     * Spreadsheet Value: 7815636
     *
     * requires 11 prior months
     */
    get TotalRawMaterials() {
        return this.source.TotalRawMaterials;
    }

    /**
     * Raw Material Multiple
     * Reference: D45
     * Spreadsheet Value: 0.02
     *
     * requires 11 prior months
     */
    get RawMaterialMultiple() {
        return this.Revenue / this.TotalRawMaterials;
    }

    /**
     * Operational Investment
     * Reference: C47
     * Spreadsheet Value: 2253392
     *
     * requires only current month
     */
    get OperationalInvestment() {
        return this.source.Noa;
    }

    /**
     * Leverage
     * Reference: D48
     * Spreadsheet Value: 0.0694
     *
     * requires 11 prior months
     */
    get Leverage() {
        return this.source.Leverage;
    }

    /**
     * ROO %
     * Reference: C50
     * Spreadsheet Value: 0.2182
     *
     * requires 11 prior months
     */
    get RooPercent() {
        return this.source.RooPercent;
    }
}

export default DashboardCalculator;