"use strict";
class RevenueForecast {
    constructor(item) {
        this.engagement_probability = null;
        this.material_formula = '';
        this.material_amount = null;
        this.wages_amount = null;
        this.marriage_formula = '';
        this.engagement_amount = null;
        this.account_item_id = null;
        this.marriage_amount = null;
        this.wages_formula = '';
        this.engagement_formula = '';

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }

    /**
     *
     * @returns {string}
     * @constructor
     */
    get MaterialFormula() {
        return this.material_formula;
    }

    /**
     *
     * @returns {string}
     * @constructor
     */
    get WagesFormula() {
        return this.wages_formula;
    }

    get MarriageAmount() {
        return parseFloat(this.marriage_amount);
    }

    get EngagementAmount() {
        return parseFloat(this.engagement_amount);
    }

    get EngagementProbability() {
        return parseFloat(this.engagement_probability);
    }

    /**
     *
     * @param {string} value
     * @constructor
     */
    set MaterialFormula(value) {
        this.material_formula = value;
    }

    /**
     *
     * @param {string} value
     * @constructor
     */
    set WagesFormula(value) {
        this.wages_formula = value;
    }

    set MarriageAmount(value) {
        this.marriage_amount = value;
    }

    set EngagementAmount(value) {
        this.engagement_amount = value;
    }

    set EngagementProbability(value) {
        this.engagement_probability = value;
    }
}

module.exports = RevenueForecast;