"use strict";
class ForecastSummary {
    constructor(item) {
        this.material_dating_percent = 0;
        this.wages_dating_percent = 0;
        this.aspiration_amount = 0;
        this.aspiration_formula = null;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }

    get MaterialDatingPercent() {
        return this.material_dating_percent;
    }

    get WagesDatingPercent() {
        return this.wages_dating_percent;
    }

    get AspirationAmount() {
        return this.aspiration_amount;
    }

    get AspirationFormula() {
        return this.aspiration_formula;
    }
}

module.exports = ForecastSummary;
