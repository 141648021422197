"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaggingOkayIcon = exports.TagWarningIcon = void 0;
const React = require("react");
function TagWarningIcon(props) {
    return (React.createElement("img", { src: "/images/icon_tagging_alert.svg", width: 22, height: 22, 
        // className="TagWarningIcon"
        style: {
            marginLeft: `${props.marginLeft || 0}px`,
            marginRight: `${props.marginRight || 0}px`,
        } }));
}
exports.TagWarningIcon = TagWarningIcon;
function TaggingOkayIcon(props) {
    return (React.createElement("img", { src: "/images/icon_tagging_tick.svg", width: 22, height: 22, 
        // className="TagWarningIcon"
        style: {
            marginLeft: `${props.marginLeft || 0}px`,
            marginRight: `${props.marginRight || 0}px`,
        } }));
}
exports.TaggingOkayIcon = TaggingOkayIcon;
