"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EnumsTs_1 = require("../models/EnumsTs");
function getValues(dataPoints) {
    return dataPoints.map(it => it.y);
}
class OperationalStatementsSource {
    constructor(valuesFromStores, months) {
        this.valuesFromStores = valuesFromStores;
        this.months = months;
    }
    totalOfTagRolling(tagCode) {
        return getValues(this.valuesFromStores.getTotalForTagCodesRolling([tagCode], this.months));
    }
    totalOfTag(tagCode) {
        return getValues(this.valuesFromStores.getTotalForTagCodes([tagCode], this.months));
    }
    get TotalRevenue() { return getValues(this.valuesFromStores.getTotalOperationalRevenueRolling(this.months)); }
    get DirectRawMaterials() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_DirectCosts_Materials); }
    get DirectWages() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_DirectCosts_Wages); }
    get DirectDepreciation() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_DirectCosts_Depreciation); }
    get OtherDirectCosts() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_DirectCosts_Other); }
    get TotalDirectCosts() { return getValues(this.valuesFromStores.getTotalOperationalDirectCostsRolling(this.months)); }
    get ContributionMargin() { return getValues(this.valuesFromStores.getRollingContributionMargins(this.months)); }
    get IndirectWages() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_IndirectCosts_Wages); }
    get IndirectDepreciation() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_IndirectCosts_Depreciation); }
    get OtherIndirectCosts() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_IndirectCosts_Other); }
    get TotalIndirectCosts() { return getValues(this.valuesFromStores.getTotalOperationalIndirectCostsRolling(this.months)); }
    get NetMiscellaneous() { return getValues(this.valuesFromStores.getNetMiscellaneousRolling(this.months)); }
    get RealOperationalProfit() { return getValues(this.valuesFromStores.getRollingOperationalProfit(this.months)); }
    get NonOpRevenue() { return getValues(this.valuesFromStores.getTotalNonOpRevenueRolling(this.months)); }
    get InterestRevenue() { return getValues(this.valuesFromStores.getTotalInterestRevenueRolling(this.months)); }
    get NonOpExpense() { return getValues(this.valuesFromStores.getTotalNonOpExpenseRolling(this.months)); }
    get InterestExpense() { return getValues(this.valuesFromStores.getTotalInterestExpenseRolling(this.months)); }
    get Distributions() { return this.totalOfTagRolling(EnumsTs_1.TagCode.IS_Distributions_TotalDistributions); }
    get ChangeInRetainedEarnings() { return getValues(this.valuesFromStores.getChangeInRetainedEarningsRolling(this.months)); }
    get AccountsReceivable() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision); }
    get Inventory() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalAssets_InventoryNetOfProvision); }
    get FixedAssets() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalAssets_FixedAssetsNetOfProvision); }
    get OtherOperatingAssets() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalAssets_Other); }
    get TotalOperatingAssets() { return getValues(this.valuesFromStores.getOperatingAssets(this.months)); }
    get AccountsPayable() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalLiabilities_TradeAccountsPayable); }
    get OtherOperatingLiabilities() { return getValues(this.valuesFromStores.getOtherOperatingLiabilitiesForMonths(this.months)); }
    get TotalOperationalLiabilities() { return getValues(this.valuesFromStores.getTotalOperatingLiabilitiesForMonths(this.months)); }
    get OperationalInvestment() { return getValues(this.valuesFromStores.getOperationalInvestment(this.months)); }
    // Debt Funding
    get InterestBearingDebt() { return getValues(this.valuesFromStores.getInterestBearingDebt(this.months)); }
    get CashAtBank() { return getValues(this.valuesFromStores.getCashAtBankAsDebt(this.months)); }
    // get MiscellaneousInterestBearingAssets() { return getValues(this.valuesFromStores.getMiscInterestBearingAssetsAsDebt(this.months)); }
    // get MiscellaneousInterestBearingDebt() { return getValues(this.valuesFromStores.getMiscInterestBearingDebt(this.months)); }
    get MiscDebt() { return getValues(this.valuesFromStores.getMiscDebt(this.months)); }
    // get TaxPayable() { return getValues(this.valuesFromStores.getTaxPayable(this.months)); } // check
    get TotalDebt() { return getValues(this.valuesFromStores.getTotalDebt(this.months)); }
    get InitialCapital() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalEquity_InitialCapital); }
    get RetainedEarnings() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalEquity_RetainedEarnings); }
    // get DividendsPayable() { return this.totalOfTag(TagCode.BS_TotalLiabilities_DividendsPayable) }
    get MiscellaneousDebtInEquity() { return getValues(this.valuesFromStores.getMiscDebtInEquity(this.months)); }
    get MiscellaneousAssetsInEquity() { return getValues(this.valuesFromStores.getMiscAssetsAsDebtInEquity(this.months)); }
    get NonOperationalAssets() { return getValues(this.valuesFromStores.getNonOperationalAssetsInEquity(this.months)); }
    get NonOperationalLiabilities() { return getValues(this.valuesFromStores.getNonOperationalLiabilitiesInEquity(this.months)); }
    get OtherEquity() { return this.totalOfTag(EnumsTs_1.TagCode.BS_TotalEquity_Other); }
    get TotalEquity() { return getValues(this.valuesFromStores.getTotalOperationalEquity(this.months)); }
    get DebtPlusEquity() { return getValues(this.valuesFromStores.getDebtPlusEquity(this.months)); }
    get placeholder() { return this.months.map(() => Infinity); }
}
exports.default = OperationalStatementsSource;
