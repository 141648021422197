import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */

const AccountActions = alt.generateActions(
    'itemReceived',
    'itemReceiving',
    'itemReceivingFailed',

    'itemSaved',
    'itemSaving',
    'itemSavingFailed',

    'inviteSent',
    'inviteSending',
    'inviteSendingFailed',

    'inviteResent',
    'inviteResending',
    'inviteResendingFailed',

    'userDeleted',
    'userDeleting',
    'userDeletingFailed',

    'userPermissionsdUpdated',
    'userPermissionsdUpdating',
    'userPermissionsdUpdatingFailed',
);

module.exports = AccountActions;