class Colour {
    static LightenDarkenColor(col, amt) {
        var usePound = false;
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    }

    static Lighten(color, luminosity) {

        // validate hex string
        color = new String(color).replace(/[^0-9a-f]/gi, '');
        if (color.length < 6) {
            color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
        }
        luminosity = luminosity || 0;

        // convert to decimal and change luminosity
        var newColor = "#", c, i, black = 0, white = 255;
        for (i = 0; i < 3; i++) {
            c = parseInt(color.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(black, c + (luminosity * white)), white)).toString(16);
            newColor += ("00" + c).substr(c.length);
        }
        return newColor;
    }

    static HexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

}

export default Colour;