"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherTemplates = void 0;
const SonSectionRow_1 = require("../types/SonSectionRow");
const ForecastingNotesStyle_1 = require("../types/ForecastingNotesStyle");
const textValue = ['text'];
const multipleValues = ['text', 'stability_dropdown', 'number'];
exports.OtherTemplates = [{
        title: 'Other Costs',
        id: 'other-costs-v2',
        allowedValueTypes: multipleValues,
        showComments: true,
        showCompletedCheckbox: false,
        showSubsections: true,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: true,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Other Direct Costs',
        id: 'other-direct-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'Other Indirect Costs',
        id: 'other-indirect-v2',
        allowedValueTypes: [],
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.VisibleNoHeader,
        showLikelyFunding: false,
        showPageStatus: true,
        rowKind: SonSectionRow_1.SonSectionRowKind.singleValueRow,
    }, {
        title: 'General Other Notes',
        id: 'other-general',
        allowedValueTypes: textValue,
        showComments: false,
        showCompletedCheckbox: false,
        showSubsections: false,
        showTotalRow: false,
        showPreviousMonth: true,
        showResponsiblePerson: false,
        forecastingNotesStyle: ForecastingNotesStyle_1.ForecastingNotesStyle.None,
        showLikelyFunding: false,
        showPageStatus: false,
        rowKind: SonSectionRow_1.SonSectionRowKind.notes,
    }];
