import Formatter from "../Formatter"
var moment = require('moment');

class SonAnswer {
    constructor(answer, date, question_id) {
        this.answer = answer;
        this.date = date;
        this.question_id = question_id;

        if (typeof date !== "string") {
            this.date = date.format(Formatter.serverDateFormat);
        }
    }
}

export default SonAnswer;