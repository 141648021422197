/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-tiny-spin';
import AltContainer from 'alt-container';
import classNames from 'classnames';

import Datastore from '../datastore';
const { AsyncQueueStore } = Datastore;
import Popup from './Popup';

const LoadingIndicatorDebounce = 10;

class InnerIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            delayHide: false,
        };
    }

    getSpinner() {
        let spinCfg = {
            width: 12,
            radius: 35,
        };

        return (
            <Spinner config={spinCfg}/>
        );
    }

    componentWillReceiveProps(nextProps, nextState) {
        // debounce clearing the indicator so it doesn't flicker
        if (nextProps.done && !this.props.done) {
            this.setState({
                delayHide: true,
            }, () => {
                setTimeout(() => {
                    this.setState((state, props) => {
                        if (state.delayHide && props.done) {
                            return {
                                delayHide: false,
                            };
                        }
                        return null;
                    })
                }, LoadingIndicatorDebounce)
            });
        }
    }

    getPopupContent() {
        let spinCfg = {
            width: 12,
            radius: 35,
            position:"absolute",
            top:`80px`
        };

        return (
            <Popup>
                <div>
                    {this.props.children}
                    <div style={{position:"relative",height:"140px"}}>
                        <Spinner config={spinCfg}/>
                    </div>
                </div>
            </Popup>
        );
    }

    render() {
        const { done, fastEntryTransition } = this.props;

        const { alwaysDisplay } = this.props;

        if (done && !this.state.delayHide && alwaysDisplay !== true) {
            return (
                <div style={{ display: 'none' }} />
            );
        }

        if (this.props.children) {
            return this.getPopupContent();
        } else if (this.props.fullscreen === true) {
            return (
                <div className={classNames('loading-indicator-overlay', { fastEntryTransition })}>
                    {this.getSpinner()}
                </div>
            );
        } else {
            return this.getSpinner()
        }
    }
}

class LoadingIndicator extends React.Component {
    render() {
        return (
            <AltContainer
                stores={{ AsyncQueueStore }}
                render={props => (
                    <InnerIndicator done={props.AsyncQueueStore.done} {...this.props} />
                )}
            />
        )
    }
}

LoadingIndicator.propTypes = {
    fullscreen: PropTypes.bool,
    alwaysDisplay: PropTypes.bool, // force display, otherwise display is based on AsyncQueueStore loading status
    fastEntryTransition: PropTypes.bool, // causes the background dim animation to trigger immediately
};

export default LoadingIndicator;
