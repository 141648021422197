"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AspirationalInputMethod = exports.AspirationSplittingOptions = exports.AspirationalRevenueScreens = void 0;
var AspirationalRevenueScreens;
(function (AspirationalRevenueScreens) {
    AspirationalRevenueScreens["setAspirationalRevenue"] = "setAspirationalRevenue";
    AspirationalRevenueScreens["setMonthlySplitting"] = "setMonthlySplitting";
    AspirationalRevenueScreens["applyMonthlyTargets"] = "applyMonthlyTargets";
})(AspirationalRevenueScreens = exports.AspirationalRevenueScreens || (exports.AspirationalRevenueScreens = {}));
var AspirationSplittingOptions;
(function (AspirationSplittingOptions) {
    AspirationSplittingOptions[AspirationSplittingOptions["keyMonthlyTargets"] = 0] = "keyMonthlyTargets";
    AspirationSplittingOptions[AspirationSplittingOptions["keySeasonal"] = 1] = "keySeasonal";
})(AspirationSplittingOptions = exports.AspirationSplittingOptions || (exports.AspirationSplittingOptions = {}));
var AspirationalInputMethod;
(function (AspirationalInputMethod) {
    AspirationalInputMethod[AspirationalInputMethod["keyManual"] = 0] = "keyManual";
    AspirationalInputMethod[AspirationalInputMethod["keyPercentage"] = 1] = "keyPercentage";
})(AspirationalInputMethod = exports.AspirationalInputMethod || (exports.AspirationalInputMethod = {}));
