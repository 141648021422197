var AccountNumberTagStore = require('../').AccountNumberTagsStore;
var TagCategoryStore = require('../').TagCategoryStore;
import Utils from "../../utilities/Utils";

import { TagCategoryName } from "../../models/Enums"

import { getImportAccountNumberTag } from '../../utilities/AccountNumberTagUtils.ts';

class TagHelper {
    static getTag(accountNumber) {
        const ant = TagHelper.getAccountNumberTag(accountNumber);

        if (ant == null) {
            return null;
        }

        return ant.Tag;
    }

    static getTagCategory(accountNumber) {
        const tag = TagHelper.getTag(accountNumber);

        if (tag == null) {
            return null;
        }

        return TagCategoryStore.getTagCategory(tag.tag_category_id);
    }

    /**
     * @param accountNumber {string}
     * @return {AccountNumberTag | null}
     */
    static getAccountNumberTag(accountNumber) {
        const accountNumberTags = AccountNumberTagStore.getEnabledAccountNumberTags();

        return getImportAccountNumberTag(accountNumber, accountNumberTags);
    }

    /**
     * Transform the Tag structure, eg. [{ id, ledgerType, name, tags: [{ code, id, name, tag_category_id }] }]
     * For use in TagMenu
     *
     * @param {TagCategory[]} tags
     * @param {object} selectedLedger
     */
    static tagsToSectionsAndItems(tags, selectedLedger) {
        // 1. Filter out unselected ledgers.
        var sections = tags.filter(category => !selectedLedger || category.ledger_type == selectedLedger);

        // 2. Split into categories
        var groups = sections.reduce((prev, curr) => {
            if (prev.indexOf(curr.name) === -1) {
                prev.push(curr.name);
            }

            return prev;
        }, []);

        var categories = sections.reduce((prev, category) => {
            var sectionIndex = groups.indexOf(category.name);
            var section = {
                id: category.id,
                name: category.name,
                items: category.tags.map(tag => {
                    // identical to the tag model, but includes tagSubSection
                    return {
                        id: tag.id,
                        code: tag.code,
                        name: tag.name,
                        className: Utils.toClassName(category.name), // Special field that overrides the item class name.
                        tag_category_id: tag.tag_category_id,
                        tagSubSection: tag.tagSubSection

                    }
                })
            };

            prev[sectionIndex] = section;

            return prev;
        }, []);

        //move profit category before misc
        const profitCategory = categories.filter(cat => cat.name === TagCategoryName.Profit).shift();
        if (typeof profitCategory !== 'undefined') {
            categories = categories.filter(cat => cat.name !== TagCategoryName.Profit);
            categories.splice(3, 0, profitCategory);
        }

        return categories;
    }

}

export default TagHelper;