"use strict";
var Enums;
(function (Enums) {
    let TagCode;
    (function (TagCode) {
        TagCode["IS_Revenue_Revenue"] = "ISREV";
        TagCode["IS_Revenue_InterestRevenue"] = "ISINTREV";
        TagCode["IS_Revenue_TotalRevenue"] = "ISTOTREV";
        TagCode["IS_Revenue_Other"] = "ISREVOTR";
        TagCode["IS_Revenue_NonOperational"] = "ISREVNONOP";
        TagCode["IS_DirectCosts_Materials"] = "ISDIRMATS";
        TagCode["IS_DirectCosts_Wages"] = "ISDIRWAGES";
        TagCode["IS_DirectCosts_Depreciation"] = "ISDIRDEP";
        TagCode["IS_DirectCosts_TotalDirectCosts"] = "ISDIRCST";
        TagCode["IS_DirectCosts_Other"] = "ISDIROTR";
        TagCode["IS_DirectCosts_NonOperational"] = "ISDIRNONOP";
        TagCode["IS_IndirectCosts_Wages"] = "ISINTWAGES";
        TagCode["IS_IndirectCosts_Depreciation"] = "ISINTDEP";
        TagCode["IS_IndirectCosts_TotalIndirectCosts"] = "ISTOTINDCST";
        TagCode["IS_IndirectCosts_InterestExpense"] = "ISINDINTEXP";
        TagCode["IS_IndirectCosts_Other"] = "ISINDROTR";
        TagCode["IS_IndirectCosts_NonOperational"] = "ISINDNONOP";
        TagCode["IS_Misc_InterestRevenue"] = "ISMISCINTREV";
        TagCode["IS_Misc_InterestExpense"] = "ISMISCINTEXP";
        TagCode["IS_Misc_Depreciation"] = "ISMISCDEP";
        TagCode["IS_Misc_TotalMisc"] = "ISTOTMISC";
        TagCode["IS_Misc_Other"] = "ISMISCOTR";
        TagCode["IS_Misc_Wages"] = "ISMISCWAGES";
        TagCode["IS_Misc_NonOperational"] = "ISMISCNONOP";
        TagCode["IS_Distributions_Distributions"] = "ISDIST";
        TagCode["IS_Distributions_TotalDistributions"] = "ISTOTDIST";
        TagCode["IS_Distributions_Other"] = "ISDISTOTR";
        // IS_Distributions_NonOperational = 'ISDISTNONOP',
        TagCode["IS_NetProfit"] = "ISNETPROFIT";
        TagCode["BS_TotalAssets_BankAccountsAndCashDeposits"] = "BALBANK";
        TagCode["BS_TotalAssets_TradeAccountsReceivableNetOfProvision"] = "BALTRADE";
        TagCode["BS_TotalAssets_InventoryNetOfProvision"] = "BALINVEN";
        TagCode["BS_TotalAssets_FixedAssetsNetOfProvision"] = "BALFIXED";
        // BS_TotalAssets_NonInterestBearingLoansToRelatedParties = 'BALNONINTER',
        // BS_TotalAssets_InterestBearingLoansToRelatedParties = 'BALINTER',
        TagCode["BS_TotalAssets_LoansToRelatedParties"] = "BSTALOANSTO";
        TagCode["BS_TotalAssets_TotalAssets"] = "BALTOTASS";
        TagCode["BS_TotalAssets_Other"] = "BSTAOTR";
        TagCode["BS_TotalAssets_NonOperational"] = "BSTANONOP";
        TagCode["BS_TotalLiabilities_InterestBearingDebt"] = "BALINTBEDE";
        TagCode["BS_TotalLiabilities_TradeAccountsPayable"] = "BALTRADEP";
        TagCode["BS_TotalLiabilities_TaxPayable"] = "BALTAXP";
        // BS_TotalLiabilities_DividendsPayable = 'BALDIV',
        TagCode["BS_TotalLiabilities_DeferredPrepaidRevenue"] = "BALDEF";
        // BS_TotalLiabilities_NonInterestBearingLoansFromRelatedParties = 'BALNONINTFROM',
        // BS_TotalLiabilities_InterestBearingLoansFromRelatedParties = 'BALINTFROM',
        TagCode["BS_TotalLiabilities_LoansFromRelatedParties"] = "BSTLLOANSFROM";
        TagCode["BS_TotalLiabilities_TotalLiabilities"] = "BALTOTLIB";
        TagCode["BS_TotalLiabilities_Other"] = "BSTLOTR";
        TagCode["BS_TotalLiabilities_NonOperational"] = "BSTLNONOP";
        TagCode["BS_TotalEquity_InitialCapital"] = "BALCAPITAL";
        TagCode["BS_TotalEquity_RetainedEarnings"] = "BALRETEARN";
        TagCode["BS_TotalEquity_TotalEquity"] = "BALTOTEQU";
        TagCode["BS_TotalEquity_Other"] = "BSTEOTR";
    })(TagCode = Enums.TagCode || (Enums.TagCode = {}));
    const TagNameOther = 'Other';
    const TagNameNonOperational = 'Non Op';
    const TagNameTotal = 'Total';
    const TagNameDepreciation = 'Depr';
    const TagNameWages = 'Wages';
    Enums.ShortTagNames = {
        [TagCode.IS_Revenue_Revenue]: 'Rev',
        [TagCode.IS_Revenue_InterestRevenue]: 'Int Rev',
        [TagCode.IS_Revenue_TotalRevenue]: TagNameTotal,
        [TagCode.IS_Revenue_Other]: TagNameOther,
        [TagCode.IS_Revenue_NonOperational]: TagNameNonOperational,
        [TagCode.IS_DirectCosts_Materials]: 'Materials',
        [TagCode.IS_DirectCosts_Wages]: TagNameWages,
        [TagCode.IS_DirectCosts_Depreciation]: TagNameDepreciation,
        [TagCode.IS_DirectCosts_TotalDirectCosts]: TagNameTotal,
        [TagCode.IS_DirectCosts_Other]: TagNameOther,
        [TagCode.IS_DirectCosts_NonOperational]: TagNameNonOperational,
        [TagCode.IS_IndirectCosts_Wages]: TagNameWages,
        [TagCode.IS_IndirectCosts_Depreciation]: TagNameDepreciation,
        [TagCode.IS_IndirectCosts_TotalIndirectCosts]: TagNameTotal,
        [TagCode.IS_IndirectCosts_InterestExpense]: 'Int Exp',
        [TagCode.IS_IndirectCosts_Other]: TagNameOther,
        [TagCode.IS_IndirectCosts_NonOperational]: TagNameNonOperational,
        [TagCode.IS_Misc_InterestRevenue]: 'Int Rev',
        [TagCode.IS_Misc_InterestExpense]: 'Int Exp',
        [TagCode.IS_Misc_Depreciation]: TagNameDepreciation,
        [TagCode.IS_Misc_TotalMisc]: TagNameTotal,
        [TagCode.IS_Misc_Other]: TagNameOther,
        [TagCode.IS_Misc_Wages]: 'Wages',
        [TagCode.IS_Misc_NonOperational]: TagNameNonOperational,
        [TagCode.IS_Distributions_Distributions]: 'Tax/Dist',
        [TagCode.IS_Distributions_TotalDistributions]: TagNameTotal,
        [TagCode.IS_Distributions_Other]: TagNameOther,
        // [TagCode.IS_Distributions_NonOperational]: TagNameNonOperational,
        [TagCode.BS_TotalAssets_BankAccountsAndCashDeposits]: 'Bank',
        [TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision]: 'AR',
        [TagCode.BS_TotalAssets_InventoryNetOfProvision]: 'Inv',
        [TagCode.BS_TotalAssets_FixedAssetsNetOfProvision]: 'FA',
        [TagCode.BS_TotalAssets_LoansToRelatedParties]: 'RP Loans',
        // [TagCode.BS_TotalAssets_NonInterestBearingLoansToRelatedParties]: 'NIB to RP',
        // [TagCode.BS_TotalAssets_InterestBearingLoansToRelatedParties]: 'IB to RP',
        [TagCode.BS_TotalAssets_TotalAssets]: TagNameTotal,
        [TagCode.BS_TotalAssets_Other]: TagNameOther,
        [TagCode.BS_TotalAssets_NonOperational]: TagNameNonOperational,
        [TagCode.BS_TotalLiabilities_InterestBearingDebt]: 'Debt',
        [TagCode.BS_TotalLiabilities_TradeAccountsPayable]: 'AP',
        [TagCode.BS_TotalLiabilities_TaxPayable]: 'Tax',
        // [TagCode.BS_TotalLiabilities_DividendsPayable]: 'Div',
        [TagCode.BS_TotalLiabilities_DeferredPrepaidRevenue]: 'Def Rev',
        [TagCode.BS_TotalLiabilities_LoansFromRelatedParties]: 'RP Loans',
        // [TagCode.BS_TotalLiabilities_NonInterestBearingLoansFromRelatedParties]: 'NIB from RP',
        // [TagCode.BS_TotalLiabilities_InterestBearingLoansFromRelatedParties]: 'IB from RP',
        [TagCode.BS_TotalLiabilities_TotalLiabilities]: TagNameTotal,
        [TagCode.BS_TotalLiabilities_Other]: TagNameOther,
        [TagCode.BS_TotalLiabilities_NonOperational]: TagNameNonOperational,
        [TagCode.BS_TotalEquity_InitialCapital]: 'IC / Stock',
        [TagCode.BS_TotalEquity_RetainedEarnings]: 'RE',
        [TagCode.BS_TotalEquity_TotalEquity]: TagNameTotal,
        [TagCode.BS_TotalEquity_Other]: TagNameOther,
    };
    function getShortTagName(tagCode) {
        return Enums.ShortTagNames[tagCode] || null;
    }
    Enums.getShortTagName = getShortTagName;
})(Enums || (Enums = {}));
module.exports = Enums;
