"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Graphs_1 = require("../dashboard/Graphs");
const firebase_config_1 = require("../son-communication-2/firebase-config");
function onLogout() {
    void firebase_config_1.auth.signOut();
    delete localStorage.token;
    delete localStorage[Graphs_1.GRAPHS_LOCALSTORAGE_KEY];
    window.location.href = '/login';
}
exports.default = onLogout;
