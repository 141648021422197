/**
 * Determines the seasonally adjusted value for a value of a month.
 * Percentages are scaled appropriately when applying adjustment for a partial year.
 * i.e. for a partial year of 5 months, the percentages for those 5 months are collectively scaled to make 100%
 *
 * @param {number} initialValue year or partial year total value to adjust
 * @param {number} percentage seasonal percentage to apply
 * @param {number} numMonthsInYear number of months in year or partial year
 * @param {Array<{number}>} seasonality array of 12 seasonal percentages, used to scale percentages for partial year
 * @returns {number}
 */
function getSeasonalAdjustmentValue(initialValue, percentage, numMonthsInYear, seasonality) {
    // yearTotal assumed === 100 when numMonthsInYear === 12
    const yearTotal = seasonality.slice(0, numMonthsInYear).reduce((accum, num) => accum + num, 0);

    // scale percentage up so that numMonthsInYear percentages === 100%
    const adjustedPercentage = percentage / yearTotal * 100;
    return Math.round(initialValue * adjustedPercentage / 100.0);
}

module.exports = {
    getSeasonalAdjustmentValue,
};
