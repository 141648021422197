import React from 'react';
import PropTypes from 'prop-types';
var ReactTooltip = require("react-tooltip");

/**
 * A Generic component that handles opening and closing when clicking the header.
 */
class ToolTipAccordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        }
    }

    onExpandToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    getCollapseIcon() {
        var iconClass = this.state.expanded ? "glyphicon glyphicon-triangle-bottom" : "glyphicon glyphicon-triangle-right";
        return React.Children.count(this.props.children) > 0 ? <div className="col-xs-1 no-padding"><span className={iconClass}></span></div> : null;
    }

    getToolTip() {
        return <div className="col-xs-1 no-padding">
            <a data-tip={this.props.tooltip}>
                <span className="help pull-right"></span>
            </a>
            <ReactTooltip place="right" type="dark" effect="solid" offset={{bottom: 10 , right: 20 }} />

        </div>
    }

    render() {

        return <div className={`${this.props.className}`}>
            <div className={`col-xs-12 parent-row`} onClick={() => this.onExpandToggle()}>
                {this.getCollapseIcon()}
                <div className="heading col-xs-10 no-padding">
                    {this.props.heading}
                </div>
                {this.getToolTip()}
            </div>
            {this.state.expanded ? this.props.children : null}
        </div>
    }
}


ToolTipAccordion.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    tooltip: PropTypes.string
};

export default ToolTipAccordion;