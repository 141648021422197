import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popup from './Popup.tsx';

class PopupWithButtons extends React.Component {
    render() {
        const { onCancel, onDone, className, onDoneDisabled, leftButtonName, rightButtonName, doneButtonClass } = this.props;
        return (
            <Popup className={classNames('PopupWithButtons', className)}>
                <div className="container-fluid">
                    <div className="row popup-row">
                        <div className="col-xs-12 no-padding">
                            {this.props.children}
                        </div>
                    </div>
                    <div className="button-row row popup-button-row">
                        <div className="col-xs-12 no-padding">
                            <button
                                className="btn btn-primary forecast-button pull-left"
                                onClick={onCancel}
                            >
                                {leftButtonName || 'Cancel'}
                            </button>
                            <button
                                disabled={onDoneDisabled}
                                className={classNames('btn', doneButtonClass || 'btn-action', 'forecast-button', 'pull-right', { 'btn-disabled': onDoneDisabled })}
                                onClick={onDone}
                            >
                                {rightButtonName || 'Accept & Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

PopupWithButtons.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    doneButtonClass: PropTypes.string,
    leftButtonName: PropTypes.string,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onDoneDisabled: PropTypes.bool,
    rightButtonName: PropTypes.string,
};

export default PopupWithButtons;
