/* eslint-env browser */

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.tsx';
import trackJs from './modules/utilities/trackJs';
import sessionstack from './modules/utilities/sessionstack';


trackJs.init();
sessionstack.init();

if (!HTMLCollection.prototype[Symbol.iterator]) {
    HTMLCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
}

if (!NamedNodeMap.prototype[Symbol.iterator]) {
    NamedNodeMap.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
}

ReactDOM.render(<App />, document.getElementById('container'));
