// @ts-check
/* eslint-disable semi */
import React from 'react';
import AltContainer from 'alt-container';
import moment from 'moment';
/** @typedef { import('moment').Moment } Moment */

import PropTypes from 'prop-types';

import MonthSelector from '../components/MonthSelector';
import LoadingIndicator from '../components/LoadingIndicator';

/** @typedef { import('./GenericOnboardingAccordion.tsx') }  GenericOnboardingAccordion */
/** @typedef { import('./OnboardingAdjustmentRows') }  OnboardingAdjustmentRows */
/** @typedef { import('./OnboardingWizardTypes').MultiPeriodNbaData } MultiPeriodNbaData */

import { getSeasonalAdjustmentValue } from './utilities';

import WizardScreen from './WizardScreen';
import Formatter from '../Formatter.ts';
import Accordion from '../components/Accordion';
import DetailedAccordion from '../components/DetailedAccordion';
import GenericOnboardingAccordion from './GenericOnboardingAccordion.tsx';
import OnboardingDateHeader from './OnboardingDateHeader.tsx';
import OnboardingAdjustmentRows from './OnboardingAdjustmentRows.tsx';

import Clicker from '../components/Clicker';
import FileSelector from '../components/FileSelector';
import {
    LedgerTypes,
    TagCategoryName,
    SpecialAccountItems,
    UploadTypes,
    CsvType,
    Fields,
    VideoLinks, LedgerName
} from '../models/Enums';
import {
    TagCode
} from '../models/EnumsTs.ts'
import Import from '../import/Import';
import ImportText from '../import/Text';
import Utils, {toClassName} from '../utilities/Utils';
import TagColumns from '../historical/TagColumns';
import TagRows from '../historical/TagRows';
import Popup from '../components/Popup';
import { OnboardingLineGraph } from '../dashboard/Graphs.tsx';
import DebounceInput from '../components/Debounce';
import OptionSelector from '../components/OptionSelector';
import InfoButton from '../components/InfoButton.tsx';
import OnboardingFullScreenContainer from './OnboardingFullScreenContainer';
import ImportErrorTable from '../historical/ImportErrorTable.tsx';

import { generateNewAccountNumberTags } from '../utilities/AccountNumberTagUtils.ts'

import Text from './Text';

import CategoryUtils from '../utilities/CategoryUtils';

import ProgressBar from '../components/ProgressBar';
import PreviewTable from '../components/PreviewTable.tsx';
import { 
    getWagesAccountNumberTags,
    getCostsAccountNumberTags,
    applyWagesAdjustment,
    applyNonBusinessExpensesAdjustment,
 } from '../utilities/Adjustments';

import {TaggingErrorPopup} from "../import/TaggingErrorPopup";

const DataStore = require('../datastore');

import AccountNumberTagSuggestionBox from '../view-historical-data/AccountNumberTagSuggestionBox.tsx';
import TagButton from '../components/TagButton';
import {TagWarningIcon} from "../historical/importIcons";
import CsvConflictMessageBox from '../historical/CsvConflictMessageBox.tsx';

import MultiPeriodValues from './MultiPeriodValues.ts';
import { MultiPeriodNosPopup, MultiPeriodNbaPopup } from './adjustmentsPopups';
/** @typedef { import('./MultiPeriodValues').MultiPeriodAccountItemsStruct } MultiPeriodAccountItemsStruct */

const NonBusinessAdjustmentsText = ImportText.NonBusinessAdjustments;
const {
    MonthStore,
    AccountItemStore,
    TagCategoryStore,
    AccountNumberTagsStore,
    AsyncQueueStore,
    FieldStore,
} = DataStore;

import AccountItem from '../datastore/models/AccountItem';
import AccountNumberTag from '../datastore/models/AccountNumberTag';
import Month from '../datastore/models/Month';

const isNullOrUndefined = function (val) {
    return val === null || typeof val === 'undefined';
};

const exists = function (val) {
    return !isNullOrUndefined(val);
};

import {
    WizardPages, WizardPagesTotal, YearType, MonthIndices
} from './OnboardingWizardConstants.ts'
import { AccountItemTagQuery } from '../utilities/AccountItemQueryTs';
import IconButton from '../components/IconButton';
import { makeUnspecifiedRemainderName } from '../import/ImportTs';

/**
 * @typedef { import('./OnboardingWizardTypes').Props } Props
 */

 /**
  * @typedef { import('./OnboardingWizardTypes').State } State
  */

/**
 * @extends {React.Component<Props, State>}
 * @property {State} this.state
 */
class OnboardingWizard extends React.Component {
    constructor(props) {
        super(props);

        /** @type {State} */
        this.state = {
            screen: 0,
            testVal: 0,
            ready: false,

            import_first_month: new Import(),

            import_current_year: new Import(),
            import_previous_year: new Import(),

            // Month range selection
            lastYearDate: Formatter.discardDatePrecision(moment().subtract(2, 'months')),
            thisYearDate: Formatter.discardDatePrecision(moment().subtract(1, 'months')),

            startYearBalanceSheetFileName: '',

            nosAdjustments: [],
            nbaAdjustments: [],
        
            nosPopupShown: false,
            nbaPopupShown: false,

            // Tag Rows ledger
            selectedLedgerType: LedgerTypes.IncomeStatement,
            ledgerTypes: [
                LedgerTypes.IncomeStatement,
                LedgerTypes.BalanceSheet
            ],

            //non business adjustments
            //adjustment to reported indirect costs
            // indirectCostsNonBusinessAdjustments: new MultiPeriodValues(),
            // directCostsNonBusinessAdjustments: new MultiPeriodValues(),
            // miscRevenueNonBusinessAdjustments: new MultiPeriodValues(),
            // miscExpenseNonBusinessAdjustments: new MultiPeriodValues(),
            //amount paid to owners as wages (owners may be paid in part or full by dividends)
            // salaryPaidToOwners: new MultiPeriodValues(),
            //amount owner would be paid in wages if they were paid only in wages, and not dividends
            // equivalentFullOwnersSalary: new MultiPeriodValues(),

            //selection of wages adjustment line
            // wagesSuggestionInputValue: '',
            // wagesAccountNumberTagData: null,

            // Hard coded values for initialisation. these are changed when we enter the seasonal screen.
            seasonalData: {
                values: [
                    { x: 'Jul', y: 8.3333 },
                    { x: 'Aug', y: 8.3333 },
                    { x: 'Sep', y: 8.3333 },
                    { x: 'Oct', y: 8.3333 },
                    { x: 'Nov', y: 8.3333 },
                    { x: 'Dec', y: 8.3333 },
                    { x: 'Jan', y: 8.3333 },
                    { x: 'Feb', y: 8.3333 },
                    { x: 'Mar', y: 8.3333 },
                    { x: 'Apr', y: 8.3333 },
                    { x: 'May', y: 8.3333 },
                    { x: 'Jun', y: 8.3333 },
                ]
            },

            popup: null,

            // tagging sections
            tagRowsSection: null,

            // disable buttons when pressed
            finishManualEntryPressed: false,
            seasonalOnNextPressed: false,

            taggingErrors: null,

            loadingMessage: null,
        };
    }

    dismissPopup() {
        this.setState({
            popup: null
        });
    }

    showPopup(popup) {
        this.setState({
            popup,
        });
    }

    /**
     *
     * @param importsWithLabels
     * @param onSuccess
     */
    validateImports(importsWithLabels, onSuccess) {
        try {
            const errors = importsWithLabels
                .map(it => ({ errors: it.import.validateImportTagging(), label: it.label }))
                .filter(it => !!it.errors);

            if (errors.length > 0) {
                this.setState({
                    taggingErrors: errors,
                });
                return;
            }
        } catch (err) {
            this.setState({
                taggingErrors: err,
            });
            return;
        }

        onSuccess();
    }

    getTaggingErrorPopup() {
        const { taggingErrors } = this.state;

        if (!taggingErrors) {
            return null;
        }

        let onDismiss = () => {
            this.setState({
                taggingErrors: null,
            });
        };

        return (
            <TaggingErrorPopup
                {...(
                    Array.isArray(taggingErrors)
                        ? { labelledErrors: taggingErrors }
                        : { error: taggingErrors }
                )}
                onClose={onDismiss}
                tagCategories={TagCategoryStore.getTagImportCategories()}
            />
        );
    }

    showOnCancelWizardPopup() {
        const onProceed = () => {
            this.dismissPopup();

            this.gotoScreen(WizardPages.SelectOnboardingMethod);

            // reset state
            this.setState({
                finishManualEntryPressed: false,

                nosAdjustments: [],
                nbaAdjustments: [],
            });
        };

        const onCancel = () => {
            this.dismissPopup();
        };

        const popup = (
            <Popup>
                <h3>Restart Wizard?</h3>
                <br />
                <div className="text-left">
                    <p>Do you want to restart the Onboarding Wizard?</p>
                    <p>CSV Import tagging will be retained, but your progress will be lost.<br/>You will be able to re-select CSV files, or switch between Manual and Import methods.</p>
                    <br />
                    <div className="row text-center">
                        <div className="col-xs-6">
                            <button className="btn btn-danger wide" onClick={onProceed}>Restart Wizard</button>
                        </div>
                        <div className="col-xs-6">
                            <button className="btn btn-action wide" onClick={onCancel}>Continue</button>
                        </div>
                    </div>
                </div>
            </Popup>
        );

        this.showPopup(popup);
    }

    get ThisYearDate() {
        return this.state.thisYearDate.clone();
    }

    get LastYearDate() {
        return this.state.lastYearDate.clone();
    }

    get StartYearDate() {
        return this.LastYearDate.subtract(12, 'months');
    }

    get NumberOfMonthsThisYear() {
        return this.ThisYearDate.diff(this.LastYearDate, 'months');
    }

    get StartMonth() {
        const months = MonthStore.getMonths();
        if (months.length > 12) {
            return months[MonthIndices.first];
        }
        throw Error(`not enough months: ${months.length} months`);
    }

    get LastYearMonth() {
        const months = MonthStore.getMonths();
        if (months.length > 12) {
            return months[MonthIndices.lastYear];
        }
        throw Error(`not enough months: ${months.length} months`);
    }

    get ThisYearMonth() {
        const months = MonthStore.getMonths();
        if (months.length > 12) {
            return months[months.length - 1];
        }
        throw Error(`not enough months: ${months.length} months`);
    }

    /**
     * 
     * @param {string} accountNumber 
     * @returns {MultiPeriodAccountItemsStruct | null}
     */
    getAccountItemMultiYear(accountNumber) {
        const items = {
            startMonth: this.StartMonth.AccountItems.filter(item => item.account_number == accountNumber).shift(),
            lastYear: this.LastYearMonth.AccountItems.filter(item => item.account_number == accountNumber).shift(),
            thisYear: this.ThisYearMonth.AccountItems.filter(item => item.account_number == accountNumber).shift()
        };

        if (typeof items.lastYear === 'undefined' ||
            typeof items.thisYear === 'undefined') {
            return null;
        }
        return items;
    }

    duplicateAccountItemsForMonth(accountItems, monthId) {
        return accountItems.map(item => {
            const newItem = new AccountItem(
                item
            );
            newItem.month_id = monthId;
            return newItem;
        });
    }

    componentDidMount() {
        MonthStore.fetchAllMonths()
            .catch(err => {
                if (err.status === 404) {
                    //months haven't been created yet
                    return;
                }
                throw err;
            })
            .then(() => TagCategoryStore.fetchData())
            .then(() => AccountNumberTagsStore.fetchData())
            .then(() => FieldStore.fetchData())
            .then(() => {
                // This is first page load, need to move user if they are in import, they might be in a screen not valid
                // without data. (All CSV data is stored in the state, lost on page reload)
                const currentPageId = this.getWizardScreen();
                if (this.isPageIDofType(currentPageId, WizardPages.Import)) {
                    if (![WizardPages.Welcome.pageId, WizardPages.SelectOnboardingMethod.pageId].includes(currentPageId)) {
                        return this.onUpdateOnboardingProgress(WizardPages.SelectOnboardingMethod.pageId)
                            .then(() => this.showPopup(this.getProgressLostPopup()));
                    }
                }

                if ([WizardPages.AdjustmentsNonBusinessExpenses.pageId, WizardPages.SeasonalAdjustments.pageId].includes(currentPageId)) {
                    return this.onUpdateOnboardingProgress(WizardPages.AdjustmentsOwnersSalary.pageId);
                }
            })
            .then(() => {
                this.setState({ ready: true });
            });
    }

    /**
     * 
     * @param {string} val 
     * @param {YearType} period 
     * @param {"salaryPaidToOwners" | "salaryPaidToOwners" | "equivalentFullOwnersSalary" | "equivalentFullOwnersSalary"} item 
     * @deprecated
     */
    onChangeAdjustmentsItem(val, period, item) {
        const updatedData = this.state[item].clone();
        const parsedVal = Utils.parseMoney(val);

        //if NaN, just update state to reset value
        if (!isNaN(parsedVal)) {
            switch (period) {
                case YearType.CurrentYear:
                    updatedData.ThisYearValue = parsedVal;
                    break;
                case YearType.LastYear:
                    updatedData.LastYearValue = parsedVal;
                    break;
                default:
                    return;
            }
        }

        /* eslint-disable react/no-set-state */
        this.setState({
            [item]: updatedData,
        });
    }

    getAccount() {
        return this.props.accountStore.getAccount();
    }

    getContent(pageId) {
        if (!this.state.ready) {
            return <div />;
        } else if (pageId == null) {
            return null;
        }

        // Special Cases
        if (pageId == WizardPages.Welcome.pageId) {
            const displayName = this.props.user.first_name;

            const title = (
                <div>
                    <h2 className="text-center">
                        Hello {displayName}!<br />Welcome to RealTime CEO.
                    </h2>
                </div>
            );

            return (
                <WizardScreen
                    onFinish={() => this.nextScreen()}
                    finishButtonText="Get Started"
                >
                    <div className="row">
                        {title}
                        <div className="text-center">
                            <iframe
                                className="welcome-video"
                                width="800"
                                height="450"
                                src="https://www.youtube.com/embed/RvupN7TUfR4?rel=0&amp;showinfo=0"
                              />
                            <div className="flex-center">
                                <div className="tip-container">
                                    <img className="tip-icon" src="/images/ic_video_grey.svg" />
                                    <div className="tip-body">
                                        <p><strong>TIP:</strong> Wherever you see this icon, click it to see a short
                                            tutorial video.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardScreen>
            );
        } else if (pageId == WizardPages.SelectOnboardingMethod.pageId) {
            const setMonthsToDate = (val) => {
                this.setState({
                    lastYearDate: Formatter.discardDatePrecision(this.ThisYearDate.subtract(val, 'months')),
                });
            };

            const format = _moment => _moment.format(Formatter.shortMonthOnlyDisplayFormat);

            const monthsDates = {
                thisYearStart: format(this.LastYearDate.add(1, 'months')),
                thisYearEnd: format(this.ThisYearDate),
                lastYearStart: format(this.LastYearDate.subtract(11, 'months')),
                lastYearEnd: format(this.LastYearDate),
                startYear: format(this.StartYearDate),
                numMonthsThisYear: this.NumberOfMonthsThisYear,
            };

            const currentMonthDate = Formatter.discardDatePrecision(moment());
            const lastMonthDate = Formatter.discardDatePrecision(currentMonthDate.clone().subtract(1, 'month'));
            const isSelectedMonthBeforeLastMonth = this.ThisYearDate.isBefore(lastMonthDate);

            return (
                <WizardScreen
                    onBack={() => this.previousScreen()}
                    onNext={() => {
                        this.setState({
                            import_first_month: new Import(),
                            import_current_year: new Import(),
                            import_previous_year: new Import(),
                        });
                        this.gotoScreen(WizardPages.Import.SelectFiles)
                    }}
                    nextButtonText="Next: Select and Import CSV Files"
                >
                    <div className="row">
                        <div>
                            <h2 className="text-center">
                                RealTime CEO Onboarding Wizard
                            </h2>
                        </div>
                        <div className="col-xs-10 col-xs-offset-1">
                            {Text.WelcomeScreen.Introduction}
                            <div className="row">
                                <div className="col-xs-6"><h3>Last Month of File 4:</h3></div>
                                <div className="col-xs-6"><h3>Number of Months in File 4:</h3></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6">
                                    <MonthSelector
                                        onDateChanged={date => this.onThisYearChange(date)}
                                        date={this.ThisYearDate}
                                        alignment={'left'}
                                        forwardEnabled={isSelectedMonthBeforeLastMonth}
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <Clicker
                                        minValue={1}
                                        maxValue={12}
                                        onChange={setMonthsToDate}
                                        stateless
                                        value={this.NumberOfMonthsThisYear}
                                    />
                                </div>
                            </div>
                            <br />
                            {Text.WelcomeScreen.getDateList(monthsDates)}
                        </div>
                    </div>
                </WizardScreen>
            );
        } else if (pageId === WizardPages.AdjustmentsOwnersSalary.pageId) {
            const { nosPopupShown, nosAdjustments } = this.state;

            const accountItems = [this.StartMonth, this.LastYearMonth, this.ThisYearMonth]
                .reduce((accum, month) => accum.concat(month.account_items), []);

            const tagQuery = new AccountItemTagQuery(TagCategoryStore.getTagCategories())

            return (
                <WizardScreen
                    backDisabled
                    onNext={() => this.nextScreen()}
                    number={1}
                    total={3}
                    pageNumberLabel="Adjustments"
                    title={`Onboarding Wizard - ${NonBusinessAdjustmentsText.AdjustmentsTitle}`}
                    videoId={VideoLinks.OnboardingWizard.NormalisationOfOwnersSalary}
                >
                    {NonBusinessAdjustmentsText.NormalisationOfOwnersSalary.Title}
                    {NonBusinessAdjustmentsText.NormalisationOfOwnersSalary.Description}
                    {NonBusinessAdjustmentsText.AdjustmentsExplanationHelp}
                    <OnboardingAdjustmentRows
                        yearHeader={this.getYearHeader(false, true)}
                        onRemove={deleteIndex => {
                            this.setState({
                                nosAdjustments: nosAdjustments.filter((_, ind) => ind !== deleteIndex),
                            })
                        }}
                        items={
                            nosAdjustments.map(
                                /**
                                 * @returns {{
                                        label: string
                                        valueLastYear: number
                                        valueThisYear: number
                                    }}
                                */
                                (adjustment) => {
                                const adjustedValueLastYear = adjustment.valueCommercialWageLastYear - adjustment.valueAmountPaidLastYear
                                const adjustedValueThisYear = adjustment.valueCommercialWageThisYear - adjustment.valueAmountPaidThisYear

                                const tag = tagQuery.getTagForCode(adjustment.tagCode)
                                const category = tagQuery.getCategoryForTag(tag)

                                
                                return {
                                    label: `${category.name} - ${adjustment.name}`,
                                    valueLastYear: adjustedValueLastYear,
                                    valueThisYear: adjustedValueThisYear,
                                }
                            })
                        }
                    />
                    <div className="col-xs-12 no-padding">
                        <button className="btn btn-primary" onClick={() => {
                            this.setState({
                                nosPopupShown: true,
                            })
                        }}>
                            Add an adjustment
                        </button>
                        {
                            nosPopupShown && (
                                <MultiPeriodNosPopup
                                    onCancel={() => {
                                        this.setState({ nosPopupShown: false })
                                    }}
                                    onDone={(payload) => {
                                        this.setState({
                                            nosAdjustments: nosAdjustments.concat(payload),
                                            nosPopupShown: false,
                                        })
                                    }}
                                    tagCategories={TagCategoryStore.getTagCategories()}
                                    accountNumberTags={this.getWagesAccountNumberTags(accountItems)}
                                    accountItems={accountItems}
                                    thisYearDate={this.ThisYearMonth.Date}
                                    lastYearDate={this.LastYearMonth.Date}
                                    startMonthDate={this.StartMonth.Date}
                                />
                            )
                        }
                    </div>
                </WizardScreen>
            );
        } else if (pageId === WizardPages.AdjustmentsNonBusinessExpenses.pageId) {
            const { nbaAdjustments, nbaPopupShown } = this.state;

            const accountItems = [this.StartMonth, this.LastYearMonth, this.ThisYearMonth]
                .reduce((accum, month) => accum.concat(month.account_items), []);

            const tagQuery = new AccountItemTagQuery(TagCategoryStore.getTagCategories())

            return (
                <WizardScreen
                    onNext={() => this.nextScreen()}
                    onBack={() => this.previousScreen()}
                    number={2}
                    total={3}
                    pageNumberLabel="Adjustments"
                    title={`Onboarding Wizard - ${NonBusinessAdjustmentsText.AdjustmentsTitle}`}
                    videoId={VideoLinks.OnboardingWizard.NonBusinessAdjustments}
                >
                    {NonBusinessAdjustmentsText.NonBusinessExpenses.Title}
                    {NonBusinessAdjustmentsText.NonBusinessExpenses.Description}
                    {NonBusinessAdjustmentsText.AdjustmentsExplanationHelp}
                    <OnboardingAdjustmentRows
                        yearHeader={this.getYearHeader(false, true)}
                        onRemove={deleteIndex => {
                            this.setState(
                                /** @type {Partial<State>} */
                                {
                                nbaAdjustments: nbaAdjustments.filter((_, ind) => ind !== deleteIndex),
                            });
                        }}
                        items={
                            nbaAdjustments.map(
                                /**
                                 * @param {MultiPeriodNbaData} adjustment
                                 * @returns {{
                                        label: string
                                        valueLastYear: number
                                        valueThisYear: number
                                    }}
                                */
                                (adjustment) => {
                                const tag = tagQuery.getTagForCode(adjustment.tagCode)
                                const category = tagQuery.getCategoryForTag(tag)
                                
                                return {
                                    label: `${category.name} - ${adjustment.name}`,
                                    valueLastYear: adjustment.adjustmentValueLastYear,
                                    valueThisYear: adjustment.adjustmentValueThisYear,
                                }
                            })
                        }
                    />
                    <button className="btn btn-primary" onClick={() => {
                        this.setState({
                            nbaPopupShown: true,
                        })
                    }}>
                        Add an adjustment
                    </button>
                    {
                        nbaPopupShown && (
                            <MultiPeriodNbaPopup
                                onCancel={() => {
                                    this.setState({ nbaPopupShown: false })
                                }}
                                onDone={(payload) => {
                                    this.setState({
                                        nbaAdjustments: nbaAdjustments.concat(payload),
                                        nbaPopupShown: false,
                                    })
                                }}
                                tagCategories={TagCategoryStore.getTagCategories()}
                                accountNumberTags={this.getCostsAccountNumberTags(accountItems)}
                                accountItems={accountItems}
                                accountItemsThisYear={this.ThisYearMonth.account_items}
                                accountItemsLastYear={this.LastYearMonth.account_items}
                                thisYearDate={this.ThisYearMonth.Date}
                                lastYearDate={this.LastYearMonth.Date}
                                startMonthDate={this.StartMonth.Date}
                            />
                        )
                    }
                </WizardScreen>
            );
        } else if (pageId === WizardPages.SeasonalAdjustments.pageId) {
            const seasonalTotal = this.getSeasonalTotal();

            const seasonalData = this.seasonalUpdateDataNames();

            const debounces = [];
            for (let i = 0; i < 12; i++) {
                debounces.push(
                    <div key={i} className="col-xs-1" style={{ padding: '0px 5px' }}>
                        <DebounceInput
                            className="debounce"
                            updateOnlyOnBlur
                            value={seasonalData.values[i].y}
                            onChange={(value) => this.debounceUpdate(value, i)}
                        />
                    </div>
                );
            }

            return (
                <WizardScreen
                    title={'Onboarding Wizard - Seasonality Pattern'}
                    number={3}
                    total={3}
                    pageNumberLabel="Adjustments"
                    onFinish={() => this.seasonalOnNext()}
                    onBack={() => this.previousScreen()}
                    finishEnabled={() => this.seasonalNextEnabled()}
                    videoId={VideoLinks.OnboardingWizard.Seasonality}
                >
                    <h2> Seasonal Adjustments</h2>
                    <p>
                        In order for us to (as accurately as possible) model your financial data, we need to know what
                        an
                        average year looks like for you (based on your revenue).
                    </p>
                    <div className="seasonal-adjustments">
                        <OnboardingLineGraph
                            data={[seasonalData]}
                            width={1070}
                            height={400}
                        />
                        <div className="row ">
                            <div className="col-xs-12">
                                <div className="row debounce-input-row">
                                    {debounces}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className={`row total-row${seasonalTotal == 100.00 ? ' done' : ''}`}>
                                    <div className="col-xs-6"><span className="left"> Total %</span></div>
                                    <div className="col-xs-1 nopadding"><input
                                        className="debounce text-center"
                                        value={seasonalTotal}
                                        disabled
                                    /></div>
                                    <div className="col-xs-5"><span
                                        className="right"
                                    > this must equal 100% to progress </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WizardScreen>
            );
        }

        // Move to Manual/Import Selector
        if (this.isPageIDofType(pageId, WizardPages.Manual)) {
            return this.getContentManual(pageId);
        } else if (this.isPageIDofType(pageId, WizardPages.Import)) {
            return this.getContentImport(pageId);
        }

        return (
            <WizardScreen
                title={'Page Not Found'}
                number={pageId}
                onNext={() => this.nextScreen()}
                onBack={() => this.gotoScreen(WizardPages.SelectOnboardingMethod)}
            />
        );
    }

    /**
     * @param {boolean} hasRemainders 
     */
    revenueClassesOnNext(hasRemainders) {
        const text = Text.Dialog.RevenueRemainder;
        if (hasRemainders) {
            this.showRemainderPopup(
                text.Body,
                text.OK,
                text.Cancel
            );
        } else {
            this.nextScreen();
        }
    }

    /**
     * @param {boolean} hasRemainders 
     */
    otherIndirectCostsOnNext(hasRemainders) {
        const text = Text.Dialog.IndirectCostsRemainder;
        if (hasRemainders) {
            this.showRemainderPopup(
                text.Body,
                text.OK,
                text.Cancel
            );
        } else {
            this.nextScreen();
        }
    }

    /**
     * @param {boolean} hasRemainders 
     */
    miscOnNext(hasRemainders) {
        const text = Text.Dialog.MiscRemainder;
        if (hasRemainders) {
            this.showRemainderPopup(
                text.Body,
                text.OK,
                text.Cancel
            );
        } else {
            this.nextScreen();
        }
    }

    /**
     * @param {TagCategoryName} catName 
     */
    getCategoryTotalByName(catName) {
        const category = TagCategoryStore.getTagCategoryByName(catName);
        const accountNumberTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(category.id);
        return this.getAccountItemMultiYear(accountNumberTag.account_number);
    }

    equal(item1, item2) {
        return (
            item1.lastYear.value === item2.lastYear.value
            && item1.thisYear.value === item2.thisYear.value
        );
    }

    /**
     * @returns {{
     *      thisYear: {
     *          value: number
     *      }
     *      lastYear: {
     *          value: number
     *      }
     *  }}
     */
    getChangeInRetainedEarningsMultiYear() {
        const retainedEarnings = this.getSubcategoryAccountItems(TagCategoryStore.getTagForCode(TagCode.BS_TotalEquity_RetainedEarnings));
        const netProfit = this.getAccountItemMultiYear(SpecialAccountItems.NetProfit.Number);
        const distributions = this.getSubcategoryAccountItems(TagCategoryStore.getTagForCode(TagCode.IS_Distributions_TotalDistributions));

        /**
         *  specified change in RetainedEarnings = RetainedEarnings(current period) - RetainedEarnings(previous period)
         * calculated change in RetainedEarnings = NetProfit(current period) - Distributions(current period)
         */

        const thisYearChangeInRetainedEarningsActual = retainedEarnings.thisYear.Value - retainedEarnings.lastYear.Value;
        const thisYearChangeInRetainedEarningsCalculated = netProfit.thisYear.Value - distributions.thisYear.Value;

        const thisYearRemainder = thisYearChangeInRetainedEarningsActual - thisYearChangeInRetainedEarningsCalculated;

        const lastYearChangeInRetainedEarningsActual = retainedEarnings.lastYear.Value - retainedEarnings.startMonth.Value;
        const lastYearChangeInRetainedEarningsCalculated = netProfit.lastYear.Value - distributions.lastYear.Value;

        const lastYearRemainder = lastYearChangeInRetainedEarningsActual - lastYearChangeInRetainedEarningsCalculated;

        return {
            thisYear: {
                value: thisYearRemainder,
            },
            lastYear: {
                value: lastYearRemainder,
            },
        };
    }

    equityOnNext() {
        const remainder = this.getChangeInRetainedEarningsMultiYear();

        if (this.checkRemainderMultiYear(remainder)) {
            this.setState({
                finishManualEntryPressed: true,
            }, () => {
                this.finaliseManualData()
                    .then(() => this.gotoScreen(WizardPages.AdjustmentsOwnersSalary));
            })
        } else {
            this.showEquityPopup();
        }
    }

    showEquityPopup() {
        const popup = (
            <Popup>
                <div>
                    <h3>Change In Retained Earnings</h3>
                    <p>The Change in Retained Earnings on the Income Statement does not match the movement in the
                        Balance
                        Sheet Retained Earnings.</p>
                    <p>If the imbalance amount is immaterial it can be applied to <strong>Distributions</strong> by
                        selecting <strong>Finish</strong>. If the imbalance is material please go back and check your
                        data
                        entry.</p>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <button
                            onClick={() => {
                                this.dismissPopup();
                                this.finaliseManualData()
                                    .then(() => this.gotoScreen(WizardPages.AdjustmentsOwnersSalary));
                            }}
                            className="btn btn-primary wide"
                        >Finish
                        </button>
                    </div>
                    <div className="col-xs-6">
                        <button onClick={() => this.dismissPopup()} className="btn btn-danger wide">Go Back</button>
                    </div>
                </div>
            </Popup>
        );
        this.showPopup(popup);
    }

    showRemainderPopup(body, ok, cancel) {
        const okText = ok || 'Okay';
        const cancelText = cancel || 'Cancel';

        const popup = (
            <Popup>
                <div>
                    {body}
                    <div className="row">
                        <div className="col-xs-12">
                            <button
                                onClick={() => {
                                    this.dismissPopup();
                                    this.nextScreen();
                                }}
                                className="btn btn-primary full-width"
                            >{okText}</button>
                            <button
                                onClick={() => this.dismissPopup()}
                                className="btn btn-danger full-width"
                            >{cancelText}</button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
        this.showPopup(popup);
    }

    onStartManualWizard() {
        this.setState({
            loadingMessage: 'Creating data...'
        });

        TagCategoryStore.fetchData()
            .then(() => this.createMonths())
            .then(() => this.createAccountItems())
            .then(() => this.setState({
                loadingMessage: null
            }))
            .then(() => this.nextScreen())
            .catch(e => {
                console.error(e);
                this.setState({
                    loadingMessage: null
                });
            });
    }

    getContentManual(pageId) {
        const total = WizardPagesTotal.Manual;
        switch (pageId) {
            case WizardPages.Manual.SelectDate.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.SelectDate.pageNum}
                        total={total}
                        onBack={() => this.gotoScreen(WizardPages.SelectOnboardingMethod)}
                        videoId={VideoLinks.OnboardingWizard.Manual.Requirements}
                    >
                        {Text.Manual.SelectDate.Header}
                        <p>You will need to enter data for the months you specified:</p>
                        <p>Opening Balance Sheet: <strong>{this.StartYearDate.format('MMM YY')}</strong></p>
                        <p>Previous financial year: <strong>{this.LastYearDate.subtract(11, 'month').format('MMM YY')}
                            - {this.LastYearDate.format('MMM YY')}</strong></p>
                        <p>Current financial year to date: <strong>{this.ThisYearDate.subtract(this.NumberOfMonthsThisYear - 1, 'months').format('MMM YY')}
                                - {this.ThisYearDate.format('MMM YY')}</strong></p>
                        <div className="row">
                            <div className="col-xs-12 text-center">
                                <div className="vertical-spacer" />
                                <p>
                                    <button className="btn-action btn wide" onClick={() => this.onStartManualWizard()}>
                                        Start
                                    </button>
                                </p>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.IncomeStatement.pageId: {
                const incomeStatement = this.getCategoriesByName([
                    TagCategoryName.Revenue,
                    TagCategoryName.DirectCosts,
                    TagCategoryName.IndirectCosts,
                ]).map(cat => this.getCategoryTotalAccordion(cat, true));

                const netProfit = this.getNetProfitAccordion(true);

                const distributions = this.getCategoriesByName([
                    TagCategoryName.Distributions,
                ]).map(cat => this.getCategoryTotalAccordion(cat, true));

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.IncomeStatement.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        onBack={() => this.showOnCancelWizardPopup()}
                        videoId={VideoLinks.OnboardingWizard.Manual.IncomeStatementTotals}
                    >
                        {Text.Manual.IncomeStatementTotals}
                        <div className="sorted">
                            {this.getYearHeader(false, true)}
                            {incomeStatement}
                            <div className="col-xs-12">
                                <div className="vertical-spacer" />
                            </div>
                            {netProfit}
                            <div className="col-xs-12">
                                <div className="vertical-spacer" />
                            </div>
                            {distributions}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.BalanceSheet.pageId: {
                const accordions = TagCategoryStore.getBalanceSheetTagCategories()
                    .map(cat => this.getCategoryTotalAccordion(cat, true));
                const remainderRow = this.getBalanceSheetRemainderRow();

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.BalanceSheet.pageNum}
                        total={total}
                        nextEnabled={() => this.checkRemainderMultiYear(this.getBalanceSheetRemainder())}
                        onNext={() => this.nextScreen()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.BalanceSheetTotals}
                    >
                        {Text.Manual.BalanceSheetTotals}
                        <div className="sorted">
                            {this.getYearHeader(true)}
                            {accordions}
                            <div className="col-xs-12 no-padding">
                                <br />
                                <h4>Balance Check</h4>
                                <p>Assets – Liabilities = Equity</p>
                            </div>
                            <div className="category">
                                {remainderRow}
                            </div>
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.Revenue.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.Revenue.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        onBack={() => this.previousScreen()}
                    >
                        {Text.Manual.InterestRevenue}
                        <div className="sorted">
                            {this.getYearHeader(false, true)}
                            {this.getInterestRevenue()}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.RevenueClasses.pageId: {
                const { content, nextEnabled } = this.getAllocateRevenueClasses();
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.RevenueClasses.pageNum}
                        total={total}
                        onNext={() => this.revenueClassesOnNext(!nextEnabled())}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.DefiningRevenueClasses}
                    >
                        {Text.Manual.RevenueClasses}
                        <div className="forecast-view">
                            <div className="sorted">
                                {this.getYearHeader(false, true)}
                                <div className="category revenue">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.DirectCosts.pageId: {
                const { content, nextEnabled } = this.getCategoryWithSubcategories(TagCategoryName.DirectCosts, true);
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.DirectCosts.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        nextEnabled={() => nextEnabled()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingDirectCosts}
                    >
                        {Text.Manual.DirectCosts}
                        <div className="sorted">
                            {this.getYearHeader(false, true)}
                            {content}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.IndirectCosts.pageId: {
                const { content, nextEnabled } = this.getCategoryWithSubcategories(TagCategoryName.IndirectCosts, true);
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.IndirectCosts.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        nextEnabled={() => nextEnabled()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingIndirectCosts}
                    >
                        {Text.Manual.IndirectCosts}
                        <div className="sorted">
                            {this.getYearHeader(false, true)}
                            {content}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.OtherIndirectCosts.pageId: {
                const { nextEnabled, content } = this.getAllocateOtherIndirectCosts();
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.OtherIndirectCosts.pageNum}
                        total={total}
                        onNext={() => this.otherIndirectCostsOnNext(!nextEnabled())}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.IndirectCostsAddingDetail}
                    >
                        {Text.Manual.OtherIndirectCosts}
                        <div className="forecast-view">
                            <div className="sorted">
                                {this.getYearHeader(false, true)}
                                <div className="category indirect-costs">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.Miscellaneous.pageId: {
                const incomeStatement = this.getCategoriesByName([
                    TagCategoryName.Revenue,
                    TagCategoryName.DirectCosts,
                    TagCategoryName.IndirectCosts,
                ]).map(cat => this.getCategoryTotalAccordion(cat, false));

                const netProfit = this.getNetProfitAccordion(false);

                let misc = this.getCategoryWithSubcategories(TagCategoryName.Misc, false);

                const totalMisc = this.getCalculatedMiscTotals();
                const lastYearMisc = totalMisc.lastYear.value;
                const thisYearMisc = totalMisc.thisYear.value;
                let message = '';
                if (lastYearMisc != 0 || thisYearMisc != 0) {
                    message = Text.Manual.MiscWarning;
                } else {
                    message = 'Your Net Profit is all accounted for. Click next to proceed.';
                }


                const { nextEnabled } = misc;
                misc = misc.content;

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.Miscellaneous.pageNum}
                        total={total}
                        onNext={() => this.miscOnNext(!nextEnabled())}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingMiscellanous}
                    >
                        <div className="sorted">
                            <h2>You entered the following totals:</h2>
                            {this.getYearHeader(false, true)}
                            {incomeStatement}
                            <div className="col-xs-12">
                                <div className="vertical-spacer" />
                            </div>
                            <h2>You entered the following Net Profit:</h2>
                            <p>(Net Profit = Revenue - Direct Costs - Indirect Costs + Miscellaneous)</p>
                            {netProfit}
                            <div className="col-xs-12">
                                <div className="vertical-spacer" />
                            </div>
                            <h2>Miscellaneous Items to Be Defined</h2>
                            <p>{message}</p>
                            {misc}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.TotalAssets.pageId: {
                const { nextEnabled, content } = this.getCategoryWithSubcategories(TagCategoryName.TotalAssets, null, Text.RemainderEqualZero);
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.TotalAssets.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        nextEnabled={() => nextEnabled()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingAssets}
                    >
                        {Text.Manual.TotalAssets}
                        <div className="sorted">
                            {this.getYearHeader(true)}
                            {content}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.TotalLiabilities.pageId: {
                const { nextEnabled, content } = this.getCategoryWithSubcategories(TagCategoryName.TotalLiabilities, null, Text.RemainderEqualZero);
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.TotalLiabilities.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        nextEnabled={() => nextEnabled()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingLiabilities}
                    >
                        {Text.Manual.TotalLiabilities}
                        <div className="sorted">
                            {this.getYearHeader(true)}
                            {content}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Manual.TotalEquity.pageId: {
                const { nextEnabled, content } = this.getCategoryWithSubcategories(TagCategoryName.TotalEquity, null, Text.RemainderEqualZero);
                const changeInRetainedEarningsRow = this.getRemainderRow(this.getChangeInRetainedEarningsMultiYear(), [], false, Text.RetainedEarningsRemainder);
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Manual Input'}
                        number={WizardPages.Manual.TotalEquity.pageNum}
                        total={total}
                        onBack={() => this.previousScreen()}
                        finishEnabled={() => !this.state.finishManualEntryPressed && nextEnabled()}
                        onFinish={() => this.equityOnNext()}
                        finishButtonText="Finish Manual Entry"
                        videoId={VideoLinks.OnboardingWizard.Manual.SpecifyingEquity}
                    >
                        {Text.Manual.TotalEquity}
                        <div className="sorted">
                            {this.getYearHeader(true)}
                            {content}
                            <div className="col-xs-12 no-padding">
                                <br />
                                <h4>Retained Earnings Roll Forward</h4>
                                <p>Movement on Balance sheet = Change of Retained Earnings on Income Statement</p>
                            </div>
                            <div className="category">
                                {changeInRetainedEarningsRow}
                            </div>
                        </div>
                    </WizardScreen>
                );
            }
        }
    }

    getContentImport(pageId) {
        const total = WizardPagesTotal.Import;
        switch (pageId) {
            case WizardPages.Import.SelectFiles.pageId: {
                const { thisYearDate } = this.state;

                const onNext = () => this.createMonths()
                    .then(() => this.gotoScreen(WizardPages.Import.CurrentYearTagColumnsIncomeStatement));

                const fileErrorMessage =  (
                    <div className="col-xs-3">
                        <div className="tag-warning-text" style={{ marginTop: '10px' }}>
                            <TagWarningIcon marginRight={10} /> This file has naming conflicts
                        </div>
                    </div>
                );

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.SelectFiles.pageNum}
                        total={total}
                        onNext={onNext}
                        onBack={() => this.gotoScreen(WizardPages.SelectOnboardingMethod)}
                        nextEnabled={() => this.allFilesLoaded()}
                        videoId={VideoLinks.OnboardingWizard.Import.Part1}
                    >
                        {Text.Import.SelectFilesDescription}
                        <div className="vertical-spacer" />
                        <div className="row">
                            <div className="col-xs-4">
                                <p className="wizard-filepicker-label">Opening Balance Sheet (At end
                                    of {this.StartYearDate.format('MMM YY')})</p>
                            </div>
                            <div className="col-xs-5">
                                <FileSelector
                                    onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, UploadTypes.Single, CsvType.BalanceSheet, YearType.StartMonth)}
                                    hideUploadButton
                                    width={300}
                                    fileName={this.getImportFilename(CsvType.BalanceSheet, YearType.StartMonth)}
                                />
                            </div>
                            {this.hasCsvError(CsvType.BalanceSheet, YearType.StartMonth) && fileErrorMessage}
                        </div>
                        <div className="row">
                            <div className="col-xs-4">
                                <p className="wizard-filepicker-label">Income Statement / P & L
                                    ({this.LastYearDate.subtract(11, 'month').format('MMM YY')}
                                    - {this.LastYearDate.format('MMM YY')})</p>
                            </div>
                            <div className="col-xs-5">
                                <FileSelector
                                    onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, UploadTypes.Single, CsvType.ProfitLoss, YearType.LastYear)}
                                    hideUploadButton
                                    width={300}
                                    fileName={this.getImportFilename(CsvType.ProfitLoss, YearType.LastYear)}
                                />
                            </div>
                            {this.hasCsvError(CsvType.ProfitLoss, YearType.LastYear) && fileErrorMessage}
                        </div>
                        <div className="row">
                            <div className="col-xs-4">
                                <p className="wizard-filepicker-label">Closing Balance Sheet (At end
                                    of {this.LastYearDate.format('MMM YY')})</p>
                            </div>
                            <div className="col-xs-5">
                                <FileSelector
                                    onFileLoaded={(filename, text) => {
                                        this.onFileLoaded(filename, text, UploadTypes.Single, CsvType.BalanceSheet, YearType.LastYear);
                                        this.setState({
                                            startYearBalanceSheetFileName: filename,
                                        });
                                    }}
                                    hideUploadButton
                                    width={300}
                                    fileName={this.getImportFilename(CsvType.BalanceSheet, YearType.LastYear)}
                                />
                            </div>
                            {this.hasCsvError(CsvType.BalanceSheet, YearType.LastYear) && fileErrorMessage}
                        </div>
                        <div className="row">
                            <div className="col-xs-4">
                                <p className="wizard-filepicker-label">Income Statement / P & L
                                    ({this.LastYearDate.add(1, 'month').format('MMM YY')}
                                    - {thisYearDate.format('MMM YY')})</p>
                            </div>
                            <div className="col-xs-5">
                                <FileSelector
                                    onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, UploadTypes.Single, CsvType.ProfitLoss, YearType.CurrentYear)}
                                    hideUploadButton
                                    width={300}
                                    fileName={this.getImportFilename(CsvType.ProfitLoss, YearType.CurrentYear)}
                                />
                            </div>
                            {this.hasCsvError(CsvType.ProfitLoss, YearType.CurrentYear) && fileErrorMessage}
                        </div>
                        <div className="row">
                            <div className="col-xs-4">
                                <p className="wizard-filepicker-label">Closing Balance Sheet (At end
                                    of {this.ThisYearDate.format('MMM YY')})</p>
                            </div>
                            <div className="col-xs-5">
                                <FileSelector
                                    onFileLoaded={(filename, text) => this.onFileLoaded(filename, text, UploadTypes.Single, CsvType.BalanceSheet, YearType.CurrentYear)}
                                    hideUploadButton
                                    width={300}
                                    fileName={this.getImportFilename(CsvType.BalanceSheet, YearType.CurrentYear)}
                                />
                            </div>
                            {this.hasCsvError(CsvType.BalanceSheet, YearType.CurrentYear) && fileErrorMessage}
                        </div>
                    </WizardScreen>
                );
            }
            case WizardPages.Import.CurrentYearTagColumnsIncomeStatement.pageId: {
                const rows = this.state.import_current_year.getRowsProfitLoss();
                const maxRows = rows.length > 0 ? rows.length : 0;

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.CurrentYearTagColumnsIncomeStatement.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.CurrentYearTagColumnsBalanceSheet)}
                        onBack={() => this.showOnCancelWizardPopup()}
                        nextEnabled={() => this.isIncomeStatementFieldsMapped()}
                        videoId={VideoLinks.OnboardingWizard.Import.Part2}
                    >
                        {Text.Import.TagColumnsThisYearIncomeStatement}
                        <div className="screen-map-column">
                            <TagColumns
                                hideFirstColumn
                                type={LedgerTypes.IncomeStatement}
                                rows={this.state.import_current_year.getIncomeStatementRowsForTagColumns()}
                                maxRows={maxRows}
                            />
                        </div>

                    </WizardScreen>
                );
            }
            case WizardPages.Import.CurrentYearTagColumnsBalanceSheet.pageId: {
                const rows = this.state.import_current_year.getRowsBalanceSheet();
                const maxRows = rows.length > 0 ? rows.length : 0;

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.CurrentYearTagColumnsBalanceSheet.pageNum}
                        total={total}
                        onNext={() => {
                            this.regenerateImportAccountNumbers();

                            const conflicts = this.getImportConflictRows()
                                .reduce((accum, curr) => accum.concat(curr));

                            if (conflicts.length > 0) {
                                this.gotoScreen(WizardPages.Import.ImportErrors);
                            } else {
                                this.gotoScreen(WizardPages.Import.Tagging);
                            }
                        }}
                        onBack={() => this.gotoScreen(WizardPages.Import.CurrentYearTagColumnsIncomeStatement)}
                        nextEnabled={() => this.isBalanceSheetFieldsMapped()}
                        videoId={VideoLinks.OnboardingWizard.Import.Part2}
                    >
                        {Text.Import.TagColumnsThisYearBalanceSheet}
                        <div className="screen-map-column">
                            <TagColumns
                                hideFirstColumn
                                type={LedgerTypes.BalanceSheet}
                                rows={this.state.import_current_year.getBalanceSheetRowsForTagColumns()}
                                maxRows={maxRows}
                            />
                        </div>

                    </WizardScreen>
                );
            }
            case WizardPages.Import.ImportErrors.pageId: {
                const conflictSets = this.getImportConflictRows();
                const onBack = () => {
                    const errorSets = [
                        this.state.import_first_month,
                        this.state.import_previous_year,
                        this.state.import_current_year,
                    ].map(mport => ({
                        import: mport,
                        errors: mport.validateDuplicates(mport.getValidationRows())
                    }));

                    errorSets.forEach(importPeriod => {
                        importPeriod.errors.forEach(conflict => {
                            conflict.rows.forEach(row => {
                                if (row.ledgerName === LedgerName.IncomeStatement) {
                                    importPeriod.import.setFileHasErrors(UploadTypes.Single, CsvType.ProfitLoss)
                                } else if (row.ledgerName === LedgerName.BalanceSheet) {
                                    importPeriod.import.setFileHasErrors(UploadTypes.Single, CsvType.BalanceSheet)
                                }
                            })
                        });
                    });

                    this.gotoScreen(WizardPages.Import.SelectFiles)
                }

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        onNext={() => {}}
                        onBack={onBack}
                        nextEnabled={() => false}
                    >
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>Naming Conflicts Detected</h1>
                            </div>

                            <div className="col-xs-12">
                                <CsvConflictMessageBox showVideo={this.props.showVideo} />
                            </div>

                            <div className="text-center">
                                <button
                                    className="btn btn-action btn-wide btn-lg marginBtn"
                                    onClick={onBack}
                                >
                                    Upload new files
                                </button>
                            </div>

                            <div className="col-xs-12">
                                {
                                    conflictSets
                                        .map((file, index) => (
                                            <div>
                                                <h3>
                                                    {
                                                        [
                                                            "Opening Balance Sheet",
                                                            "Prior Year",
                                                            "Most Recent Year",
                                                        ][index]
                                                    }
                                                </h3>
                                                {
                                                    file.length > 0 ? (
                                                        <ImportErrorTable conflicts={file} />
                                                    ) : (
                                                        <p>No Issues.</p>
                                                    )

                                                }
                                            </div>
                                        ))
                                }
                            </div>
                        </div>

                    </WizardScreen>
                );
            }
            case WizardPages.Import.Tagging.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Tagging'}
                        number={WizardPages.Import.Tagging.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.CurrentYearTagIncomeStatementRevenue)}
                        onBack={() => this.gotoScreen(WizardPages.Import.CurrentYearTagColumnsBalanceSheet)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingOverview}
                    >
                        {Text.Import.Tagging}
                    </WizardScreen>
                );
                // <img src="/images/onboarding/tagging_demo_1.png" />
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementRevenue.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.IncomeStatement,
                    TagCategoryName.Revenue,
                    WizardPages.Import.CurrentYearTagIncomeStatementRevenue.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingRevenue);
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementDirectCosts.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.IncomeStatement,
                    TagCategoryName.DirectCosts,
                    WizardPages.Import.CurrentYearTagIncomeStatementDirectCosts.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingDirectCosts);
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementIndirectCosts.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.IncomeStatement,
                    TagCategoryName.IndirectCosts,
                    WizardPages.Import.CurrentYearTagIncomeStatementIndirectCosts.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingIndirectCosts);
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementProfit.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.IncomeStatement,
                    TagCategoryName.Profit,
                    WizardPages.Import.CurrentYearTagIncomeStatementProfit.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingProfit);
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementMisc.pageId: {
                const category = this.getCategoriesByName([TagCategoryName.Misc])[0];
                const rows = this.state.import_current_year.getRowsForTagRows();
                const { ledgerTypes } = this.state;
                const categoryId = category.id;

                let formattedMiscTotal = "Tags missing!";

                try {
                    const accountItems = this.state.import_current_year.getAccountItemsFromCSV(LedgerName.IncomeStatement);
                    formattedMiscTotal = this.formatNumber(this.getCalculatedMisc(accountItems));
                } catch(err) {
                    console.warn('tagging error trying to get total misc')
                }

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.CurrentYearTagIncomeStatementMisc.pageNum}
                        total={total}
                        onNext={() => this.nextScreen()}
                        onBack={() => this.previousScreen()}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingMiscellaneous}
                    >
                        {Text.Import.TagRows.getMisc(formattedMiscTotal)}
                        <TagRows
                            tagErrors={this.getCurrentYearTaggingErrors()}
                            hideFirstColumn
                            rows={rows}
                            ledgerTypes={ledgerTypes}
                            selectedLedgerType={LedgerTypes.IncomeStatement.name}
                            onTagSelected={tag => this.setTaggingSection(tag)}
                            lockedSelection={categoryId}
                        />
                    </WizardScreen>
                );
            }
            case WizardPages.Import.CurrentYearTagIncomeStatementDistributions.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.IncomeStatement,
                    TagCategoryName.Distributions,
                    WizardPages.Import.CurrentYearTagIncomeStatementDistributions.pageNum,
                    WizardPagesTotal.Import,
                    'i1-AGKv-Nok'
                );
            }

            case WizardPages.Import.PreviewCurrentYearIncomeStatement.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Progress Check'}
                        number={WizardPages.Import.PreviewCurrentYearIncomeStatement.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.CurrentYearTagBalanceSheetAssets)}
                        onBack={() => this.gotoScreen(WizardPages.Import.CurrentYearTagIncomeStatementDistributions)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingDistributions}
                    >
                        {Text.Import.PreviewCurrentYearIncomeStatement}
                        <div className="screen-map-column">
                            <div className="col-xs-12 col-xs-offset-2">
                                <div className="col-xs-8">
                                    <PreviewTable
                                        accountItems={this.state.import_current_year.getAccountItemsFromCSV(LedgerName.IncomeStatement)}
                                        type={LedgerTypes.IncomeStatement}
                                    />
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }

            case WizardPages.Import.CurrentYearTagBalanceSheetAssets.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.BalanceSheet,
                    TagCategoryName.TotalAssets,
                    WizardPages.Import.CurrentYearTagBalanceSheetAssets.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingAssets);
            }
            case WizardPages.Import.CurrentYearTagBalanceSheetLiabilities.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.BalanceSheet,
                    TagCategoryName.TotalLiabilities,
                    WizardPages.Import.CurrentYearTagBalanceSheetLiabilities.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingLiabilities);
            }
            case WizardPages.Import.CurrentYearTagBalanceSheetEquity.pageId: {
                return this.getTagRowsScreenCurrentYear(
                    LedgerTypes.BalanceSheet,
                    TagCategoryName.TotalEquity,
                    WizardPages.Import.CurrentYearTagBalanceSheetEquity.pageNum,
                    WizardPagesTotal.Import,
                    VideoLinks.OnboardingWizard.Import.TaggingEquity);
            }

            case WizardPages.Import.PreviewCurrentYearBalanceSheet.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Progress Check'}
                        number={WizardPages.Import.PreviewCurrentYearBalanceSheet.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.PreviousYearTagIncomeStatement)}
                        onBack={() => this.gotoScreen(WizardPages.Import.CurrentYearTagBalanceSheetEquity)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingEquity}
                    >
                        {Text.Import.PreviewCurrentYearBalanceSheet}
                        <div className="screen-map-column">
                            <div className="col-xs-12 col-xs-offset-2">
                                <div className="col-xs-8">
                                    <PreviewTable
                                        accountItems={this.state.import_current_year.getAccountItemsFromCSV()}
                                        type={LedgerTypes.BalanceSheet}
                                    />
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }

            case WizardPages.Import.PreviousYearTagIncomeStatement.pageId: {
                const rows = this.state.import_previous_year.getRowsForTagRows();
                const { ledgerTypes } = this.state;

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.PreviousYearTagIncomeStatement.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.PreviewPreviousYearIncomeStatement)}
                        onBack={() => this.gotoScreen(WizardPages.Import.PreviewCurrentYearBalanceSheet)}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousIncomeStatement}
                    >
                        {Text.Import.TagRows.IncomeStatementPriorYear}
                        <TagRows
                            tagErrors={this.getPriorYearTaggingErrors()}
                            hideFirstColumn
                            rows={rows}
                            ledgerTypes={ledgerTypes}
                            onLedgerTypeChanged={ledgerType => this.onLedgerTypeChanged(ledgerType)}
                            selectedLedgerType={LedgerTypes.IncomeStatement.name}
                        />
                    </WizardScreen>
                );
            }
            case WizardPages.Import.PreviewPreviousYearIncomeStatement.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Progress Check'}
                        number={WizardPages.Import.PreviewPreviousYearIncomeStatement.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.PreviousYearTagBalanceSheet)}
                        onBack={() => this.gotoScreen(WizardPages.Import.PreviousYearTagIncomeStatement)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousIncomeStatement}
                    >
                        {Text.Import.PreviewPreviousYearIncomeStatement}
                        <div className="screen-map-column">
                            <div className="col-xs-12 col-xs-offset-2">
                                <div className="col-xs-8">
                                    <PreviewTable
                                        accountItems={this.state.import_previous_year.getAccountItemsFromCSV(LedgerName.IncomeStatement)}
                                        type={LedgerTypes.IncomeStatement}
                                    />
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }

            case WizardPages.Import.PreviousYearTagBalanceSheet.pageId: {
                const rows = this.state.import_previous_year.getRowsForTagRows();
                const { ledgerTypes } = this.state;
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.PreviousYearTagBalanceSheet.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.PreviewPreviousYearBalanceSheet)}
                        onBack={() => this.gotoScreen(WizardPages.Import.PreviewPreviousYearIncomeStatement)}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousBalanceSheet}
                    >
                        {Text.Import.TagRows.BalanceSheetPriorYear}
                        <TagRows
                            tagErrors={this.getPriorYearTaggingErrors()}
                            hideFirstColumn
                            rows={rows}
                            ledgerTypes={ledgerTypes}
                            onLedgerTypeChanged={ledgerType => this.onLedgerTypeChanged(ledgerType)}
                            selectedLedgerType={LedgerTypes.BalanceSheet.name}
                        />
                    </WizardScreen>
                );
            }
            case WizardPages.Import.PreviewPreviousYearBalanceSheet.pageId: {
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Progress Check'}
                        number={WizardPages.Import.PreviewPreviousYearBalanceSheet.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.StartMonthTagBalanceSheet)}
                        onBack={() => this.gotoScreen(WizardPages.Import.PreviousYearTagBalanceSheet)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousBalanceSheet}
                    >
                        {Text.Import.PreviewPreviousYearBalanceSheet}
                        <div className="screen-map-column">
                            <div className="col-xs-12 col-xs-offset-2">
                                <div className="col-xs-8">
                                    <PreviewTable
                                        accountItems={this.state.import_previous_year.getAccountItemsFromCSV()}
                                        type={LedgerTypes.BalanceSheet}
                                    />
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }

            case WizardPages.Import.StartMonthTagBalanceSheet.pageId: {
                const rows = this.state.import_first_month.getRowsForTagRows();
                const { ledgerTypes } = this.state;
                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Import'}
                        number={WizardPages.Import.StartMonthTagBalanceSheet.pageNum}
                        total={total}
                        onNext={() => this.gotoScreen(WizardPages.Import.PreviewStartMonthBalanceSheet)}
                        onBack={() => this.gotoScreen(WizardPages.Import.PreviewPreviousYearBalanceSheet)}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousBalanceSheet}
                    >
                        {Text.Import.TagRows.OpeningBalanceSheetStartMonth}
                        <TagRows
                            tagErrors={this.getOpeningBalanceSheetErrors()}
                            hideFirstColumn
                            rows={rows}
                            ledgerTypes={ledgerTypes}
                            onLedgerTypeChanged={ledgerType => this.onLedgerTypeChanged(ledgerType)}
                            selectedLedgerType={LedgerTypes.BalanceSheet.name}
                        />
                    </WizardScreen>
                );
            }

            case WizardPages.Import.PreviewStartMonthBalanceSheet.pageId: {
                const gotoNonBusinessAdjustments = () => {
                    const itemsToSave = this.getAccountItemsAcrossMonths_Import(this.LastYearMonth, this.ThisYearMonth);
                    return AccountNumberTagsStore.performBatchSave(itemsToSave.accountNumberTags)
                            .then(() => this.saveAccountItems(itemsToSave.accountItems))
                            .then(() => this.gotoScreen(WizardPages.AdjustmentsOwnersSalary));
                };

                return (
                    <WizardScreen
                        title={'Onboarding Wizard - Progress Check'}
                        number={WizardPages.Import.PreviewStartMonthBalanceSheet.pageNum}
                        total={total}
                        onNext={() => gotoNonBusinessAdjustments()}
                        onBack={() => this.gotoScreen(WizardPages.Import.StartMonthTagBalanceSheet)}
                        nextEnabled={() => true}
                        videoId={VideoLinks.OnboardingWizard.Import.TaggingPreviousBalanceSheet}
                    >
                        {Text.Import.PreviewStartMonthBalanceSheet}
                        <div className="screen-map-column">
                            <div className="col-xs-12 col-xs-offset-2">
                                <div className="col-xs-8">
                                    <PreviewTable
                                        accountItems={this.state.import_first_month.getAccountItemsFromCSV(LedgerName.BalanceSheet)}
                                        type={LedgerTypes.BalanceSheet}
                                    />
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                );
            }

        }
    }

    getImportConflictRows() {
        return [
            this.state.import_first_month,
            this.state.import_previous_year,
            this.state.import_current_year,
        ].map(mport => mport.validateDuplicates(mport.getValidationRows()));
    }

    getCurrentYearTaggingErrors() {
        return this.state.import_current_year.validateImportTagging();
    }

    getPriorYearTaggingErrors() {
        return this.state.import_previous_year.validateImportTagging();
    }

    getOpeningBalanceSheetErrors() {
        return this.state.import_first_month.validateImportTagging(LedgerName.BalanceSheet);
    }

    getTagRowsScreenCurrentYear(ledgerType, categoryName, pageNumber, totalPageNumber, videoId) {
        const onNext = () => this.nextScreen();

        const taggingErrors = this.state.import_current_year.validateImportTagging();

        const category = this.getCategoriesByName([categoryName])[0];
        const rows = this.state.import_current_year.getRowsForTagRows();
        const { ledgerTypes } = this.state;

        const text = Text.Import.TagRows[categoryName];
        if (!text) {
            console.warn(`no text found for TagRows:${categoryName}`);
        }

        const categoryId = category.id;

        return (
            <WizardScreen
                title={'Onboarding Wizard - Import'}
                number={pageNumber}
                total={totalPageNumber}
                onNext={onNext}
                onBack={() => this.previousScreen()}
                videoId={videoId}
            >
                {text}
                <TagRows
                    tagErrors={this.getCurrentYearTaggingErrors()}
                    hideFirstColumn
                    rows={rows}
                    ledgerTypes={ledgerTypes}
                    selectedLedgerType={ledgerType.name}
                    onTagSelected={tag => this.setTaggingSection(tag)}
                    lockedSelection={categoryId}
                />
            </WizardScreen>
        );
    }

    setTaggingSection(target) {
        //only if target is a category, not a tag
        if (target.items !== undefined) {
            this.setState({
                tagRowsSection: target
            });
        }
    }

    // pageType is WizardPages.<type>
    isPageIDofType(pageID, pageType) {
        let is_manual = false;
        for (const item in pageType) {
            if (pageType[item].pageId === pageID) {
                is_manual = true;
                break;
            }
        }

        return is_manual;
    }

    regenerateImportAccountNumbers() {
        this.state.import_first_month.regenerateAccountNumbers();
        this.state.import_previous_year.regenerateAccountNumbers();
        this.state.import_current_year.regenerateAccountNumbers();
    }

    // With companies having different financial year import start/end dates, we need to display the correct order of months
    // Use the full year to populate the graph names from
    seasonalUpdateDataNames() {
        const { seasonalData } = this.state;
        let thisYearMonth = this.LastYearMonth;
        thisYearMonth = thisYearMonth.Date.date(1).minute(0).second(0).millisecond(0);

        const monthNames = [];
        for (let i = 11; i >= 0; i--) {
            const monthName = thisYearMonth.format('MMM');
            //seasonalData.values[i].x = monthName;
            monthNames.unshift(monthName);
            thisYearMonth = thisYearMonth.subtract(1, 'month');
        }

        return {
            values: seasonalData.values.map((month, index) => ({
                x: monthNames[index],
                y: month.y,
            }))
        };
    }

    seasonalOnNext() {
        // Assumption: There are exactly two months of data saved in the database at this point
        // one month at ThisYearMonth, and one at LastYearMonth
        // and there are months created from (ThisYearMonth-11) -> LastYearMonth inclusive
        this.setState({
            seasonalOnNextPressed: true,
            loadingMessage: 'Applying Seasonal Adjustments, this may take a few minutes...'
        });

        const { seasonalData } = this.state;

        const seasonalDataValues = seasonalData.values.map(item => parseFloat(item.y));

        //clone all of the months so we don't mutate data in the store
        const months = MonthStore.getMonths().map(month => new Month(month));
        const thisYearMonth = new Month(months.find(month => month.id === this.ThisYearMonth.id));
        const lastYearMonth = new Month(months.find(month => month.id === this.LastYearMonth.id));

        const {
            nbaAdjustments, nosAdjustments
        } = this.state;

        /**
         * 
         * @param {Month} month 
         * @param {YearType} yearType 
         */
        function applyNonBusinessAdjustments(month, yearType) {
            /**
             * @type {'thisYear' | 'lastYear'}
             */
            let yearKey;

            switch (yearType) {
                case YearType.CurrentYear:
                    yearKey = 'thisYear';
                    break;
                case YearType.LastYear:
                    yearKey = 'lastYear';
                    break;
                default:
                    throw new Error(`applyNonBusinessAdjustments: invalid year type '${yearType}'`);
            }

            
            nosAdjustments.forEach(adjustment => {
                const salaryPaidToOwnersForPeriod = yearKey === 'thisYear' ? adjustment.valueAmountPaidThisYear : adjustment.valueAmountPaidLastYear;
                const equivalentFullOwnersSalaryForPeriod = yearKey === 'thisYear' ? adjustment.valueCommercialWageThisYear : adjustment.valueCommercialWageLastYear;

                month.account_items = applyWagesAdjustment(
                    TagCategoryStore.getTagCategories(),
                    month.account_items, 
                    salaryPaidToOwnersForPeriod,
                    equivalentFullOwnersSalaryForPeriod, 
                    adjustment.tagCode, 
                    adjustment.accountNumber
                );
            })

            nbaAdjustments.forEach(adjustment => {
                const adjustmentValue = yearKey === 'thisYear' ? adjustment.adjustmentValueThisYear : adjustment.adjustmentValueLastYear;

                month.account_items = applyNonBusinessExpensesAdjustment(
                    TagCategoryStore.getTagCategories(),
                    month.account_items,
                    adjustmentValue,
                    adjustment.tagCode,
                    adjustment.accountNumber
                );
            })
        }

        applyNonBusinessAdjustments(thisYearMonth, YearType.CurrentYear);
        applyNonBusinessAdjustments(lastYearMonth, YearType.LastYear);

        const monthsThisYear = months.slice(13);
        const monthsLastYear = months.slice(1, 13);

        const accountItemsForCurrentYear = this.duplicateAccountItemsAcrossMonths(monthsThisYear, thisYearMonth, seasonalData);
        const accountItemsForPreviousYear = this.duplicateAccountItemsAcrossMonths(monthsLastYear, lastYearMonth, seasonalData);

        // We need to sum all the months individually, and then -calculate- the remaining month, not use the seasonal adjustments (might be rounding errors)
        // We do the reverse because getIncomeStatementTagCategories excludes NetProfit items, but we need them included for balancing purposes later
        const tagCategories = TagCategoryStore.getBalanceSheetTagCategories();

        const BalanceSheetTagIDs = [];

        tagCategories.forEach(tagCat => {
            tagCat.tags.forEach(tag => {
                BalanceSheetTagIDs.push(tag.id);
            });
        });

        const firstMonthLastYear = accountItemsForPreviousYear[0][0].month_id;
        const lastYearMonthIncomeStatement = lastYearMonth.AccountItems.filter(
            item => BalanceSheetTagIDs.indexOf(item.tag_id) === -1
        );

        const firstMonthThisYear = accountItemsForCurrentYear[0][0].month_id;
        const thisYearMonthIncomeStatement = thisYearMonth.AccountItems.filter(
            item => BalanceSheetTagIDs.indexOf(item.tag_id) === -1
        );

        const assignRemainderToFirstMonth = (accountItems, monthId, length) => {
            accountItems.forEach((item) => {
                item.month_id = monthId;
                let itemSum = 0;
                const originalValue = item.value;

                seasonalData.values.forEach((val, index) => {
                    // Calculate seasonal adjustments for month 1 to n
                    if (index === 0 || index >= length) {
                        return;
                    }

                    const scalingFactor = val.y;
                    itemSum += getSeasonalAdjustmentValue(originalValue, scalingFactor, length, seasonalDataValues);
                });

                item.value = originalValue - itemSum;

                if (monthId !== lastYearMonth.id && monthId !== thisYearMonth.id) {
                    item.id = null;
                }
            });
        };

        // assign difference of Imported Year Totals and Calculated Year Totals to first month so that imported year totals are retained exactly

        assignRemainderToFirstMonth(lastYearMonthIncomeStatement, firstMonthLastYear, accountItemsForPreviousYear.length);
        assignRemainderToFirstMonth(thisYearMonthIncomeStatement, firstMonthThisYear, accountItemsForCurrentYear.length);

        accountItemsForPreviousYear[0] = lastYearMonthIncomeStatement;
        accountItemsForCurrentYear[0] = thisYearMonthIncomeStatement;

        const forwardBalanceSheet = this.getBalanceSheetForward(lastYearMonth, thisYearMonth, 0);
        const previousYearBalanceSheets = this.getBalanceSheetForward(this.StartMonth, lastYearMonth, 0);

        const currentYearWithBalanceSheets = accountItemsForCurrentYear.map((month, index) => month.concat(forwardBalanceSheet[index]));

        const previousYearWithBalanceSheets = accountItemsForPreviousYear.map((month, index) => month.concat(previousYearBalanceSheets[index]));

        const monthsToSave = previousYearWithBalanceSheets.concat(currentYearWithBalanceSheets);

        //sort by date
        monthsToSave.sort((a, b) => {
            const aval = parseInt(MonthStore.getMonth(a[0].month_id).date.replace('-', ''));
            const bval = parseInt(MonthStore.getMonth(b[0].month_id).date.replace('-', ''));

            if (aval < bval) {
                return -1;
            } else if (bval < aval) {
                return 1;
            }
            return 0;
        });

        // After applying seasonal adjustments, due to rounding, there may be very small remainders.
        // These are fixed by assigning any remainder arbitrarily to a subItem
        // As these remainders should be very small (remainders were previously resolved before seasonality),
        // there will be negligible impact on figures, and totals will still be consistent with imported data across each year.
        monthsToSave.forEach(items => {
            TagCategoryStore.getTagCategories()
                .forEach(cat => {
                    const totalTag = cat.tags.filter(tag => tag.isTotal).shift();
                    if (!totalTag) return;

                    const subTagsIds = cat.tags.filter(tag => !tag.isTotal).map(t => t.id);
                    const subItems = items.filter(item => subTagsIds.indexOf(item.tag_id) > -1);
                    const totalItem = items.filter(item => item.tag_id === totalTag.id).shift();
                    const specifiedTotal = totalItem.value;
                    const calculatedTotal = subItems.reduce((prev, curr) => prev + this.getAccountItemSubtotalValue(curr), 0);

                    const difference = specifiedTotal - calculatedTotal;
                    if (difference !== 0) {
                        subItems[0].value += difference;
                    }
                });
        });

        //re-balance balance sheets due to updated totals in previous step
        monthsToSave.forEach(items => this.balanceAccountItemsForMonth(items));

        this.fixRetainedEarningsForMonth(monthsToSave);

        this.saveNewAccountNumberTags([].concat(...monthsToSave))
            // delete the partial month
            .then(() => MonthStore.performDeleteSingle(this.StartMonth))
            .then(() => this.saveAccountItems(monthsToSave, true))
            .then(() => this.props.onComplete());
    }

    balanceAccountItemsForMonth(accountItems) {
        // Ensure Income Statement Items are balanced (within each category)
        const IncomeStatementTagCategories = TagCategoryStore.getIncomeStatementTagCategories();
        IncomeStatementTagCategories.forEach(tagCat => {
            this.updateTotalItemSumByTagCategory(accountItems, tagCat);
        });

        // Net Profit is a special case (needs to be done after other IS categories have balanced)
        const netProfitTag = TagCategoryStore.getTagForCode(TagCode.IS_NetProfit);
        const totalRevenueTag = TagCategoryStore.getTagForCode(TagCode.IS_Revenue_TotalRevenue);
        const directCostsTag = TagCategoryStore.getTagForCode(TagCode.IS_DirectCosts_TotalDirectCosts);
        const indirectCostsTag = TagCategoryStore.getTagForCode(TagCode.IS_IndirectCosts_TotalIndirectCosts);
        const totalMiscTag = TagCategoryStore.getTagForCode(TagCode.IS_Misc_TotalMisc);

        const netProfitItem = accountItems.filter(item => item.tag_id === netProfitTag.id).shift();
        const totalRevenueItem = accountItems.filter(item => item.tag_id === totalRevenueTag.id).shift();
        const directCostsItem = accountItems.filter(item => item.tag_id === directCostsTag.id).shift();
        const indirectCostsItem = accountItems.filter(item => item.tag_id === indirectCostsTag.id).shift();
        const totalMiscItem = accountItems.filter(item => item.tag_id === totalMiscTag.id).shift();

        const total = totalRevenueItem.value - directCostsItem.value - indirectCostsItem.value + totalMiscItem.value;
        netProfitItem.value = total;

        // Balance Sheet Balancing (Total Assets = Total Liab + Total Equity)
        const BalanceSheetTagCategories = TagCategoryStore.getBalanceSheetTagCategories();
        const BalanceSheetTagCategoryIDs = [];
        BalanceSheetTagCategories.forEach(tagCat => {
            tagCat.tags.forEach(tag => {
                if (!tag.isTotal) {
                    BalanceSheetTagCategoryIDs.push(tag.id);
                }
            });
        });

        const balanceSheetItems = accountItems.filter(item => BalanceSheetTagCategoryIDs.indexOf(item.tag_id) > -1);
        if (balanceSheetItems.length > 0) {
            const totalAssetsTagCategory = TagCategoryStore.getTagCategoryByName(TagCategoryName.TotalAssets);
            const totalLiabTagCategory = TagCategoryStore.getTagCategoryByName(TagCategoryName.TotalLiabilities);
            const totalEquityTagCategory = TagCategoryStore.getTagCategoryByName(TagCategoryName.TotalEquity);


            const totalAssets = this.updateTotalItemSumByTagCategory(accountItems, totalAssetsTagCategory, false);
            const totalLiab = this.updateTotalItemSumByTagCategory(accountItems, totalLiabTagCategory);
            const totalEquity = this.updateTotalItemSumByTagCategory(accountItems, totalEquityTagCategory);

            const calculatedTotal = totalLiab + totalEquity;
            if (calculatedTotal != totalAssets) {
                const totalAssetsTotalTag = TagCategoryStore.getTagForCode(TagCode.BS_TotalAssets_TotalAssets);
                const totalAssetsTotalItem = accountItems.filter(item => item.tag_id === totalAssetsTotalTag.id).shift();

                const inputTotal = totalAssetsTotalItem.value;

                const difference = calculatedTotal - inputTotal;

                const totalAssetsOtherTag = TagCategoryStore.getTagForCode(TagCode.BS_TotalAssets_Other);
                let totalAssetsOtherItem = accountItems.find(item => item.tag_id === totalAssetsOtherTag.id);

                // create Other Assets (unspecified remainder) item if none exists
                if (!totalAssetsOtherItem) {
                    totalAssetsOtherItem = new AccountItem({
                        account_number: SpecialAccountItems.AutomaticallyAllocated.Number + totalAssetsOtherTag.code,
                        name: makeUnspecifiedRemainderName(TagCategoryName.TotalAssets),
                        value: 0,
                        tag_id: totalAssetsOtherTag.tag_id,
                        month_id: totalAssetsTotalItem.month_id,
                    });

                    accountItems.push(totalAssetsOtherItem);
                }

                // Update other and total items to reflect accurate calculation
                totalAssetsOtherItem.value += difference;
                totalAssetsTotalItem.value = calculatedTotal;
            }
        }
    }

    fixRetainedEarningsForMonth(months) {
        months.forEach((accountItems, index) => {
            if (index === 0) {
                //there is no previous month to compare to
                return;
            }
            const monthId = accountItems[0].month_id;
            const totalDistributionsTag = TagCategoryStore.getTagForCode(TagCode.IS_Distributions_TotalDistributions);
            const retainedEarningsTag = TagCategoryStore.getTagForCode(TagCode.BS_TotalEquity_RetainedEarnings);

            const netProfitItem = accountItems.filter(item => item.account_number === SpecialAccountItems.NetProfit.Number).shift();
            const totalDistributionsItem = accountItems.filter(item => item.tag_id === totalDistributionsTag.id).shift();
            const retainedEarningsThisMonth = accountItems.filter(item => item.tag_id === retainedEarningsTag.id)
                .reduce((prev, curr) => prev + parseFloat(curr.value), 0);
            const retainedEarningsLastMonth = months[index - 1].filter(item => item.tag_id === retainedEarningsTag.id)
                .reduce((prev, curr) => prev + parseFloat(curr.value), 0);

            const actualRetainedEarnings = retainedEarningsThisMonth - retainedEarningsLastMonth;

            //console.log(MonthStore.getMonth(monthId).date);
            //console.log("retained earnings this month:", retainedEarningsThisMonth);
            //console.log("retained earnings last month:", retainedEarningsLastMonth);
            //console.log("net profit:", parseFloat(netProfitItem.value));
            //console.log("current distributions", parseFloat(totalDistributionsItem.value));
            //console.log("expected change in retained earnings:", parseFloat(netProfitItem.value) - parseFloat(totalDistributionsItem.value));
            //console.log("actual change in retained earnings:", actualRetainedEarnings);

            const profitLessDistributions = parseFloat(netProfitItem.value) - parseFloat(totalDistributionsItem.value);
            const remainder = profitLessDistributions - actualRetainedEarnings;

            if (remainder != 0) {
                const otherDistributionsTag = TagCategoryStore.getTagForCode(TagCode.IS_Distributions_Other);

                let retainedEarningsRollForward = accountItems.filter(item => item.account_number === SpecialAccountItems.RetainedEarningsRollForward.Number).shift();
                if (typeof retainedEarningsRollForward === 'undefined') {
                    retainedEarningsRollForward = new AccountItem({
                        account_number: SpecialAccountItems.RetainedEarningsRollForward.Number,
                        name: SpecialAccountItems.RetainedEarningsRollForward.Name,
                        value: remainder,
                        tag_id: otherDistributionsTag.id,
                        month_id: monthId
                    });
                    accountItems.push(retainedEarningsRollForward);
                } else {
                    retainedEarningsRollForward.value = parseFloat(retainedEarningsRollForward.value) + remainder;
                }
                totalDistributionsItem.value += remainder;
            }
        });
    }

    // accountItems is an array of account items, modified in place. tagCategory is the category to balance
    // -> Sum all items values from this category (excluding the total tag), and update the 'total' item for this category
    updateTotalItemSumByTagCategory(accountItems, tagCategory, overWriteTotal) {
        if (typeof overWriteTotal === 'undefined') {
            overWriteTotal = true;
        }

        const categoryTagIDs = [];
        let categoryTotalTagID = -1;

        tagCategory.tags.forEach(tag => {
            if (tag.isTotal) {
                categoryTotalTagID = tag.id;
            } else {
                categoryTagIDs.push(tag.id);
            }
        });

        let sum = 0.0;
        const ItemsToSum = accountItems.filter(item => (categoryTagIDs.indexOf(item.tag_id) > -1));

        ItemsToSum.forEach(item => {
            sum += this.getAccountItemSubtotalValue(item);
        });

        const totalItem = accountItems.filter(item => item.tag_id === categoryTotalTagID).shift();
        if (categoryTotalTagID != -1 && typeof totalItem !== 'undefined' && overWriteTotal === true) {
            if (ItemsToSum.length > 0) {
                totalItem.value = sum;
            }
        }

        return totalItem.value;
    }

    //this function handles any special behavior for totalling categories
    getAccountItemSubtotalValue(accountItem) {
        //misc expense items count as negative towards the misc total
        const expenseIds = this.getMiscExpenseTagIds();
        if (expenseIds.indexOf(accountItem.tag_id) >= 0) {
            return -parseFloat(accountItem.value);
        }
        return parseFloat(accountItem.value);
    }

    getMiscExpenseTagIds() {
        //TagCode.IS_Misc_Expense,
        return CategoryUtils.NegativeMiscItems
            .map(code => TagCategoryStore.getTagForCode(code).id);
    }

    showAssetsValueWrongPopup() {
        const onOK = () => {
            this.dismissPopup();
        };

        const popup = (
            <Popup>
                <h3>Fixed Assets Value is too high!</h3>
                <div className="text-center">
                    <p>Our calculation indicates that your opening Fixed Assets value is too high.</p>
                    <p>Please lower the value to continue.</p>
                    <div className="row">
                        <div className="col-xs-12">
                            <button onClick={onOK} className="btn btn-primary wide">Okay</button>
                        </div>
                    </div>
                </div>
            </Popup>
        );

        this.showPopup(popup);
    }

    /**
     * 
     * @param {Month[]} monthsToPopulate 
     * @param {Month} startingMonth 
     * @param {*} seasonalData 
     */
    duplicateAccountItemsAcrossMonths(monthsToPopulate, startingMonth, seasonalData) {
        // We do the reverse because getIncomeStatementTagCategories excludes NetProfit items, but we need them included for balancing purposes later
        const tagCategories = TagCategoryStore.getBalanceSheetTagCategories();

        const BalanceSheetTagIDs = [];

        tagCategories.forEach(tagCat => {
            tagCat.tags.forEach(tag => {
                BalanceSheetTagIDs.push(tag.id);
            });
        });

        const seasonalDataValues = seasonalData.values.map(item => parseFloat(item.y));

        const accountItemsToSave = monthsToPopulate.map((month, seasonalDataIndex) => {
            const monthId = month.id;

            const scalingFactor = seasonalData.values[seasonalDataIndex].y;
            const accountItemsForMonth = startingMonth.AccountItems;

            // Only Scale the Income Statement Values
            const incomeStatementItemsForMonthFiltered = accountItemsForMonth.filter(item => BalanceSheetTagIDs.indexOf(item.tag_id) === -1);

            incomeStatementItemsForMonthFiltered.forEach((item) => {
                item.month_id = monthId;
                item.value = getSeasonalAdjustmentValue(item.value, scalingFactor, monthsToPopulate.length, seasonalDataValues);

                if (monthId !== startingMonth.id) {
                    item.id = null;
                }
            });

            return incomeStatementItemsForMonthFiltered;
        });

        return accountItemsToSave;
    }


    // Duplicates account items forward in time.
    // Balance sheet items are scaled linearly from start month to end month,
    // but with the seasonailty applied to the increase/decreas on the previous month
    //      - items in last year but NOT year to date, are populated as 0

    // Last year --    --    --    --    --    --    -- Year to date
    //    BS     [                                    ]     BS
    //
    //  Returns all balance sheet items to be created in the most recent year (including the most recent month)
    //
    // ASSUMPTIONS. Months were created chronologically, and their corresponding monthID's are increasing
    // E.g. Jan -> Feb -> Mar -> ...
    // ID:  1    -> 2   -> 3  -> ...


    /**
     * duplicates account items from previousMonth into the months between previousMonth and finalMonth,
     * applying a scaling gradient to these items
     *
     * @param previousMonth last month BEFORE the period to be created. items duplicated from here
     * @param finalMonth most recent month to consider
     * @param startSeasonalIndex index in seasonal adjustments or the first month to consider
     *
     * @returns Array of arrays of AccountItems, corresponding to the month after previousMonth up to and including finalMonth
     */
    getBalanceSheetForward(previousMonth, finalMonth, startSeasonalIndex) {
        // Create lookup to use later for more readable/better code
        const tagCategories = TagCategoryStore.getBalanceSheetTagCategories();
        const BalanceSheetTagIDs = [];

        tagCategories.forEach(tagCat => {
            tagCat.tags.forEach(tag => {
                BalanceSheetTagIDs.push(tag.id);
            });
        });

        const seasonalData = this.state.seasonalData.values.map(coord => coord.y);

        const thisYearItems = finalMonth.AccountItems.filter(item => (BalanceSheetTagIDs.indexOf(item.tag_id) > -1));  // Get ONLY BalanceSheet items
        const finalMonthAccountNumberLookup = {};

        thisYearItems.forEach(item => {
            finalMonthAccountNumberLookup[item.account_number] = item;
        });

        // Index in months array where we start from (and work forwards chronologically)
        // (Need to know where which month we want to start making new items at)
        const months = MonthStore.getMonths();
        //months to populate
        const monthsRange = MonthStore.getMonthsWithRange(previousMonth.date, finalMonth.date).slice(1, -1);

        const balanceSheetArray = [];
        monthsRange.forEach((month, index) => {
            //make a copy of start month items
            const accountItemsCopy = previousMonth.AccountItems;
            const newBalanceSheetItems = accountItemsCopy.filter(item => (BalanceSheetTagIDs.indexOf(item.tag_id) > -1));  // Get ONLY BalanceSheet items

            // Scale number ONLY if there is a matching item in current year to date balance sheet
            // Otherwise remove data (we have to assume it balances between now and year-to-date)
            newBalanceSheetItems.forEach(item => {
                if (item.account_number in finalMonthAccountNumberLookup) {
                    const finalValue = Math.round(parseFloat(finalMonthAccountNumberLookup[item.account_number].value));
                    const difference = finalValue - parseFloat(item.value);

                    let previousValue = parseFloat(item.value);

                    //add difference * seasonality for each month up to and including current month
                    for (let backIndex = 0; backIndex <= index; backIndex++) {
                        const currentSeasonalIndex = (startSeasonalIndex + backIndex) % 12;
                        previousValue += seasonalData[currentSeasonalIndex] * difference / 100;
                    }
                    item.value = Math.round(previousValue);
                } else {
                    item.value = 0;
                }
                item.id = null;
                item.month_id = month.id;
            });

            balanceSheetArray.push(newBalanceSheetItems);
        });

        //tack on the unmodified most recent month balance sheet
        balanceSheetArray.push(thisYearItems);

        return balanceSheetArray;
    }

    seasonalNextEnabled() {
        return parseFloat(this.getSeasonalTotal()) === 100 && !this.state.seasonalOnNextPressed;
    }

    getSeasonalTotal() {
        const { seasonalData } = this.state;
        let sum = 0.0;
        seasonalData.values.forEach(item => {
            sum += item.y;
        });

        return sum.toFixed(3);
    }

    debounceUpdate(value, index) {
        const { seasonalData } = this.state;

        const attemptedParse = parseFloat(value);
        if (!isNaN(attemptedParse) && attemptedParse >= 0) {
            seasonalData.values[index].y = attemptedParse;
        }

        this.setState({
            seasonalData: this.state.seasonalData
        });
    }

    allFilesLoaded() {
        if (this.state.import_current_year.getRowsBalanceSheet().length === 0) {
            return false;
        }
        if (this.state.import_previous_year.getRowsBalanceSheet().length === 0) {
            return false;
        }
        if (this.state.import_current_year.getRowsProfitLoss().length === 0) {
            return false;
        }
        if (this.state.import_previous_year.getRowsProfitLoss().length === 0) {
            return false;
        }
        if (this.state.import_first_month.getRowsBalanceSheet().length === 0) {
            return false;
        }
        return true;
    }

    // We dont need to check if the accountNumber is mapped (if it isnt, we default to the generated accountNumbers)
    isBalanceSheetFieldsMapped() {
        const fieldCodesToCheck = [Fields.BalanceSheet.AccountName, Fields.BalanceSheet.ValueAtMonthEnd];
        const unmappedFields = this.getUnmappedFields(fieldCodesToCheck);
        return unmappedFields.length === 0;
    }

    isIncomeStatementFieldsMapped() {
        const fieldCodesToCheck = [Fields.IncomeStatement.AccountName, Fields.IncomeStatement.ValueForTheMonth];
        const unmappedFields = this.getUnmappedFields(fieldCodesToCheck);
        return unmappedFields.length === 0;
    }

    getUnmappedFields(fieldCodesToCheckFor) {
        const fields = FieldStore.getFields();
        return fieldCodesToCheckFor.reduce((prev, currentCode) => {
            const fieldCodeHasMapping = fields.filter(field => {
                const codeMatches = field.code === currentCode;
                const fieldIsMapped = field.filtered_field_mapping.length > 0 && field.filtered_field_mapping[0].index !== -1;
                return codeMatches && fieldIsMapped;
            }).length > 0;

            return fieldCodeHasMapping ? prev : prev.concat(currentCode);
        }, []);
    }

    onFileLoaded(filename, text, uploadType, csvType, yearType) {
        switch (yearType) {
            case (YearType.CurrentYear): {
                this.state.import_current_year.updateRows(filename, text, uploadType, csvType);
                break;
            }
            case (YearType.LastYear): {
                this.state.import_previous_year.updateRows(filename, text, uploadType, csvType);
                break;
            }
            case (YearType.StartMonth): {
                this.state.import_first_month.updateRows(filename, text, uploadType, csvType);
                break;
            }
        }
        this.setState({
            import_previous_year: this.state.import_previous_year,
            import_current_year: this.state.import_current_year,
            import_first_month: this.state.import_first_month,
        });
    }

    getImportFilename(csvType, yearType) {
        switch (yearType) {
            case (YearType.CurrentYear):
                return this.state.import_current_year.filenames[UploadTypes.Single][csvType] || null;
            case (YearType.LastYear):
                return this.state.import_previous_year.filenames[UploadTypes.Single][csvType] || null;
            case (YearType.StartMonth):
                return this.state.import_first_month.filenames[UploadTypes.Single][csvType] || null;
        }
    }

    hasCsvError(csvType, yearType) {
        switch (yearType) {
            case (YearType.CurrentYear):
                return this.state.import_current_year.fileErrors[UploadTypes.Single][csvType];
            case (YearType.LastYear):
                return this.state.import_previous_year.fileErrors[UploadTypes.Single][csvType];
            case (YearType.StartMonth):
                return this.state.import_first_month.fileErrors[UploadTypes.Single][csvType];
        }
    }

    getLoadingIndicator() {
        const message = this.state.loadingMessage;
        const loadingProgress = this.getLoadingProgress();
        const progressBar = loadingProgress !== null ? (
            <ProgressBar
                type="bar"
                currentValue={loadingProgress.currentValue}
                totalValue={loadingProgress.totalValue}
            />
        ) : null;

        const messageElement = message ? <h4>{message}</h4> : null;

        //the the loading indicator displays as if it has content when passed an array of nulls
        let content = [
            messageElement,
            progressBar,
        ].filter(item => item != null);

        if (content.length === 0) {
            content = null;
        }


        return (
            <AltContainer stores={[AsyncQueueStore]}>
                <div>
                    <LoadingIndicator fullscreen>{content}</LoadingIndicator>
                </div>
            </AltContainer>
        );
    }

    getLoadingProgress() {
        const { progressTotal, progressCurrent } = this.state;
        if (progressTotal > 0) {
            return {
                currentValue: progressCurrent,
                totalValue: progressTotal,
            };
        }
        return null;
    }

    nextScreen() {
        this.goToScreenId(this.getWizardScreen() + 1);
    }

    previousScreen() {
        this.goToScreenId(this.getWizardScreen() - 1);
    }

    // page is something defined in WizardPages
    gotoScreen(page) {
        if (page === null) {
            return null;
        }

        this.goToScreenId(page.pageId);
    }

    goToScreenId(nextScreen) {
        if (typeof nextScreen !== 'number') {
            throw new Error("screen id number not provided")
        }

        const currentScreen = this.getWizardScreen();
        const isForward = currentScreen <= nextScreen;

        // navigating forwards
        if (currentScreen < nextScreen) {
            const currentYearTotalsToValidate = [
                {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementRevenue.pageId,
                    tags: [TagCode.IS_Revenue_TotalRevenue]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementDirectCosts.pageId,
                    tags: [TagCode.IS_DirectCosts_TotalDirectCosts]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementIndirectCosts.pageId,
                    tags: [TagCode.IS_IndirectCosts_TotalIndirectCosts]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementProfit.pageId,
                    tags: [TagCode.IS_NetProfit]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementMisc.pageId,
                    tags: []
                }, {
                    screenId: WizardPages.Import.CurrentYearTagIncomeStatementDistributions.pageId,
                    tags: []
                }, {
                    screenId: WizardPages.Import.CurrentYearTagBalanceSheetAssets.pageId,
                    tags: [TagCode.BS_TotalAssets_TotalAssets]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagBalanceSheetLiabilities.pageId,
                    tags: [TagCode.BS_TotalLiabilities_TotalLiabilities]
                }, {
                    screenId: WizardPages.Import.CurrentYearTagBalanceSheetEquity.pageId,
                    tags: [TagCode.BS_TotalEquity_TotalEquity]
                },
            ];

            const taggingErrors = [];

            const addErrors = (possibleErrors, label) => {
                if (Array.isArray(possibleErrors)) {
                    taggingErrors.push({ label, errors: possibleErrors });
                }
            };

            try {
                const currentYearTotalsToValidateIndex = currentYearTotalsToValidate.findIndex(it => it.screenId === currentScreen);

                if (currentYearTotalsToValidateIndex > -1) {
                    const totalsToValidateForScreen = currentYearTotalsToValidate
                        .slice(0, currentYearTotalsToValidateIndex + 1)
                        .reduce((tags, it) => tags.concat(it.tags), []);

                    const currentYearTaggingErrors = this.state.import_current_year.validateImportTagging();

                    if (currentYearTaggingErrors !== null) {
                        const filteredErrors = currentYearTaggingErrors.filter(it => totalsToValidateForScreen.includes(it.tagCode));

                        if (filteredErrors.length > 0) {
                            taggingErrors.push({ errors: filteredErrors });
                        }
                    }
                }

                if (currentScreen === WizardPages.Import.PreviousYearTagIncomeStatement.pageId) {
                    addErrors(this.state.import_current_year.validateImportTagging(), 'Current Year');
                    addErrors(this.state.import_previous_year.validateImportTagging(LedgerName.IncomeStatement), 'Previous Year');
                }

                if (currentScreen === WizardPages.Import.PreviousYearTagBalanceSheet.pageId) {
                    addErrors(this.state.import_current_year.validateImportTagging(), 'Previous Year');
                    addErrors(this.state.import_previous_year.validateImportTagging(), 'Current Year');
                }

                if (currentScreen === WizardPages.Import.StartMonthTagBalanceSheet.pageId) {
                    addErrors(this.state.import_first_month.validateImportTagging(LedgerName.BalanceSheet), 'Opening Balance Sheet');
                    addErrors(this.state.import_current_year.validateImportTagging(), 'Current Year');
                    addErrors(this.state.import_previous_year.validateImportTagging(), 'Previous Year');
                }

                if (taggingErrors.length > 0) {
                    this.setState({
                        taggingErrors: taggingErrors,
                    });
                    return;
                }

            } catch (err) {
                console.error(err);
                this.setState({
                    taggingErrors: err,
                });
                return;
            }
        }

        try {
            if (nextScreen === WizardPages.Import.PreviewCurrentYearIncomeStatement.pageId) {
                this.state.import_current_year.getAccountItemsFromCSV(LedgerName.IncomeStatement);
            }

            if (nextScreen === WizardPages.Import.PreviewCurrentYearBalanceSheet.pageId) {
                this.state.import_current_year.getAccountItemsFromCSV();
            }

            if (nextScreen === WizardPages.Import.PreviewPreviousYearIncomeStatement.pageId) {
                this.state.import_previous_year.getAccountItemsFromCSV(LedgerName.IncomeStatement);
            }

            if (nextScreen === WizardPages.Import.PreviewPreviousYearBalanceSheet.pageId) {
                this.state.import_previous_year.getAccountItemsFromCSV();
            }

            if (nextScreen === WizardPages.Import.PreviewStartMonthBalanceSheet.pageId) {
                this.state.import_first_month.getAccountItemsFromCSV(LedgerName.BalanceSheet);
            }
        } catch (err) {
            console.error(err);
            if (isForward) {
                this.setState({
                    taggingErrors: err,
                });
            } else {
                this.goToScreenId(nextScreen - 1);
            }
            return;
        }

        this.onUpdateOnboardingProgress(nextScreen);
    }

    // Date Selector Functions
    onThisYearChange(date) {
        const newDate = Formatter.discardDatePrecision(date.clone());
        this.setState({
            thisYearDate: newDate,
            lastYearDate: Formatter.discardDatePrecision(date.clone().subtract(this.NumberOfMonthsThisYear, 'month')),
        });
    }

    onUpdateOnboardingProgress(newId) {
        const account = this.getAccount();
        if (account != null) {
            account.onboarding_progress = newId;
            return this.props.accountStore.performSave(account)
        }
        return Promise.resolve();
    }

    getWizardScreen() {
        const account = this.getAccount();
        if (account != null) {
            return account.OnboardingProgress;
        }
        return null;
    }

    getNumberMonthsBetweenDates(startDate, endDate) {
        // startDate and endDate are both Months or Moments
        // Assumption: startDate precedes EndDate

        // Make dates the start of their respective months (moment library treats days as 30 days..
        startDate = startDate.date(1).minute(0).second(0).millisecond(0);
        endDate = endDate.date(1).minute(0).second(0).millisecond(0);

        let counter = 0;
        while (startDate.isBefore(endDate)) {
            startDate = startDate.add(1, 'month');
            counter += 1;
        }

        return counter;
    }

    getAccountItemsAcrossMonths_Import(lastYearMonth, thisYearMonth) {
        // create items to store for current year months
        // const numMonthsToPopulate = this.getNumberMonthsBetweenDates(lastYearMonth.Date, thisYearMonth.Date);
        const currentYearItemsOneMonth = this.getAccountItemsAndTagsFromImport(this.state.import_current_year, thisYearMonth.Date);

        // create items to store for last year months
        const lastYearItemsOneMonth = this.getAccountItemsAndTagsFromImport(this.state.import_previous_year, lastYearMonth.Date);
        const firstMonthItems = this.getAccountItemsAndTagsFromImport(this.state.import_first_month, this.StartMonth.Date, LedgerName.BalanceSheet);

        return {
            accountItems: [
                firstMonthItems.accountItems,
                lastYearItemsOneMonth.accountItems,
                currentYearItemsOneMonth.accountItems
            ],
            accountNumberTags: [
                ...firstMonthItems.accountNumberTags,
                ...lastYearItemsOneMonth.accountNumberTags,
                ...currentYearItemsOneMonth.accountNumberTags,
            ],
        }
    }

    /**
     * Saves a list (or list of lists) of Account Items, but first creates any new Account Number Tags
     * @param {AccountItem[] | AccountItem[][]} accountItemListOrLists
     */
    batchSaveAccountItemsAndCreateTags(accountItemListOrLists) {
        /**
         * @type AccountItem[]
         */
        const accountItemLists = accountItemListOrLists.find(sublist => Array.isArray(sublist)) ? accountItemListOrLists : [accountItemListOrLists];

        return this.saveNewAccountNumberTags([...accountItemLists])
            .then(() => Promise.all(
                accountItemLists.map(list => AccountItemStore.performBatchSave(list))
            ));
    }

    /**
     * Saves a list (or list of lists) of Account Items, but first creates any new Account Number Tags
     * @param {AccountItem[]} accountItems
     * @return Promise
     */
    saveNewAccountNumberTags(accountItems) {
        const newAccountNumberTags = generateNewAccountNumberTags(accountItems, AccountNumberTagsStore.getAccountNumberTags());

        return (
            newAccountNumberTags.length === 0
                ? Promise.resolve()
                : AccountNumberTagsStore.performBatchSave(newAccountNumberTags)
        );
    }

    /**
     *
     * @param {AccountItem[][]} itemsToSave
     * @param {boolean} showProgress
     * @return {Promise<void>}
     */
    saveAccountItems(itemsToSave, showProgress) {
        // Save months individually, could be troublesome from a DB perspective, what happens if we loose connection halfway through?
        // (Current DB batch save can timeout if query takes too long)
        if (showProgress) {
            this.setState({
                progressTotal: itemsToSave.reduce((prev, curr) => prev + curr.length, 0),
                progressCurrent: 0
            });
        }

        const accountItemLists = itemsToSave.find(sublist => Array.isArray(sublist)) ? itemsToSave : [itemsToSave];

        const newAccountNumberTags = generateNewAccountNumberTags(accountItemLists.reduce((accum, curr) => accum.concat(curr), []), AccountNumberTagsStore.getAccountNumberTags());

        let progress = 0;

        return AccountNumberTagsStore.performBatchSave(newAccountNumberTags)
            .then(() => Promise.all(
                itemsToSave.reduce((prev, itemsInMonth) => {
                    const existingItems = itemsInMonth.filter(item => item.id != null);
                    const newItems = itemsInMonth.filter(item => item.id === null);
                    return prev.concat([existingItems, newItems].filter(ls => ls.length > 0));
                    }, []).map(group => AccountItemStore.performMultiBatchSave(group)
                    .then(() => {
                        if (showProgress) {
                            progress += group.length;
                            this.setState({ progressCurrent: progress });
                        }
                    })
                )
            ))
            .then(() => MonthStore.fetchAllMonths())
            .then(() => AccountNumberTagsStore.fetchData())
            .then(() => this.setState({
                progressTotal: 0,
                progressCurrent: 0
            }));
    }

    /**
     *
     * @param {Import} import_year
     * @param {Moment} start_month
     * @param {LedgerName=} ledgerName
     * @return {{accountItems: AccountItem[], accountNumberTags: AccountNumberTag[]}}
     */
    getAccountItemsAndTagsFromImport(import_year, start_month, ledgerName) {
        const monthDate = Formatter.momentToServerDateFormat(start_month);
        const month = MonthStore.getMonths().find(it => it.date === monthDate);
        const accountItems = import_year.getAccountItemsFromCSV(ledgerName).map(item => ({
            ...item,
            month_id: month.id,
        }));

        return {
            accountItems,
            accountNumberTags: [],
        };
    }

    createMonths() {
        const { lastYearDate, thisYearDate } = this.state;
        const lastYearMonth = lastYearDate;
        const thisYearMonth = thisYearDate;

        //start of first 12 months take an extra month
        const firstMonth = Formatter.discardDatePrecision(lastYearMonth.clone().subtract(12, 'months'));
        const lastMonth = thisYearMonth.clone();

        const months = [];
        const monthItr = firstMonth.clone();
        do {
            months.push(new Month({ date: Formatter.momentToServerDateFormat(monthItr.clone()) }));
            monthItr.add(1, 'month');
        } while (!monthItr.isAfter(lastMonth));

        return this.wipeMonths()
            .then(() => MonthStore.performSaveMonths(months))
            .then(() => MonthStore.fetchAllMonths())
            .then(() => MonthStore.fetchMonth(lastYearDate));    // Set current month
    }

    wipeMonths() {
        return MonthStore.performDeleteAll();
    }

    // get the account number for a subcategory total
    // assumes there is only one account number for the given tag
    // handles special cases,
    // else finds the first match,
    // else creates a new account number

    /**
     * @param {TagCode} tag 
     * @returns {string}
     */
    getSubcategoryAccountNumber(tag) {
        if (tag.code === TagCode.IS_IndirectCosts_Other) {
            return SpecialAccountItems.TotalOtherIndirectCosts.Number;
        }
        const num = this.getSubcategoryAccountItems(tag);
        if (typeof num === 'undefined') {
            return Utils.generateUuid();
        }
        return num.lastYear.account_number;
    }
    /**
     * @param {TagCode} tag 
     * @returns {MultiPeriodValues}
     */
    getSubcategoryAccountItems(tag) {
        let accountNumbers;
        if (tag.code === TagCode.IS_IndirectCosts_Other) {
            accountNumbers = [SpecialAccountItems.TotalOtherIndirectCosts.Number];
        } else {
            accountNumbers = AccountNumberTagsStore.getAccountNumberTags().filter(ant => ant.tag_id === tag.id)
                .map(ant => ant.account_number);
        }
        return accountNumbers.map(num => this.getAccountItemMultiYear(num))
            .filter(item => item != null).shift();
    }

    createAccountItems() {
        const tagCategories = TagCategoryStore.getTagCategories();
        const totalTags = tagCategories.map(cat =>
            TagCategoryStore.getTotalTagForCategory(cat.id)
        );

        const lastYearMonthId = this.LastYearMonth.id;

        const totalItems = totalTags.map(tag =>
            new AccountItem({
                account_number: tag.name,
                name: tag.name,
                value: 0,
                tag_id: tag.id,
                month_id: lastYearMonthId
            })
        );

        const netProfit = new AccountItem({
            account_number: SpecialAccountItems.NetProfit.Number,
            name: SpecialAccountItems.NetProfit.Name,
            value: 0,
            tag_id: TagCategoryStore.getTagForCode(TagCode.IS_NetProfit).id,
            month_id: lastYearMonthId
        });

        const interestRevenue = new AccountItem({
            account_number: Utils.generateUuid(),
            name: 'Interest Revenue',
            value: 0,
            tag_id: TagCategoryStore.getTagForCode(TagCode.IS_Revenue_InterestRevenue).id,
            month_id: lastYearMonthId
        });

        const createItemsForTagCategory = (category, exclusions) => {
            if (typeof exclusions === 'undefined') {
                exclusions = [];
            }
            return category.tags
                .filter(tag => !tag.isTotal && exclusions.indexOf(tag.code) < 0)
                .map(tag =>

                    new AccountItem({
                        account_number: this.getSubcategoryAccountNumber(tag),
                        name: tag.name,
                        value: 0,
                        tag_id: tag.id,
                        month_id: lastYearMonthId
                    })
                );
        };

        const directCostsTag = TagCategoryStore.getTagCategoryByName(TagCategoryName.DirectCosts);
        const directCostsItems = createItemsForTagCategory(directCostsTag);

        const indirectCostsTag = TagCategoryStore.getTagCategoryByName(TagCategoryName.IndirectCosts);
        const indirectCostsItems = createItemsForTagCategory(indirectCostsTag);

        const miscTag = TagCategoryStore.getTagCategoryByName(TagCategoryName.Misc);
        const miscExclusions = [TagCode.IS_Misc_Other];
        const miscItems = createItemsForTagCategory(miscTag, miscExclusions);

        const balanceSheetItems = TagCategoryStore.getBalanceSheetTagCategories()
            .map(cat => createItemsForTagCategory(cat))
            .reduce((prev, curr) => prev.concat(curr), []);

        const accountItems = totalItems
            .concat([netProfit, interestRevenue])
            .concat(directCostsItems)
            .concat(indirectCostsItems)
            .concat(miscItems)
            .concat(balanceSheetItems);
        const thisYearAccountItems = this.duplicateAccountItemsForMonth(accountItems, this.ThisYearMonth.id);

        const balanceSheetTagIds = TagCategoryStore.getBalanceSheetTagCategories().map(cat => TagCategoryStore.getTotalTagForCategory(cat.id).id);
        const balanceTotalItems = totalItems.filter(item => balanceSheetTagIds.indexOf(item.tag_id) >= 0);
        const openingBalanceSheetAccountItems = this.duplicateAccountItemsForMonth(balanceTotalItems.concat(balanceSheetItems), this.StartMonth.id);

        return this.saveNewAccountNumberTags(accountItems.concat(thisYearAccountItems, openingBalanceSheetAccountItems))
            .then(() => AccountItemStore.performBatchSave(accountItems))
            .then(() => AccountItemStore.performBatchSave(thisYearAccountItems))
            .then(() => AccountItemStore.performBatchSave(openingBalanceSheetAccountItems))
            .then(() => AccountNumberTagsStore.fetchData())
            .then(() => this.updateMonths());
    }

    updateMonths() {
        return MonthStore.fetchAllMonths();
    }

    /**
     * 
     * @param {AccountItem} accountItem 
     * @param {number} val 
     */
    updateAccountItem(accountItem, val) {
        val = Utils.parseMoney(val);
        if (isNaN(val)) {
            this.forceUpdate();
            return Promise.resolve();
        }
        accountItem.value = val;
        return AccountItemStore.performSave(accountItem)
            .then(() => MonthStore.fetchAllMonths())
            .then(() => this.forceUpdate());
    }

    /**
     * 
     * @param {boolean=} showStartMonth 
     * @param {boolean=} showRange 
     */
    getYearHeader(showStartMonth, showRange) {
        return (
            <OnboardingDateHeader
                showStartMonth={showStartMonth}
                showRange={showRange}
                thisYearDate={this.ThisYearMonth.Date}
                lastYearDate={this.LastYearMonth.Date}
                startMonthDate={this.StartMonth.Date}
            />
        );
    }

    getCategoriesByName(names) {
        return names.map(catName => this.getCategoryByName(catName));
    }

    getCategoryByName(name) {
        return TagCategoryStore.getTagImportCategories().find(cat => cat.name === name);
    }

    getCategoryTotalAccordion(category, editable, children) {
        let accountItems;
        if (category.name === TagCategoryName.Misc) {
            accountItems = this.getCalculatedMiscTotals();
        } else {
            const accountNumberTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(category.id);
            accountItems = this.getAccountItemMultiYear(accountNumberTag.account_number);
        }
        const totalTag = TagCategoryStore.getTotalTagForCategory(category.id);

        const className = Utils.toClassName(category.name);

        return this.getTopLevelAccordion(accountItems, totalTag.name, className, children, editable);
    }

    getTopLevelAccordion(accountItems, heading, className, children, editable) {
        const startMonthProps = exists(accountItems.startMonth)
            ? {
                value3: Utils.removePrecisionAndGetCommas(accountItems.startMonth.value),
                label3: '$',
                onValueChange3: editable ? (val => this.updateAccountItem(accountItems.startMonth, val)) : null,
            }
            : {};

        return (
                <Accordion
                    key={heading}
                    collapsible={false}
                    heading={heading}
                    className={`category inset-row no-background ${className}`}
                    value={Utils.removePrecisionAndGetCommas(accountItems.lastYear.value)}
                    value2={Utils.removePrecisionAndGetCommas(accountItems.thisYear.value)}
                    label="$"
                    label2="$"
                    onValueChange={editable ? (val => this.updateAccountItem(accountItems.lastYear, val)) : null}
                    onValueChange2={editable ? (val => this.updateAccountItem(accountItems.thisYear, val)) : null}
                    {...startMonthProps}
                >{children}</Accordion>
        );
    }

    getNetProfitAccordion(editable, children) {
        const accountItems = this.getAccountItemMultiYear(SpecialAccountItems.NetProfit.Number);

        const className = Utils.toClassName(TagCategoryName.Profit);

        return this.getTopLevelAccordion(accountItems, SpecialAccountItems.NetProfit.Name, className, children, editable);
    }

    getAccountNumbersForTagCode(code) {
        const tag = TagCategoryStore.getTagForCode(code);
        const nums = AccountNumberTagsStore.getAccountNumberTagsWithTag(tag.id);
        return nums;
    }

    /**
     *
     * @param {MultiPeriodValues} accountItems
     * @param {String} title
     * @param {boolean} editable
     * @param {JSX.Element[]=} children
     * @param {Function=} onAdd
     * @return {JSX.Element}
     */
    getSubcategoryAccordion(accountItems, title, editable, children, onAdd) {
        const value = Utils.removePrecisionAndGetCommas(accountItems.lastYear.value);
        const label = '$';
        let onChangeAmount;
        const value2 = Utils.removePrecisionAndGetCommas(accountItems.thisYear.value);
        const label2 = '$';
        let onChangeAmount2;

        if (editable) {
            onChangeAmount = val => this.updateAccountItem(accountItems.lastYear, val);
            onChangeAmount2 = val => this.updateAccountItem(accountItems.thisYear, val);
        } else {
            onChangeAmount = null;
            onChangeAmount2 = null;
        }

        const startMonthProps = exists(accountItems.startMonth)
            ? {
                value3: Utils.removePrecisionAndGetCommas(accountItems.startMonth.value),
                label3: '$',
                onChangeAmount3: editable ? (val => this.updateAccountItem(accountItems.startMonth, val)) : null,
            }
            : {};

        return (
            <DetailedAccordion
                key={title}
                collapsible={false}
                heading={title}
                value={value}
                label={label}
                onChangeAmount={onChangeAmount}
                value2={value2}
                label2={label2}
                onChangeAmount2={onChangeAmount2}
                onAddPressed={onAdd}
                {...startMonthProps}
            >
                {children}
            </DetailedAccordion>
        );
    }

    /**
     * 
     * @param {{
     *      accountItems: MultiPeriodAccountItemsStruct,
     *      title: string
     *      editable: boolean
     *      onChangeThisYear: (value: string) => void
     *      onChangeLastYear: (value: string) => void
     * }} options 
     */
    getGenericAccordion(options) {
        const { accountItems, title, editable } = options;
        const { onChangeThisYear, onChangeLastYear } = options;

        return (
            <GenericOnboardingAccordion
                valueThisYear={accountItems.thisYear.value}
                valueLastYear={accountItems.lastYear.value}
                valueStartMonth={accountItems.startMonth ? accountItems.startMonth.value : null}
                {...{
                    title,
                    editable,
                    onChangeThisYear,
                    onChangeLastYear,
                    onChangeStartMonth: val => this.updateAccountItem(accountItems.startMonth, val),
                }}
            />
        )
    }

    getInterestRevenue() {
        let interestRevenue = this.getAccountNumbersForTagCode(TagCode.IS_Revenue_InterestRevenue)
            .map(num => this.getAccountItemMultiYear(num.account_number));
        interestRevenue = interestRevenue
            .filter(item => item != null).shift();

        const totalTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(TagCategoryStore.getTagCategoryByName(TagCategoryName.Revenue).id);
        const totalItems = this.getAccountItemMultiYear(totalTag.account_number);

        const remainderRow = this.getRemainderRow(totalItems, [interestRevenue], false);
        const interestRevenueRow =
            this.getSubcategoryAccordion(interestRevenue, 'Interest Revenue', true);

        const accordion = this.getCategoriesByName([
            TagCategoryName.Revenue
        ])
            .map(cat => this.getCategoryTotalAccordion(cat, false,
                [interestRevenueRow,
                    remainderRow])
            );
        return accordion;
    }

    getCalculatedMiscTotals() {
        const getTotals = catName => {
            const cat = TagCategoryStore.getTagCategoryByName(catName);
            const totalTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(cat.id);
            return this.getAccountItemMultiYear(totalTag.account_number);
        };

        const revenueMinusExpense = this.getRemainders(
            getTotals(TagCategoryName.Revenue),
            [
                getTotals(TagCategoryName.DirectCosts),
                getTotals(TagCategoryName.IndirectCosts)
            ]
        );

        return this.getRemainders(this.getAccountItemMultiYear(SpecialAccountItems.NetProfit.Number), [revenueMinusExpense]);
    }

    getTagFromTagCategory(tagCategory, tagCode) {
        if (isNullOrUndefined(tagCategory) || isNullOrUndefined(tagCategory.tags)) {
            return null;
        }

        return tagCategory.tags.filter(tag => tag.code === tagCode).shift();
    }

    getTagCategoryFromName(tagCategoryName) {
        return TagCategoryStore.getTagCategories().filter(tagCategory => tagCategory.name === tagCategoryName).shift();
    }

    getAccountItemsForTag(accountItems, tag) {
        return accountItems.filter(accountItem => accountItem.tag_id === tag.id);
    }

    /**
     * 
     * @param {AccountItem[]} accountItems 
     */
    getCalculatedMisc(accountItems) {
        const revenueTagCategory = this.getTagCategoryFromName(TagCategoryName.Revenue);
        const directCostsTagCategory = this.getTagCategoryFromName(TagCategoryName.DirectCosts);
        const indirectCostsCategory = this.getTagCategoryFromName(TagCategoryName.IndirectCosts);

        const netProfitItem = accountItems.filter(accountItem => accountItem.account_number === SpecialAccountItems.NetProfit.Number).shift();

        const revenueTag = this.getTagFromTagCategory(revenueTagCategory, TagCode.IS_RevenueCodes_TotalRevenue);
        const directCostsTag = this.getTagFromTagCategory(directCostsTagCategory, TagCode.IS_DirectCostsCodes_TotalDirectCosts);
        const indirectCostsTag = this.getTagFromTagCategory(indirectCostsCategory, TagCode.IS_IndirectCostsCodes_TotalIndirectCosts);

        const revenueItem = this.getAccountItemsForTag(accountItems, revenueTag).shift();
        const directCostsItem = this.getAccountItemsForTag(accountItems, directCostsTag).shift();
        const indirectCostsItem = this.getAccountItemsForTag(accountItems, indirectCostsTag).shift();

        const netProfitSum = isNullOrUndefined(netProfitItem) ? 0 : netProfitItem.value;
        const revenueSum = isNullOrUndefined(revenueItem) ? 0 : revenueItem.value;
        const directCostsSum = isNullOrUndefined(directCostsItem) ? 0 : directCostsItem.value;
        const indirectCostsSum = isNullOrUndefined(indirectCostsItem) ? 0 : indirectCostsItem.value;

        return (netProfitSum - revenueSum + directCostsSum + indirectCostsSum);
    }

    formatNumber(x) {
        const isNaNOrInfinity = val => val === Infinity || isNaN(val);

        const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        if (isNaNOrInfinity(x)) {
            return ' - ';
        }
        return `${numberWithCommas(Math.round(x))}`;
    }

    get NegativeTagIds() {
        return CategoryUtils.NegativeMiscItems
            .map(code => TagCategoryStore.getTagForCode(code).id);
    }

    getCategoryWithSubcategories(categoryName, remainderMustBeZero, customRemainderText) {
        if (typeof remainderMustBeZero === 'undefined') {
            remainderMustBeZero = false;
        }

        const categoryTag = TagCategoryStore.getTagCategoryByName(categoryName);

        const rowValues = [];

        const rows = categoryTag.tags
            .filter(tag => !tag.isTotal)
            .map(tag => {
                const accountNumber = this.getSubcategoryAccountNumber(tag);
                const accountItems = this.getAccountItemMultiYear(accountNumber);
                if (accountItems === null) {
                    return null;
                }
                rowValues.push(accountItems);
                return this.getSubcategoryAccordion(accountItems, tag.name, true);
            }
            )
            .filter(item => item != null);

        let totalItems;
        if (categoryName === TagCategoryName.Misc) {
            totalItems = this.getCalculatedMiscTotals();
        } else {
            const totalTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(categoryTag.id);
            totalItems = this.getAccountItemMultiYear(totalTag.account_number);
        }


        const remainderRow = this.getRemainderRow(totalItems, rowValues, remainderMustBeZero, customRemainderText);
        rows.push(remainderRow);

        const remainders = this.getRemainders(totalItems, rowValues);

        const accordion = this.getCategoryTotalAccordion(categoryTag, false,
            rows
        );

        const nextEnabled = () => this.checkRemainderMultiYear(remainders);

        return {
            content: accordion,
            nextEnabled
        };
    }

    getAllocateRevenueClasses() {
        const subtotals = this.getRevenueRemainder();
        return this.getAllocateSubcategory('Revenue Subtotal', subtotals, TagCode.IS_Revenue_Revenue, [], false);
    }

    getRevenueRemainder() {
        const interestRevenueNumber = this.getAccountNumbersForTagCode(TagCode.IS_Revenue_InterestRevenue);
        const interestRevenue = interestRevenueNumber.map(item => this.getAccountItemMultiYear(item.account_number))
            .filter(item => item != null).shift();

        const totalTag = AccountNumberTagsStore.getTaggedTotalAccountNumberTagForTagCategory(TagCategoryStore.getTagCategoryByName(TagCategoryName.Revenue).id);
        const totalItems = this.getAccountItemMultiYear(totalTag.account_number);

        const subtotals = this.getRemainders(totalItems, [interestRevenue]);

        return subtotals;
    }

    getAllocateOtherIndirectCosts() {
        const subtotals = this.getOtherIndirectCostsSubtotal();
        return this.getAllocateSubcategory('Other Indirect Costs', subtotals, TagCode.IS_IndirectCosts_Other, [SpecialAccountItems.TotalOtherIndirectCosts.Number], false);
    }

    getOtherIndirectCostsSubtotal() {
        const otherIndirectCostsTag = TagCategoryStore.getTagForCode(TagCode.IS_IndirectCosts_Other);
        const subtotalAccountNumber = this.getSubcategoryAccountNumber(otherIndirectCostsTag);
        const subtotals = this.getAccountItemMultiYear(subtotalAccountNumber);
        return subtotals;
    }

    getBalanceSheetRemainder() {
        const totalAssets = this.getCategoryTotalByName(TagCategoryName.TotalAssets);
        const totalLiabilities = this.getCategoryTotalByName(TagCategoryName.TotalLiabilities);
        const totalEquity = this.getCategoryTotalByName(TagCategoryName.TotalEquity);

        // e = a - l
        return this.getRemainders(totalAssets, [totalLiabilities, totalEquity]);
    }

    getBalanceSheetRemainderRow() {
        return this.getRemainderRow(this.getBalanceSheetRemainder(), [], true, Text.BalanceCheckEqualZero);
    }



    /**
     *
     * @param {string} title title to display
     * @param {AccountItem[]} subtotals pair of account items representing the subtotal row
     * @param {TagCode} subcategory the category for which to create and display new account items
     * @param {TagCode[]} subcategoryExclusions account numbers to exclude from display
     * @param {boolean} remainderMustBeZero boolean describing remainder behaviour
     * @returns {{ content: JSX.Element, nextEnabled: () => void }}
     */
    getAllocateSubcategory(title, subtotals, subcategory, subcategoryExclusions, remainderMustBeZero) {
        if (typeof subcategoryExclusions === 'undefined') {
            subcategoryExclusions = [];
        }

        if (typeof remainderMustBeZero === 'undefined') {
            remainderMustBeZero = false;
        }

        const subcategoryTag = TagCategoryStore.getTagForCode(subcategory);

        const createSubcategoryItem = () => {
            const newAccountNumberTag = new AccountNumberTag({
                account_number: Utils.generateUuid(),
                tag_id: subcategoryTag.id,
            });

            const newItem = [new AccountItem({
                account_number: newAccountNumberTag.account_number,
                name: 'New Item',
                value: 0,
                tag_id: newAccountNumberTag.tag_id,
                month_id: this.LastYearMonth.id
            })];

            const item2 = this.duplicateAccountItemsForMonth(newItem, this.ThisYearMonth.id);

            return AccountNumberTagsStore.performSave(newAccountNumberTag)
                .then(() => AccountItemStore.performBatchSave(newItem.concat(item2)))
                .then(() => this.updateMonths())
                .then(() => this.forceUpdate());
        };

        const subcategoryAccountNumbers = this.getAccountNumbersForTagCode(subcategoryTag.code)
            .filter(tag => subcategoryExclusions.indexOf(tag.account_number) < 0);

        const subcategoryItems = subcategoryAccountNumbers.map(number => this.getAccountItemMultiYear(number.account_number))
            .filter(item => item != null);

        const itemRows = subcategoryItems
            .map((item, index) => (
                <DetailedAccordion
                    key={index}
                    heading={item.lastYear.name}
                    onChangeHeading={(value) => this.renameAccountItem(item.lastYear.account_number, value)}
                    value={Utils.removePrecisionAndGetCommas(item.lastYear.value)}
                    label="$"
                    value2={Utils.removePrecisionAndGetCommas(item.thisYear.value)}
                    label2="$"
                    onChangeAmount={val => this.updateAccountItem(item.lastYear, val)}
                    onChangeAmount2={val => this.updateAccountItem(item.thisYear, val)}
                    onDeletePressed={() => this.removeAccountItem(item.lastYear.account_number)}
                    className="coloured-accordion"
                />
            ));
        const remainderRow = this.getRemainderRow(subtotals, subcategoryItems, remainderMustBeZero);
        const content = this.getSubcategoryAccordion(subtotals, title, false, [
            itemRows,
            remainderRow
        ], createSubcategoryItem);

        const remainders = this.getRemainders(subtotals, subcategoryItems);

        const nextEnabled = () => this.checkRemainderMultiYear(remainders);

        return {
            content,
            nextEnabled
        };
    }

    /**
     * @param values: object {
     *          startYear: { value } (optional),
     *          lastYear: { value },
     *          thisYear: { value }
     *      }
     * @returns boolean: whether all values are 0
     */
    checkRemainderMultiYear(values) {
        return (
            (isNullOrUndefined(values.startMonth) || values.startMonth.value === 0)
            && values.lastYear.value === 0
            && values.thisYear.value === 0
        );
    }

    removeAccountItem(accountNumber) {
        const items = this.getAccountItemMultiYear(accountNumber);
        if (items != null) {
            return AccountItemStore.performDeleteItem(items.lastYear)
                .then(() => AccountItemStore.performDeleteItem(items.thisYear))
                .then(() => this.updateMonths())
                .then(() => this.forceUpdate());
        }
    }

    renameAccountItem(accountNumber, name) {
        const items = this.getAccountItemMultiYear(accountNumber);
        if (items != null) {
            const accountNumberTag = AccountNumberTagsStore.getAccountNumberTag(accountNumber);
            accountNumberTag.name = name;
            items.lastYear.name = name;
            items.thisYear.name = name;
            return AccountItemStore.performMultiBatchSave([items.lastYear, items.thisYear])
                .then(() => AccountNumberTagsStore.performSave(accountNumberTag))
                .then(() => this.updateMonths())
                .then(() => this.forceUpdate());
        }
    }

    addItemsMultiYear(itemsToSum) {
        const negativeTags = this.NegativeTagIds;

        const sums = new MultiPeriodValues(0, 0);

        itemsToSum.forEach(items => {
            const startMonthExists = exists(sums.startMonth) && exists(items.startMonth);
            if (negativeTags.indexOf(items.lastYear.tag_id) >= 0) {
                if (startMonthExists) {
                    sums.startMonth.value -= parseInt(items.startMonth.value);
                }
                sums.lastYear.value -= parseInt(items.lastYear.value);
                sums.thisYear.value -= parseInt(items.thisYear.value);
            } else {
                if (startMonthExists) {
                    sums.startMonth.value += parseInt(items.startMonth.value);
                }
                sums.lastYear.value += parseInt(items.lastYear.value);
                sums.thisYear.value += parseInt(items.thisYear.value);
            }
        });
        return sums;
    }

    getRemainders(totals, others) {
        const negativeTags = this.NegativeTagIds;

        const remainders = {
            startMonth: exists(totals.startMonth) ? { value: parseInt(totals.startMonth.value) } : null,
            lastYear: { value: parseInt(totals.lastYear.value) },
            thisYear: { value: parseInt(totals.thisYear.value) }
        };

        [].concat(others).forEach(
            items => {
                const startMonthExists = exists(remainders.startMonth) && exists(items.startMonth);
                if (negativeTags.indexOf(items.lastYear.tag_id) >= 0) {
                    if (startMonthExists) {
                        remainders.startMonth.value += parseInt(items.startMonth.value);
                    }
                    remainders.lastYear.value += parseInt(items.lastYear.value);
                    remainders.thisYear.value += parseInt(items.thisYear.value);
                } else {
                    if (startMonthExists) {
                        remainders.startMonth.value -= parseInt(items.startMonth.value);
                    }
                    remainders.lastYear.value -= parseInt(items.lastYear.value);
                    remainders.thisYear.value -= parseInt(items.thisYear.value);
                }
                if (!startMonthExists) {
                    remainders.startMonth = null;
                }
            }
        );
        return remainders;
    }

    getRemainderRow(totals, others, remainderMustBeZero, customText) {
        const values = this.getRemainders(totals, others);
        let remainderText = 'Remainder';
        if (customText) {
            remainderText = customText;
        } else if (remainderMustBeZero) {
            remainderText = 'Remainder (must equal zero to progress)';
        }

        const startMonthProps = exists(values.startMonth)
            ? {
                value3: Utils.removePrecisionAndGetCommas(values.startMonth.value),
                label3: '$',
            }
            : {};

        return (
            <DetailedAccordion
                key={'remainder'}
                collapsible={false}
                heading={remainderText}
                value={Utils.removePrecisionAndGetCommas(values.lastYear.value)}
                label="$"
                value2={Utils.removePrecisionAndGetCommas(values.thisYear.value)}
                label2="$"
                {...startMonthProps}
            />
        );
    }

    finaliseManualData() {
        let newItems = [];
        let itemsToDelete = [];
        let itemsToUpdate = [];

        //allocate revenue from remainder
        const revenueCat = TagCategoryStore.getTagForCode(TagCode.IS_Revenue_Revenue);
        const otherRevenueCat = TagCategoryStore.getTagForCode(TagCode.IS_Revenue_Other);
        const revenueTags = this.getAccountNumbersForTagCode(TagCode.IS_Revenue_Revenue);
        const revenueItems = revenueTags.map(tag => this.getAccountItemMultiYear(tag.account_number))
            .filter(items => items != null);
        const interestRemainder = this.getRevenueRemainder();
        const revenueRemainder = this.getRemainders(interestRemainder, revenueItems);

        if (revenueItems.length === 0 || revenueRemainder.lastYear.value != 0 || revenueRemainder.thisYear.value != 0) {
            const tag = revenueItems.length === 0 ? revenueCat : otherRevenueCat;
            const revenueNumber = Utils.generateUuid();
            const newRevenueItems = [{ month: this.LastYearMonth, remainder: revenueRemainder.lastYear },
                { month: this.ThisYearMonth, remainder: revenueRemainder.thisYear }]
                .map(year =>
                    new AccountItem({
                        account_number: revenueNumber,
                        name: 'Revenue',
                        value: year.remainder.value,
                        tag_id: tag.id,
                        month_id: year.month.id
                    })
                );
            newItems = newItems.concat(newRevenueItems);
        }

        //add automatically allocated other indirect costs
        const otherIndirectCostsCat = TagCategoryStore.getTagForCode(TagCode.IS_IndirectCosts_Other);

        const otherIndirectCostsItems = this.getAccountNumbersForTagCode(TagCode.IS_IndirectCosts_Other)
            .filter(tag => tag.account_number != SpecialAccountItems.TotalOtherIndirectCosts.Number)
            .map(tag => this.getAccountItemMultiYear(tag.account_number))
            .filter(item => item != null);

        const otherIndirectCostsSubtotalItems = this.getOtherIndirectCostsSubtotal();
        const unallocatedIndirectCosts = this.getRemainders(otherIndirectCostsSubtotalItems, otherIndirectCostsItems);
        if (unallocatedIndirectCosts.lastYear.value != 0 ||
            unallocatedIndirectCosts.thisYear.value != 0) {
            const newIndirectCostsItems =
                [{ month: this.LastYearMonth, remainder: unallocatedIndirectCosts.lastYear },
                    { month: this.ThisYearMonth, remainder: unallocatedIndirectCosts.thisYear }]
                    .map(year =>
                        new AccountItem({
                            account_number: SpecialAccountItems.AutomaticallyAllocated.Number + otherIndirectCostsCat.code,
                            name: makeUnspecifiedRemainderName(TagCategoryName.IndirectCosts),
                            value: year.remainder.value,
                            tag_id: otherIndirectCostsCat.id,
                            month_id: year.month.id
                        })
                    );
            newItems = newItems.concat(newIndirectCostsItems);
        }

        const totalMiscTag = TagCategoryStore.getTagForCode(TagCode.IS_Misc_TotalMisc);
        const otherMiscTag = TagCategoryStore.getTagForCode(TagCode.IS_Misc_Other);
        const totalMisc = this.getAccountItemMultiYear(totalMiscTag.name);
        const calculatedTotalMisc = this.getCalculatedMiscTotals();

        totalMisc.lastYear.value = calculatedTotalMisc.lastYear.value;
        totalMisc.thisYear.value = calculatedTotalMisc.thisYear.value;
        itemsToUpdate = itemsToUpdate.concat([totalMisc.lastYear, totalMisc.thisYear]);

        const miscTags = TagCategoryStore.getTagCategoryByName(TagCategoryName.Misc)
            .tags.filter(tag => tag.tag_id != totalMiscTag.id);

        const miscItems = miscTags.map(tag => this.getAccountNumbersForTagCode(tag.code))
            .reduce((prev, curr) => prev.concat(curr), [])
            .map(ant => this.getAccountItemMultiYear(ant.account_number))
            .filter(item => item != null);

        const miscRemainder = this.getRemainders(totalMisc, miscItems);

        if (miscRemainder.lastYear.value != 0 ||
            miscRemainder.thisYear.value != 0) {
            const newMisc =
                [{ month: this.LastYearMonth, remainder: miscRemainder.lastYear },
                    { month: this.ThisYearMonth, remainder: miscRemainder.thisYear }]
                    .map(year =>
                        new AccountItem({
                            account_number: SpecialAccountItems.NetProfitRemainder.Number,
                            name: SpecialAccountItems.NetProfitRemainder.Name,
                            value: year.remainder.value,
                            tag_id: otherMiscTag.id,
                            month_id: year.month.id
                        })
                    );
            newItems = newItems.concat(newMisc);
        }

        const otherDistributionsTag = TagCategoryStore.getTagForCode(TagCode.IS_Distributions_Other);
        const distributionsTag = TagCategoryStore.getTagForCode(TagCode.IS_Distributions_Distributions);
        const totalDistributions = this.getSubcategoryAccountItems(TagCategoryStore.getTagForCode(TagCode.IS_Distributions_TotalDistributions));

        const distributionsAccountNumber = Utils.generateUuid();
        const newDistributions = [{ month: this.LastYearMonth, value: totalDistributions.lastYear },
            { month: this.ThisYearMonth, value: totalDistributions.thisYear }]
            .map(year =>
                new AccountItem({
                    account_number: distributionsAccountNumber,
                    name: 'Distributions',
                    value: year.value.value,
                    tag_id: distributionsTag.id,
                    month_id: year.month.id,
                })
            );
        newItems = newItems.concat(newDistributions);

        const retainedEarnings = this.getSubcategoryAccountItems(TagCategoryStore.getTagForCode(TagCode.BS_TotalEquity_RetainedEarnings));
        const netProfit = this.getAccountItemMultiYear(SpecialAccountItems.NetProfit.Number);

        //calculated current period change in retained earnings
        const profitLessDistributions = this.getRemainders(netProfit, [totalDistributions]);
        //calculated cross-period change in retained earnings
        const differenceInRetainedEarningAcrossPeriods = new MultiPeriodValues(
            retainedEarnings.thisYear.value - retainedEarnings.lastYear.value,
            retainedEarnings.lastYear.value - retainedEarnings.startMonth.value,
        );

        if (!this.equal(profitLessDistributions, differenceInRetainedEarningAcrossPeriods)) {
            const distributionsRemainder = this.getRemainders(profitLessDistributions, [differenceInRetainedEarningAcrossPeriods]);
            const newOtherDistributions = [
                {
                    month: this.LastYearMonth,
                    remainder: distributionsRemainder.lastYear,
                    totalItem: totalDistributions.lastYear
                },
                {
                    month: this.ThisYearMonth,
                    remainder: distributionsRemainder.thisYear,
                    totalItem: totalDistributions.thisYear
                },
            ]
                .map(year => {
                    year.totalItem.value = parseInt(year.totalItem.value, 10) + parseInt(year.remainder.value, 10);
                    return new AccountItem({
                        account_number: SpecialAccountItems.RetainedEarningsRollForward.Number,
                        name: SpecialAccountItems.RetainedEarningsRollForward.Name,
                        value: year.remainder.value,
                        tag_id: otherDistributionsTag.id,
                        month_id: year.month.id,
                    });
                });
            newItems = newItems.concat(newOtherDistributions);
            itemsToUpdate = itemsToUpdate.concat([totalDistributions.lastYear, totalDistributions.thisYear]);
        }

        itemsToDelete = itemsToDelete.concat([otherIndirectCostsSubtotalItems.lastYear, otherIndirectCostsSubtotalItems.thisYear]);

        const lastYearItems = newItems.filter(item => item.month_id === this.LastYearMonth.id);
        const thisYearItems = newItems.filter(item => item.month_id === this.ThisYearMonth.id);

        return Promise.all(
            itemsToDelete.map(item => AccountItemStore.performDeleteItem(item))
        )
            .then(() => this.saveNewAccountNumberTags(itemsToUpdate.concat(lastYearItems, thisYearItems)))
            .then(() => AccountItemStore.performBatchSave(itemsToUpdate))
            .then(() => AccountItemStore.performBatchSave(lastYearItems))
            .then(() => AccountItemStore.performBatchSave(thisYearItems))
            .then(() => this.updateMonths());
    }

    getWagesAccountNumberTags(accountItems) {
        return getWagesAccountNumberTags(
            TagCategoryStore.getTagCategories(),
            AccountNumberTagsStore.getAccountNumberTags(),
            accountItems
        );
    }

    getCostsAccountNumberTags(accountItems) {
        return getCostsAccountNumberTags(
            TagCategoryStore.getTagCategories(),
            AccountNumberTagsStore.getAccountNumberTags(),
            accountItems
        );
    }

    getProgressLostPopup() {
        const onClick = () => this.setState({ popup: null });

        const okButton = (
            <button
                className="btn btn-lg btn-primary"
                onClick={() => onClick()}
            >OK</button>
        );

        const body = (
            <div>
                <h3>You disappeared for a while..</h3>
                <p> You will need to re-upload your files (and select your end dates again),
                    <br />but all tagging progress has been saved!</p>
                <div className="row">
                    <div className="col-xs-12">
                        {okButton}
                    </div>
                </div>
            </div>
        );

        return (
            <Popup>
                {body}
            </Popup>
        );
    }

    render() {
        const { accountStore } = this.props;
        if (!accountStore.isSubscribed() && !accountStore.isMasquerade()) {
            return (
                <AltContainer stores={[AsyncQueueStore]}>
                    <OnboardingFullScreenContainer
                        showSubscription
                    >
                        <WizardScreen
                            title="Subscription Error"
                        >
                            <div>
                                You don't appear to have an active subscription. To check your subscription, click 'Account'
                                (top right).
                                <br />Contact <a href="mailto:support@realtimeceo.com">support@realtimeceo.com</a> for support.
                            </div>
                        </WizardScreen>
                    </OnboardingFullScreenContainer>
                </AltContainer>
            );
        }

        if (this.props.userPermissions.historical_editing === false) {
            return (
                <AltContainer stores={[AsyncQueueStore]}>
                    <OnboardingFullScreenContainer>
                    <WizardScreen
                        onFinish={() => {
                            delete localStorage.token
                            window.location.href = `/login`
                        }}
                        finishButtonText="Log out"
                    >
                        <div>
                            <h2 className="text-center">
                                Hello {this.props.user.first_name}!<br />Welcome to RealTime CEO.
                            </h2>
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className="flex-center" style={{ paddingBottom: '160px' }}>
                                    Onboarding in currently in progress. An account administrator needs to complete this before you can access RealTime CEO.
                                </div>
                            </div>
                        </div>
                    </WizardScreen>
                    </OnboardingFullScreenContainer>
                </AltContainer>
            );
        }

        const { popup } = this.state;
        const onboardingProgress = this.getWizardScreen();
        const loadingIndicator = this.getLoadingIndicator();

        return (
            <AltContainer stores={[AsyncQueueStore]}>
                <OnboardingFullScreenContainer
                    showSubscription
                    onClickRestartWizard={
                        [
                            WizardPages.Welcome.pageId,
                            WizardPages.SelectOnboardingMethod.pageId,
                            WizardPages.Manual.SelectDate.pageId,
                            WizardPages.Import.SelectFiles.pageId,
                        ].indexOf(
                            accountStore.getAccount().OnboardingProgress
                        ) === -1 ? () => this.showOnCancelWizardPopup() : undefined
                    }
                >
                    {this.getContent(onboardingProgress)}
                    {loadingIndicator}
                    {popup || <div style={{ display: 'none' }} />}
                    {this.getTaggingErrorPopup()}
                </OnboardingFullScreenContainer>
            </AltContainer>
        );
    }
}

OnboardingWizard.propTypes = {
    onComplete: PropTypes.func.isRequired,
    showVideo: PropTypes.func.isRequired,
    accountStore: PropTypes.shape({
        performSave: PropTypes.func.isRequired,
        isSubscribed: PropTypes.func.isRequired,
        isMasquerade: PropTypes.func.isRequired,
        getAccount: PropTypes.func.isRequired,
    }).isRequired,
    userPermissions: PropTypes.shape({
        administration: PropTypes.bool.isRequired,
        historical_viewing: PropTypes.bool.isRequired,
        historical_editing: PropTypes.bool.isRequired,
        forecasting_editing: PropTypes.bool.isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired
};

export default OnboardingWizard;
