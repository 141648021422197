"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EnumsTs_1 = require("./EnumsTs");
// tag display sort order
function getTagOrder(tag) {
    if (tag.isTotal) {
        return -1;
    }
    switch (tag.code) {
        case EnumsTs_1.TagCode.IS_Revenue_Revenue: return 1;
        case EnumsTs_1.TagCode.IS_Revenue_InterestRevenue: return 2;
        // case TagCode.IS_Revenue_TotalRevenue: return
        case EnumsTs_1.TagCode.IS_Revenue_Other: return 100;
        case EnumsTs_1.TagCode.IS_Revenue_NonOperational: return 101;
        case EnumsTs_1.TagCode.IS_DirectCosts_Materials: return 1;
        case EnumsTs_1.TagCode.IS_DirectCosts_Wages: return 2;
        case EnumsTs_1.TagCode.IS_DirectCosts_Depreciation: return 3;
        // case TagCode.IS_DirectCosts_TotalDirectCosts: return 0
        case EnumsTs_1.TagCode.IS_DirectCosts_Other: return 100;
        case EnumsTs_1.TagCode.IS_DirectCosts_NonOperational: return 101;
        case EnumsTs_1.TagCode.IS_IndirectCosts_Wages: return 1;
        case EnumsTs_1.TagCode.IS_IndirectCosts_Depreciation: return 2;
        // case TagCode.IS_IndirectCosts_TotalIndirectCosts: return 3
        case EnumsTs_1.TagCode.IS_IndirectCosts_InterestExpense: return 3;
        case EnumsTs_1.TagCode.IS_IndirectCosts_Other: return 100;
        case EnumsTs_1.TagCode.IS_IndirectCosts_NonOperational: return 101;
        case EnumsTs_1.TagCode.IS_Misc_InterestRevenue: return 1;
        case EnumsTs_1.TagCode.IS_Misc_InterestExpense: return 2;
        case EnumsTs_1.TagCode.IS_Misc_Depreciation: return 3;
        case EnumsTs_1.TagCode.IS_Misc_Wages: return 4;
        // case TagCode.IS_Misc_TotalMisc: return 0
        case EnumsTs_1.TagCode.IS_Misc_Other: return 100;
        case EnumsTs_1.TagCode.IS_Misc_NonOperational: return 101;
        case EnumsTs_1.TagCode.IS_Distributions_Distributions: return 1;
        case EnumsTs_1.TagCode.IS_Distributions_Other: return 100;
        // case TagCode.IS_Distributions_TotalDistributions: return 0
        case EnumsTs_1.TagCode.IS_NetProfit: return 0;
        case EnumsTs_1.TagCode.BS_TotalAssets_BankAccountsAndCashDeposits: return 1;
        case EnumsTs_1.TagCode.BS_TotalAssets_TradeAccountsReceivableNetOfProvision: return 2;
        case EnumsTs_1.TagCode.BS_TotalAssets_InventoryNetOfProvision: return 3;
        case EnumsTs_1.TagCode.BS_TotalAssets_FixedAssetsNetOfProvision: return 4;
        case EnumsTs_1.TagCode.BS_TotalAssets_LoansToRelatedParties: return 5;
        // case TagCode.BS_TotalAssets_TotalAssets: return 0
        case EnumsTs_1.TagCode.BS_TotalAssets_Other: return 100;
        case EnumsTs_1.TagCode.BS_TotalAssets_NonOperational: return 101;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_InterestBearingDebt: return 1;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_TradeAccountsPayable: return 2;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_TaxPayable: return 3;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_DeferredPrepaidRevenue: return 4;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_LoansFromRelatedParties: return 5;
        // case TagCode.BS_TotalLiabilities_TotalLiabilities: return 0
        case EnumsTs_1.TagCode.BS_TotalLiabilities_Other: return 100;
        case EnumsTs_1.TagCode.BS_TotalLiabilities_NonOperational: return 101;
        case EnumsTs_1.TagCode.BS_TotalEquity_InitialCapital: return 1;
        case EnumsTs_1.TagCode.BS_TotalEquity_RetainedEarnings: return 2;
        // case TagCode.BS_TotalEquity_TotalEquity: return 0
        case EnumsTs_1.TagCode.BS_TotalEquity_Other: return 100;
        default: {
            console.warn('no sort order defined for tag:', tag.code);
            return 50;
        }
    }
}
exports.default = getTagOrder;
