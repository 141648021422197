"use strict";
var RevenueForecastClass = require('./RevenueForecast');

class AccountItem {
    constructor(item) {
        this.name = null;
        this.revenue_forecast = null;
        this.account_number = null;
        this.pre_split_name = null;
        this.value = 0;
        this.value_formula = null;
        this.month_id = null;
        this.id = null;
        this.tag_id = null;

        for (var key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }

    get Value() {
        const parsed = parseFloat(this.value);

        if (isNaN(parsed) || Math.abs(parsed) === Infinity) {
            return 0;
        }

        return parsed;
    }

    set Value(val) {
        const parsed = parseFloat(val);

        if (isNaN(parsed) || Math.abs(parsed) === Infinity) {
            this.value = 0;
        } else {
            this.value = parsed;
        }
    }

    add(amount) {
        this.value = this.Value + amount;
        return this;
    }

    subtract(amount) {
        this.value = this.Value - amount;
        return this;
    }

    /**
     *
     * @returns {RevenueForecastClass|null}
     */
    get RevenueForecast() {
        return new RevenueForecastClass(this.revenue_forecast);
    }

    get NetActivity() {
        return parseFloat(this.value);
    }

    set NetActivity(newValue) {
        this.value = newValue;
    }

    get Percentage() {
        if (typeof this.value_formula == "string") {
            let percent = this.value_formula.split("*").shift();
            if (percent != null) {
                var result = parseFloat(percent);

                if (isNaN(result) == false) {
                    return result;
                }
            }
        }
        return null;
    }

    get FormulaType() {
        if (typeof this.value_formula == "string" && this.value_formula.indexOf("*") > -1) {
            let type = this.value_formula.substr(this.value_formula.indexOf("*")+1);
            if (type != null && typeof type == "string" && type != "") {
                return type;
            }
        }
        return null;
    }

    /**
     * @deprecated
     * @param newValue
     */
    set Percentage(newValue) {
        this.value_formula = newValue;
    }

    /**
     * @deprecated
     * @return {*}
     */
    get MaterialPercent() {

        if (this.revenue_forecast == null) {
//            console.log("Cannot get material percent, Revenue Forecast is null");
            return;
        }

        if (typeof this.revenue_forecast.material_formula == "string") {
            let percent = this.revenue_forecast.material_formula.split("*").shift();
            if (percent != null) {
                var result = parseFloat(percent);

                if (isNaN(result) == false) {
                    return result;
                }
            }
        }
        //if unset
        return null;
    }

    /**
     * @deprecated
     * @return {*}
     */
    get MarriagePercent() {

        if (this.revenue_forecast == null) {
//            console.log("Cannot get material percent, Revenue Forecast is null");
            return;
        }

        return this.getFormulaPercent(this.revenue_forecast.marriage_formula);
    }

    /**
     * @deprecated
     * @return {*}
     */
    get MarriageFormulaType() {
        if (this.revenue_forecast == null) {
//            console.log("Cannot get marriage amount, Revenue Forecast is null");
            return;
        }

        return this.getTypeFromFormula(this.revenue_forecast.marriage_formula);
    }

    /**
     * @deprecated
     * @param formula
     * @return {*}
     */
    getFormulaPercent(formula) {
        if (typeof formula == "string") {
            let percent = formula.split("*").shift();
            if (percent != null) {
                var result = parseFloat(percent);

                if (isNaN(result) == false) {
                    return result;
                }
            }
        }
        //if unset
        return null;
    }

    /**
     * @deprecated
     * @param formula
     * @return {*}
     */
    getTypeFromFormula(formula) {
        if (typeof formula == "string") {
            let components = formula.split("*");
            if (components.length == 2) {
                return components[1];
            }
        }
        return null;
    }

    /**
     * @deprecated
     * @return {*}
     */
    get MaterialFormulaType() {
        if (this.revenue_forecast != null && typeof this.revenue_forecast.material_formula == "string" && this.revenue_forecast.material_formula.indexOf("*") > -1) {
            let type = this.revenue_forecast.material_formula.substr(this.revenue_forecast.material_formula.indexOf("*")+1,this.revenue_forecast.material_formula.length);
            if (type != null && typeof type == "string" && type != "") {
                return type;
            }
        }
        return null;
    }

    /**
     * @deprecated
     * @return {*}
     */
    get WagesPercent() {

        if (this.revenue_forecast == null) {
//            console.log("Cannot get wages percent, Revenue Forecast is null");
            return;
        }

        if (typeof this.revenue_forecast.wages_formula == "string") {
            let percent = this.revenue_forecast.wages_formula.split("*").shift();
            if (percent != null) {
                var result = parseFloat(percent);

                if (isNaN(result) == false) {
                    return result;
                }
            }
        }
        //if unset
        return null;
    }

    /**
     * @deprecated
     * @param newValue
     */
    set MarriageAmount(newValue) {
        if (this.revenue_forecast == null) {
//            console.log("Cannot set Marriage Amount, Revenue Forecast is null");
            return;
        }

        this.revenue_forecast.marriage_amount = newValue;
    }

    /**
     * @deprecated
     * @param newValue
     */
    set EngagementAmount(newValue) {
        if (this.revenue_forecast == null) {
//            console.log("Cannot set Engagement Amount, Revenue Forecast is null");
            return;
        }

        this.revenue_forecast.engagement_amount = newValue;
    }

    /**
     * @deprecated
     * @return {number}
     */
    get EngagementAmount() {
        if (this.revenue_forecast == null) {
//            console.log("Cannot get Engagement Amount, Revenue Forecast is null");
            return;
        }

        return parseFloat(this.revenue_forecast.engagement_amount);
    }

    /**
     * @deprecated
     * @param newValue
     */
    set MaterialAmount(newValue) {
        if (this.revenue_forecast == null) {
//            console.log("Cannot set Material Amount, Revenue Forecast is null");
            return;
        }

        this.revenue_forecast.material_amount = newValue;
    }

    /**
     * @deprecated
     * @param newValue
     */
    set WagesAmount(newValue) {
        if (this.revenue_forecast == null) {
//            console.log("Cannot set Wage Amount, Revenue Forecast is null");
            return;
        }

        this.revenue_forecast.wages_amount = newValue;
    }

    /**
     * @deprecated
     * @return {null}
     */
    get MaterialAmount() {
        if (this.revenue_forecast == null) {
//            console.log("Cannot get Material Amount, Revenue Forecast is null");
            return null;
        }

        return this.revenue_forecast.material_amount;
    }

    /**
     * @deprecated
     * @return {null}
     */
    get WagesAmount() {
        if (this.revenue_forecast == null) {
//            console.log("Cannot get Wage Amount, Revenue Forecast is null");
            return null;
        }

        return this.revenue_forecast.wages_amount;
    }

    /**
     * @deprecated
     * @return {*}
     */
    get MarriageAmount() {
        if (this.revenue_forecast == null) {
//            console.log("Cannot get Marriage Amount, Revenue Forecast is null");
            return null;
        }

        return parseFloat(this.revenue_forecast.marriage_amount);
    }

    set Value(newValue) {
        this.value = newValue;
    }

    /**
     * @deprecated
     * @return {AccountItem}
     */
    reCalculate() {
        var revenueForecast = this.RevenueForecast;
        if (revenueForecast == null) {
//            console.warn("Cannot recalculate account item, Revenue Forecast is null");
        }


        if (this.revenue_forecast == null){
            //not revenue or direct costs case
            return this;
        }

        const { MaterialFormula, WagesFormula, MarriageAmount, EngagementAmount, EngagementProbability } = revenueForecast;

        if (typeof MaterialFormula == 'string' && MaterialFormula.length > 0) {
            let materialFormula = MaterialFormula.split('*').shift();

            if (materialFormula == null) {
                materialFormula = 0;
            }

            materialFormula = parseFloat(materialFormula);

            // Sets the raw data.
            this.revenue_forecast.material_amount = this.revenue_forecast.material_amount * materialFormula / 100;
        }

        if (typeof WagesFormula == 'string' && WagesFormula.length > 0) {
            let wagesFormula = WagesFormula.split('*').shift();

            if (wagesFormula == null) {
                wagesFormula = 0;
            }

            wagesFormula = parseFloat(wagesFormula);

            this.revenue_forecast.wages_amount = this.revenue_forecast.wages_amount * wagesFormula / 100;
        }

        return this;
    }

    /**
     * @deprecated
     * @param value
     */
    set MaterialFormula(value) {
        if (this.revenue_forecast == null) {
            this.revenue_forecast = new RevenueForecastClass();
        }

        this.revenue_forecast.material_formula = value;
    }

    /**
     * @deprecated
     * @param value
     */
    set WagesFormula(value) {
        if (this.revenue_forecast == null) {
            this.revenue_forecast = new RevenueForecastClass();
        }

        this.revenue_forecast.wages_formula = value;
    }

//    set MarriageAmount(value) {
//        if (this.revenue_forecast == null) {
//            this.revenue_forecast = new RevenueForecastClass();
//        }
//
//        this.revenue_forecast.marriage_amount = value;
//    }
    /**
     * @deprecated
     * @param value
     */
    set EngagementAmount(value) {
        if (this.revenue_forecast == null) {
            this.revenue_forecast = new RevenueForecastClass();
        }

        this.revenue_forecast.engagement_amount = value;
    }

    /**
     * @deprecated
     * @param value
     */
    set EngagementProbability(value) {
        if (this.revenue_forecast == null) {
            this.revenue_forecast = new RevenueForecastClass();
        }

        this.revenue_forecast.engagement_probability = value;
    }

    /**
     * Checks if value_formula exists, returns true if so, else false
     * @returns Boolean
     */
    hasValueFormula() {
        return (typeof this.value_formula === 'string') && (this.value_formula.length > 0);
    }

    static generateUuid() {
        var d = new Date().getTime();

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}

module.exports = AccountItem;
