"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ForecastScreens;
(function (ForecastScreens) {
    ForecastScreens["Summary"] = "Summary";
    ForecastScreens["AspirationRevenue"] = "AspirationRevenue";
    ForecastScreens["MarriageRevenue"] = "MarriageRevenue";
    ForecastScreens["EngagementRevenue"] = "EngagementRevenue";
    ForecastScreens["Revenue"] = "Revenue";
    ForecastScreens["DirectCosts"] = "DirectCosts";
    ForecastScreens["IndirectCosts"] = "IndirectCosts";
    ForecastScreens["Miscellaneous"] = "Miscellaneous";
    ForecastScreens["Distributions"] = "Distributions";
    ForecastScreens["AccountsReceivable"] = "AccountsReceivable";
    ForecastScreens["Inventory"] = "Inventory";
    ForecastScreens["AccountsPayable"] = "AccountsPayable";
    ForecastScreens["FixedAssets"] = "FixedAssets";
})(ForecastScreens || (ForecastScreens = {}));
(function (ForecastScreens) {
    function values() {
        return [
            ForecastScreens.Summary,
            ForecastScreens.AspirationRevenue,
            ForecastScreens.MarriageRevenue,
            ForecastScreens.EngagementRevenue,
            ForecastScreens.DirectCosts,
            ForecastScreens.IndirectCosts,
            ForecastScreens.Miscellaneous,
            ForecastScreens.Distributions,
            ForecastScreens.AccountsReceivable,
            ForecastScreens.Inventory,
            ForecastScreens.AccountsPayable,
            ForecastScreens.FixedAssets,
        ];
    }
    ForecastScreens.values = values;
})(ForecastScreens || (ForecastScreens = {}));
exports.default = ForecastScreens;
