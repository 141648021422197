import alt from '../alt.ts';

/**
 * List of actions stores can subscribe to.
 */
const TagActions = alt.generateActions(
    'receivedItem',
    'loadingItem',
    'fetchingItemFailed'
);

module.exports = TagActions;
