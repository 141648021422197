"use strict";
class SonAnswer {
    constructor(item) {
        this.question_id = null;
        this.answer = null;
        this.date = null;
        for (let key in item) {
            if (this.hasOwnProperty(key)) {
                this[key] = item[key];
            }
        }
    }
}
module.exports = SonAnswer;
