class TagV1 {
    static getParentTag(childTagId, tags) {
        for (var i in tags) {
            var parentTags = tags[i];

            parentTags.forEach(ptag => {
                var foundTag = ptag.tags.filter(tag => tag.id == childTagId).shift();

                if (foundTag != null) {
                    return ptag;
                }
            });
        }

        return null;
    }

    static getTag(tagId, tags) {
        for (var i in tags) {
            var parentTags = tags[i];
            var foundTag = parentTags
                .reduce((prev, curr) => prev.concat(curr.tags), [])
                .filter(tag => tag.id == tagId).shift();

            if (foundTag != null) {
                return foundTag;
            }
        }
    }
}

class TagV2 {
    constructor() {
        this.id;
        this.name;
        this.code;
        this.category; // Derived from categories
    }

    /**
     *
     * @param code Tag Enum Value
     * @param tags All tags
     */
    static getTag(code, tags) {
        return tags.filter(tag => tag.code == code).shift();
    }
}

export default TagV1;
