import NumberFormatter from "../utilities/Number"

function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

class RevenueForecast {
    constructor(options) {
        RevenueForecast.getFields().forEach((field) => {
            if(typeof options[field] === "undefined") {
                return;
            }

            if (endsWith(field, "amount")) {
                this[field] = NumberFormatter.parseMoney(options[field]);
            }
            else {
                this[field] = options[field];
            }
        });
    }

    static defaultRevenueForecastOptions() {
        return {
            engagement_probability:null,
            material_formula:'',
            material_amount:null,
            wages_amount:null,
            marriage_formula:'',
            engagement_amount:null,
            account_item_id:null,
            marriage_amount:null,
            wages_formula:'',
            engagement_formula:'',
        }
    }

    /**
     * Re-calculates all derived fields
     */
    reCalculate() {
        this.material_amount = (this.marriage_amount * this.material_formula/100) +
            (this.engagement_amount * this.material_formula/100 * this.engagement_probability/100);

        this.wages_amount = (this.marriage_amount * this.wages_formula/100) +
            (this.engagement_amount * this.wages_formula/100 * this.engagement_probability/100);
    }

    static getFields() {
        return [
            "material_formula",
            "wages_amount",
            "engagement_formula",
            "wages_formula",
            "marriage_amount",
            "material_amount",
            "account_item_id",
            "engagement_amount",
            "engagement_probability",
            "marriage_formula",
        ];
    }

    clone() {
        return new RevenueForecast(this.cloneOptions());
    }

    cloneOptions() {
        return {
            material_formula: this.material_formula,
            wages_amount: this.wages_amount,
            engagement_formula: this.engagement_formula,
            wages_formula: this.wages_formula,
            marriage_amount: this.marriage_amount,
            material_amount: this.material_amount,
            account_item_id: this.account_item_id,
            engagement_amount: this.engagement_amount,
            engagement_probability: this.engagement_probability,
            marriage_formula: this.marriage_formula,
        };
    }
}

export default RevenueForecast;