"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSonSectionByUid = void 0;
const SonDB_1 = require("../SonDB");
function deleteSonSectionByUid(uid) {
    return (0, SonDB_1.getSonSectionCollection)().doc(uid)
        .get()
        .then((query) => {
        void query.ref.delete();
    });
}
exports.deleteSonSectionByUid = deleteSonSectionByUid;
