/* eslint-disable */
// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import TableColumn from "./TableColumn"
import TableRow from "./TableRow"
import Utils from "../utilities/Utils"
import { Fields, ImportRenamedTags } from "../models/Enums"
import TagHelper from "../datastore/helpers/TagHelper"

class TableCell extends React.Component {
    render() {
        return <div className={`cell`}>
           {this.props.children}
        </div>
    }
}

class Table extends React.Component {
    getRow(index) {
        return this.props.rows[index];
    }

    render() {
        const { rows, columns, maxRows, onRowClick, onColumnClick, highlightColumnIndex, hideFirstColumn } = this.props;
        const maximumRows = maxRows ? maxRows : rows.length;

        if (onRowClick) {
            return (
                <div className="table">
                    {
                        rows.map((rowStruct, rowIndex) => {

                            let accountNumber = rowStruct.row[0];
                            const ant = TagHelper.getAccountNumberTag(accountNumber);
                            const tagCategory = TagHelper.getTagCategory(accountNumber);

                            const tagCategoryName = tagCategory == null || tagCategory.name == null ? '' : tagCategory.name;
                            let className = Utils.toClassName(tagCategoryName);

                            let cells = rowStruct.row.map((cellText, cellIndex) => (
                                <TableCell key={cellIndex}>{cellText}</TableCell>
                            ));

                            if (hideFirstColumn) {
                                if (cells.length > 0) {
                                    cells = cells.slice(1);
                                }
                            }

                            let clickAction = () => onRowClick(rowStruct);
                            let defaultFirstColumnText = '';

                            // Header row should never be styled or have a click action
                            if (rowIndex == 0) {
                                className = '';
                                clickAction = e => true;
                                defaultFirstColumnText = 'Tag';
                            }

                            // Add first column, which is the tag value.
                            const tag = TagHelper.getTag(accountNumber);

                            //get tagName from tag if ite exists
                            //possibly replace with a different display name
                            let tagName;
                            if (tag == null) {
                                tagName = defaultFirstColumnText;
                            } else {
                                if (tag.code in ImportRenamedTags) {
                                    tagName = ImportRenamedTags[tag.code];
                                } else {
                                    tagName = tag.name;
                                }
                            }

                            cells.unshift(<TableCell key={-1} name={tagName}>{tagName}</TableCell>);

                            return (
                                <TableRow
                                    title={ant ? ant.account_number : undefined}
                                    key={rowIndex}
                                    content={cells}
                                    onClick={clickAction}
                                    className={className}
                                />
                            );
                        })
                    }
                </div>
            );
        } else if (onColumnClick) {
            if (rows.length === 0) {
                return null;
            }

            return (
                <div className="table">
                    {
                        rows[0].row.map((cellText, index) => {
                            if (index === 0 && hideFirstColumn) {
                                return null;
                            }

                            return (
                                <TableColumn
                                    key={index}
                                    column={cellText}
                                    columnIndex={index}
                                    columns={columns}
                                    getRow={(index) => this.getRow(index)}
                                    maxRows={maximumRows}
                                    onColumnClick={onColumnClick}
                                    highlightColumnIndex={highlightColumnIndex}
                                />
                            );
                        })
                    }
                </div>
            );
        }

        throw new Error('Row Click or Column Click must be defined. There can be only one');
    }
}

Table.propTypes = {
    maxRows: PropTypes.number,
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array,
    onColumnClick: PropTypes.func,
    onRowClick: PropTypes.func,
    hideFirstColumn: PropTypes.bool,
};

export default Table;